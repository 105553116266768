import React from 'react'

//helper
import {Link} from "react-router-dom"

//redux hooks
import {useAppDispatch} from "../../../redux/hooks"
import MusicIcon from '../newMusic/src/MusicIcon'
import Player from '../../../pages/main/components/eventsSection/src/Player'
import CardShareIcon from '../featuredArtists/CardShareIcon'
//redux actions
import { playSingle } from "../../../redux/data/musicPlayer/index"
import { setsharedUrl } from '../../../redux/data/artistProfile'
import UserIcon from '../newMusic/src/UserIcon'

interface props{
    title:string;
    thumbnail:string;
    file:string;
    username:string;
    count:Number;
    link:string;
    songs:any
}

const MusicElement:React.FC<props> = (props) => {
    const dispatch = useAppDispatch()
    const handleError = (event:any) => {
        event.target.src = '/assets/images/thumb_avatar-default-image.jpg'; // Replace with your default image URL
    };
    return (
        <div className="col-md-3 px-2 card music-e-card  shadow-sm" onClick={(e) => {
            e.preventDefault()
        }}>
                <div className="cover">
                   <div className='cover-wrapper'>
                    <div>
                        <img src={props.thumbnail} alt="" aria-hidden="false"  loading='lazy' onError={handleError}  />
                    </div>   

                                     
                   </div>
                   {props.songs && props.songs.length>0 &&(
                        <div className='music-play-btn'onClick={(e) => {
                            e.preventDefault()
                            console.log(props.songs)
                                dispatch(playSingle({
                                    value: props.songs
                                }))
                           
                        }}>
                    <Player/>
                 </div>
                    )} 
                </div>
                <Link to={props.link}><div className="song-name px-1 mt-2" >{props.title}</div></Link>
            <Link to={`/a/${props.username.replaceAll(' ','-').toLowerCase()}`}><div className="numbering mb-1 mt-1 px-1">By {props.username}</div></Link>
            <div className='card-share-btn' onClick={(e) => {
                e.preventDefault()
                
                dispatch(setsharedUrl(
                    `https://leerecs.com${props.link}`
                  ))
            }}
                    ><CardShareIcon></CardShareIcon></div>
            
        </div>
    )
}

export default MusicElement
