import React,{useState,useEffect} from 'react';
import TopMusic from "../../pages/main/components/eventsSection/topMusic"
import configuration from '../../config/configuration';
import "./index.scss"

export default function Suggestion(props) {
  const [style, setstyle] = useState("suggestion");
  const handleSuggestion = (type, singleMusic,changeSong) => {
   // console.log(singleMusic)
    switch (type) {
      case "playlist":
        return (
          <TopMusic
            imageUrl={`${configuration.FILE_STORAGE_LINK}${singleMusic.cover}`}
            musicUrl={singleMusic.playListId}
            artist={singleMusic.username}
            musicName={singleMusic.title}
            dataType={"playlist"}
          />
        );
      case "music":
        return (
          <TopMusic
            imageUrl={`https://s3.amazonaws.com/detroitpubliclibrary/assets/images/material-cd.jpg`}
            musicUrl={`https:/${singleMusic.file}`}
            artist={singleMusic.username}
            musicName={singleMusic.title}
            dataType={"music"}
            
          />
        );
      case "podcast":
        return (
          <TopMusic
            imageUrl={singleMusic.imageUrl}
            musicName={singleMusic.musicName}
            artist={singleMusic.artist}
            musicUrl={singleMusic.musicUrl}
            dataType={"podcast"}
            changeSong={changeSong}
          />
        );
      default:
        break;
    }
  };
  const handleName=(type)=>{
    switch (type) {
      case "playlist":
        return "Trending Playlists";
      case "music":
        return "You might also like...";
      case "podcast":
        return "Recent Podcast";
      default:
        break;
    }
  }
  const handleStyle=(type)=>{
    switch (type) {
      case "playlist":
        return setstyle("suggestions");
      case "music":
        return setstyle("suggestions");
      case "podcast":
        console.log("ready to set podcast")
        return setstyle("podcaststyle");
      default:
         break
    }
  }
  useEffect(() => {
    handleStyle(props.type);
  }, [style]);
  
  return (
    <div>
      <div id={style} style={{minHeight:"100vh", paddingLeft:"15px"}}>
        <div className="suggestions-head mt-5">
          <h6 className="suggestions-title">{handleName(props.type)}</h6>
          {/* <SuggestionToggle /> */}
        </div>
        <div className="suggestion-container">
          {props.playlistData &&
            props.playlistData.map((singleMusic, idx) => {
             // console.log(props.type)
              return <>{handleSuggestion(props.type, singleMusic,props.changeSong)}</>;
            })}
        </div>
      </div>
    </div>
  );
}
