// @ts-nocheck
import React, { useRef, useEffect } from "react";
import Notification from './Notification';
import { gql,useMutation } from "@apollo/client"
import "../notification.scss"
import NotificationCard from './NotificationCard';
import LottieWrapper from "../../../lottie/lottie";
import configuration from '../../../../config/configuration';

function useOutsideAlerter(ref) {
  const MUTATION = gql`mutation($user_id:Int!)
       {
        update_notification(user_Id:$user_id){
               desc
           }
       }`
        const [updateNotification] = useMutation(MUTATION, { errorPolicy: "all" })
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      const handleClickOutside= async (event)=> {
        const note = document.querySelector("#notification-dropdown");
        if (ref.current && !ref.current.contains(event.target)) {
          note.style.display="none"
        }
        if(ref.current && ref.current.contains(event.target)){
            note.style.display="block"
            const result= await updateNotification({
              variables:{
                user_id: parseInt(localStorage.getItem('userId')),
              }
            })
            if(result){
              console.log(result)
            }
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

const NotificationContainer:React.FC<{notification:any}> = ({notification}) => {
    const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
        
          const countNotification=()=>{
            let count = 0;

           for (let i = 0; i < notification.length; i++) {
                if (!notification[i].seen) {
                count++;
             }
              }
              // console.log(count)
              return count
          }
    return (
        <div id="notification" className='notification-icon mr-3'ref={wrapperRef} >
            {notification&&countNotification()!==0&& (<span className="notification-number">{countNotification()}</span>)}
            
            <Notification/>
        <div className="dropdown-menu animated" id="notification-dropdown">
                      <div className="dropdown-header">
                        <span className="triangle"></span>
                        <span className="heading">Notifications</span>
                        {notification&&countNotification()!==0&&(<span className="count" id="dd-notifications-count">{countNotification()}</span>)}
                        
                      </div>
                      <div className="dropdown-body">
                        {notification && notification.map((item:any)=>(
                        <NotificationCard 
                        img={`${configuration.FILE_STORAGE_LINK}${item.userProfile}`} 
                        name={item.username}
                        message={item.message}
                        type={item.type}
                        music={item.music}
                        user_id={item.user_id}
                        buddie_list={item.buddie_list}
                        owner_name={item.owner_name}
                        date={item.date}
                        seen={item.seen}
                        is_verified={item.is_verified}
                        />
                        ))}
                        
                        {notification && notification.length===0&&(<div>
                          <LottieWrapper url="https://assets3.lottiefiles.com/packages/lf20_HDck25.json" width="100% " height="34vh" />
                          <div className="text-white no-note-nav-msg">No notifications at this time, go ahead and interact with the community.</div>
                          </div>
                        )}

                      </div>
                    </div>
        </div>
    );
}

export default NotificationContainer;
