
import {useQuery,gql} from "@apollo/client"
//components
import NewMusic from "../../components/musicPageComponents/newMusic/index";
import Component from "../../components/musicPageComponents/allMusicInPlaylist/Component";
import { useAppSelector } from "../../redux/hooks";
import MusicFooter from "../main/components/footerSection/MusicFooter";
import { useEffect,useState } from "react";
import { useAppDispatch } from "../../redux/hooks";
import { setShowPayWall } from "../../redux/data/uiData";

const AllMusic = () => {
  const { isThumbnail } = useAppSelector((state) => state.uiData);
  const [isUser,setIsUser]=useState(parseInt(localStorage.getItem("userId") as string))
  const [scrollCount,setScrollCount] = useState(1)
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [scrollDirection, setScrollDirection] = useState('down');
  const dispatch=useAppDispatch();
  const QUERY =gql`query{
     AllMusic{
        title
        thumbnail
        file
        username
        musicId
        imageId
        memberId
        topicId
        anonymous
        messageId
        color_name
    }
}`
   //loadind query
    const { loading, data, error } = useQuery(QUERY)
    //console.log(document.getElementById('scroller'))
    useEffect(() => {
      const handleScroll = () => {
        const currentScroll = window.pageYOffset || document.documentElement.scrollTop;
        if (currentScroll > lastScrollTop) {
          setScrollDirection('down');
        } else {
          setScrollDirection('up');
        }
    
        setLastScrollTop(currentScroll <= 0 ? 0 : currentScroll);
        // Show modal when scrolling down
        if (!isUser&&scrollDirection === "down" && (window.scrollY >= (scrollCount * window.innerHeight) - 1)) {
          setScrollCount(scrollCount + 1);
          dispatch(setShowPayWall(true));
        }
    
        // Show modal when scrolling up
        if (!isUser&&scrollDirection === "up" && (window.scrollY <= ((scrollCount - 1) * window.innerHeight) + 1)) {
          setScrollCount(scrollCount > 1 ? scrollCount - 1 : 1); // Ensure scrollCount does not go below 1
          dispatch(setShowPayWall(true));
        }
      };
      
      document.getElementById('scroller')?.addEventListener('scroll', handleScroll);
    
      return () => document.getElementById('scroller')?.removeEventListener('scroll', handleScroll);
    }, [dispatch, isUser, lastScrollTop, scrollCount, scrollDirection]);
  return (
    <>
    {isThumbnail  && (<NewMusic data={data?.AllMusic} loading={loading} searchResult={false} error={error} allMusic={true} addMargin={true}></NewMusic>)}
      {!isThumbnail && (<Component data={data?.AllMusic} loading={loading} error={error}/>)}
      {data && <MusicFooter/>}
    </>
  );
};

export default AllMusic;
