import React from 'react'
import SignUp from '../../components/signup'

const SignUpPage = () => {
    return (
        <div>
            <SignUp />
        </div>
    )
}

export default SignUpPage
