// @ts-nocheck
import React, { useEffect, useState, useRef } from 'react';
import './index.scss';

function GoogleAds({ adwidth, adheight }:any) {
  const [info, setInfo] = useState([]);
  const [count, setCount] = useState(0);
  const intervalIdRef = useRef(null);

  useEffect(() => {
    async function getMoviesFromApi() {
      try {
        let response = await fetch(
          
        );
        let responseJson = await response.json();
        console.log(responseJson.adSense)
        setInfo(responseJson.adSense);
      } catch (error) {
        console.error(error);
      }
    }

    if (info.length === 0) {
      getMoviesFromApi();
    }

    if (info) {
      let i = 0;
      intervalIdRef.current = setInterval(() => {
        if (i === info.length - 1) {
          i = 0;
        } else {
          i++;
        }
        setCount(i);
      }, 5000);
    }

    return () => clearInterval(intervalIdRef.current);
  }, [info]);

  return (
    <div>
      {info.length !== 0 && (
        <a href={info[count].url} target="_blank" rel="noreferrer">
          <div
            className="ad-card"
            style={{ backgroundImage: `url(${info[count].link})` }}
          ></div>
        </a>
      )}
    </div>
  );
}

export default GoogleAds;