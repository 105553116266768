// @ts-nocheck
import React from 'react'
import { useParams } from 'react-router-dom'
import AllMusicsInPlaylist from "../../components/musicPageComponents/allMusicInPlaylist/index"
import MusicFooter from '../main/components/footerSection/MusicFooter'
import "./index.scss"


const AllMusicInPlaylist = (props: any) => {
    const {playlist}=useParams();
    return (
       <>
       <AllMusicsInPlaylist playlistId={playlist}></AllMusicsInPlaylist>
       {/* <MusicFooter/> */}
       </>
                            
                            
                    
    )
}

export default AllMusicInPlaylist
