import React from 'react'

const Favorite = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22.454" height="20" viewBox="0 0 22.454 20">
      <g id="favorite" transform="translate(-1 -3.002)">
        <path id="Path_75" data-name="Path 75" d="M21.6,4.86a6.354,6.354,0,0,0-8.975,0q-.206.206-.394.435c-.125-.152-.257-.3-.394-.434a6.346,6.346,0,1,0-8.976,8.973l9.025,9.024a.488.488,0,0,0,.69,0L21.6,13.835a6.352,6.352,0,0,0,0-8.975Z" transform="translate(0 0)" fill="#ff3456" />
      </g>
    </svg>

  )
}

export default Favorite
