import React from 'react'

const Statistics = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="26.557" viewBox="0 0 30 26.557">
      <g id="statistics" transform="translate(-2.968 -6.33)">
        <rect id="Rectangle_146" data-name="Rectangle 146" width="4.918" height="7.869" rx="1" transform="translate(3.461 25.018)" fill="#fff" />
        <rect id="Rectangle_147" data-name="Rectangle 147" width="5.901" height="7.869" rx="1" transform="translate(19.198 25.018)" fill="#fff" />
        <rect id="Rectangle_148" data-name="Rectangle 148" width="4.918" height="16.721" rx="1" transform="translate(11.329 16.166)" fill="#fff" />
        <rect id="Rectangle_149" data-name="Rectangle 149" width="4.918" height="16.721" rx="1" transform="translate(28.05 16.166)" fill="#fff" />
        <path id="Path_76" data-name="Path 76" d="M10.946,15.184,6.991,19.138a2.593,2.593,0,1,0,.731.731l3.955-3.953a3.627,3.627,0,0,1-.732-.732Z" transform="translate(0 -4.431)" fill="#fff" />
        <circle id="Ellipse_14" data-name="Ellipse 14" cx="2.459" cy="2.459" r="2.459" transform="translate(11.329 6.33)" fill="#fff" />
        <path id="Path_77" data-name="Path 77" d="M39.669,15.184l-3.954,3.954a2.567,2.567,0,0,0-2.846,0l-3.952-3.954a3.627,3.627,0,0,1-.732.732l3.954,3.954a2.585,2.585,0,1,0,4.308,0L40.4,15.916a3.628,3.628,0,0,1-.732-.732Z" transform="translate(-12.18 -4.431)" fill="#fff" />
        <circle id="Ellipse_15" data-name="Ellipse 15" cx="2.459" cy="2.459" r="2.459" transform="translate(28.05 6.33)" fill="#fff" />
      </g>
    </svg>

  )
}

export default Statistics
