import React from 'react'

export default function Search() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15.811" height="15.811" viewBox="0 0 15.811 15.811">
      <g id="search" transform="translate(-4.25 -4.25)">
        <path id="Path_30" data-name="Path 30" d="M15.5,15.5,19,19" fill="none" stroke="#bfbfbf" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
        <path id="Path_31" data-name="Path 31" d="M5,11a6,6,0,1,0,6-6,6,6,0,0,0-6,6Z" fill="none" stroke="#bfbfbf" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      </g>
    </svg>

  )
}
