import './WorkingsCard.scss'

function WorkingsCard(props) {

    return (
        <div className='workings-card-wrapper'>
            <img className='background-image' src={props.backgroundImage} alt="icon" />
            <h1>{props.id}</h1>
            <div className="workings-icon">
                <img src={props.icon} alt="icon" />
            </div>
            <h5>Define the sound</h5>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid reiciendis quas impedit fugiat obcaecati suscipit praesentium maiores. Similique in distinctio aliquid iusto fugit eum, natus quam aspernatur, adipisci quas maiores? Quidem laboriosam saepe nam dolor veniam, consectetur odio. Soluta, natus.</p>
        </div>
    )
}

export default WorkingsCard
