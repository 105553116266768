import React from 'react'

//helper
import {Link} from "react-router-dom"

//redux hooks
import {useAppDispatch} from "../../../redux/hooks"
import Player from '../../../pages/main/components/eventsSection/src/Player'
//redux actions
import { playSingle } from "../../../redux/data/musicPlayer/index"
import { setShowAlbumConfig, } from '../../../redux/data/uiData'
import { setAlbumData } from '../../../redux/data/uiData'

interface props{
    title:string;
    thumbnail:string;
    vendor:string;
    owner:boolean
    published:boolean
    url:string;
    price?:string

}

const ProductElement:React.FC<props> = (props) => {
    const dispatch = useAppDispatch()
    return (
        <div className="col-md-3 px-2 card music-e-card  shadow-sm" onClick={(e) => {
            e.preventDefault()
        }}>
                <div className="cover" style={{minHeight:'20vh'}}>
                   <div className='cover-wrapper'>
                    <div>
                        <img src={props.thumbnail} alt="" aria-hidden="false"  loading='lazy' style={{ objectFit: "contain" }} />
                    </div>  
                   </div>
                </div>
                <Link to={`/a/${props.vendor.replaceAll(' ','-').toLowerCase()}`}><div className="song-name px-1 mt-2" >{props.title}</div></Link>
            <Link to={`/a/${props.vendor.replaceAll(' ','-').toLowerCase()}`}><div className="numbering mb-1 mt-1 px-1">By {props.vendor}</div></Link>
            {props.published && (<div className='text-white mb-1'> $ {parseFloat(props.price||"0").toFixed(2)} </div>)}
            {(props.owner &&props.published)&&( <div className="text-white border rounded px-2 text-center " style={{cursor:'pointer'}}
            onClick={()=>{
                dispatch(setShowAlbumConfig(true))
                dispatch(setAlbumData({
                   albumName:props.title,
                   owner:props.vendor,
                   url:`https://leerecs.com/music/album/${props.title.replaceAll(' ','-').toLowerCase()}`,
                   message:'Wants to remove this album from the shop',
                   action:'REMOVE'
                }))
   
               }}
            >Remove from shop </div>)}
            {(props.owner &&!props.published)&&( <div className="text-white border rounded px-2 text-center " style={{cursor:'pointer'}} onClick={()=>{
             dispatch(setShowAlbumConfig(true))
             dispatch(setAlbumData({
                albumName:props.title,
                owner:props.vendor,
                url:`https://leerecs.com/music/album/${props.title.replaceAll(' ','-').toLowerCase()}`,
                message:'Wants to add his album to the shop',
                action:'ADD'
             }))

            }} >Add to shop </div>)}

            {(!props.owner &&props.published)&&(<a href={props?.url} onClick={() => {
    // Navigate to the specified URL
    window.open(props?.url, '_blank', 'noopener,noreferrer');
  }}  className='text-white border rounded px-2 text-center' > Buy </a>)}
          
        </div>
    )
}

export default ProductElement
