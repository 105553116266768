import {useState,useMemo}from "react";
import { gql,useMutation,useQuery } from "@apollo/client"

import "./index.scss"
import ProductElement from "../ArtistPlaylist/ProductElement";
import configuration from "../../../config/configuration";
import { useAppDispatch } from "../../../redux/hooks";
import { setShowAlbumConfig } from "../../../redux/data/uiData";
import { setAlbumData } from "../../../redux/data/uiData";
import Rating from '@mui/material/Rating';
import Box from '@mui/material/Box';
import StarIcon from '@mui/icons-material/Star';

const ArtistShop =(props:any)=>{
    const dispatch = useAppDispatch()

    const MUTATION = gql`mutation($shopData:albumToShopData!)
    {
        ClaimShop(shopData:$shopData){
            desc
        }
    }`

    const ALBUMQUERY = gql`query{
        AllAlbumByUser(userId:${props.musician}){
            title
            cover
            albumId
            username
        }
    }`
    
     const [ClaimShop, { loading, data, error}] = useMutation(MUTATION, { errorPolicy: "all" })
     const handleClaim=(e:any)=>{
        e.preventDefault()
        dispatch(setAlbumData({
            albumName:'',
            owner:props.username,
            url:`https://leerecs.com/a/${props.username.replaceAll(' ','-').toLowerCase()}`,
            message:'is requesting a shop on leerecs shop',
            action:'CLAIM',
            userId:props.musician
         }))
        dispatch(setShowAlbumConfig(true))
     }
     const handleRequest=(e:any)=>{
        e.preventDefault()
        dispatch(setAlbumData({
            albumName:'',
            owner:props.username,
            url:`https://leerecs.com/a/${props.username.replaceAll(' ','-').toLowerCase()}`,
            message:'Listener is rquesting a shop on leerecs.',
            action:'REQUEST',
            userId:props.musician,
            requester:localStorage.getItem("userId"),
         }))
        dispatch(setShowAlbumConfig(true))
     }
     const handleActivityRequest=(e:any)=>{
        e.preventDefault()
        dispatch(setAlbumData({
            albumName:'',
            owner:props.username,
            url:`https://leerecs.com/a/${props.username.replaceAll(' ','-').toLowerCase()}`,
            message:'Listener is rquesting a shop on leerecs.',
            action:'REQUEST-ACTIVITY',
            userId:props.musician,
            requester:localStorage.getItem("userId"),
         }))
        dispatch(setShowAlbumConfig(true))
     }

     const {  data:albumData } = useQuery(ALBUMQUERY)
    const unPublishedAlbums= useMemo(()=>{
        const existingProducts = props.products?.map((product:any)=>product.name.toLowerCase())||[]
        return albumData?.AllAlbumByUser?.filter((item:any) => !existingProducts.includes(item.title.toLowerCase()))
    },[albumData?.AllAlbumByUser, props.products, props.status])
    
    const [claim,setClaim]=useState(props.status)
    return(
        <div id="single-artist-albums mb-2 ">
            <div id="shop">
                {(!props.owner||props.isAdmin)&&claim==='N'&&(
                     <Box
     
                     >
                       <Rating
                         name="simple-controlled"
                         size="large"
                         value={(props.shop_requests.split(",").length/10)*5}
                         emptyIcon={<StarIcon style={{ opacity: 1,color:"#fff" }} fontSize="inherit" />}
                        
                       />
                       <Box style={{color:"#fff"}}>{props.shop_requests.split(",").length} of 10 requests required</Box>
                     </Box>
                )}
           
            {props.owner&&(
                <>
                {claim==='Y' && (<p>
                <h2>Start selling your music on Leerecs</h2> 
                <p>Add your existing albums or create a new one to put up for sale on the Leerecs shop</p> 
            </p>)}
            {claim==='P' && (<p>
                 <p>Your shop has already been requested, our team will get back to you soon.</p> 
            </p>)}

           {claim==='N'&&<>
            <p>
                <h2>You can now sell your music on Leerecs

</h2><p>Request adding your shop onto your profile now to start adding your albums on the Leerecs shop.</p> 
            </p>
           <div className="claim-shop-btn" onClick={handleClaim}>Request Shop</div></>}
        
                </>
            )}

            {!props.owner&&claim==='N'&&!props.shop_requests.split(",").includes(localStorage.getItem("userId"))&&(
                <>
                <p>
                <h2>If you want this artist to sell their music on Leerecs
</h2><p>Request adding their shop onto their profile now to start adding their albums on the Leerecs shop.</p> 
            </p>
           <div className="claim-shop-btn" onClick={handleRequest}>Request Shop</div></>
                

            )}

{props.products&&props.products.length===0&&!props.owner&&claim==='Y' && (<>
                <h2>Invite this Artist to sell their music on Leerecs</h2> 
                <p>They can add their existing albums to start selling Cassettes, CDs and Digital on the Leerecs shop</p> 
                <div className="claim-shop-btn" onClick={handleActivityRequest} >Request Activity</div>
            </>)}

          
            </div>
            
            {((props.products||unPublishedAlbums)&&(claim==='Y'))&&( 
                <>
                <div className={`row card-deck wrap `}>
                {props.products?.map((product:any)=>(<ProductElement
                title={product.name}
                vendor={product.vendor}
                thumbnail={product.image}
                owner={props.owner}
                published={true}
                price={product.price}
                url={product.url}
                />))}

                {
                   props.owner&& unPublishedAlbums?.map(
                        (album:any)=>(
                            <ProductElement
                title={album.title}
                vendor={album.username}
                thumbnail={`${configuration.FILE_STORAGE_LINK}${album.cover}`}
                owner={props.owner}
                published={false}
                url=''
                
                
                />
                        )
                    )
                }
{/* {
    props.owner&&(<div className="col-md-3 px-2 card music-e-card  shadow-sm justify-content-center align-items-center"  onClick={(e:any)=>{
        e.preventDefault()
        dispatch(setAlbumState('new'))
        dispatch(album())}}
        >
<div className="border d-flex rounded-circle justify-content-center align-items-center" style={{width:'50px', height:'50px'}}>
<svg xmlns="http://www.w3.org/2000/svg" stroke="#f8107d"fill='#f8107d' viewBox="0 0 448 512" width={24}><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/></svg>
</div>

</div>)
} */}

                
                </div>
                <div> </div>
                </>
            )}
            
            </div>
    )
}
export  default  ArtistShop; 