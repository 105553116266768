import React from 'react'

//helpers
import { gql, useQuery } from "@apollo/client"

//tsx components
import SingleActivity from "./SingleActivity"

//svg icons
import ActivityIcon from "./src/ActivityIcon"
import MusicIcon from "./src/MusicIcon"

//scss files
import "./index.scss"

import configuration from '../../../config/configuration'
import ReactHtmlParser from 'react-html-parser';


const Activity = () => {
    const QUERY = gql`query{
    Notifications(userId:${localStorage.getItem("userId")}){
        userProfile
        message
        music{
            title
            thumbnail
            desc
            file
            musicId
            username
            imageId
            messageId
            topicId
            memberId
        }
    }
    }`

    const { loading, data, error } = useQuery(QUERY/*, { pollInterval: 500 }*/)
    return (
        <div id="activity">
            <div className="activity-wrapper">
                <div className="activity">
                    <div className="d-flex justify-content-between">
                        <ActivityIcon />
                        <div className="title">Activity</div>
                        <div></div>
                    </div>
                </div>
                <div className="all-activity">
                    <div className="d-flex justify-content-between titles">
                        <div className="period">Earlier</div>
                        {/* <div className="show-more">Show More</div> */}
                    </div>
                    <div className="all-activity-list">
                        {data && data.Notifications.map((key: any, idx: any) => {
                            return (<SingleActivity
                                userImage={`${configuration.FILE_STORAGE_LINK}${key.userProfile}`}
                                text={<div>{ReactHtmlParser(key.message)}</div>}
                                action={<MusicIcon
                                    music_file={key.music.musicId}
                                    music_thumbnail={key.music.imageId}
                                    music_artist={key.music.memberId}
                                    topic_id={key.music.topicId}
                                    title={key.music.title}
                                    thumbnail={`${configuration.FILE_STORAGE_LINK}${key.music.thumbnail}`}
                                    file={`${configuration.FILE_STORAGE_LINK}${key.music.file}`}
                                    username={key.music.username}
                                    count={(idx + 1).toLocaleString('en-US', {
                                        minimumIntegerDigits: 2,
                                        useGrouping: false,
                                    })}
                                />}
                            />)
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Activity
