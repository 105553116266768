// @ts-nocheck
import React, { useState,useEffect,useRef } from 'react'

//helpers
import { useQuery, gql } from "@apollo/client"
import Parser from "rss-parser";

//redux hooks
import { useAppDispatch } from "../../../redux/hooks"

//redux actions
import { playSingle } from "../../../redux/data/musicPlayer/index"
import { setsharedUrl } from "../../../redux/data/artistProfile/index"
import { playlist,setCurrentPlaylist,setPlaylistState } from '../../../redux/data/uiData';
//icons
import PlayMusicIcon from "./src/PlayMusicIcon"
import axios from 'axios';
//prelaoders
import HeaderPreloader from "../headerPreloader/index"
import SingleMusicPreloader from "../singleMusicPreloader"

import MusicElement from "./MusicElement"
import PodcastMusicElement from "./PodcastMusicElement"

import "./index.scss"

//configuration
import configuration from '../../../config/configuration'
import ShareIcon from './src/ShareIcon'
import PlaylistInfo from './PlaylistInfo';
import MusicEmptyCase from '../../../pages/Emptycase/MusicEmptyCase';
import MusicFooter from '../../../pages/main/components/footerSection/MusicFooter';
import BigLoader from '../../signup/src/icons/bigLoader';

interface props {
    playlistId: Number|String;
}
const AllMusicsInPlaylist: React.FC<props> = (props) => {
    const dispatch = useAppDispatch()
    //state that will contain music data array
    let musicData: any = []
    const[isAdmin,setIsAdmin]=useState(false)

    const isUser=parseInt(localStorage.getItem("userId") as string)
    // const [musics, setmusics] = useState([])
    const[Header,setHeader]=useState("")

    const QUERY = gql`query{
    AllMusicInPlaylist(playlistId:"${props.playlistId.replaceAll('-',' ')}"){
        title
        thumbnail
        desc
        file
        musicId
        username
        messageId
        topicId
        userId
        color_name
    }
}`
    const { loading, data, error } = useQuery(QUERY/*, { pollInterval: 500 }*/)

    //query to get playlist data
    // console.log(props.playlistId)
    const PLAYLISTDATA = gql`query{
    SinglePlaylistData(playListId:"${props.playlistId.replaceAll('-',' ')}"){
        title
	    desc
	    cover
        coverId
        playListId
        userId
    }
    }`

    const { loading: loadindInfo, data: playlistInfo, error: playlistError } = useQuery(PLAYLISTDATA/*, { pollInterval: 500 }*/)
    const ref = useRef([]);
    const [podcast,setPodcast]=useState([])
    useEffect(() => {
        
            
        const resultFunc = async () => {
            const listOfMusic = async () => {
              let parser = new Parser();
              const CORS_PROXY = "https://proxy.leerecs.com/?url=";
              let feed = await parser.parseURL(
                CORS_PROXY + "https://www.spreaker.com/show/3073366/episodes/feed"
              );
              if (feed) {
                feed.items.forEach((item, idx) => {
                    if(idx===0){
                        setHeader(item.itunes.image)
                    }
                  ref.current.push({
                    imageUrl: item.itunes.image,
                    musicUrl: item.enclosure?.url,
                    artist: "leerecs",
                    musicName: item.title,
                    description: item.contentSnippet,
                  });

                });
                setPodcast(ref.current)
                return ref.current;
              }
            };
      
            const title = (id) => {
              for (let index = 0; index < ref.current.length; index++) {
                const element = ref.current[index];
                if(id){
                if (element.musicName === id) {
                  return element;
                }
              }
              else{
                return ref.current[0]
              }
            }
            };
      
            const result = await listOfMusic();
            // if (result) {
            // //   const details = title(id);
            // //   setstate({
            // //     ...details,
            // //     title: details.musicName,
            // //     description: details.description,
            // //     musicUrl: details.musicUrl,
            // //     listOfPoscast: ref.current,
            // //   });
            // //   dispatch(setMusicProfile(
            // //    { value:{
            // //       title:details.musicName,
            // //       description: details.description,
            // //       imageUrl:details.imageUrl
      
            // //     }}
            // //   ))
            // //   setimage(details.imageUrl);
            // //   setStatus("success")
            // }
          };
          //if (!state.listOfPoscast.length){
            if(props.playlistId==="sundaycoffee")
          {  resultFunc()
                }
    }, [])

    useEffect(()=>{
        const isAdmin= async()=>{
        const form = new FormData();
        form.append('userId',parseInt(localStorage.getItem("userId")));
        const result = await axios.post(`${configuration.BACK_END_HOST}/isadmin`, form)
        if(result.data.admin){
            setIsAdmin(result.data.admin)
        }
      }
      isAdmin()
      },[])

    
    return (
        <>
        {loadindInfo&&props.playlistId !=="sundaycoffee"&&(
            <div id="all-music-in-playlist">
            {loadindInfo && <HeaderPreloader />}
            {loading && (<SingleMusicPreloader />)}
            </div>
        )}
        {(!loadindInfo||props.playlistId ==="sundaycoffee")&&(
            <>
            <div id="all-music-in-playlist">
                {loadindInfo && <HeaderPreloader />}
                {playlistInfo && (
                    <PlaylistInfo
                    musicData={musicData}
                     cover={playlistInfo.SinglePlaylistData.cover} 
                     title={playlistInfo.SinglePlaylistData.title} 
                     desc={playlistInfo.SinglePlaylistData.desc}
                     playlistId={playlistInfo.SinglePlaylistData.playListId}
                     coverId={playlistInfo.SinglePlaylistData.coverId}
                     userId={playlistInfo.SinglePlaylistData.userId}
                    />
                    
                )}
                {!loadindInfo&&props.playlistId ==="sundaycoffee"&&(
                  <div className="header" style={{ backgroundImage: `linear-gradient(rgba(56, 56, 56, 0.22) 0%, #292929 100%),url("${Header}"` }}>
                  <div className="d-flex justify-content-between">
                      <div>
                          <div className="panel-title">playlist</div>
                          <div className="playlist-title">Sunday Coffee</div>
                          <div className="playlist-desc">Leerecs with friends</div>
                      </div>
                      <div className="btn-collection">
                          <div onClick={(e) => {
                              e.preventDefault()
                              dispatch(playSingle({
                                  value: musicData
                              }))
                          }}>
                              <PlayMusicIcon />
                          </div>
                          <div style={{
                              marginTop: "15px"
                          }}
                          className='share-btn'
                              onClick={(e) => {
                                  e.preventDefault()
                                  dispatch(setsharedUrl(
                                          `${window.location.href}`
                                        ))
                              }}>
                              <ShareIcon />
                          </div>
                      </div>
                  </div>
                  <div className="btn play-all-music" onClick={(e) => {
                      e.preventDefault()
                      dispatch(playSingle({value:musicData}))
                  }}>
                      Play All Music
                  </div>
              </div>
                )}
                {(props.playlistId ==="sundaycoffee" ||(data && data.AllMusicInPlaylist.length!==0)) && (
                    <div className="row mx-0 table-heading justify-content-between">
                    <div className="col-md-1 px-0">#</div>
                    <div className="col-md-6 px-0">Title</div>
                    <div className="col-md-3 px-0"></div>
                    <div className="col-md-1 px-0">React</div>
                    <div className="col-md-1 px-0">
                        <div className="d-flex justify-content-end">
                            <div></div>
                            <div>Play</div>
                        </div>
                    </div>
                    <div className="col-md-12 px-0"><hr /></div>
                </div>
                )}
                
                {loading && (<SingleMusicPreloader />)}
                {data && data.AllMusicInPlaylist.length===0 && props.playlistId !=="sundaycoffee" && playlistInfo? <div className=' center-div text-white'>
                    <h1>Empty Playlist</h1>
                    {isUser&&playlistInfo && isUser===playlistInfo.SinglePlaylistData.userId ?( <p>Add your first song.</p>)
                    : (<p>The owner of this playlist hasn't added songs yet.</p>)}
                   
                    </div>:!playlistInfo&&props.playlistId !=="sundaycoffee" ?<MusicEmptyCase/>:""}
                {data && (data.AllMusicInPlaylist.map((music: any, count: any) => {
                    
                    musicData.push({
                        name: music.title,
                        singer: music.username,
                        cover: `${configuration.FILE_STORAGE_LINK}${music.thumbnail}`,
                        musicSrc: `${configuration.READFILE_LINK}${music.file}`,
                        color:music.color_name
                    })
                    
                    return (<MusicElement
                        music_file={music.musicId}
                        recommend={data.AllMusicInPlaylist.filter((song)=>song.title!==music.title)}
                        music_thumbnail={music.imageId}
                        music_artist={music.memberId}
                        title={music.title}
                        thumbnail={`${configuration.FILE_STORAGE_LINK}${music.thumbnail}`}
                        file={`${configuration.READFILE_LINK}${music.file}`}
                        username={music.username}
                        music_id={music.topicId}
                        color_name={music.color_name}
                        count={(count + 1).toLocaleString('en-US', {
                            minimumIntegerDigits: 2,
                            useGrouping: false,
                        })}
                        userId={music.userId}
                        index={count}
                    />)
                }))}
                {/* {console.log(ref.current)} */}
                {podcast&& (podcast.map((podcast:any,count:any)=>{
                    
                    let name =podcast.musicName.split(" with ");
                    let owner=name[1]
                    let real=name[1]
                    
                    if(owner&&owner.includes("(")){
                      owner=owner.split('(')
                       real=owner[0]
                    }
                    
                        musicData.push({
                            name:name[0],
                            singer:name[1],
                            Cover:podcast.imageUrl,
                            musicScr:podcast.muiscUrl,
                        })
                        return (<PodcastMusicElement
                            music_file={0}
                            music_thumbnail={0}
                            music_artist={0}
                            title={name[0]}
                            thumbnail={podcast.imageUrl}
                            file={podcast.musicUrl}
                            username={real?real:"leerecs"}
                            music_id={0}
                            count={(count + 1).toLocaleString('en-US', {
                                minimumIntegerDigits: 2,
                                useGrouping: false,
                            })}
                            userId={0}
                            index={count}
                        />)
    
                    }))}
                    {isUser&&playlistInfo && (isUser===playlistInfo.SinglePlaylistData.userId||isAdmin) ?
                    <div className='add-songs-to-playlist'
                    onClick={(e)=>{
                        e.preventDefault();
                        dispatch(setPlaylistState(false))
                        dispatch(setCurrentPlaylist(parseInt(playlistInfo.SinglePlaylistData.playListId as string)))
                        dispatch(playlist())
                    }}
                    > Add Music</div>:("")
                    }
                    
                    <div style={{marginBottom:"80px"}}></div>
            </div>
            <MusicFooter/>
            </>
        )}
        
        </>
    )
}

export default AllMusicsInPlaylist
