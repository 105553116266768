import React from 'react'

//scss files
import "./singleActvity.scss"

interface Props {
    userImage: String;
    text: JSX.Element;
    action: JSX.Element;
}

const SingleActivity: React.FC<Props> = (props) => {
    return (
        <div id="singleActivity">
            <div className="d-flex justify-content-between single" >
                <div className="image-and-message d-flex">
                    <div
                        className="rounded-circle"
                        style={{ backgroundImage: `url("${props.userImage}")` }}
                    ></div>
                    <div className="message">{props.text}</div>
                </div>
                <div className="icon">
                    {props.action}
                </div>
            </div>
        </div>
    )
}

export default SingleActivity
