// @ts-nocheck
import React,{useEffect, useState} from 'react'

//helpers
import { useQuery, gql } from "@apollo/client"
import { useAppSelector } from '../../../redux/hooks'
import {Link,useLocation} from "react-router-dom"

//components
import MusicElement from "./MusicElement"
import ShowMoreComponent from "../showMoreComponent/index"
import LottieWrapper from '../../lottie/lottie'

//preloader
import Preloader from "./preloader"


//icons
import AllIcon from "./src/AllIcon"

import "./index.scss"

//configuration
import configuration from '../../../config/configuration'

interface props{
    allMusic?:boolean;
    searchResult?:boolean;
    addMargin?: boolean;
    userId?:Integer;
    name:String;
    songId:Integer;
}

const NewMusic: React.FC<props> = (props) => {
    const [musician,setMusician]=useState(props.musician)

     
    const QUERY =gql`query{
       RecommendedMusic(songId:${props.songId}) {
            title
            thumbnail
            file
            username
            musicId
            imageId
            memberId
            topicId
            messageId
            color_name
        }
        }`
// console.log(QUERY)
    const { searchQuery } = useAppSelector(state => state.search)
    const MUSIC_SEARCH_QUERY = gql`query{
    SearchInAllMusic(query:"${searchQuery}"){
        title
        thumbnail
        desc
        file
        musicId
        username
        imageId
        messageId
        topicId
        memberId
        likes
    }
}`

    const { loading, data, error } = useQuery(QUERY/*, { pollInterval: 500 }*/)
    const { loading: loadingSearch, data: searchData, error: searchError } = useQuery(MUSIC_SEARCH_QUERY/*, { pollInterval: 500 }*/)
    var count=0;

    
    const location=useLocation()
    const [countnumber, setcountnumber] = useState(5)
    useEffect(() => {
        function handleResize() {
            let width = window.innerWidth
            if (width <= 1024) {
                setcountnumber(4)
            }
            if (width <= 768) {
                setcountnumber(4)
            }
        }
        handleResize()
        window.addEventListener('resize', handleResize)

    }, [])
    const preloader = () => {
        const i = props.allMusic ? [0, 1, 2, 3, 4, 0, 1, 2, 3, 4, 0, 1, 2, 3, 4] : [...Array(countnumber).keys()]
        return i.map(() => {
            return (<Preloader />)
        })
    }

    const noWrap=(link:String)=>{
        if (location.pathname ===link){
            return "wrap"
        }
        else{
            return ""
        }
    }
    const searchResult=(search:any) =>{
        
        if (search.SearchInAllMusic.length !== 0){
            return search.SearchInAllMusic.map((music: any, idx: any) => {
                count++;
                if (props.allMusic) {
                    count = 0
                }
                if (count <= countnumber) {
                    return (<MusicElement
                        music_file={music.musicId}
                        music_thumbnail={music.imageId}
                        music_artist={music.memberId}
                        topic_id={music.topicId}
                        title={music.title}
                        thumbnail={`${configuration.FILE_STORAGE_LINK}${music.thumbnail}`}
                        file={`${configuration.READFILE_LINK}${music.file}`}
                        username={music.username}
                        color_name={music.color_name}
                        count={(idx + 1).toLocaleString('en-US', {
                            minimumIntegerDigits: 2,
                            useGrouping: false,
                        })} />)
                }
            })
        }
        if (search.SearchInAllMusic.length === 0){
            return (<LottieWrapper url="https://assets3.lottiefiles.com/packages/lf20_oURJje.json" width="calc(100% -  20px)" height="30vh" />)
        }
    }

    
    return ( 
        <div> 
            {!props.searchResult && (
                <div id={props.allMusic?("All-music"):("new-music")}>
                    <div className="d-flex justify-content-between">
                        <h3>{props.name}</h3>
                        {!props.allMusic && (<ShowMoreComponent title="Show All Music" link="/music/allmusic" />) }
                        
                    </div>
                    {loading && (
                        <div className={`row card-deck ${noWrap("/music/allmusic")}`}>
                            {preloader()}
                        </div>)}
                        {props.userId?(<div className={`row card-deck ${noWrap("/music/allmusic")}`}>
                            
                        {data && ( 
                            data.RecommendedMusic.map((music: any, idx: any) => {
                                count++;
                                if (props.allMusic) {
                                    count = 0
                                }
                                if (count <= countnumber) {
                                    return (<MusicElement
                                        music_file={music.musicId}
                                        music_thumbnail={music.imageId}
                                        music_artist={music.memberId}
                                        topic_id={music.topicId}
                                        title={music.title}
                                        thumbnail={`${configuration.FILE_STORAGE_LINK}${music.thumbnail}`}
                                        file={`${configuration.READFILE_LINK}${music.file}`}
                                        username={music.username}
                                        claim={music.anonymous}
                                        messageId={music.messageId}
                                        color_name={music.color_name}
                                        count={(idx + 1).toLocaleString('en-US', {
                                            minimumIntegerDigits: 2,
                                            useGrouping: false,
                                        })} 
                                        approved={music.activated}/>)
                                }
                            }))}
                            {(error) && (<LottieWrapper url="https://assets3.lottiefiles.com/packages/lf20_oURJje.json" width="calc(100% -  20px)" height="30vh" />)}
                            {(data &&(data.AllMusicByAristUserId.length === 0)) && (<LottieWrapper url="https://assets3.lottiefiles.com/packages/lf20_oURJje.json" width="calc(100% -  20px)" height="30vh" />)}
                    </div>):(<div className={`row card-deck ${noWrap("/music/allmusic")}`}>
                        {data && (
                            data.RecommendedMusic.map((music: any, idx: any) => {
                                count++;
                                if (props.allMusic) {
                                    count = 0
                                }
                                if (count <= countnumber) {
                                    return (<MusicElement
                                        music_file={music.musicId}
                                        music_thumbnail={music.imageId}
                                        music_artist={music.memberId}
                                        topic_id={music.topicId}
                                        title={music.title}
                                        thumbnail={`${configuration.FILE_STORAGE_LINK}${music.thumbnail}`}
                                        file={`${configuration.READFILE_LINK}${music.file}`}
                                        username={music.username}
                                        claim={music.anonymous}
                                        color_name={music.color_name}
                                        messageId={music.messageId}
                                        count={(idx + 1).toLocaleString('en-US', {
                                            minimumIntegerDigits: 2,
                                            useGrouping: false,
                                        })} />)
                                }
                            }))}
                    {(error) && (<LottieWrapper url="https://assets3.lottiefiles.com/packages/lf20_oURJje.json" width="calc(100% -  20px)" height="30vh" />)}
                    {(data &&(data.RecommendedMusic.length === 0)) && (<LottieWrapper url="https://assets3.lottiefiles.com/packages/lf20_oURJje.json" width="calc(100% -  20px)" height="30vh" />)}
                    </div>)}
                    
                </div>
            )}
            {
                props.searchResult && (
                    <div id="new-music">
                        <div className="d-flex justify-content-between">
                            <h3>Music Results</h3>
                            <ShowMoreComponent title="Show All Search Results" link="/music/searchResult/music" />
                        </div>
                        {loadingSearch && (
                            <div className={`row card-deck ${noWrap("/music/searchResult/music")}`}>
                                {preloader()}
                            </div>)}
                        <div className={`row card-deck ${noWrap("/music/searchResult/music")}`}>
                            {searchData && (searchResult(searchData))}
                            {searchError && (<LottieWrapper url="https://assets3.lottiefiles.com/packages/lf20_oURJje.json" width="calc(100% -  20px)" height="30vh" />)}
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default NewMusic
