import React from 'react'
import AllHistory from "../../components/musicPageComponents/allHistory/index"

import "./index.scss"

const HistoryOfUser = (props:any) => {
    return (
        <>
                        <AllHistory></AllHistory>              
        </>
    )
}

export default HistoryOfUser
