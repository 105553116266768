import React,{useEffect} from 'react';
import Done from './icons/Info_Icon';
import CloseIcon from '../../mobileNavBar/src/CloseIcon';
import { setInfoBanner } from '../../../redux/data/uiData';
import { useAppDispatch,useAppSelector } from '../../../redux/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link,useLocation } from 'react-router-dom';
import Info_Icon from './icons/Info_Icon';
import './icon.scss'
import './index.scss'

export default function Info(props) {
  const { text } = props;
  const location=useLocation();
  const {infobanner,infoShowPage} = useAppSelector(state => state.uiData)
  useEffect(()=>{
  if(infobanner && infoShowPage !==location.pathname){
    dispatch(setInfoBanner())
  }
  },[location])
  
  const dispatch = useAppDispatch()
  return (
      <div className="info-banner mx-auto position-fixed on-top px-2" style={{zIndex:"2000",top:"20px",left:"12.5%"}}>
        <div className="btn-success bg-info px-3">
          
            

<Info_Icon/>


          
          <div className="col-md-11 text-sucess-pane">
            <div style={{lineHeight:1.3}} onClick={()=>{ dispatch(setInfoBanner())}}>  Please <Link to="/login" className='banner-link'>log in</Link> to your account to interact </div>
           
          </div>
          <div></div>

          <div className='close-icon' onClick={(e)=>{
                 e.preventDefault();
                //  console.log(props.close(false))
                 dispatch(setInfoBanner())
            }}>
            <CloseIcon/></div>
        </div>
      </div>
  );
}
