import React from 'react'

//helpers
import {Link} from "react-router-dom"
import Player from '../../../pages/main/components/eventsSection/src/Player'
import {useAppDispatch} from "../../../redux/hooks"
import { playSingle } from "../../../redux/data/musicPlayer/index"
import { setsharedUrl } from '../../../redux/data/artistProfile'
import CardShareIcon from "./CardShareIcon"

interface props{
    artistName:string;
    artistProfile:string;
    profileLink:string;
    songs:any;
    allMusic?:boolean;
}

const FeaturedArtistElement:React.FC<props> = (props) => {
    const dispatch = useAppDispatch()
    const handleError = (event:any) => {
        event.target.src = '/assets/images/thumb_avatar-default-image.jpg'; // Replace with your default image URL
    };
    return (
        <div className={`col-md-3 px-3 py-2 card card ${props.allMusic?'mt-3':''}  music-e-card  shadow-sm`} style={{backgroundColor:"#1e1e1e"}}>
            <Link to={`/a/${props.artistName.replaceAll(' ','-').toLowerCase().replaceAll("/","")}`}>
                <div className="artist" >
                    <div className='artist-wrapper'>
                        <div>
                    <img src={props.artistProfile} alt="" className='artist-img' loading='lazy' aria-hidden="false" onError={handleError} />
                    </div>
                    </div>
                    {((props.songs)&&(props.songs.length > 0))&&(<div className='music-play-btn-artist'onClick={(e) => {
                e.preventDefault()
                
                dispatch(playSingle({
                    value: props.songs
                }))
            }}>
                <Player/>
                </div>)}
                </div>
                
                <div className="artist-name" >
                    <div title={props.artistName} className="artist-names">{props.artistName}</div>
                    <div className='mt-2 artist-type' title={props.artistName}>Musician</div>
                    <div className='card-share-btn' onClick={(e) => {
                e.preventDefault()
                
                dispatch(setsharedUrl(
                    `https://leerecs.com/a/${props.artistName.replaceAll(" ","-").toLowerCase()}`
                  ))
            }}
                    ><CardShareIcon></CardShareIcon></div>
                </div>
            </Link>
        </div>
    )
}

export default FeaturedArtistElement
