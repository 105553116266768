import { gql } from "@apollo/client";
export const QUERY = gql`query{
    userInfo(userId:${localStorage.getItem("userId")}){
        username
        title
        website
        bio
        profileImg
        headerImg
        profileId
        headerId
    }
}`;


export const MUTATION = gql`
  mutation (
    $username: String!
    $title: String!
    $website: String!
    $bio: String!
    $profileImg: Int!
    $headerImg: Int!
    $userId: Int!
  ) {
    UpdateUserProfile(
      profileInfo: {
        username: $username
        title: $title
        website: $website
        bio: $bio
        profileImg: $profileImg
        headerImg: $headerImg
        userId: $userId
      }
    ) {
      desc
    }
  }
`;

export const APPROVEMUTATION = gql`
  mutation (
    $userId:Int!
  ) {
    approveShop(
      userId:$userId
    ) {
      desc
    }
  }
`;
