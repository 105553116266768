//@ts-nocheck
import React, { useEffect, useState } from 'react'

//helpers
import { gql, useMutation,useQuery} from "@apollo/client"
import { useHistory} from "react-router-dom"
import ProgressBanner from '../../musicianPageComponents/addMusic/ProgressBanner'

//components
import InputForm from "../../formInput/index"

//import FileUpload from "./FileUpload"
import { FileUpload,useUpload } from '../../../genericComponents/fileUploud/FileUpload'

//icon
import TitleIcon from "./src/TitleIcon"
import CategoryIcon from "./src/CategoryIcon"
import TagIcon from "./src/TagIcon"
import DecriptionIcon from "./src/DescriptionIcon"
import MusicFileIcon from "./src/MusicFileIcon"
import ImageIcon from "./src/ImageIcon"
import Done from '../../sucess/icons/done_all';
import CloudErrorComponent from '../../signup/src/CloudErrorComponent';
import Loader from '../../signup/src/icons/loader';
//info icons
import Sucess from "../../sucess/index"
import Loading from "../../loading/index"
import Error from "../../error/index"

//configurations
import configuration from "../../../config/configuration"

import "./index.scss"

const BecomeContributor = () => {
    //state values
    const [uploading, setuploading] = useState(false)
    const [{ upload: uploadMusic, setfiles: setMusicFile }, { loading: uplaodingMusic, error: uploadMusicError }] = useUpload("music-file")
    const [{ upload: uploadCover, setfiles: setCoverFile }, { loading: uploadingCover, error: uploadCoverError }] = useUpload("image-file")
    const history = useHistory() 
    
    //the graphl mutation
    const MUTATION = gql`mutation(
        $userId:Int!,
        $title:String!,
        $musicTitle:String!,
        $socialMediaAccount:String!,
        $desc:String!,
        $music:Int!,
        $musicProfile:String!,
    ){
    RequestContribution(contributionInfo:{
        userId:$userId,
        title:$title,
        musicTitle:$musicTitle,
        socialMediaAccount:$socialMediaAccount,
        desc:$desc,
        music:$music,
        musicProfile:$musicProfile
    }){
        desc
    }
}`
 const QUERY = gql`query{
 IsContributor(
     userId:${localStorage.getItem("userId")},){
     desc
 }
}`
 const { loading:contributorLoading, data:contributorData, error:contributorError } = useQuery(QUERY)
    
    const [ RequestContribution, { loading, data, error }] = useMutation(MUTATION, { errorPolicy: "all" })
  
    const value = (id: string) => {
        var inputValue = (document.getElementById(id) as HTMLInputElement).value
        return inputValue;
    };
    if(!localStorage.getItem("userId")){
        history.push("/login")
     }
    useEffect(()=>{

        if(contributorData){
            history.push("/music/addMusic")
        }
    },[contributorData])

    const[addingstatus,setAddingStatus]=useState(false)
    const [message,setMessage]=useState("loading")
     const [color,setColor]=useState("#7171ff")
     const [width,setWidth]=useState("10%")
     const [btn,setbtn]=useState(false);
     const [icon,setIcon]=useState(<Loader/>)
     const [status,setStatus]=useState(null);
     useEffect(()=>{
        if(uploadMusicError){
            setColor("#FF7171")
            setMessage(uploadMusicError)
            setIcon(<CloudErrorComponent/>)
        }
       },[uploadMusicError])

       useEffect(()=>{
        if(uploadCoverError){
            setColor("#FF7171")
            setMessage(uploadCoverError)
            setIcon(<CloudErrorComponent/>)  
        }
       },[uploadCoverError])
   
    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setAddingStatus(true)
        setbtn(true)
 
        //intializing
        setStatus(null)
        setMessage("loading ...")
        setColor("#7171ff")
        setWidth("10%")
        setIcon(<Loader/>)
        setTimeout(() => {
            
        },100);
        const [musicTitle, website, desc] = [value("title"), value("website"), value("description")]

        try {
            if(!website.match("https")){
                    setColor("#FF7171")
                    setMessage("Your Social Media Link is not Secure")
                    setbtn(false)
                    setIcon(<CloudErrorComponent/>) 
                return
              }
            const musicId = await uploadMusic("audio");
            //console.log(musicId)
            if (musicId) {
                setWidth("40%")
                setColor("#7171ff")
                setMessage("Uploading Audio File")
                const musicThumbNail = await uploadCover("image");
                //console.log(musicThumbNail)
                if (musicThumbNail) {
                    setWidth("70%")
                    setColor("#7171ff")
                    setMessage("Uploading Cover File")
                    console.log(musicThumbNail)
                    const result = await  RequestContribution({
                        variables: {
                            userId: parseInt(localStorage.getItem("userId") as string),
                            title: "musician",
                            musicTitle: musicTitle,
                            socialMediaAccount: website,
                            desc: desc,
                            music:musicId.id,
                            musicProfile: musicThumbNail.filename
                        }
                    })
                    if (result) {
                        if (result.data) {
                            setWidth("100%")
                            setColor("#5DD97C")
                            console.log(result.data)
                              setMessage("Check your email for our reply (including the junk and spam folders) and best of luck!")
                            setIcon(<Done/>) 
                            setStatus(true)
                            setbtn(false)
                        }
                        else{
                            setColor("#FF7171")
                            setMessage(result.errors[0].message)
                            console.log(result.errors)
                            setbtn(false)
                            setIcon(<CloudErrorComponent/>) 
                        }
                    }
                }
                else{
                    setColor("#FF7171")
                    if(uploadCoverError && uploadCoverError!=message){
                setMessage(uploadCoverError)}
                setbtn(false)
                setIcon(<CloudErrorComponent/>)
                
                }
            }
            else{
                setColor("#FF7171")
                if(uploadMusicError && uploadMusicError !=message){
                setMessage(uploadMusicError)}
                setIcon(<CloudErrorComponent/>)
                setbtn(false)
            }

        } catch (e) {
            setColor("#FF7171")
                setMessage(e.message)
                console.log(e)
                setbtn(false)
                setIcon(<CloudErrorComponent/>)
        }
    }
    return (
        <>{contributorError &&(
            <div id="add-music">
            {addingstatus &&(<div className="col-10 mx-auto mt-2 loaders">
                <ProgressBanner close={setAddingStatus} message={message} width={width} color={color} icon={icon} status={status}/>
            </div>)}
            <h3>Manage music {">"} Add music</h3>
            <form action="" onSubmit={handleSubmit} >
                <div className="d-flex justify-content-between top-header">
                    <div>
                        <h1>Contribute</h1>
                        <h6>Submit your original music and social media account for human verification.</h6>
                    </div>
                    
                    <button type="submit" className="btn add-music" disabled={btn}>Request Contributing</button>
                </div>
                <div className="row mx-0" onSubmit={handleSubmit}>
                    <div className="col-md-4 px-0">
                        <InputForm placeholder="Music Title" icon={<TitleIcon />} id="title" type="text" />
                        {/* <InputForm placeholder="Your job title" icon={<CategoryIcon />} id="category" type="text" /> */}
                        <InputForm placeholder="Social Media Link" icon={<TagIcon />} id="website" type="url" />
                        <InputForm placeholder="Tell us more about you." icon={<DecriptionIcon />} id="description" type="text" area={true} />
                    </div>
                    <div className="col-md-4">
                    {setMusicFile && <FileUpload fileId="music-file" acceptedFiles="audio/mp3,audio/mpeg,audio/x-wav" title="Music Files" text="Drag and drop an audio" rule3="The file must not be bigger than 10 MB" rule1="Add high quality audio" rule2="Add music you have right to" icon={<MusicFileIcon></MusicFileIcon>} onDropHandler={setMusicFile} />}
                    </div>
                    <div className="col-md-4">
                    {setCoverFile && <FileUpload fileId="image-file" acceptedFiles="image/jpeg, image/png" title="Music Cover" text="Drag and drop an image"  rule3="The file must not be bigger than 10 MB" rule1="Add high quality image" rule2="Add image you have right to" icon={<ImageIcon></ImageIcon>} onDropHandler={setCoverFile} />}
                    </div>
                </div>
            </form>
        </div>
        )}</>
        
    )
}

export default BecomeContributor
