import React from 'react'

//redux hooks
import {useAppDispatch,useAppSelector} from "../../../redux/hooks"

//helpers
import {Link} from "react-router-dom"

//wrapper
import LikeMusic from '../../wrappers/likeMusic'

//iicons
import HeartIcon from '../allMusicByArtist/src/HeartIcon'
import PlayIcon from "./src/PlayIcon"

//redux actions
import { playSingle,setIndex,setMusicId } from "../../../redux/data/musicPlayer/index"
import configuration from '../../../config/configuration'

interface props{
    title:string;
    thumbnail:string;
    file:string;
    username:string;
    count:Number;
    music_file: Number;
    music_thumbnail: Number;
    music_artist: Number;
    music_id:number;
    index:number;
    userId:number;
    color_name:string;
    recommend:any;
}

const MusicElement:React.FC<props> = (props) => {
    const dispatch = useAppDispatch()
    const { musicData } = useAppSelector(state => state.musicPlayerData)
    const regex = /[?@\/#:\,;%+&=\{\}\[\]'\^-]/g
    return (
        <div className="the-music">
            <div className="row mx-0 single-music justify-content-between">
                <div className="col-1 px-0 numbering">{props.count}</div>
                <div className="col-9 px-0 music">
                    <div className="d-flex">
                        <div className="music-thumbnail" style={{ backgroundImage: `url("${props.thumbnail}")`,backgroundColor:`${props.color_name}`,border:`1px solid ${props.color_name}` }}></div>
                        <div className="music-info">
                            <Link to={`/a/${props.username.replaceAll(' ','-').toLowerCase()}/s/${props.title.replaceAll(regex,'').replaceAll(' ','-').toLowerCase()}`}  >
                            <div className="music-title">{props.title}</div></Link>
                            <Link to={`/a/${props.username.replaceAll(' ','-').toLowerCase()}`} ><div className="music-artist">{props.username}</div></Link>
                        </div>
                    </div>
                </div>
                {/* <div className="col-1 px-0 album"></div> */}
                <div className="col-1 px-0 react"><LikeMusic music_id={props.music_id}><HeartIcon musicId={props.music_id}/></LikeMusic></div>
                <div className="col-1 px-0 play">
                    <div className="d-flex justify-content-end">
                        <div></div>
                        <div onClick={(e)=>{
                            e.preventDefault()
                            // alert(musicData.length)
                            let recommend;

                              recommend=props.recommend.map((song:any)=>( {
                                name:song.title,
                                singer: song.username,
                                cover: `${configuration.FILE_STORAGE_LINK}${song.thumbnail}`,
                                musicSrc: `${configuration.READFILE_LINK}${song.file}`,
                                color:song.color_name}))
                                dispatch(playSingle({
                                    value: [{
                                        name: props.title,
                                        singer: props.username,
                                        cover: props.thumbnail,
                                        musicSrc: props.file,
                                        color:props.color_name
                                    },...recommend]
                                }))
                                dispatch(setMusicId({ value: props.music_id }))
                            
                           
                        }}>
                            <PlayIcon />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MusicElement
