// @ts-nocheck
import { useEffect,useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ReactGA from 'react-ga4';
import { useHistory } from "react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons'; 
import HomePage from "./pages/main/index";
import LoginPage from "./pages/login/index";
import SignUpPage from "./pages/signup/index";

import RequestInterview from "./components/requestInterview";

import MusicHomePage from "./pages/musicHome/index";
// import MusicSearchResult from "./pages/musicSearchResult/index";
import ArtistProfile from "./pages/ArtistProfile";
import AllMusicInPlaylist from "./pages/allMusicInPlaylist/index";
import LikedMusicByUser from "./pages/likedMusic/index";
import HistoryOfUser from "./pages/historyPage/index";
import AllMusicAlbum from "./pages/allMusicInAlbum/index";
import BecomeContributor from "./components/musicPageComponents/beContributor";
import ColorPlaylist from "./pages/allMusicInPlaylist/ColorPlaylist";
import MusicianDashboard from "./pages/musicianDashboard/MusicianDashboard";
import ProductionService from "./pages/production/index.jsx";
import AddMusicPage from "./pages/musicanAddMusic/index";
import MusicProfilePage from "./pages/musicProfile/index";
import MultipleMuiscUpload from "./pages/multipleMusicUpload/indesx";
import AddMultipleMusicPage from "./pages/musicianAddMultipleMusic";
import SingleSongPage from "./pages/songByArtist";

//music player
import MusicOfficialPlayer from "./components/musicPageComponents/musicPlayer/index";

//redux helper
import { useAppSelector } from "./redux/hooks";

//childrens componets
import AllFeaturedArtists from "./components/musicPageComponents/featuredArtists/AllFeaturedArtist";
import AllArtists from "./components/musicPageComponents/allArtists";
import UnassignedNewMusic from "./components/musicPageComponents/newMusic/unsignedMusic";
import UpdateAudioFile from "./components/UpdateAudioFile";
import EmptyCaseHome from "./pages/Emptycase";

//wrapper
import AddHistory from "./components/wrappers/addMusicHistory";
import MusicPageWrapper from "./pages/wrapper/index";
import SecureRoute from "./pages/wrapper/SecureRoute";
import SecureMusician from "./pages/wrapper/SecureMusician";
import SecureContributor from "./pages/wrapper/SecureContributor";
import AddPlaylist from "./components/addPlaylist";
import AddAlbum from "./components/addAlbum";
import Info from "./components/musicPageComponents/info";

// claim music
import ClaimMusic from "./components/musicPageComponents/claimYourMusic/ClaimMusic";
import AssignMusic from "./components/musicPageComponents/claimYourMusic/AssignMusic";
import ClaimProfile from "./components/musicPageComponents/claimYourMusic/ClaimProfile";
import UpdateAttachment from "./components/musicPageComponents/claimYourMusic/UpdateAttachement";

import "./App.scss";
// import AllLikedMusic from "./components/musicPageComponents/allLikedMusic";
import TermsAndCondition from "./components/term";
import Share from "./pages/artistProfilepage/components/artistCard/Share";
import DeletePlaylist from "./components/musicPageComponents/claimYourMusic/DeletePlaylist";
import DeleteAlbum from "./components/musicPageComponents/claimYourMusic/DeleteAlbum";
import AssignPlaylist from "./components/musicPageComponents/claimYourMusic/AssignPlaylist";
import Friends from "./components/musicPageComponents/claimYourMusic/Friends";
import Notification from "./pages/Notification";
import AllMusic from "./pages/AllMusic";
import AllPlaylists from "./pages/allPlaylists";
import AllAlbum from "./pages/AllAlbum";
import MusicSearch from "./pages/Searches/music";
import AllSearches from "./pages/Searches";
import ArtistSearch from "./pages/Searches/artist";
import PlaylistSearch from "./pages/Searches/playlist";
import AlbumSearch from "./pages/Searches/album";
import MyMusic from "./pages/Mymusic";
import PayWall from "./components/pay-wall";
import MusicPayWall from "./components/pay-wall/musicPayWall";
import AddToShop from "./components/musicPageComponents/claimYourMusic/AddToShop";
import MentainancePage from "./pages/Emptycase/mentainancePage";
import MissongTrack from "./components/musicPageComponents/info/missongTrack";

library.add(fab, fas);
export default function App() {
  const { musicData } = useAppSelector((state) => state.musicPlayerData);
  const { claimMusic } = useAppSelector((state) => state.uiData);
  const { sharedUrl } = useAppSelector((state) => state.artistProfile);
  const { assignMusic } = useAppSelector((state) => state.uiData);
  const { claimProfile } = useAppSelector((state) => state.uiData);
  const { updateAttachment } = useAppSelector((state) => state.uiData);
  const {addplaylist} = useAppSelector(state => state.uiData)
  const {infobanner,missingTrack} = useAppSelector(state => state.uiData)
  const {addAlbum} = useAppSelector(state => state.uiData)
  const {updateAudio} = useAppSelector(state => state.uiData)
  const {deletePlaylist} = useAppSelector(state => state.uiData)
  const {deleteAlbum} = useAppSelector(state => state.uiData)
  const {assignPlaylist} = useAppSelector(state => state.uiData)
  const {showFriends} = useAppSelector(state => state.uiData)
  const {showPaywall} = useAppSelector(state => state.uiData)
  const {showMusicPayWall} = useAppSelector(state => state.uiData)
  const {showAlbumConfig} = useAppSelector(state => state.uiData)
  const [info, setinfo] = useState(null);
  const [isMaintenance, setIsMaintenance] = useState(false);
  
const history=useHistory()

  useEffect(() => {
    // Initialize Google Analytics with your tracking ID
    ReactGA.initialize('G-SBS20ZV1C4');
    
    
    async function getMoviesFromApi() {
      try {
        
        let response = await fetch(
          "https://proxy.leerecs.com?url=https://leerecs.com/home.json"
        );
        console.log("Remember to set your Google Analytics")
        let responseJson = await response.json();
        setinfo(responseJson);
        if(responseJson.underMentainance){
          setIsMaintenance(responseJson.underMentainance)
          history.push("/maintenance")
        }
        // setloaded(true)
      } catch (error) {
        console.error(error);
      }
    }
    getMoviesFromApi()
  }, []);
  
  

  ReactGA.send({
    hitType:"pageview",
    page:window.location.pathname}
  )
  return (
    <>
    {
      isMaintenance&&(<MentainancePage info={info.underMentainanceInfo}/>)
    }{
      !isMaintenance&&(
        <Router> 
        {sharedUrl && <Share />}
        {musicData[0].musicSrc && <MusicOfficialPlayer />}
        {addplaylist && <AddPlaylist />}
        {addAlbum && <AddAlbum />}
        {updateAudio&&<UpdateAudioFile/>}
        {/* {true && <MusicOfficialPlayer />} */}
        <AddHistory />
        <TermsAndCondition />
        {claimMusic && <ClaimMusic />}
        {claimProfile && <ClaimProfile />}
        {showFriends && <Friends/>}
        {assignMusic  &&<AssignMusic />}
        {deletePlaylist &&<DeletePlaylist/>}
        {showAlbumConfig&&<AddToShop/>}
        {deleteAlbum && <DeleteAlbum/>}
        {updateAttachment  &&<UpdateAttachment/>}
        {infobanner&&<div className="w-100 position-relative"><Info/></div>}
        {missingTrack&&<div className="w-100 position-relative"><MissongTrack/></div>}
        {assignPlaylist &&<AssignPlaylist/>}
        {showPaywall&&<PayWall/>}
        {showMusicPayWall &&<MusicPayWall/>}
        <Switch>
          <Route exact path="/" component={HomePage} /> 
          <Route path="/login" component={LoginPage} />
          <Route path="/signup" component={SignUpPage} />
          <Route path="/request" component={RequestInterview} />
          <Route path="/production" component={ProductionService} />
          <Route path="/admin/multiple/uploads" component={MultipleMuiscUpload} />
          <Route path="/music/notification" component={
            (
              <SecureRoute>
                  <MusicPageWrapper>
                   <Notification/>
                  </MusicPageWrapper>
                </SecureRoute>
            )
            } />
          {/* secure routes */}
          <Route
            exact
            path="/music"
            component={() => {
              return (
                <SecureRoute>
                  <MusicPageWrapper>
                  <MusicHomePage />
                  </MusicPageWrapper>
                </SecureRoute>
              );
            }}
          />
          <Route

            exact
            path="/music/searchResult"
            component={() => {
              return (
                <SecureRoute>
                  <MusicPageWrapper>
                  <AllSearches/>
                  </MusicPageWrapper>
                </SecureRoute>
              );
            }}
          />
          <Route
            exact
            path="/music/searchResult/music"
            component={() => {
              return (
                <SecureRoute>
                  <MusicPageWrapper>
                    <MusicSearch/>
                  </MusicPageWrapper>
                </SecureRoute>
              );
            }}
          />
          <Route
            exact
            path="/music/searchResult/artists"
            component={() => {
              return (
                <SecureRoute>
                  <MusicPageWrapper>
                   <ArtistSearch/>
                  </MusicPageWrapper>
                </SecureRoute>
              );
            }}
          />
          <Route
            exact
            path="/music/searchResult/playlists"
            component={() => {
              return (
                <SecureRoute>
                  <MusicPageWrapper>
                    <PlaylistSearch/>
                  </MusicPageWrapper>
                </SecureRoute>
              );
            }}
          />
          <Route
            exact
            path="/music/searchResult/albums"
            component={() => {
              return (
                <SecureRoute>
                  <MusicPageWrapper>
                   <AlbumSearch/>
                  </MusicPageWrapper>
                </SecureRoute>
              );
            }}
          />
          <Route
            exact
            path="/a/:username"
            component={()=>(
              <SecureRoute>
                  <MusicPageWrapper>
                   <ArtistProfile/>
                  </MusicPageWrapper>
                </SecureRoute>
            )
              }/>
            <Route
            exact
            path="/a/:username/albums"
            component={()=>(
              <SecureRoute>
                  <MusicPageWrapper>
                   <ArtistProfile/>
                  </MusicPageWrapper>
                </SecureRoute>
            )
              }/>
              <Route
            exact
            path="/a/:username/shop"
            component={()=>(
              <SecureRoute>
                  <MusicPageWrapper>
                   <ArtistProfile/>
                  </MusicPageWrapper>
                </SecureRoute>
            )
              }/>
            <Route
            exact
            path="/a/:username/playlists"
            component={()=>(
              <SecureRoute>
                  <MusicPageWrapper>
                   <ArtistProfile/>
                  </MusicPageWrapper>
                </SecureRoute>
            )
              }/>
            <Route
            exact
            path="/a/:username/listens"
            component={()=>(
              <SecureRoute>
                  <MusicPageWrapper>
                   <ArtistProfile/>
                  </MusicPageWrapper>
                </SecureRoute>
            )
              }/>
             <Route
            exact
            path="/a/:username/friends"
            component={()=>(
              <SecureRoute>
                  <MusicPageWrapper>
                   <ArtistProfile/>
                  </MusicPageWrapper>
                </SecureRoute>
            )
              }/>

          <Route
            exactA
            path="/a/:owner/s/:songname"
            component={
              ()=>(
                <SecureRoute>
                    <MusicPageWrapper>
                     <SingleSongPage/>
                    </MusicPageWrapper>
                  </SecureRoute>
              )
              }
          />

          <Route
            exact
            path="/music/playlist/:playlist"
            component={()=>(
              <SecureRoute>
                  <MusicPageWrapper>
                   <AllMusicInPlaylist/>
                  </MusicPageWrapper>
                </SecureRoute>
            )}
          />
          <Route
            exact
            path="/music/colour/:colorId"
            component={()=>(
              <SecureRoute>
                  <MusicPageWrapper>
                   <ColorPlaylist/>
                  </MusicPageWrapper>
                </SecureRoute>
            )
            }
          />
          <Route 
          exact 
          path="/music/album/:playlist"
           component={ ()=>(
           <SecureRoute>
                  <MusicPageWrapper>
                  <AllMusicAlbum/>
                  </MusicPageWrapper>
                </SecureRoute>
           
           )} />
          <Route exact path="/music/likedSongs" component={
            ()=>(
              <SecureRoute>
                     <MusicPageWrapper>
                     <LikedMusicByUser/>
                     </MusicPageWrapper>
                   </SecureRoute>
              )
            
            } />
          <Route
            exact
            path="/music/history"
            component={() => {
              return (
                <SecureRoute>
                  <MusicPageWrapper>
                  <HistoryOfUser />
                  </MusicPageWrapper>
                  
                </SecureRoute>
              );
            }}
          />
          <Route
            exact
            path="/music/allmusic"
            component={() => {
              return (
                <MusicPageWrapper>
                  <AllMusic/>
                </MusicPageWrapper>
              );
            }}
          />
          <Route
            exact
            path="/music/mymusic"
            component={() => {
              return (
                <MusicPageWrapper>
                  <MyMusic/>
                </MusicPageWrapper>
              );
            }}
          />
          <Route
            exact
            path="/music/unassignedmusic"
            component={() => {
              return (
                <MusicPageWrapper>
                  <UnassignedNewMusic allMusic={true} addMargin={true} />
                </MusicPageWrapper>
              );
            }}
          />
          <Route
            exact
            path="/music/allfeatured"
            component={() => {
              return (
                <MusicPageWrapper>
                  <AllFeaturedArtists allMusic={true} addMargin={true} />
                </MusicPageWrapper>
              );
            }}
          />
            <Route
            exact
            path="/music/allartist"
            component={() => {
              return (
                <MusicPageWrapper>
                  <AllArtists allMusic={true} addMargin={true} />
                </MusicPageWrapper>
              );
            }}
          />
          <Route
            exact
            path="/music/allplaylist"
            component={() => {
              return (
                <MusicPageWrapper>
                  <AllPlaylists />
                </MusicPageWrapper>
              );
            }}
          />
          <Route
            exact
            path="/music/allalbums"
            component={() => {
              return (
                <MusicPageWrapper>
                  <AllAlbum/>
                </MusicPageWrapper>
              );
            }}
          />
          <Route
            exact
            path="/music/becomeContributor"
            component={() => {
              return (
                <SecureRoute>
                  <MusicPageWrapper>
                    <BecomeContributor />
                  </MusicPageWrapper>
                </SecureRoute>
              );
            }}
          />

          <Route
            exact
            path="/musician"
            component={() => {
              return (
                <SecureRoute>
                  <SecureMusician>
                  <MusicPageWrapper>
                  <MusicianDashboard />
                  </MusicPageWrapper>
                    
                  </SecureMusician>
                </SecureRoute>
              );
            }}
          />
          <Route
            exact
            path="/music/addMusic"
            component={() => {
              return (
                <SecureRoute>
                  <SecureContributor>
                  <MusicPageWrapper>
                  <AddMusicPage />
                  </MusicPageWrapper>
                  </SecureContributor>
                </SecureRoute>
              );
            }}
          />
          <Route
            exact
            path="/music/addMusic/multiple"
            component={() => {
              return (
                <SecureRoute>
                  <SecureMusician>
                  <MusicPageWrapper>
                  <AddMultipleMusicPage/>
                  </MusicPageWrapper>
                    
                  </SecureMusician>
                </SecureRoute>
              );
            }}
          />
          <Route
            exact
            path="/podcast/:title"
            component={MusicProfilePage}
          >
          </Route>
          <Route
            exact
            path="/podcast"
            component={MusicProfilePage}
          >
          </Route>
          <Route exact path="/maintenance" component={EmptyCaseHome} />
          <Route exact path="/*" component={EmptyCaseHome} />
          

        </Switch>
      </Router>
      )

    }
      
    </>
  );
}
