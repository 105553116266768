import React,{useEffect}from 'react';
import MusicSideBar from "../../components/musicPageComponents/sidebar/index"
import MusicNavBar from '../../components/musicPageComponents/navbar'
import MusicFooter from '../main/components/footerSection/MusicFooter'
import { useQuery, gql,useMutation } from "@apollo/client"
import configuration from '../../config/configuration';
import NotificationCard from './NotificationCard';
import LottieWrapper from '../../components/lottie/lottie';
import "./index.scss"

const Notification= () => {
       //the graphl mutation
       const MUTATION = gql`mutation($user_id:Int!)
       {
        update_notification(user_Id:$user_id){
               desc
           }
       }`
    const QUERY=gql`query{
        UserNotification(userId:${localStorage.getItem('userId')}){
            music
            user_id
            buddie_list
            type
          username
          message
        userProfile
        owner_name
        date
        seen
        is_verified
         }
         }`;
          const { loading, data, error } = useQuery(QUERY)
          const [updateNotification, { loading:notificationLoading, data:notificationData, error:notificationError }] = useMutation(MUTATION, { errorPolicy: "all" })
          useEffect(()=>{
            const updateNote= async()=>{
                const result= await updateNotification({
                    variables:{
                      user_id: parseInt(localStorage.getItem('userId') as string, 10),
                    }
                  })
                  if(result){
                    console.log(result)
                  }
            }
            updateNote();
          },[])
          const countNotification=()=>{
            let count = 0;

           for (let i = 0; i < data.UserNotification.length; i++) {
                if (!data.UserNotification[i].seen) {
                count++;
             }
              }
              return count
          }
    return (
        <>
           
                        <div className=" bg-color ">
                            <div id="mobile-notification">
                        <div className="notification-container">
                            <div className="notification-header d-flex text-white justify-content-center align-items-end">
                             <h4>Notifications <span className='number'> {data&&countNotification()!==0 && countNotification()} </span></h4>
                            </div>
                            <div className="notification-body">
                                {data&&data.UserNotification.map((item:any)=>(<NotificationCard image={`${configuration.FILE_STORAGE_LINK}${item.userProfile}`} info={item}/>))}
                                {data && data.UserNotification.length===0&&(<div>
                          <LottieWrapper url="https://assets3.lottiefiles.com/packages/lf20_HDck25.json" width="100% " height="70vh" />
                          <div className='text-white no-note-msg'>No notifications at this time, go ahead and interact with the community.</div>
                          </div>
                        )}
                    
                            </div>

                        </div>
                        </div>
                        </div>
                        </>
                        
    );
}

export default Notification;
