import React from 'react'

export default function Favorite() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24.999" viewBox="0 0 25 24.999">
      <g id="favorite" transform="translate(-2.001 -3.042)">
        <g id="time-machine" transform="translate(2.001 3.032)">
          <path id="Path_17" data-name="Path 17" d="M21.342,3.675A12.42,12.42,0,0,0,12.505.01H12.5A12.5,12.5,0,0,0,0,12.5a1.042,1.042,0,0,0,1.042,1.042h0A1.042,1.042,0,0,0,2.083,12.5,10.416,10.416,0,0,1,12.5,2.093H12.5a10.416,10.416,0,0,1,0,20.833H12.5A10.438,10.438,0,0,1,6.9,21.275L8.6,19.838a.521.521,0,0,0-.2-.9L2.47,17.287a.521.521,0,0,0-.642.361.515.515,0,0,0-.015.205l.759,6.038a.521.521,0,0,0,.854.332l1.817-1.541a12.545,12.545,0,0,0,7.251,2.326H12.5A12.5,12.5,0,0,0,21.342,3.675Z" transform="translate(0 0)" fill="#aeaeae" />
          <path id="Path_18" data-name="Path 18" d="M30.593,17.114a.781.781,0,0,0,0-1.562l-6.545-.226-.226-6.545a.781.781,0,0,0-1.562,0L22,16.333a1.042,1.042,0,0,0,1.042,1.042Z" transform="translate(-10.542 -3.829)" fill="#363636" />
        </g>
      </g>
    </svg>

  )
}
