// @ts-nocheck
import React, { useState,useEffect,useCallback,useRef} from 'react'
import {Helmet} from "react-helmet";
import axios from 'axios';
import ContentEditable from "react-contenteditable";
import PencilIcon from '../singleSongProfile/src/PencilIcon';
import {useDropzone} from 'react-dropzone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setShowFriends } from '../../../redux/data/uiData';
import Loading from '../../loading';
import Sucess from '../../sucess';
//helpers
import { useQuery, gql,useMutation } from "@apollo/client"
import {MUTATION,APPROVEMUTATION } from "./graphql"

//redux hooks
import { useAppDispatch,useAppSelector } from "../../../redux/hooks"

//redux actions
import { playSingle } from "../../../redux/data/musicPlayer/index"
import { setsharedUrl } from "../../../redux/data/artistProfile/index"
import { setUpdateBtn } from '../../../redux/data/uiData';
import { callUpdateFunction } from '../../../redux/data/uiData';
import { claimProfile } from '../../../redux/data/uiData';
import { claimData } from '../../../redux/data/claimProfile';

//icons
// import PlayMusicIcon from "./src/PlayMusicIcon"

//prelaoders
import HeaderPreloader from "../headerPreloader/index"
import SingleMusicPreloader from "../singleMusicPreloader"
import { Link,useLocation } from 'react-router-dom'
import Parser from "rss-parser";

import MusicElement from "./MusicElement"
import PodcastMusicElement from './PodcastMusicElement'
import Loader from '../../signup/src/icons/loader';
import CloudErrorComponent from '../../signup/src/CloudErrorComponent';
import Done from '../../sucess/icons/done_all';
import ClaimIcon from '../newMusic/src/ClaimIcon';

import "./index.scss"

//configuration
import configuration from '../../../config/configuration'
// import ShareIcon from './src/ShareIcon'
import FeaturedArtists from '../featuredArtists'
import AllAlbums from '../ArtistAlbums'
import AllPlaylist from '../ArtistPlaylist'
import VerifiedIcon from './src/VerifiedIcon'
import TwitterIcon from './src/Twitter'
import Instagram from './src/Instagram'
import YouTubeIcon from './src/YouTube'
import PlayMusicIcon from './src/PlayIcon'
import ShareButton from './src/ShareButton'
import MyDropZone from './MyDropezone'
import FollowIcon from './src/FollowIcon';
import { userInfo } from 'os'
import Closeicon from '../../addPlaylist/src/Closeicon';
import ArtistShop from '../ArtistShop';
import { isCallOrNewExpression } from 'typescript';
import AddBuddie from '../../wrappers/AddBuddie';
import Ig from './src/Ig';
import Facebook from './src/Facebook';
import YoutubeIcon from './src/YoutubeIcon';
import Sportify from './src/Sportify';
import TwitterIco from './src/TwitterIcon';
interface props {
    username: string;
    profileImage:string;
    products:any
    headerImage:string;
    bio:string;
    website:string;
    title:string;
    profileId:integer;
    headerId:integer;
    userId:integer;
    buddies:integer;
    karma:integer;
    buddie_list:string;
    id_group:int;
    songTitle?:String;
    shop_status?:String;
    shop_requests?:integer
}
const AllMusicsByArtist: React.FC<props> = (props) => {
    const {callupadtefunction } = useAppSelector((state) => state.uiData);
    const dispatch = useAppDispatch()
    
    //state that will contain music data array
    const [UpdateUserProfile, { updateloading, updatedata, updateerror }] = useMutation(MUTATION, { errorPolicy: "all" })
    const [appproveShop, { approveoading, approvedata, approvexerror }] = useMutation(APPROVEMUTATION, { errorPolicy: "all" })
    let musicData: any = []
    const location=useLocation()
    
    const[status,setStatus]=useState(
        location.pathname==`/a/${props.username.toLowerCase().replaceAll(' ','-')}/albums`?"albums":location.pathname==`/a/${props.username.toLowerCase().replaceAll(' ','-')}/playlists`?"playlists":location.pathname==`/a/${props.username.toLowerCase().replaceAll(' ','-')}/shop`?'shop':"songs")

    const[isOwner,setIsOwner]=useState(parseInt(localStorage.getItem("userId"))===props.userId)
    // console.log(isOwner)
    let hasPodcast =false
    const [countNumber,setcountnumber]=useState(5)
    const[isAdmin,setIsAdmin]=useState(false)
    const [file,setFile]=useState();
    const [Headerfile,setHeaderFile]=useState();
    const [profile,setProfile]=useState(`${configuration.FILE_STORAGE_LINK}${props.profileImage}`);
    const [header,setHeader]=useState(`${configuration.FILE_STORAGE_LINK}${props.headerImage}`)
    const [username,setUsername]=useState(props.username)
    const [bio,setBio] =useState(props.bio)
    // const [website,setWebsite]=useState(props.website)
    const [profileId,setProfileId]=useState(props.profileId)
    const [headerId,setHeaderId]=useState(props.headerId)
    const [website,setWebsite] = useState(props.website)
    const [sucess,setSuccess] =useState(false)
    const [uploading,setUploading]=useState(false)
    // console.log(props.buddie_list)
    const [beFriendStatus,setBeFriendStatus] =useState(props.buddie_list.includes(localStorage.getItem("userId") as string)?" Unfriend":" Befriend")
    const [karma,setKarma]=useState(props.karma)
    const [buddies,setBuddies]=useState(props.buddies)
    var count=0;
    const[errordata,setErrorData]=useState(null)
    useEffect(()=>{
       
        setProfile(`${configuration.FILE_STORAGE_LINK}${props.profileImage}`)
        setWebsite(props.website)
        setUsername(props.username)
        setBio(props.bio)
        setProfileId(props.profileId);
        setHeaderId(props.headerId)
        setKarma(props.karma)
        setHeader(`${configuration.FILE_STORAGE_LINK}${props.headerImage}`)
        return () => {
        };
    },[props.bio, props.headerId, props.headerImage, props.karma, props.profileId, props.profileImage, props.username, props.website])

    const BefriendAction =(action,status)=>{
        // console.log(action)
        if(action==="Loading"){
            if(beFriendStatus==="Befriend"){
            setBeFriendStatus(" Unfriending...");}
            else{
                setBeFriendStatus(" Befriending...")
            }
        }
//  setBeFriendStatus(status());
 if(action==="added"){
    // console.log(action)
    setBuddies(buddies + 1)
    setKarma(karma + 1);
    setBeFriendStatus(" Unfriend");

 }
 if(action==="removed"){
    // console.log(action)
    setBuddies(buddies - 1)
    setKarma(karma-1)
    setBeFriendStatus(" Befriend");
 }

    }
    const onDrop = useCallback(acceptedFiles => {
        // console.log(acceptedFiles[0]);
        setFile(acceptedFiles[0]);
          const reader = new FileReader()
          reader.addEventListener("load",()=>{
            setProfile(reader.result)
          })
          console.log(reader.result)
          reader.readAsDataURL(acceptedFiles[0])
          
      }, [])
      const onDropheader = useCallback(File => {
        // console.log(File)
         setHeaderFile(File);
        const reader = new FileReader()
        reader.addEventListener("load",()=>{
        setHeader(reader.result)
         })
         reader.readAsDataURL(File)
          
      },[])
      const handleusername=(e)=>{
        setUsername(e.target.value)
        // dispatch(
        // setUpdateBtn(file||Headerfile||(props.username !== username)||(props.website!==website)||(props.bio !==bio)))
      }
      const handleBio=(e)=>{
        setBio(e.target.value)
      }
      const handleWebsite=(e)=>{
        setWebsite(e.target.value)
      }
      const handleUpdate=async()=>{
        // const form=new FormData();
        
        setUploading(true)
        setErrorData(null);
        setSuccess(false)    
        const regex = /[?@\/#:\,;%+&=\{\}\[\]'\^-]/g

        //validating username
        if(username.match(regex)){

            return (setErrorData("Usernames can only contain letters, numbers, underscores and dots."))
      
          }

        //checking name
        if((props.username !== username)){
            const check=new FormData();
            check.append("username",username);
            const checkres = await axios.post(`${configuration.BACK_END_HOST}/checkname`, check)
            if(checkres.data.exists){
                return(setErrorData("This user already exist "))
            }
        
        }
     let pId=profileId;
     let hId=headerId;
        if(file){
        const form1 = new FormData();
        const form = new FormData();
        form1.append("file",file,file.name)
        const result1 = await axios.post(`https://leerecs.com/backend/public/api/uploadfile`, form1)
        //console.log(result1.data)  
        //setProfile(result1.data.title)
          form.append("fileTitle", result1.data.title)
          form.append("userId", localStorage.getItem("userId") as string)
            form.append("fileExt", "profile")
            form.append("mimeType", file.type)
            form.append("size", file.size)
            const result = await axios.post(`${configuration.BACK_END_HOST}/upload`, form)
            pId=result.data.fileId;
        }
        if(Headerfile){
            const form1 = new FormData();
        const form = new FormData();
        form1.append("file",Headerfile,Headerfile.name)
        //s3bucket comment
        // const result1 = await axios.post(`https://uploadbackend.leerecs.net/api/upload_video`, form1)
        const result1 = await axios.post(`https://leerecs.com/backend/public/api/uploadfile`, form1)
       // console.log(result1)
         //setHeader(result1.data.title)
          form.append("fileTitle", result1.data.title)
          form.append("userId", localStorage.getItem("userId") as string)
            form.append("fileExt", "profile")
            form.append("mimeType", Headerfile.type)
            form.append("size", Headerfile.size)
            const result = await axios.post(`${configuration.BACK_END_HOST}/upload`, form)
            hId=result.data.fileId;

        }
        // console.log(profileId)
        // console.log(result1)
       const res= await UpdateUserProfile({
            variables: {
                username: username.replace(/&nbsp;/g, ''),
                title: props.title.replace(/&nbsp;/g, ''),
                website:website.replace(/&nbsp;/g, ''),
                bio: bio.replace(/&nbsp;/g, ''),
                profileImg: pId,
                headerImg: hId,
                userId: props.userId,
            }
        })
        setUploading(false)
        setSuccess(true)

        if(res){
            if(updatedata){
                setUploading(false)
                setSuccess(true)


   
            }
        }

        if((props.username !== username)){
            window.location.replace(`/a/${username.replaceAll(" ","-").toLowerCase()}`)
          }
      }
    // const [musics, setmusics] = useState([])
    const {getRootProps, getInputProps, isDragActive} = useDropzone({accept:"image/jpeg, image/png",multiple:false,onDrop})
    const {getRootProps:getRootHeaderProps, getInputProps:getInputHeaderProps, isDragActive:isDragHeaderActive} = useDropzone({accept:"image/jpeg, image/png",multiple:false,onDrop})
    const QUERY =props.title==="ghost" ? (gql`query{
          AllMusicByGhostUser(username:"${props.username}",user_type:${isAdmin?1:0}){
            thumbnail
	        file
	        title
	        tag
	        desc
	        musicId
	        imageId
	        username
            activated
	        messageId
	        topicId
	        memberId
            color_name
    }
         }`):(gql`query{
        AllMusicByArist(userId:${props.userId}) {
             title
             thumbnail
             file
             username
             musicId
             imageId
             memberId
             topicId
             messageId
             color_name
         }
         }`);
    const { loading, data, error } = useQuery(QUERY/*, { pollInterval: 500 }*/)

    //query to get playlist data
    const QUERYOTHER = gql`query{
         MoreArtist(userId:${props.userId}) {
            username
            profileImg
            userId
            criteria
            songs{
            cover
            singer
            name
            musicSrc
        }
        }
    }`
    //all songs
    const ALLmusic =gql`query{
         AllMusicByAristUserId(userId:${props.userId}) {
            title
            thumbnail
            file
            username
            musicId
            imageId
            memberId
            topicId
            messageId
            activated
            color_name
        }

    }`
    //Query to get all Playlist by user
    const ALLMYPLAYLIST = gql`query{
        AllPlaylistsByUsername(username:"${props.username}") {
        title
        desc
        username
        cover
        playListId
        musicCount
        }
       }`
     //Query to get all Albums by user  
//    console.log(props.userId);
     const ALLMYALBUM = gql`query{
        AllAlbumByUser(userId:${props.userId}) {
           title
	desc
	username
	cover
	albumId
        }
       }`

     
   
    const { loading: loadindPlaylstInfo, data: Allmyplaylist, error: playlistserrors } = useQuery(ALLMYPLAYLIST/*, { pollInterval: 500 }*/)
    const { loading: loadingAllMusic, data: AllMusic, error: allMusicErrors } = useQuery(ALLmusic/*, { pollInterval: 500 }*/)
    const { loading: loadindalbumInfo, data: Allmyalbum, error: albumerrors } = useQuery(ALLMYALBUM/*, { pollInterval: 500 }*/)
    const { loading: artistsloadind, data: Otherartist, error: artistslerrors } = useQuery(QUERYOTHER/*, { pollInterval: 500 }*/)
    console.log(Otherartist)
    const ref = useRef([]); 
    const [podcast,setPodcast]=useState([])
    const [title,setTitle]=useState("")
    const [tooltip,setTooltip]=useState(false)
    useEffect(()=>{
        // console.log("hello")
        const resultFunc = async () => {
            const listOfMusic = async () => {
              let parser = new Parser();
              const CORS_PROXY = "https://proxy.leerecs.com/?url=";
              let feed = await parser.parseURL(
                CORS_PROXY + "https://www.spreaker.com/show/3073366/episodes/feed"
              );
              if (feed) {
                feed.items.forEach((item, idx) => {
                    // console.log(item)
                  ref.current.push({
                    imageUrl: item.itunes.image,
                    musicUrl: item.enclosure?.url,
                    artist: "leerecs",
                    musicName: item.title,
                    description: item.contentSnippet,
                  });
                });
                setPodcast(ref.current)
                return ref.current;
              }
            };
      
            const title = (id) => {
              for (let index = 0; index < ref.current.length; index++) {
                const element = ref.current[index];
                if(id){
                if (element.musicName === id) {
                  return element;
                }
              }
              else{
                return ref.current[0]
              }
            }
            };
      
            const result = await listOfMusic();
            
          };
         
            resultFunc()
            return () => {
            };
                
     },[])
     const[addingstatus,setAddingStatus]=useState(false)
     const [message,setMessage]=useState("loading")
     useEffect(()=>{
        const progress =document.querySelector(".progress-bar")
         if(addingstatus){
        if(uploading&& !errordata){
            progress.style.width="30%"
            progress.style.backgroundColor="#7171ff"
           progress.classList.remove('successfull')
            setMessage("loading")
        }
        if(sucess && !errordata){
            progress.style.width="100%"
            progress.style.backgroundColor="#5DD97C"
            progress.classList.add('successfull')
            setMessage("Your profile has been updated successfully")
            
        }
       
            if(errordata){
                setMessage(errordata)
                progress.classList.add('successfull')
                progress.style.backgroundColor ="#FF7171"
            }
             
         }
         return () => {
        };
 
     },[uploading,sucess,errordata])
    useEffect(()=>{
        if(file||Headerfile||(props.username !== username)||(props.website!==website)||(props.bio !==bio)){
            // dispatch(
                // setUpdateBtn((props.website!==website)||file||Headerfile||(props.username !== username)||(props.website!==website)||(props.bio !==bio)))
           
        }
        else{
            dispatch(
                setUpdateBtn((props.website!==website)||file||Headerfile||(props.username !== username)||(props.website!==website)||(props.bio !==bio)))
        }

        if(callupadtefunction){
            handleUpdate();
            setAddingStatus(true)
          dispatch(callUpdateFunction())
        }
        return () => {
          };
    },[file, Headerfile, username, website, bio, callupadtefunction])

    useEffect(()=>{
        const isAdmin= async()=>{
        const form = new FormData();
        form.append('userId',parseInt(localStorage.getItem("userId")));
        const result = await axios.post(`${configuration.BACK_END_HOST}/isadmin`, form)
        if(result.data.admin){
            setIsOwner(result.data.admin)
            setIsAdmin(result.data.admin)
        }
    }
    isAdmin()
    return () => {
       
      };
    },[])
    const handleError = (event) => {
        event.target.src = '/assets/images/thumb_avatar-default-image.jpg'; // Replace with your default image URL
    };
    return (
        <div>
        <div id="artist-profile">
            {/* {loadindInfo && <HeaderPreloader />} */}
            {/* <Helmet>
                <meta charSet="utf-8" />
                <meta name="og:title" content={`${username}`}/>
                <meta name="og:description" content={`${bio}`}/>
                <meta name="og:image" content={`${profile}`}/>
            </Helmet> */}
            <div className="col-10 mx-auto mt-2 loaders">
                {/* {uploading&&(<Loading text="Updating Profile" />)}
                    {sucess&&(<Sucess close={setSuccess}  text="you have succesfully updated your profile"></Sucess>)} */}
                   {addingstatus &&(<div className="col-10 mx-auto mt-2 loaders"><div className=" progress-container px-0">
                        <div className='cont'>
                        <div className='progress-bar btn-success'></div>
                        </div>
                        <div className='message fw-bold text-sucess-pane' style={{fontWeight:"bold"}}> {sucess && !errordata ?<span className='ml-2'> <Done/> {message} </span>:<span className='ml-3'> {uploading && !errordata ?<Loader/>:<CloudErrorComponent/>}  {message}</span>}</div>
                        <div className='close' onClick={(e)=>{setAddingStatus(false)}}> <Closeicon /></div>
                    </div></div>)}
                    </div>
                <div>
                    {/* {console.log(UserInfo.UserInfoByusername.username)} */}
                    <div className=" artist-cover filter-blur" style={{backgroundImage:` linear-gradient(rgba(94, 94, 94, 0) 0%, rgba(15, 15, 15, 0.64) 0%),url("${header}")`,}}></div>
                <div className="artist-header" >
                    {isOwner&&(<MyDropZone onDropheader={onDropheader}/>)}
                    {(file||Headerfile||(props.username !== username)||(props.website!==website)||(props.bio !==bio))&&(
                         <div className='edit-profile-btn' onClick={()=>{
                            handleUpdate();
                            setAddingStatus(true)
                        //   dispatch(callUpdateFunction())
                         }}> Update Profile</div>
                    )}
                    <div className="row" style={{ marginLeft:0,marginRight:0,}}>
                        <div className="col-7 col-md-3 mt-5 col-middle">
                            <div className="cover-profile">
                                <div className="cover-wrapper-profile">
                                    <img src={`${profile}`} alt="" onError={handleError}  />
                                </div>
                                {isOwner&&(<div className="upload-dropzone"{...getRootProps()}>
                                <input {...getInputProps()} multipe={false} />
                                    <PencilIcon/></div>)}
                                
                            </div>
                        </div>
                        <div className="col-8"><div className=" ">
                        <div className='user-details'>
                            <div className="playlist-title d-flex" style={{color:'#fff'}}> <ContentEditable
                      disabled={!isOwner}
                      html={username}
                      onChange={handleusername}
                      className="real_name mr-3"
                      tagName='span'
                    /> {props.id_group===9 &&(<VerifiedIcon/>)}  {props.title==='ghost'&& (!localStorage.getItem("userId")||localStorage.getItem("usergroupId")===10)&&(<span  classname="claim-span"onClick={(e)=>{
                        e.preventDefault();
                        dispatch(claimProfile())
                        dispatch(claimData({value:{title:props.song,real_name:username}}))
                    }} > {tooltip&&props.title==="ghost" &&( 
                    <div className='ghost-tooltip'> 
                    {/* <div className='close-tooltip'><Closeicon/></div> */}
                     click here to claim this profile <div className='pointer'></div></div>)} <FontAwesomeIcon icon="fa fa-user-plus" className='claim-icon' onMouseEnter={(e)=>{
                        setTooltip(true)
                     }} 
                     onMouseLeave={(e)=>{
                        setTooltip(false)
                     }}
                     /></span>)} </div>
                    
                           
                            <div className="playlist-desc" title={bio} style={{color:'#fff'}}><ContentEditable
                      disabled={!isOwner}
                      html={bio===""?"No Bio":(bio)}
                      onChange={handleBio}
                      className="bio"
                      tagName='div'
                    /> </div>
                    {!isOwner ?(website===""?(<div></div>): (<>
                        { website.toLowerCase().includes("instagram")&& <a href={website} target="_blank" rel="noopener noreferrer"> <Ig/> </a>}
                        { website.toLowerCase().includes("facebook")&& <a href={website} target="_blank" rel="noopener noreferrer"> <Facebook/> </a>}
                        { website.toLowerCase().includes("youtube")&& <a href={website} target="_blank" rel="noopener noreferrer"> <YoutubeIcon/> </a>}
                        { website.toLowerCase().includes("spotify")&& <a href={website} target="_blank" rel="noopener noreferrer"> <Sportify/> </a>}
                        { website.toLowerCase().includes("twitter")&& <a href={website} target="_blank" rel="noopener noreferrer"><TwitterIco/></a>}
                         </>
                         )):(<div><TwitterIcon/> <ContentEditable
                             disabled={!isOwner}
                             html={website===""?"no website":website}
                             onChange={handleWebsite}
                             className="social-icon mt-1"
                             tagName='span'
                             /></div>)}
                           
                             
                   
                            <div className="playlist-desc mt-2" style={{color:'#fff'}}>{data && (props.title==="ghost"?data.AllMusicByGhostUser.length:data.AllMusicByArist.length)}  Songs {karma} Karma {buddies} Buddies</div>
                           {isAdmin&&props.shop_status==="P"&&(<div 
                            className='mt-3 artist-btn px-3 py-1 text-center'  
                            style={{cursor:'pointer' ,width:"150px"}}
                            onClick={async ()=>{
                                const res= await appproveShop({
                                    variables: {
                                        userId: props.userId,
                                    }
                                })
                                console.log(res)
                            }}
                            > Approve Shop</div>)}
                            
                             
                        </div>
                        
                       
                    </div></div>
                    </div>
                    
                    
                   
                    
                </div>
                </div>
               
                 
    <div className="row  mt-4"style={{ marginLeft:0,marginRight:0,}}>
        <div className="col-auto mx-auto col-md-6 col-lg-7 nav-down">
            <div className="d-flex artist-nav"><Link to={`/a/${props.username.toLowerCase().replaceAll(' ','-')}`}> <div className={`ml-4 artist-nav-text ${status==="songs"?"nav-active":""}`} onClick={(e)=>{
                  
                  setStatus("songs")
            }}>Songs</div></Link> <Link to={`/a/${props.username.toLowerCase().replaceAll(' ','-')}/playlists`}><div className={`ml-4 artist-nav-text ${status==="playlists"?"nav-active":""}`}
            onClick={(e)=>{
                setStatus("playlists")
          }}>Playlists</div> </Link> <Link to={`/a/${props.username.toLowerCase().replaceAll(' ','-')}/albums`}> <div className={`ml-4 artist-nav-text ${status==="albums"?"nav-active":""}`} onClick={(e)=>{
            
            setStatus("albums")
              }}>Albums</div> </Link> <Link to={`/a/${props.username.toLowerCase().replaceAll(' ','-')}/shop`}> <div className={`ml-4 artist-nav-text ${status==="shop"?"nav-active":""}`} onClick={(e)=>{
        
        setStatus("shop")
            }}>Shop</div> </Link></div>
        </div>
        <div className="col-5 col-md-5 col-lg-4 hidebtn ">
            <div className="d-flex">{ (!isOwner&& props.title!=="ghost") ?(<AddBuddie myBuddieId={props.userId} Beaction={BefriendAction}><div className='artist-btn px-4 py-1 sider' style={{cursor:"pointer"}} >
                <FollowIcon/>  
                <span className='' style={{cursor:'pointer'}}>{beFriendStatus} </span> </div></AddBuddie>):(isOwner&&(<div className='artist-btn px-4 py-1 sider' style={{cursor:"pointer"}} 
                onClick={(e)=>{
                    e.preventDefault();
                    dispatch(setShowFriends())
                }}
                >
                {/* <FollowIcon/>   */}
                <span className='' style={{cursor:'pointer'}}>Friends </span> </div>))} <div className='ml-2 artist-btn px-3 py-1' style={{cursor:"pointer"}} onClick={(e) => {
                                e.preventDefault()
                                dispatch(
                                    setsharedUrl(
                                      `${window.location.href}`
                                    )
                                  );
                            }}> <ShareButton/> <span className='' style={{cursor:"pointer"}}> Share </span></div></div>
        </div>
    </div>
    
<div className='row mt-3' style={{ marginLeft:0,marginRight:0,}}>
{status==="songs" ?(
     <div className="col-12 col-md-12 col-lg-8  ">
     <div className="table-heading ">
             <div className="row mx-0 single-music-header justify-content-between" style={{ marginLeft:0,marginRight:0,}}>
                 <div className="col-1 px-0 numbering">#</div>
                 <div className="col-6 px-0 music">
                     <div className="d-flex">
                         <div className="music-thumbnail" ></div>
                         <div className="music-info">
                             <div className="music-title">Title</div>
                         </div>
                     </div>
                 </div>
                 <div className="col-1 px-0 album"></div>
                 <div className="col-2 pl-2 react">React</div>
                 <div className="col-1 px-0 play">
                     <div className="d-flex justify-content-end">
                         <div></div>
                         <div >
                             Play
                         </div>
                     </div>
                 </div>
             </div>
             <div className="col-md-12 px-0"><hr /></div>
         </div>
         
           {podcast&& podcast.map((podcast)=>{
            const name =podcast.musicName.split(" with ");
            if(name[1]){
            if(name[1].toLowerCase().includes(props.username.toLowerCase())){
                //setTitle(name[0])
            return (<PodcastMusicElement
                music_file={0}
                music_thumbnail={0}
                music_artist={0}
                title={name[0]}
                thumbnail={podcast.imageUrl}
                file={podcast.musicUrl}
                username={name[1]}
                music_id={0}
                count={0}
                userId={0}
                index={0}
            />) ;}}
           })}
             {loading && (<SingleMusicPreloader />)}
             {data && (props.title==="ghost"?(data.AllMusicByGhostUser.map((music: any, count: any) => {
                 musicData.push({
                     name: music.title,
                     singer: music.username,
                     cover: `${configuration.FILE_STORAGE_LINK}${music.thumbnail}`,
                     musicSrc: `${configuration.READFILE_LINK}${music.file}`,
                 }) 
                 
                 if(count<countNumber){
                 return (<MusicElement
                     music_file={music.musicId}
                     music_thumbnail={music.imageId}
                     topic_id={music.topicId}
                     music_artist={music.memberId}
                     title={music.title}
                     isAdmin={isAdmin}
                     thumbnail={`${configuration.FILE_STORAGE_LINK}${music.thumbnail}`}
                     file={`${configuration.READFILE_LINK}${music.file}`}
                     username={music.username}
                     music_id={music.topicId}
                     count={(count + 1).toLocaleString('en-US', {
                         minimumIntegerDigits: 2,
                         useGrouping: false,
                     })}
                     approved={music.activated}
                     userId={music.userId}
                     color_name={music.color_name}
                     index={count}
                 />)}
             })):isAdmin?(AllMusic&&
                AllMusic.AllMusicByAristUserId.map((music: any, count: any) => {
                musicData.push({
                    name: music.title,
                    singer: music.username,
                    cover: `${configuration.FILE_STORAGE_LINK}${music.thumbnail}`,
                    musicSrc: `${configuration.READFILE_LINK}${music.file}`,
                }) 
                
                if(count<countNumber){
                return (<MusicElement
                    music_file={music.musicId}
                    music_thumbnail={music.imageId}
                    music_artist={music.memberId}
                    title={music.title}
                    isAdmin={isAdmin}
                    topic_id={music.topicId}
                    thumbnail={`${configuration.FILE_STORAGE_LINK}${music.thumbnail}`}
                    file={`${configuration.READFILE_LINK}${music.file}`}
                    username={music.username}
                    music_id={music.topicId}
                    color_name={music.color_name}
                    approved={music.activated}
                    count={(count + 1).toLocaleString('en-US', {
                        minimumIntegerDigits: 2,
                        useGrouping: false,
                    })}
                    userId={music.userId}
                    index={count}
                />)}
            })):
                data.AllMusicByArist.map((music: any, count: any) => {
                 musicData.push({
                     name: music.title,
                     singer: music.username,
                     cover: `${configuration.FILE_STORAGE_LINK}${music.thumbnail}`,
                     musicSrc: `${configuration.READFILE_LINK}${music.file}`,
                 }) 
                 
                 if(count<countNumber){
                 return (<MusicElement
                    recommend={data.AllMusicByArist.filter((song)=>song.title!==music.title)}
                     music_file={music.musicId}
                     music_thumbnail={music.imageId}
                     music_artist={music.memberId}
                     title={music.title}
                     isAdmin={isAdmin}
                     topic_id={music.topicId}
                     thumbnail={`${configuration.FILE_STORAGE_LINK}${music.thumbnail}`}
                     file={`${configuration.READFILE_LINK}${music.file}`}
                     username={music.username}
                     color_name={music.color_name}
                     music_id={music.topicId}
                     count={(count + 1).toLocaleString('en-US', {
                         minimumIntegerDigits: 2,
                         useGrouping: false,
                     })}
                     userId={music.userId}
                     index={count}
                 />)}
             }))}
             {data&& (props.title==="ghost"?(data.AllMusicByGhostUser.length>5):(data.AllMusicByArist.length>5))&&(countNumber<=5?(
                <div className='text-center text-white cursor-pointer' onClick={()=>{
                    setcountnumber(100)
                   }}>  view more</div>
             ):(
                <div className='text-center text-white cursor-pointer ' onClick={()=>{
                    setcountnumber(5)
                   }}>  view less</div>
             ))}
           
             </div>
): status==="playlists"?(<div className="col-12 col-md-12 col-lg-8  ">
     <AllPlaylist searchResult={false} musician={props.userId} username={props.username}  />
</div>):status==="albums"? (<div className="col-12 col-md-12 col-lg-8 "> <AllAlbums searchResult={false} musician={props.userId}  /></div>):status==="shop"?(<div className="col-12 col-md-12 col-lg-8 "><ArtistShop shop_requests={props.shop_requests} username={props.username} owner={isOwner} musician={props.userId} products={props.products} status={props.shop_status} isAdmin={isAdmin}/></div>):''}
   
            <div className="col-sm-12 col-md-12   col-lg-4 mb-5">
                <h5 className='mt-3' style={{color:"#fff",fontSize:"bold"}}>More Artist</h5>
        {
        
        Otherartist &&(Otherartist.MoreArtist.map(artist=>{
            count++
            if(count<4)
            {return( <div className='row song-owner py-2 mt-1' style={{ marginLeft:0,marginRight:0,}}>
            <div className='col-2 col-md-2 col-sm-2 col-lg-4'>
            <div className="artist"  title={artist.username}>
                    <div className='artist-wrapper'>
                        <div>
                    <img  src={`${configuration.FILE_STORAGE_LINK}${artist.profileImg}`} alt="" className='artist-img' loading='lazy' aria-hidden="false"/>
                    </div>
                    </div>
                </div>
                </div>
                <div className='col-6 mt-lg-2 mt-md-3 mt-sm-3'>
                   <Link to={`/a/${artist.username.replaceAll(' ','-').toLowerCase()}`}> <div className='artist-name ' title={artist.username}>{artist.username}</div></Link>
                    <div className='artist-title'>Musician</div>
                </div>
        </div>)}})
       )}
        
       
    </div>
   
   
</div>

        </div>
        </div>
    )
}

export default  AllMusicsByArtist
