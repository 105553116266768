/* eslint-disable jsx-a11y/anchor-is-valid */
// @ts-nocheck
import React, { useState, useRef, MutableRefObject } from 'react';
//helpers
import { Link } from "react-router-dom"
import { useMutation, gql, ApolloError } from "@apollo/client"

//google recaptcha
import ReCAPTCHA from "react-google-recaptcha"

import Sucess from "../sucess/index"
import Loading from "../loading/index"
import Error from "../error/index"

import "./index.scss"

import axios from "axios";

import configuration from '../../config/configuration';

import GoBackIcon from "../login/src/GoBackIcon"


export default function SignUp() {

  //states
  const [errorData, seterrorData] = useState<String | null>(null)

  //apollo client initialization
  const MUTATION = gql`mutation($fullname:String!,$username:String!,$email:String!,$password:String!,$token:String!){
    SignUp(signUpData:{fullname:$fullname,username:$username,email:$email,password:$password,token:$token}){
        userId
    }
  }`
  const [SignUp, { loading, data, error }] = useMutation(MUTATION, {
    errorPolicy: "all"
  })
  //END OF APOLLO CLIENT INITIALIZATION

  //captch ref
  const reRef = useRef()
  const [close,setClose]=useState<Boolean>(false);

  const value = (id: string) => {
    var inputValue = (document.getElementById(id) as HTMLInputElement).value
    return inputValue;
  };
  const handleSubmit = async (e: { preventDefault: () => void; }) => {
    seterrorData(null)
    e.preventDefault();
    setClose(true)
    const [
      fullName,
      username,
      email,
      password,
      comfirmPassword,
    ] = [
        value('fullName'),
        value('username'),
        value('emailAdress'),
        value('password'),
        value('comfirmPassword'),
      ];
    //@ts-ignore
    const regex = /[?@\/#:\,;%+&=\{\}\[\]'\^-]/g
    // if(fullName.match(regex)){

    //   return (seterrorData("Usernames can only contain letters, numbers, underscores and dots."))

    // }
    if(username.match(regex)){

      return (seterrorData("Usernames can only contain letters, numbers, underscores and dots."))

    }
    const { checked } = document.getElementById('flexCheckDefault');
    if (!checked) {
      seterrorData("Please accept terms and condition to proceed")
    }
    if (checked) {
      const token = await reRef.current.executeAsync()
      reRef.current.reset()
      if (token) {
        if (password === comfirmPassword) {
          //PROCESS THE SIGN UP
          try {
            const result = await SignUp({
              variables: {
                fullname: `${fullName}`,
                username: `${username}`,
                email: `${email}`,
                password: `${password}`,
                token: `${token}`
              }
            })
            // console.log(result)
            if (result) {
              if (data) {
              }
            }
          } catch (e) {
          }
        }
      }
    }
  };
  return (
    <div className="container-fluid" id="signup">
      <div className="row">
      <div className="col-md-8 px-3 col-sm-8 loaders">
                            {close&&loading && <Loading></Loading>}
                            {close&&data && <Sucess text="you have succesfully signed up. Please verify your email." close={setClose}>{localStorage.setItem("userId", data.SignUp.userId)}</Sucess>}
                            {close&&errorData && <Error close={setClose} error={errorData}></Error>}
                            {close&&error &&
                              error.graphQLErrors.map(({ message }, i) => (
                                <Error error={message}close={setClose}></Error>
                              ))
                            }
                          </div>
        <div className="col-md-4 ">
          <div className="row onboard">
            <div className="col-md-12 pl-0">
              <div className="d-flex justify-content-end">
                <h6 className="onboard-title">
                The True Alternative to Alternative Music(TM).
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-8 justify-content-center">
          <div className="row  sign-up">
            <div className="all-items">
              <div className="row mx-0 justify-content-center">
                <div className="col-md-1">
                  <Link to="/">
                    <div className="go-back-icon">
                      <GoBackIcon />
                    </div>
                  </Link>
                </div>
                <div className="col-md-11 col-lg-10 col-xl-8">
                  <div className="col-md-12 col-xl-12">
                    <h1>Create a New Account</h1>
                    <h6>
                      Enter a new paradigm in Music streaming: Leerecs is The True Alternative to Alternative Music.  Where the spotlight is focussed on Independent Musicians making original Alternative Music.
                    </h6>
                    <div className="row">
                      <form action="" onSubmit={handleSubmit}>
                        <div className="col-md-12 row forms">
                          {/* <div className="col-md-12 px-0">
                            {loading && <Loading></Loading>}
                            {data && <Sucess text="you have succesfully signed up. Please verify your email.">{localStorage.setItem("userId", data.SignUp.userId)}</Sucess>}
                            {errorData && <Error error={errorData}></Error>}
                            {error &&
                              error.graphQLErrors.map(({ message }, i) => (
                                <Error error={message}></Error>
                              ))
                            }
                          </div> */}
                          <div className="col-md-12">
                            <div className="label">Full name</div>
                            <input className="input-style" type="text" id="fullName" maxLength={25}/>
                          </div>
                          <div className="col-md-12">
                            <div className="label">Username</div>
                            <input className="input-style" type="text" id="username" maxLength={25}/>
                          </div>
                          <div className="col-md-12">
                            <div className="label">Email Address</div>
                            <input className="input-style" type="email" id="emailAdress" />
                          </div>
                          <div className="col-md-12">
                            <div className="label">Password</div>
                            <input
                              className="input-style"
                              type="password"
                              id="password"
                            />
                          </div>
                          <div className="col-md-12">
                            <div className="label">Comfirm Password</div>
                            <input
                              className="input-style"
                              type="password"
                              id="comfirmPassword"
                            />
                          </div>
                          <div className="col-md-12 recaptcha">
                            <ReCAPTCHA sitekey={configuration.RECAPTCHA_SECRET_KEY} ref={reRef} theme="dark" size="invisible" />
                          </div>
                          <div className="col-md-12">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="flexCheckDefault"
                                checked
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckDefault"
                                required >
                                By creating an account it mean you are okay with{' '}
                                <a href="https://idealiner.com/privacy/" target="_blank" rel="noreferrer">our privacy policy</a> and our default
                                <a href="https://idealiner.com/terms/" target="_blank" rel="noreferrer"> Terms of service</a>
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <button
                              className="btn btn-primary create-btn"
                              type="submit"
                            >
                              Create Account
                            </button>
                          </div>
                          <div className="col-md-6">
                            <Link to="/login">
                              <div className="btn btn-outline btn-primary login-btn">
                                Login
                              </div>
                            </Link>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
