import React, {useState} from 'react'
import {useLocation} from 'react-router-dom';
import { useAppDispatch } from '../../redux/hooks';
import { setInfoBanner,setInfoShowPage } from '../../redux/data/uiData';

//helerps
import {gql,useMutation} from "@apollo/client"
import ReactGA from 'react-ga4';

interface props{
    music_id:Number;
}

const LikeMusic: React.FC<props> = (props) => {
    // const history =useHistory()
    const dispatch = useAppDispatch();
    const location =useLocation()
    const MUTATION = gql`mutation
    (
        $music_id: Int!,
        $userId: Int!,
    ){
    AddMusicLike(musicLikedData:{
        music_id: $music_id,
	    userId: $userId,
    }){
        desc
    }
}`

    const [AddMusicLike,{loading,data,error}]=useMutation(MUTATION)

    const handleClick=async (e:any)=>{
        e.preventDefault()
        if(!localStorage.getItem("token")){
            dispatch(setInfoBanner())
            dispatch(setInfoShowPage(location.pathname))
        }
        try {
            // console.log("music_id",props.music_id)
            const result=await AddMusicLike({variables:{
                music_id: props.music_id,
                userId: parseInt(localStorage.getItem("userId") as string),
            }})
            if (result){
                 //console.log(result)
                 ReactGA.event({
                    category: 'Engagement', // You can choose your own category name
                    action: 'Liked a post', // You can name the action as you like
                    label: 'Like' // Optional, to provide more details about the event
                  })
                setstate(true)
            }
            
        } catch (e) {
        }

    }
    const [state, setstate] = useState(false)
    let id = state ? "heart-clicked":" "
    return (
        <div onClick={handleClick} id={`${id}`}>
            {props.children}
        </div>
    )
}

export default LikeMusic
