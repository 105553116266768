import React from 'react'

const Logout = () => {
  return (
    <div className='song-simple'>
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 30 30">
      <g id="Group_73" data-name="Group 73" transform="translate(-1250.992 -15)">
        <rect id="Rectangle_17" data-name="Rectangle 17" width="30" height="30" rx="5" transform="translate(1250.992 15)" fill="#383838" strokeWidth="2" />
        <g id="log-out" transform="translate(1253.992 17)">
          <path id="Path_34" data-name="Path 34" d="M12,12h7m0,0-3,3m3-3L16,9" transform="translate(0 0)" fill="none" stroke="#bfbfbf" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
          <path id="Path_35" data-name="Path 35" d="M19,6V5a2,2,0,0,0-2-2H7A2,2,0,0,0,5,5V19a2,2,0,0,0,2,2H17a2,2,0,0,0,2-2V18" transform="translate(0 0)" fill="none" stroke="#bfbfbf" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
        </g>
      </g>
    </svg>
    </div>

  )
}

export default Logout
