import React,{useState} from 'react'
import { useAppDispatch } from "../../redux/hooks"
//dispatch action
import { hideContributeBtn } from "../../redux/data/navbar/index"
import "../musicHome/index"
import Dashbaord from "../../components/musicianPageComponents/countCard/index"
import Activity from "../../components/musicianPageComponents/activity/index"
import Chart from "../../components/musicianPageComponents/charts/index"

//scss files
import "./index.scss"
import PieIcon from './src/PieIcon'
import NotificationIcon from './src/NotificationIcon'

// import MusicOfficialPlayer from "../../components/musicPageComponents/musicPlayer/index"

const MusicianDashboard = () => {
    const dispatch = useAppDispatch()
    const hideContribute =() => { dispatch(hideContributeBtn())
}

const [state, setstate] = useState("charts")

    const whiteBtn = (state === "notification") ? "white-color" : "black-color"
    const chartWhiteBtn = (state === "charts") ? "white-color" : "black-color"
return (
    
                    <div className="row mx-0">
                        <div className="dashboard px-0">
                            <div className="dashboard-navigator d-flex">
                                <div className={`charts ${chartWhiteBtn}`} onClick={(e) => {
                                    setstate("charts")
                                }}>
                                    <div className="d-flex">
                                        <PieIcon />
                                        <div className={`text ${chartWhiteBtn}`}>Charts</div>
                                    </div>
                                </div>
                                <div className={`notification ${whiteBtn}`} onClick={(e)=>{
                                    setstate("notification")
                                }}>
                                    <div className="d-flex">
                                        <NotificationIcon />
                                        <div className={`text ${whiteBtn}`}>Notes</div>
                                    </div>
                                </div>
                            </div>
                            {(state === "charts") && (
                            <div>
                                <Dashbaord />
                                <Chart />
                            </div>)}
                        </div>
                        <div className={`activity px-0`}>
                            {(state === "notification") && (
                                <div><Activity /></div>
                            )}
                        </div>
                    </div>
               
)
}

export default MusicianDashboard
