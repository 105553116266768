import React, { useEffect } from "react";

//redux hooks
import { useAppDispatch } from "../../../redux/hooks";
import { Link } from "react-router-dom";

//wrapper
import LikeMusic from "../../wrappers/likeMusic";
import Player from "../../../pages/main/components/eventsSection/src/Player";
import { useQuery, gql } from "@apollo/client";
import configuration from "../../../config/configuration";

//iicons
import AddPlaylistIcon from "./src/AddPlaylistIcon";
import Heartcon from "./src/Heartcon";

//redux actions
import { playSingle, setMusicId } from "../../../redux/data/musicPlayer/index";
import { setsharedUrl } from "../../../redux/data/artistProfile";
import CardShareIcon from "../featuredArtists/CardShareIcon";
import { addMusic } from "../../../redux/data/playlist/index";
import { playlist } from "../../../redux/data/uiData/index";

interface props {
    title:string;
    thumbnail:string;
    vendor:string;
    owner:boolean
    published:boolean
    url:string;
    price?:string
    home?:boolean
}

const Product: React.FC<props> = (props) => {
  const dispatch = useAppDispatch();
  const handleError = (event:any) => {
    event.target.src = '/assets/images/thumb_avatar-default-image.jpg'; // Replace with your default image URL
};
  return (
    <div className="col-md-3 px-2  card  music-e-card  shadow-sm">
      {/* <Link to={`/music/album/${props.albumId}`}> */}
      <div className="cover">
        <div className="cover-wrapper">
          <div>
            <img src={props.thumbnail} alt="" aria-hidden="false" loading="lazy" onError={handleError}  />
          </div>
        </div>
         
        
      </div>
      <Link to={`/a/${props.vendor.replaceAll(' ','-').toLowerCase()}`}><div className="song-name px-1 mt-2" >{props.title}</div></Link>
            <Link to={`/a/${props.vendor.replaceAll(' ','-').toLowerCase()}`}><div className="numbering mb-1 mt-1 px-1">By {props.vendor}</div></Link>
            {!props.home&&( <a href={props?.url} onClick={() => {
    // Navigate to the specified URL
    window.open(props?.url, '_blank', 'noopener,noreferrer');
  }}  className='text-white border rounded px-2 text-center' > Buy </a>)}
           
  {props.home&&(<a href={props?.url} className='text-white border rounded px-2 text-center' target="_blank" rel="noopener noreferrer">Buy</a>)}
 
    </div>
  );
};

export default Product;
