// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useQuery, gql } from "@apollo/client";
import MusicSideBar from "../../components/musicPageComponents/sidebar/index";
import MusicNavBar from "../../components/musicPageComponents/navbar";
import LottieWrapper from "../../components/lottie/lottie";
import BigLoader from "../../components/signup/src/icons/bigLoader";
import configuration from "../../config/configuration";
import SingleSongProfile from "../../components/musicPageComponents/singleSongProfile";
import MusicEmptyCase from "../Emptycase/MusicEmptyCase";

//wrappers
import SecureRoute from "../wrapper/SecureRoute";

import "./index.scss";
import MusicFooter from "../main/components/footerSection/MusicFooter";
import { useParams } from "react-router-dom";

const SingleSongPage = (props: any) => {
  const {owner,songname}=useParams()
  const QUERY = gql`
    query {
      SingleSongData(
        profileInfo: { owner: "${owner.replaceAll('-',' ')}", songname: "${songname.replaceAll('-',' ')}" }
      ) {
        title
        thumbnail
        file
        username
        musicId
        imageId
        memberId
        topicId
        anonymous
        messageId
        lyrics
        cover_id
        id_msg
        user_id
        albumNo
        playlistNo
        color
        color_name
        owner{
           username
	title
	website
	bio
	profileImg
	headerImg
	profileId
	headerId
	userId
	karma
	buddies
	buddie_list
	id_group
       }    
    
      }
    }
  `;

  const { loading, data, error } = useQuery(QUERY /*, { pollInterval: 500 }*/);
  
  
  return (
    <>
      
              {data ? (<>
                <SingleSongProfile
                  songData={data.SingleSongData}
                />
                <MusicFooter/>
                </>
              ) :!loading? (
                <MusicEmptyCase/>
              ) : (
                <div className="bigLoader">
                  <BigLoader />
                </div>
              )}

            </>
  );
};

export default SingleSongPage;
