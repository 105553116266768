import React, {useState} from 'react'
import {useHistory,useLocation} from 'react-router-dom';

//helerps
import {gql,useMutation} from "@apollo/client"
import ActivityIcon from '../musicianPageComponents/activity/src/ActivityIcon';
import { useAppDispatch } from '../../redux/hooks';
import { setInfoBanner ,setInfoShowPage} from '../../redux/data/uiData';

interface props{
    myBuddieId:Number;
    Beaction:any;
}

const AddBuddie: React.FC<props> = (props) => {
    const MUTATION = gql`mutation
    (
        $myId:Int!,
        $myBuddieId:Int!
    ){
        AddBuddie(buddie:{
        myId: $myId,
	    myBuddieId: $myBuddieId,
    }){
        desc
    }
}`

    const [Buddie,{loading,data,error}]=useMutation(MUTATION)
    const location =useLocation()
    const dispatch = useAppDispatch();
    const handleClick=async (e:any)=>{
        e.preventDefault()
       
        
        if(!localStorage.getItem("token")){
            
            dispatch(setInfoShowPage(location.pathname))
            dispatch(setInfoBanner())
        }
        try {
            
            if(localStorage.getItem("userId")){
                
           props.Beaction("Loading","BeFriending...")
           console.log(props.myBuddieId)
           
            const result=await Buddie({variables:{
                myId: parseInt(localStorage.getItem("userId") as string),
                myBuddieId: props.myBuddieId,
            }})
           
            if (result){
                console.log(result.data.AddBuddie.desc)
                props.Beaction(result.data.AddBuddie.desc,"BeFriending...")
                setstate(true)
            }
            }
        } catch (e:any) {
            console.log(e.message)
        }

    }
    const [state, setstate] = useState(false)
    let id = state ? "user-followed":" "
    return (
        <div onClick={handleClick} id={`${id}`}>
            {props.children}
        </div>
    )
}

export default AddBuddie
