import React from 'react'
import "./index.scss"

export default function HeaderPreloader() {
    return (
        <div id="header-preloader">
            
        </div>
    )
}
