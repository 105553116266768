// @ts-nocheck
import React, { useEffect, useState} from 'react'

//helpers
import { useQuery, gql } from "@apollo/client"
import { useAppSelector } from '../../../redux/hooks'
import { useLocation } from 'react-router'

//components
import MusicElement from "./MusicElement"
import ShowMoreComponent from '../showMoreComponent'
import LottieWrapper from "../../lottie/lottie"

// preloader
import Preloader from './preloader'

import "./index.scss"

//configuration
import configuration from '../../../config/configuration'

interface props {
    allMusic?: boolean;
    searchResult?: boolean;
    addMargin?: boolean;
    data:any;
    loading: boolean;
    error: any;
}

const AllAlbums: React.FC<props> = (props) => {
    var count = 0;
    const margin = (props.addMargin) && "marg-t"

    

    const location = useLocation()

    const noWrap = (link:String) => {
        if (location.pathname === link) {
            return "wrap"
        }
        else {
            return "nowrap"
        }
    }

    const [countnumber, setcountnumber] = useState(5)
    useEffect(() => {
        function handleResize() {
            console.log('resized to: ', window.innerWidth, 'x', window.innerHeight)
            let width = window.innerWidth
            if (width <= 1024) {
                setcountnumber(4)
            }
            if (width <= 768) {
                setcountnumber(4)
            }
        }
        handleResize()
        window.addEventListener('resize', handleResize)

    }, [])

    const preloader = () => {
        const i = props.allMusic ? [0, 1, 2, 3, 4, 0, 1, 2] : [...Array(countnumber).keys()]
        return i.map((item,id) => {
            return (<Preloader key={id} />)
        })
    }

    return (
        <div>
           
                <div id={props.allMusic?"all-playlist":"new-playlist"}className='mb-3'>
                    <div className={`d-flex justify-content-between ${margin} `}>
                        <h3> {props.allMusic ?"All Albums":"Trending Albums"}</h3>
                        {!props.allMusic && !props.searchResult&& <ShowMoreComponent title="Show More Albums" link="/music/allalbums" />}
                        {!props.allMusic && props.searchResult && (
              <div>
              <ShowMoreComponent
               title="Show All Albums Results" link="/music/searchResult/albums"
              />
            </div>
            )}
                    </div>
                    {props.loading && (
                        <div className={`row card-deck ${noWrap("/music/allalbums")}`}>
                            {preloader()}
                        </div>)}
                    <div className={`row card-deck ${noWrap("/music/allalbums")}`} >
                        {props.data && (props.data.map((album: any, idx: any) => {
                            
                            count++;
                            if (props.allMusic) {
                                count = 0
                            }
                            if (count <= countnumber) {
                                return (<MusicElement
                                    key={idx}
                                    title={album.title}
                                    cover={`${configuration.FILE_STORAGE_LINK}${album.cover}`}
                                    count={(idx + 1).toLocaleString('en-US', {
                                        minimumIntegerDigits: 2,
                                        useGrouping: false,
                                    })}
                                    username={album.username}
                                    albumId={album.albumId}
                                    songs={album.songs}
                                    
                                />)
                            }
                        }))}
                        {props.error && (<LottieWrapper url="https://assets2.lottiefiles.com/packages/lf20_GlZGOi.json" width="calc(100% -  20px)" height="50vh" />)}
                        {(props.data &&(props.data.length === 0)) && (<LottieWrapper url="https://assets2.lottiefiles.com/packages/lf20_GlZGOi.json" width="calc(100% -  20px)" height="50vh" />)}
                    </div>
                </div>

        </div>
    )
}

export default AllAlbums
