import React from "react";
//redux
import {useQuery,gql} from "@apollo/client"

//components
import NewMusic from "../../components/musicPageComponents/newMusic/index";
import MusicFooter from "../main/components/footerSection/MusicFooter";


const MyMusic = () => {
  const userId=parseInt(localStorage.getItem("userId") as string)
  const QUERY =gql`query{
     AllMusicByAristUserId(userId:${userId}){
            title
            thumbnail
            file
            username
            musicId
            imageId
            memberId
            topicId
            messageId
            activated
            color_name
            playData{
        cover
        color
        singer
        name
        musicSrc
        }
        }
}`
   //loadind query
    const { loading, data, error } = useQuery(QUERY/*, { pollInterval: 500 }*/)
  return (
    <>
      <NewMusic data={data?.AllMusicByAristUserId} allMusic={true} loading={loading} searchResult={false} error={error} userId={parseInt(localStorage.getItem("userId") as string)}></NewMusic>
      {data && <MusicFooter/>}
    </>
  );
};

export default MyMusic;
