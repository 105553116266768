import React from 'react'

const ThumbIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18.065" height="20" viewBox="0 0 18.065 20">
      <g id="thumb-up" transform="translate(-2)">
        <path id="Path_79" data-name="Path 79" d="M5.226,14H2.645A.645.645,0,0,0,2,14.645v9.677a.645.645,0,0,0,.645.645H5.226Z" transform="translate(0 -4.968)" fill="#10f88b" />
        <path id="Path_80" data-name="Path 80" d="M9,20h9.858a2.581,2.581,0,0,0,2.535-2.11l1.145-6.159a.658.658,0,0,0,.011-.118v-.645a2.581,2.581,0,0,0-2.581-2.581H14.161V2.581A2.581,2.581,0,0,0,11.581,0h0a.645.645,0,0,0-.631.51L9,9.032Z" transform="translate(-2.484)" fill="#10f88b" />
      </g>
    </svg>

  )
}

export default ThumbIcon
