import React from 'react'

const AddMusicIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21.5" height="21.5" viewBox="0 0 21.5 21.5">
      <g id="add-circled-outline" transform="translate(-1.25 -1.25)">
        <path id="Path_82" data-name="Path 82" d="M8,12h4m4,0H12m0,0V8m0,4v4" fill="none" stroke="#383838" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
        <path id="Path_83" data-name="Path 83" d="M12,22A10,10,0,1,0,2,12,10,10,0,0,0,12,22Z" fill="none" stroke="#383838" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      </g>
    </svg>

  )
}

export default AddMusicIcon
