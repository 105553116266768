import React from 'react'

import { useQuery, gql } from '@apollo/client'

import { useHistory } from "react-router-dom"

const SecureMusician = (props: any) => {
    const history = useHistory()
    const QUERY = gql`query{
    IsMusicianAuthorized(auth:{
        userId:${localStorage.getItem("userId")},
        token:"${localStorage.getItem("musician")}"
    }){
        desc
    }
}`
    // const { loading, data, error } = useQuery(QUERY)
    // const auth = () => {
    //     if (error) {
    //         history.push("/login")
    //     }
    // }
    return (
        <div>
            
            {true && (<div>{props.children}</div>)}
            {/* {error && (auth())} */}
        </div>
    )
}

export default SecureMusician
