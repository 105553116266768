import React,{useState} from 'react'

export default function Eye(props:any) {

 const [close,setClose]=useState(props.approved)
 const clickeye=(e:any)=>{
  e.preventDefault();
  if(close===1){
    setClose(0)
  }else{
    setClose(1)
  }

 }
  return (
    <div onClick={clickeye}>
      {close=== 1?<><svg width="24" height="24" strokeWidth="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z" stroke="#ffff" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M21 12C19.1114 14.991 15.7183 18 12 18C8.2817 18 4.88856 14.991 3 12C5.29855 9.15825 7.99163 6 12 6C16.0084 6 18.7015 9.1582 21 12Z" stroke="#ffff" strokeLinecap="round" strokeLinejoin="round"/>
</svg></>:<><svg width="24" height="24" strokeWidth="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3 3L21 21" stroke="#ffff" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M10.5 10.6771C10.1888 11.0296 10 11.4928 10 12C10 13.1045 10.8954 14 12 14C12.5072 14 12.9703 13.8112 13.3229 13.5" stroke="#ffff" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M7.36185 7.5611C5.68002 8.73968 4.27894 10.4188 3 12C4.88856 14.991 8.2817 18 12 18C13.5499 18 15.0434 17.4772 16.3949 16.6508" stroke="#ffff" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M12 6C16.0084 6 18.7015 9.1582 21 12C20.6815 12.5043 20.3203 13.0092 19.922 13.5" stroke="#ffff" strokeLinecap="round" strokeLinejoin="round"/>
</svg></>}
      
    </div>
  )
}
