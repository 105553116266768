import Closeicon from './src/Closeicon';

const  ProgressBannerPlaylist=(props:any)=>{

    return(
        <div>
            <div className=" progress-container-playlist mb-3 px-0">
                        <div className='cont'>
                        <div className={`progress-bar btn-success ${props.status?"successfull":""}`} style={{width:`${props.width}`,backgroundColor:`${props.color}`}}></div>
                        </div>
                        <div className='message fw-bold text-sucess-pane pl-2' style={{fontWeight:"bold"}} > {props.icon} <span dangerouslySetInnerHTML={{__html: props.message}}></span>  </div>
                        <div className='close' onClick={(e)=>{props.close(false)}}> <Closeicon /></div>
                    </div>
        </div>
    )
}
export default ProgressBannerPlaylist;