// @ts-nocheck
import React, { useEffect, useState } from "react";
//helpers

import { useAppDispatch } from "../../../redux/hooks";
import { useLocation } from "react-router-dom";

//components
import MusicElement from "./MusicElement";
import ShowMoreComponent from "../showMoreComponent/index";
import LottieWrapper from "../../lottie/lottie";

//preloader
import Preloader from "./preloader";

import "./index.scss";

//configuration
import configuration from "../../../config/configuration";
import Previous from "./src/Previous";
import Next from "./src/Next";
import { setShowPayWall } from "../../../redux/data/uiData";

interface props {
  allMusic?: boolean;
  searchResult?: boolean;
  addMargin?: boolean;
  userId?: Integer;
  data:any;
  loading: boolean;
  error: any;
  
}

const NewMusic: React.FC<props> = (props) => {
  const [isUser,setIsUser]=useState(parseInt(localStorage.getItem("userId") as string))
  var count = 0;
  const location = useLocation();
  const dispatch = useAppDispatch();
  //handling size of music
  const [countnumber, setcountnumber] = useState(5);
  useEffect(() => {
    function handleResize() {
      let width = window.innerWidth;
      if (width <= 1024) {
        setcountnumber(4);
      }
      if (width <= 768) {
        setcountnumber(4);
      }
    }
    handleResize();
    window.addEventListener("resize", handleResize);
  }, []);
  const preloader = () => {
    const i = props.allMusic
      ? [0, 1, 2, 3, 4, 0, 1, 2, 3, 4, 0, 1, 2, 3, 4]
      : [...Array(countnumber).keys()];
    return i.map((itme,id) => {
      return <Preloader  key={id}/>;
    });
  };

  const noWrap = (link: String) => {
    if (location.pathname === link) {
      return "wrap";
    } else {
      return "";
    }
  };
  const [next5, setNext5] = useState(0);

  
  return (
    <div>
      
        <div id={props.allMusic ? "All-music" : "new-music"}>
          <div className="d-flex justify-content-between">
            <h3>
              {props.userId
                ? "My Music"
                : props.allMusic
                ? "All Music"
                : "Trending Music"}
            </h3>
            {!props.allMusic && !props.searchResult  && (
              <div>
                <ShowMoreComponent
                  title="Show All Music"
                  link="/music/allmusic"
                />
              </div>
            )}
            {!props.allMusic && props.searchResult && (
              <div>
              <ShowMoreComponent
                title="Show All Search Results"
                link="/music/searchResult/music"
              />
            </div>
            )}
          </div>
          {props.loading && (
            <div className={`row card-deck ${noWrap("/music/allmusic")}`}>
              {preloader()}
            </div>
          )}
          
          <div className="slider">
              {props.data && next5 < 0 && (
                <div
                  className="previous-5 slider-icon"
                  onClick={(e) => {
                    e.preventDefault();
                    if (next5 < 0) {
                      setNext5(next5 + 1015);
                      
                    }
                  }}
                >
                  <Previous />
                </div>
              )}

              <div
                className={`row card-deck ${noWrap("/music/allmusic")}`}
                style={{ transform: `translate3d(${next5}px,0px,0px)` }}
              >
                {!props.allMusic
                  ? props.data &&
                    props.data.map(
                      (music: any, idx: any) => {
                        count++;
                        if (props.allMusic) {
                          count = 1;
                        }

                        if (count) {
                          return (
                            <MusicElement
                              playData={music.playData}
                              key={idx}
                              music_file={music.musicId}
                              music_thumbnail={music.imageId}
                              music_artist={music.memberId}
                              topic_id={music.topicId}
                              title={music.title}
                              thumbnail={`${configuration.FILE_STORAGE_LINK}${music.thumbnail}`}
                              file={`${configuration.READFILE_LINK}${music.file}`}
                              username={music.username}
                              claim={music.anonymous}
                              color_name={music.color_name}
                              messageId={music.messageId}
                              count={(idx + 1).toLocaleString("en-US", {
                                minimumIntegerDigits: 2,
                                useGrouping: false,
                              })}
                            />
                          );
                        }
                      }
                    )
                  : props.data &&
                    props.data.map((music: any, idx: any) => {
                     
                      count++;
                      if (props.allMusic) {
                        count = 1;
                      }
                      if (count) {
                        return (
                          <MusicElement
                            playData={music.playData}
                            key={idx}
                            music_file={music.musicId}
                            music_thumbnail={music.imageId}
                            music_artist={music.memberId}
                            topic_id={music.topicId}
                            title={music.title}
                            thumbnail={`${configuration.FILE_STORAGE_LINK}${music.thumbnail}`}
                            file={`${configuration.READFILE_LINK}${music.file}`}
                            username={music.username}
                            claim={music.anonymous}
                            color_name={music.color_name}
                            messageId={music.messageId}
                            count={(idx + 1).toLocaleString("en-US", {
                              minimumIntegerDigits: 2,
                              useGrouping: false,
                            })}
                          />
                        );
                      }
                    })}
                
                {props.error && (
                  <LottieWrapper
                    url="https://assets3.lottiefiles.com/packages/lf20_oURJje.json"
                    width="calc(100% -  20px)"
                    height="30vh"
                  />
                )}
                {props.data && props.data.length === 0 && (
                  <LottieWrapper
                    url="https://assets3.lottiefiles.com/packages/lf20_oURJje.json"
                    width="calc(100% -  20px)"
                    height="30vh"
                  />
                )}
              </div>
              {props.data &&
                !props.allMusic &&
                next5 >
                  -(props.data.length / countnumber - 1) *
                    200 *
                    countnumber && (
                  <div
                    className="next-5 slider-icon"
                    onClick={(e) => {
                      e.preventDefault();
                      if (next5 > -(props.data.length / countnumber -1) *1000 * countnumber) {
                        setNext5(next5 - 1015);
                       
                      }
                      if(next5>-1015){
                        if(!isUser){
                        dispatch(setShowPayWall(true))}
                        }
                    }}
                  >
                    <Next />
                  </div>
                )}
            </div>
        </div>

    </div>
  );
};

export default NewMusic;
