import React,{useState,useEffect,useRef} from 'react'

//helper
import { gql, useQuery } from "@apollo/client"

//chart libaray
//documentation at https://www.youtube.com/watch?v=e4en8kRqwe8
import {ResponsiveContainer,AreaChart,Area,XAxis,YAxis,Tooltip} from "recharts"


//scss files
import "./index.scss"

const Chart = () => {
    const [data, setdata] = useState([{}])
    const [likes, setlikes] = useState([{}])
    const ref = useRef({
        count:0,
        likes:0
    });

    const QUERY=gql`
    query{
    AllMusicPlayRealTime(userId:${localStorage.getItem("userId")}){
        count
    }
    }`
    const LIKES=gql`
    query{
    AllLikesPlayRealTime(userId:${localStorage.getItem("userId")}){
        count
    }
    }`
    const MUSICWEEKLY=gql`
    query{
    AllMusicWeeklyStats(userId:${localStorage.getItem("userId")}){
        day
        count
    }
    }`
    const LIKESWEEKLY=gql`
    query{
    AllLikesWeekly(userId:${localStorage.getItem("userId")}){
        day
        count
    }
    }`

    const { loading, data: realTimeData, error, refetch}=useQuery(QUERY)
    const { loading: likesLoading, data: realtimelikes, error: likesError, refetch: likesRefetch } = useQuery(LIKES/*, { pollInterval: 500 }*/)
    const { loading:musicweekLoading, data:musicweek, error:musicweekError}=useQuery(MUSICWEEKLY/*, { pollInterval: 500 }*/)
    const { loading:likesweekLoading, data:likesweek, error:likesweekError}=useQuery(LIKESWEEKLY/*, { pollInterval: 500 }*/)

    useEffect(() => {
        const fecth=async()=>{
            const result = await refetch()
        try {
            if (result) {
                var today = new Date();
                var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
                if (ref.current.count === parseInt(realTimeData.AllMusicPlayRealTime.count)){
                }
                if (ref.current.count !== realTimeData.AllMusicPlayRealTime.count)
                {
                    ref.current.count = realTimeData.AllMusicPlayRealTime.count
                    setdata([...data, { day: time, value: ref.current.count }])
                }
            }
        } catch (error) {
        }
        }
        const fecthlikes=async()=>{
            const result = await likesRefetch()
        try {
            if (result) {
                var today = new Date();
                var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
                if (ref.current.likes === parseInt(realtimelikes.AllLikesPlayRealTime.count)){
                }
                if (ref.current.likes !== realtimelikes.AllLikesPlayRealTime.count)
                {
                    ref.current.likes = realtimelikes.AllLikesPlayRealTime.count
                    setlikes([...likes, { day: time, value: ref.current.likes }])
                }
            }
        } catch (error) {
        }
        }
        fecth()
        fecthlikes()
        
    })

    return (
        <div id="charts-data">
            <h3>Graphs</h3>
            <div className="chart-container">
                <div className="single-chart">
                    <h3>Live Music Plays</h3>
                    <h6>User that are playing your music in realtime</h6>
                    {data && (<ResponsiveContainer width="100%" height={250}>

                        <AreaChart margin={{ top: 0, left: 0, right: 0, bottom: 0 }} data={data}>
                            <defs>
                                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#F8107D" stopOpacity={1} />
                                    <stop offset="95%" stopColor="#F8107D" stopOpacity={0.0} />
                                </linearGradient>
                            </defs>
                            <Area dataKey="value" stroke="#F8107D" fill='url(#colorUv)' type="monotone" ></Area>
                            <XAxis dataKey="day" axisLine={false} tickLine={false} tickCount={3} tickMargin={0} height={18} padding={{ right: 15, left: 15 }} tick={{ fill: '#fff', fontSize: 12 }} />
                            <YAxis dataKey="value" axisLine={false} tickLine={false} tickCount={3} tickMargin={0} width={20} tick={{ fill: '#fff', fontSize: 12 }} />
                            <Tooltip content={<CustomTooltip />} />
                        </AreaChart>
                    </ResponsiveContainer>)}
                </div>
                <div className="single-chart">
                    <h3>Live Music Likes</h3>
                    <h6>User that likes your music in realtime</h6>
                    {likes && (<ResponsiveContainer width="100%" height={250}>

                        <AreaChart margin={{ top: 0, left: 0, right: 0, bottom: 0 }} data={likes}>
                            <defs>
                                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#F8107D" stopOpacity={1} />
                                    <stop offset="95%" stopColor="#F8107D" stopOpacity={0.0} />
                                </linearGradient>
                            </defs>
                            <Area dataKey="value" stroke="#F8107D" fill='url(#colorUv)' type="monotone" ></Area>
                            <XAxis dataKey="day" axisLine={false} tickLine={false} tickCount={3} tickMargin={0} height={18} padding={{ right: 15, left: 15 }} tick={{ fill: '#fff', fontSize: 12 }} />
                            <YAxis dataKey="value" axisLine={false} tickLine={false} tickCount={3} tickMargin={0} width={20} tick={{ fill: '#fff', fontSize: 12 }} />
                            <Tooltip content={<CustomTooltip1 />} />
                        </AreaChart>
                    </ResponsiveContainer>)}
                </div>
                <div className="single-chart">
                    <h3>Weekly music plays</h3>
                    <h6>How user played your music during the week</h6>
                    {musicweek && (<ResponsiveContainer width="100%" height={250}>

                        <AreaChart margin={{ top: 0, left: 0, right: 0, bottom: 0 }} data={musicweek.AllMusicWeeklyStats}>
                            <defs>
                                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#F8107D" stopOpacity={1} />
                                    <stop offset="95%" stopColor="#F8107D" stopOpacity={0.0} />
                                </linearGradient>
                            </defs>
                            <Area dataKey="count" stroke="#F8107D" fill='url(#colorUv)' type="monotone" ></Area>
                            <XAxis dataKey="day" axisLine={false} tickLine={false} tickCount={3} tickMargin={0} height={18} padding={{ right: 15, left: 15 }} tick={{ fill: '#fff', fontSize: 12 }} />
                            <YAxis dataKey="count" axisLine={false} tickLine={false} tickCount={3} tickMargin={0} width={20} tick={{ fill: '#fff', fontSize: 12 }} />
                            <Tooltip content={<CustomTooltip />} />
                        </AreaChart>
                    </ResponsiveContainer>)}
                </div>
                <div className="single-chart">
                    <h3>Weekly music Likes</h3>
                    <h6>How user liked your music during the week</h6>
                    {likesweek && (<ResponsiveContainer width="100%" height={250}>

                        <AreaChart margin={{ top: 0, left: 0, right: 0, bottom: 0 }} data={likesweek.AllLikesWeekly}>
                            <defs>
                                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#F8107D" stopOpacity={1} />
                                    <stop offset="95%" stopColor="#F8107D" stopOpacity={0.0} />
                                </linearGradient>
                            </defs>
                            <Area dataKey="count" stroke="#F8107D" fill='url(#colorUv)' type="monotone" ></Area>
                            <XAxis dataKey="day" axisLine={false} tickLine={false} tickCount={3} tickMargin={0} height={18} padding={{ right: 15, left: 15 }} tick={{ fill: '#fff', fontSize: 12 }} />
                            <YAxis dataKey="count" axisLine={false} tickLine={false} tickCount={3} tickMargin={0} width={20} tick={{ fill: '#fff', fontSize: 12 }} />
                            <Tooltip content={<CustomTooltip />} />
                        </AreaChart>
                    </ResponsiveContainer>)}
                </div>
            </div>
        </div>
    )
}

function CustomTooltip({ active, payload, label }: any) {
    try {
        if (active && payload[0].value) {
        }
    } catch (error) {
        return null
    }
    if (active) {
        return (
            <div id="tip">
                <h4>{label}</h4>
                <p>{payload[0].value} User played your musics</p>
            </div>
        );
    }
    return null;
}
function CustomTooltip1({ active, payload, label }: any) {
    try {
        if (active && payload[0].value) {
        }
    } catch (error) {
        return null
    }
    if (active) {
        return (
            <div id="tip">
                <h4>{label}</h4>
                <p>{payload[0].value} User That liked Your musics</p>
            </div>
        );
    }
    return null;
}


export default Chart
