import React from 'react'

//redux hooks
import {useAppDispatch} from "../../../redux/hooks"
//iicons
import AddPlaylistIcon from "./src/AddPlaylistIcon"

//redux actions
import { playSingle,setMusicId } from "../../../redux/data/musicPlayer/index"
import PencilIcon from './src/PencilIcon'
import { assignMUsic } from "../../../redux/data/assignMusic/index"
import ClaimIcon from './src/ClaimIcon'
import { assignMusicUi,updateAttachmentUi } from "../../../redux/data/uiData/index"
import UserIcon from './src/UserIcon'


interface props{
    title:string;
    thumbnail:string;
    file:string;
    username:string;
    count:Number;
    music_file: Number;
    music_thumbnail: Number;
    music_artist: Number;
    topic_id: Number;
    claim?:Number;
    messageId?:Number;
}

const UnassignedMusicElement:React.FC<props> = (props) => {
    const dispatch = useAppDispatch()
    return (
        <div className="col-md-3 px-0 card" >
            <div className="single-image" style={{ backgroundImage: `linear-gradient(rgba(94, 94, 94, 0) 0%, #0f0f0f 100%),url("${props.thumbnail}")` }} onClick={(e) => {
                e.preventDefault()
                dispatch(playSingle({
                    value: [{
                        name: props.title,
                        singer: props.username,
                        cover: props.thumbnail,
                        musicSrc: props.file,
                    }]
                }))
                //@ts-ignore
                dispatch(setMusicId({ value: props.topic_id }))
                // @ts-ignore
            }}>
            </div>
            <div className="actual" style={{zIndex:"100",}}>
                <div className="d-flex justify-content-between">
                    <div onClick={(e:any)=>{
                        e.preventDefault()
                        dispatch(assignMUsic({
                            value:{
                             name:props.title,
                            id_attach:props.topic_id,
                            }
                        }))
                        dispatch(assignMusicUi())
                    }}><UserIcon /></div>
                     <div  onClick={(e:any)=>{
                    e.preventDefault()
                    // console.log("hello")
                    dispatch(assignMUsic({
                        value:{
                         name:props.title,
                        id_attach:props.topic_id,
                        }
                    }))
                    dispatch(updateAttachmentUi())
                }}>
                        <PencilIcon/>
                    </div>
                    
                    
                </div>
                <div className='mb-3 ml-1'>
                    <div className="numbering">{props.count}</div>
                    <div className="song-name"
                   
                    >{props.title}</div>
                </div>
            </div>
        </div>
    )
}

export default UnassignedMusicElement
