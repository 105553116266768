import React,{useState} from 'react';
import {gql,useQuery} from "@apollo/client"
import BeFriend from '../../claimYourMusic/src/BeFriend';
import configuration from '../../../../config/configuration';
import { Link, useLocation } from "react-router-dom"
import FeaturedArtistElement from '../../featuredArtists/FeaturedArtistElement';
import FollowingArtist from './FollowingArtist';
import Previous from '../../newMusic/src/Previous';
import Next from '../../newMusic/src/Next';
const UserFriends = () => {
    const [beFriendStatus,setBeFriendStatus] =useState("Befriend")
    const location = useLocation()
    
    const QUERY= gql`query{
        AllUserFriends(userId:${localStorage.getItem('userId')}){
           name
           picture
           buddie_list
           userId
            
        }
        }`
         const { loading, data, error } = useQuery(QUERY)  
         const BefriendAction =(action: string)=>{
           if(action==="Loading"){
               if(beFriendStatus==="Befriend"){
               setBeFriendStatus(" Unfriend");}
               else{
                   setBeFriendStatus(" Befriend")
               }
           }
        }
        const noWrap = (link: String) => {
            if (location.pathname === link) {
                return "wrap"
            }
            else {
                return "nowrap"
            }
        }
        const [next5,setNext5]=useState(5)
    return (
        <div className="col-12 col-md-12 col-lg-8  " id="featured-artists">
            {data&&data.AllUserFriends.length===0&&(
                        <div className='text-center text-white'>This user have no friends yet</div>
                    )}
                    <div className='slider'>
                    {data && next5<0 &&(
                            <div className='previous-5 slider-icon' onClick={(e)=>{
                                e.preventDefault()
                                if(next5<0){
                                setNext5(next5+1015)}
                            }}><Previous/></div>
                         )}
                    <div className={`row card-deck follow-container nowrap`} style={{transform:`translate3d(${next5}px,0px,0px)`}}>
                    {data&&data.AllUserFriends.map((user:any,index:number)=>{
                  return (
                  <>
                  <FollowingArtist
                   artistName={user.name}
                   artistProfile={`${configuration.FILE_STORAGE_LINK}${user.picture}`}
                   profileLink={`/music/artistMusics/${user.userId}`}
                   songs={[]}
                  />
                   
                  </>
                  )
                    })}
                    </div>
                    {data&&(next5>-(data.AllUserFriends.length/5-1)*10)&&(
                        <div className='next-5 slider-icon' onClick={(e)=>{
                            e.preventDefault()
                           
                            if(next5>-(data.AllUserFriends.length/5-1)*10){
                            setNext5(next5-1015)}
                        }}> <Next/></div>
                    )}
                    </div>
        </div>
    );
}

export default UserFriends;
