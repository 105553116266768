// @ts-nocheck
import React from 'react'

import AllMusicsInAlbum from "../../components/musicPageComponents/allMusicInAlbum/index"

import "./index.scss"
import { useParams } from 'react-router-dom'


const AllMusicAlbum = (props:any) => {
    const {playlist}=useParams()
    return (
           <>
           <AllMusicsInAlbum playlistId={playlist}></AllMusicsInAlbum>
           </>
                
                           
                            
                     
         
    )
}

export default AllMusicAlbum
