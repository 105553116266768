// @ts-nocheck
import React, { useState } from 'react'
import "./index.scss"
import ClosePanelIcon from './src/ClosePanelIcon'
import InputForm from '../../formInput'
import TitleIcon from './src/TitleIcon'
// redux functionality
import {useAppDispatch,useAppSelector} from "../../../redux/hooks"
import {updateAttachmentUi} from "../../../redux/data/uiData"

// apollo client
import {gql,useMutation} from "@apollo/client"

import Loading from '../../loading'
import Error from '../../error'
import Sucess from '../../sucess'
import axios from 'axios'
import configuration from '../../../config/configuration'


export default function UpdateAttachment() {
    const dispatch = useAppDispatch()
    const [loading,setLoading]=useState(false)
    const[status,setStatus]=useState(false)
    const[error,setError]=useState(null)
    const[close,setClose]=useState(false)
       const { assignData } = useAppSelector((state) => state.assignMusic);
    
    const value = (id: string) => {
        var inputValue = (document.getElementById(id) as HTMLInputElement).value
        return inputValue;
    };
    const HandleUpdateAttachment= async (e:any)=>{
        e.preventDefault()
        setLoading(true)
        setClose(true)
        setError(null)
        setStatus(false)
        const form = new FormData()
        if(!value("title")){
            setError("Title is required")
            setLoading(false)
            setStatus(false)
            return
        }
        form.append("id_attach",`${assignData.id_attach}`)
        form.append("title",`admin/${value("title")}.mp3`)
        const result = await axios.post(`${configuration.BACK_END_HOST}/updatename`,form)
        if(result.data){
            setLoading(false) 
            setStatus(true)
        }
    }

    return (
        <div id="claim-music">
            <div className="panel">
                <div className="d-flex justify-content-between top-button">
                    <div className="title">Update Name</div>
                    <div onClick={(e)=>{
                        e.preventDefault()
                        dispatch(updateAttachmentUi())
                    }}>
                        <ClosePanelIcon />
                    </div>
                </div>
                {close&&loading && <Loading></Loading>}
                {close&&status&& <Sucess text="Updated" close={setClose}></Sucess>}
                {close&&error && <Error text={error} close={setClose}></Error>}
                
                <div className="prerequist">
                Write below the New name for the song
                    </div>
                <InputForm placeholder="Song title" icon={<TitleIcon />} id="title" type="text" />
                
                <div className="claim-music-btn mt-2" onClick={HandleUpdateAttachment}>
                    Update Name
                </div>
            </div>
        </div>
    )
}
