/* eslint-disable jsx-a11y/anchor-is-valid */
// @ts-nocheck
import{ useState,useEffect } from 'react';
import { Link,useHistory} from 'react-router-dom';
import "./index.scss"

import { useDispatch } from 'react-redux';
import { hideContributeBtn, } from '../../redux/data/navbar';
import { setIsLogedIn } from '../../redux/data/uiData';

import Sucess from "../sucess/index"
import Loading from "../loading/index"
import Error from "../error/index"

//configuration
import configuration from '../../config/configuration';

import axios from "axios";
import GoBackIcon from './src/GoBackIcon';

import { useAppSelector} from "../../redux/hooks";
import GoogleButton from '../socialLogin/GoogleButton';
import FacebookButton from '../socialLogin/FacebookButton';




export default function Login() {
  const [error, seterror] = useState<String>("");
  const [loading, setloading] = useState<Boolean>(false);
  const [success, setsuccess] = useState<Boolean>(false);
  const history=useHistory()
  const [close,setClose] =useState<Boolean>(false);
  const [info,setInfo] = useState(null);

  useEffect(() => {

    async function getMoviesFromApi() {
      try {
        
        let response = await fetch(
          "https://proxy.leerecs.com?url=https://leerecs.com/home.json"
        );

        let responseJson = await response.json();
        setInfo(responseJson);
       
        // setloaded(true)
      } catch (error) {
        console.error(error);
      }
    }
    getMoviesFromApi()
  }, []);

  const value = (id: string) => {
    var inputValue = (document.getElementById(id) as HTMLInputElement).value
    return inputValue;
  };
 

  const dispatch = useDispatch()
  const { nextRoute } = useAppSelector((state) => state.artistProfile);
  const handleSubmit = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    setClose(true)
    setloading(true)
    seterror("");
    setsuccess(false);
    const [
      email,
      password,
    ] = [
        value('emailAdress'),
        value('password'),
      ];
    if (password && email) {
      //needs refactorings
      axios.post(`${configuration.BACK_END_HOST}/login?email=${email}&password=${password}`)
        .then(function (response) {
          setsuccess(true);
          setloading(false);
          seterror('');
          localStorage.setItem("userId", response.data.data.user_id)
          
          localStorage.setItem("token", response.data.data.token?response.data.data.token:"old")
          dispatch(setIsLogedIn(true))
          
          if ((!(typeof response.data.data.musician === "undefined"))){
            localStorage.setItem("musician", response.data.data.musician)
            dispatch(hideContributeBtn())
          }
          localStorage.setItem("isContributor",response.data.data.userGroup)
          localStorage.setItem("usergroupId",response.data.data.usergroupId)

          if (nextRoute){
            history.push(nextRoute)
          }
          else{
            history.push("/music")
          }
        })
        .catch(function (error) {
          setloading(false);
          if(error.response){
            seterror(error.response.data.message);
          }
          setsuccess(false);
        });
    }
    else {
      setloading(false);
      seterror("Fill the forms please");
      setsuccess(false);
    }
  };
 
  return (
    <div className="container-fluid" id="login">
      <div className="row">
      <div className="col-8 px-3 loaders" style={{width:"80%"}}>
                            {close&&loading && <Loading></Loading>}
                            {close&&success && <Sucess text="you have succesfully Logged In" close={setClose}></Sucess>}
                            {close&&error && <Error error={error} close={setClose}></Error>}
                          </div>
        <div className="col-md-4 ">
          <div className="row onboard">
            <div className="col-md-12 pl-0">
              <div className="d-flex justify-content-end w-100">
                <h6 className="onboard-title">
                The True Alternative to Alternative Music(TM).
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-8 justify-content-center">
          <div className="row sign-up">
            <div className="all-items">
              <div className="col-md-12 col-xl-10 mb-1 mb-md-5 px-0 px-md-3">
                <div className="row mx-0 justify-content-center">
                  <div className="col-md-1">
                    <Link to="/">
                      <div className="go-back-icon">
                        <GoBackIcon />
                      </div>
                    </Link>
                  </div>
                  <div className="col-md-11 col-lg-10 col-xl-8">
                    <div className="col-md-12 col-xl-12">
                      <h1>Log In Account</h1>
                      <h6>
                        Enter a new paradigm in Music streaming: Leerecs is The True Alternative to Alternative Music.  Where the spotlight is focussed on Independent Musicians making original Alternative Music.
                        <Link to="/signup" className="create-account"> Create your free account.</Link>
                      </h6>
                      <div className="row">
                        <form action="" onSubmit={handleSubmit}>
                         
                          <div className="col-md-12 row forms">
                            <div className="col-md-12">
                              <div className="label">Email Address</div>
                              <input className="input-style" type="email" id="emailAdress" />
                            </div>
                            <div className="col-md-12">
                              <div className="label">Password</div>
                              <input
                                className="input-style"
                                type="password"
                                id="password"
                              />
                            </div>
                            <div className="col-md-6">
                              <button
                                className="btn btn-primary create-btn"
                                type="submit"
                              >
                                Log In Account
                              </button>
                            </div>

                            <div className="col-md-6">
                              <a href={info&&info.miscellaneousLink} target="__blank">
                                <div className="btn btn-outline btn-primary login-btn">
                                  Forgot Password
                                </div>
                              </a>
                            </div>
                          </div>
                        </form>
                        <div className='d-flex flex-column align-items-center w-100 mt-3 justify-content-center '>
                          <div className='text-white  mb-2  row' style={{width:"100%"}}>
                          <div className="col-5 border-bottom"/>
                             <div className="col-auto mr-2">OR</div>
                             <div className="col-5    border-bottom"/>

                             </div>
{/*                       
                        <GoogleButton close={setClose} success={setsuccess} loading={setloading} error={setloading}/>
                        
                        
                        <FacebookButton close={setClose} success={setsuccess} loading={setloading} error={setloading}/> */}
                        
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
