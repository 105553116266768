// @ts-nocheck
import React, { useEffect, useState } from "react";
//helpers

import { useAppDispatch } from "../../../redux/hooks";
import { useLocation } from "react-router-dom";

//components
import MusicElement from "./MusicElement";
import ShowMoreComponent from "../showMoreComponent/index";
import LottieWrapper from "../../lottie/lottie";

//preloader
import Preloader from "./preloader";

import "./index.scss";

//configuration
import configuration from "../../../config/configuration";
import Previous from "../newMusic/src/Previous";
import Next from "../newMusic/src/Next";
import { setShowPayWall } from "../../../redux/data/uiData";
import AllIcon from "../showMoreComponent/src/AllIcon";

import Product from './product'
interface props {
    allMusic?: boolean;
    searchResult?: boolean;
    addMargin?: boolean;
    loading: boolean;
    error: any;
    data:any
}
const Products: React.FC<props> = (props) => {
    const [isUser,setIsUser]=useState(parseInt(localStorage.getItem("userId") as string))
    var count = 0;
    const location = useLocation();
    const dispatch = useAppDispatch();
    //handling size of music
    const [countnumber, setcountnumber] = useState(5);
    useEffect(() => {
      function handleResize() {
        let width = window.innerWidth;
        if (width <= 1024) {
          setcountnumber(4);
        }
        if (width <= 768) {
          setcountnumber(4);
        }
      }
      handleResize();
      window.addEventListener("resize", handleResize);
    }, []);
    const preloader = () => {
      const i = props.allMusic
        ? [0, 1, 2, 3, 4, 0, 1, 2, 3, 4, 0, 1, 2, 3, 4]
        : [...Array(countnumber).keys()];
      return i.map((itme,id) => {
        return <Preloader  key={id}/>;
      });
    };
  
    const noWrap = (link: String) => {
      if (location.pathname === link) {
        return "wrap";
      } else {
        return "";
      }
    };
    const [next5, setNext5] = useState(0);
    console.log(props.data)
    return (
        <div>
             <div id={props.allMusic ? "All-music" : "new-music"}>
             <div className="d-flex justify-content-between">
            <h3>
              {props.userId
                ? "My Music"
                : props.allMusic
                ? "All Music"
                : "Physical Media"}
            </h3>
            {!props.allMusic && !props.searchResult  && (
              <div>
                <div id="show-more">
           <a href="https://shop.leerecs.com" target="_blank" rel="noopener noreferrer">
                <div className="d-flex justify-content-between show-more">
                
                    <div className="more" style={{color:"#bfbfbf !important"}}>Show all products</div>
                    
                    <div className="show-more-icon">
                        <AllIcon />
                    </div>
                </div>
                </a>
        </div>
              </div>
            )}
            {!props.allMusic && props.searchResult && (
              <div>
              <ShowMoreComponent
                title="Show All Search Results"
                link="/music/searchResult/music"
              />
            </div>
            )}
          </div>
          {(props.data &&(props.data.length === 0))&& (
            <div className={`row card-deck ${noWrap("/music/allmusic")}`}>
              {preloader()}
            </div>
          )}

                        <div className="slider">
                        {props.data && next5 < 0 && (
                <div
                  className="previous-5 slider-icon"
                  onClick={(e) => {
                    e.preventDefault();
                    if (next5 < 0) {
                      setNext5(next5 + 1015);
                      
                    }
                  }}
                >
                  <Previous />
                </div>
              )}
                        <div className={`row card-deck ${noWrap("/music/allalbums")}`} style={{ transform: `translate3d(${next5}px,0px,0px)` }}>

                        {!props.allMusic
                  ? props.data &&
                    props.data.map(
                      (product: any, idx: any) => {
                        count++;
                        if (props.allMusic) {
                          count = 1;
                        }

                        if (count) {
                          return (
                            <Product
                            key={idx}
                         title={product.name}
                         vendor={product.vendor}
                         thumbnail={product.image}
                         owner={props.owner}
                         published={true}
                         price={product.price}
                         url={product.url}
                         home={true}
                         />
                          );
                        }
                      }
                    )
                  : ""
                    }  
                       
                         {props.data.map((product)=>(
                         <Product
                         title={product.name}
                         vendor={product.vendor}
                         thumbnail={product.image}
                         owner={props.owner}
                         published={true}
                         price={product.price}
                         url={product.url}
                         home={true}
                         />
                         ))}

                         </div>

                        {/* {(props.data &&(props.data.length === 0)) && (<LottieWrapper url="https://assets2.lottiefiles.com/packages/lf20_GlZGOi.json" width="calc(100% -  20px)" height="50vh" />)} */}

                        {props.data &&
                !props.allMusic &&
                next5 >
                  -(props.data.length / countnumber - 1) *
                    200 *
                    countnumber && (
                  <div
                    className="next-5 slider-icon"
                    onClick={(e) => {
                      e.preventDefault();
                      if (next5 > -(props.data.length / countnumber -1) *1000 * countnumber) {
                        setNext5(next5 - 1015);
                       
                      }
                      if(next5>-1015){
                      //   if(!isUser){
                      //   dispatch(setShowPayWall(true))
                      // }
                        }
                    }}
                  >
                    <Next />
                  </div>
                )}


                   

            </div>
            </div>
            
        </div>
    );
}

export default Products;
