import React , {useState} from 'react'

//helpers
import { useQuery, gql } from "@apollo/client"

//redux hooks
import { useAppDispatch } from "../../../redux/hooks"

//redux actions
import { playSingle } from "../../../redux/data/musicPlayer/index"

//icons
import PlayMusicIcon from "./src/PlayMusicIcon"

//prelaoders
import HeaderPreloader from "../headerPreloader/index"
import SingleMusicPreloader from "../singleMusicPreloader"

import MusicElement from "./MusicElement"

import "./index.scss"

//configuration
import configuration from '../../../config/configuration'
import MusicFooter from '../../../pages/main/components/footerSection/MusicFooter'

const AllHistory= () => {
    const dispatch = useAppDispatch()
    //state that will contain music data array
    let musicData:any=[]
    // const [musics, setmusics] = useState([])

    const QUERY = gql`query{
    AllUserHistory(userId:${parseInt(localStorage.getItem("userId") as string)}){
        title
        thumbnail
        desc
        file
        musicId
        username
        imageId
        messageId
        topicId
        memberId
        color_name
    }
}`
    const { loading, data, error } = useQuery(QUERY/*, { pollInterval: 500 }*/)

    return (
        <div id="all-music-in-playlist">
            
            <div className="header" style={{ backgroundImage: `linear-gradient(rgba(56, 56, 56, 0.22) 0%, #292929 100%),url("https://mir-s3-cdn-cf.behance.net/project_modules/fs/bb4cbb106821353.5f98ae33c9746.png")`}}>
                    <div className="d-flex justify-content-between">
                        <div>
                            <div className="panel-title">Your History</div>
                            <div className="playlist-title">Recently played</div>
                            <div className="playlist-desc">The sound you have listened in the past few minutes,days,or years.</div>
                        </div>
                        <div onClick={(e) => {
                            e.preventDefault()
                            dispatch(playSingle({
                                value: musicData
                            }))
                        }}>
                            <PlayMusicIcon />
                        </div>
                    </div>
                <div className="btn play-all-music" onClick={(e) => {
                    e.preventDefault()
                    dispatch(playSingle({
                        value: musicData
                    }))
                }}>
                    Play All Music
                </div>
                </div>
            

            <div className="row mx-0 table-heading justify-content-between">
                <div className="col-md-1 px-0">#</div>
                <div className="col-md-6 px-0">Title</div>
                {/* <div className="col-md-3 px-0">Album</div> */}
                <div className="col-md-1 px-0">React</div>
                <div className="col-md-1 px-0">
                    <div className="d-flex justify-content-end">
                        <div></div>
                        <div>Play</div>
                    </div>
                </div>
                <div className="col-md-12 px-0"><hr /></div>
            </div>
            {loading && (<SingleMusicPreloader />)}
            {data && (data.AllUserHistory.map((music: any, count: any) => {
                musicData.push({
                    name: music.title,
                    singer: music.username,
                    cover: `${configuration.READFILE_LINK}${music.thumbnail}`,
                    musicSrc: `${configuration.FILE_STORAGE_LINK}${music.file}`,
                    color:music.color_name
                })
                return (<MusicElement
                    music_file={music.musicId}
                    recommend={data.AllUserHistory.filter((song:any)=>song.title!==music.title)}
                    music_thumbnail={music.imageId}
                    music_artist={music.memberId}
                    title={music.title}
                    thumbnail={`${configuration.FILE_STORAGE_LINK}${music.thumbnail}`}
                    file={`${configuration.READFILE_LINK}${music.file}`}
                    username={music.username}
                    color_name={music.color_name}
                    count={(count + 1).toLocaleString('en-US', {
                        minimumIntegerDigits: 2,
                        useGrouping: false,
                    })}
                    index={count}
                    music_id={music.topicId}
                     />)
            }))}
            <div style={{marginBottom:"80px"}}></div>

            {data && <MusicFooter/>}
        </div>
    )
}

export default AllHistory
