import React from "react";
//redux
import {useQuery,gql} from "@apollo/client"
import AllPlaylist from "../../components/musicPageComponents/allPlaylist";
import MusicFooter from "../main/components/footerSection/MusicFooter";



const AllPlaylists = () => {
  // const {addplaylist} = useAppSelector(state => state.uiData)
  const QUERY =gql`query{
    AllPlaylistsWithMusic{
            title
            desc
            username
            cover
            playListId
            musicCount
            songs{
        cover
        color
        singer
        name
        musicSrc
    }
            }
}`
   //loadind query
    const { loading, data, error } = useQuery(QUERY/*, { pollInterval: 500 }*/)
  return (
    <>
      <AllPlaylist data={data?.AllPlaylistsWithMusic} loading={loading} error={error} searchResult={false}   allMusic={true} addMargin={true}/>
      {data && <MusicFooter/>}

    </>
  );
};

export default AllPlaylists;
