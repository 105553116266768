// @ts-nocheck
import React,{useState,useEffect} from 'react'
import MusicSideBar from "../../components/musicPageComponents/sidebar/index"
import MusicNavBar from '../../components/musicPageComponents/navbar'
import AllMusicsInAlbum from "../../components/musicPageComponents/allMusicInAlbum/index"
import AddMusic from '../../components/musicianPageComponents/addMultipleMusic'
import axios from 'axios'
import configuration from '../../config/configuration'
import "./index.scss"

import SecureRoute from '../wrapper/SecureRoute'

const MultipleMuiscUpload = (props:any) => {
    const [isAdmin,setIsAdmin]=useState(false)
    useEffect(()=>{
        const isAdmin= async()=>{
        const form = new FormData();
        form.append('userId',parseInt(localStorage.getItem("userId")));
        const result = await axios.post(`${configuration.BACK_END_HOST}/isadmin`, form)
        if(result.data.admin){
            setIsAdmin(result.data.admin)
        }
    }
    isAdmin()
    },[])
    return (
        <div>
        <div className="container-fluid px-0" id="add-music-page">
            <div className="row mx-0">
                <div className="col-md-3 px-0">
                    <MusicSideBar></MusicSideBar>
                </div>
                <div className="col-md-9 contents">
                    <MusicNavBar></MusicNavBar>
                    {isAdmin?(<AddMusic/>):(<></>)}
                </div>
            </div>
        </div>
    </div>
    )
}

export default MultipleMuiscUpload
