// @ts-nocheck
import React,{useState} from 'react';
import MusicElement from "../MusicElement"
import { useQuery, gql,useMutation } from "@apollo/client"
import configuration from '../../../../config/configuration';

const HistoryMusic = () => {
    const ALLmusic =gql`query{
        AllUserHistory(userId:114) {
            title
            thumbnail
            file
            username
            musicId
            imageId
            memberId
            topicId
            messageId
            color_name
        }
 
    }`
    const { loading: loadingAllMusic, data: AllMusic, error: allMusicErrors } = useQuery(ALLmusic/*, { pollInterval: 500 }*/)
    let musicData: any = []
    const [countNumber,setcountnumber]=useState(5)
    return (
        <div className="col-12 col-md-12 col-lg-8  ">
        <div className="table-heading ">
                <div className="row mx-0 single-music-header justify-content-between" style={{ marginLeft:0,marginRight:0,}}>
                    <div className="col-1 px-0 numbering">#</div>
                    <div className="col-6 px-0 music">
                        <div className="d-flex">
                            <div className="music-thumbnail" ></div>
                            <div className="music-info">
                                <div className="music-title">Title</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-1 px-0 album"></div>
                    <div className="col-2 pl-2 react">React</div>
                    <div className="col-1 px-0 play">
                        <div className="d-flex justify-content-end">
                            <div></div>
                            <div >
                                Play
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 px-0"><hr /></div>
            </div>
        {AllMusic && AllMusic.AllUserHistory.map((music: any, count: any) => {
                musicData.push({
                    name: music.title,
                    singer: music.username,
                    cover: `${configuration.FILE_STORAGE_LINK}${music.thumbnail}`,
                    musicSrc: `${configuration.READFILE_LINK}${music.file}`,
                }) 
                
                if(count<countNumber){
                return (<MusicElement
                   recommend={AllMusic.AllUserHistory.filter((song)=>song.title!==music.title)}
                    music_file={music.musicId}
                    music_thumbnail={music.imageId}
                    music_artist={music.memberId}
                    title={music.title}
                    isAdmin={false}
                    topic_id={music.topicId}
                    thumbnail={`${configuration.FILE_STORAGE_LINK}${music.thumbnail}`}
                    file={`${configuration.READFILE_LINK}${music.file}`}
                    username={music.username}
                    color_name={music.color_name}
                    music_id={music.topicId}
                    count={(count + 1).toLocaleString('en-US', {
                        minimumIntegerDigits: 2,
                        useGrouping: false,
                    })}
                    userId={music.userId}
                    index={count}
                />)}
            })
        }
        {AllMusic && ((AllMusic.AllUserHistory.length>5))&&(countNumber<=5?(
               <div className='text-center text-white cursor-pointer' onClick={()=>{
                   setcountnumber(100)
                  }}>  view more</div>
            ):(
               <div className='text-center text-white cursor-pointer ' onClick={()=>{
                   setcountnumber(5)
                  }}>  view less</div>
            ))}
        </div>
    );
}

export default HistoryMusic;
