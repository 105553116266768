import React from 'react'

const MusicIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <g id="library_music" transform="translate(36 -62)">
        <path id="Path_78" data-name="Path 78" d="M20,2H8A2.006,2.006,0,0,0,6,4V16a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2V4A2.006,2.006,0,0,0,20,2ZM18,7H15v5.5A2.5,2.5,0,1,1,12.5,10a2.447,2.447,0,0,1,1.5.51V5h4ZM4,6H2V20a2.006,2.006,0,0,0,2,2H18V20H4Z" transform="translate(-38 60)" fill="#f8af10" />
      </g>
    </svg>

  )
}

export default MusicIcon
