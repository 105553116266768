import React from 'react'

const ActivityIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="27.5" height="22" viewBox="0 0 27.5 22">
      <g id="local_activity" transform="translate(-2 -4)">
        <path id="Path_71" data-name="Path 71" d="M4.014,9.492A5.5,5.5,0,0,1,4,19.007V22.5H26V19.007a5.49,5.49,0,0,1,0-9.515V6H4Zm9.24,2.31L15,7.375l1.733,4.441,4.771.275-3.7,3.025,1.224,4.634L15,17.165,10.971,19.75l1.21-4.634-3.7-3.025Z" transform="translate(0.75 0.75)" fill="#fff" opacity="0.3" />
        <path id="Path_72" data-name="Path 72" d="M26.75,4h-22A2.746,2.746,0,0,0,2.014,6.75v5.5A2.75,2.75,0,0,1,2,17.75v5.5A2.758,2.758,0,0,0,4.75,26h22a2.758,2.758,0,0,0,2.75-2.75v-5.5a2.75,2.75,0,0,1,0-5.5V6.75A2.758,2.758,0,0,0,26.75,4Zm0,6.243a5.49,5.49,0,0,0,0,9.515V23.25h-22V19.757A5.518,5.518,0,0,0,7.5,15a5.489,5.489,0,0,0-2.736-4.757L4.75,6.75h22ZM11.721,20.5l4.029-2.585L19.779,20.5l-1.224-4.62,3.7-3.025-4.771-.289L15.75,8.125,14,12.552l-4.771.289,3.7,3.025Z" transform="translate(0)" fill="#fff" />
      </g>
    </svg>
  )
}

export default ActivityIcon
