// @ts-nocheck
import React from 'react'

//redux helper
import { useAppSelector } from "../../../redux/hooks"
import axios from 'axios'
import configuration from '../../../config/configuration'

//apollo client helpers
import { gql, useQuery } from "@apollo/client"
import Logout from '../navbar/src/Logout'


//router-helper
import { NavLink } from "react-router-dom"

import SideBarElement from "./SideBarElement"
import LoginSideBarElement from './LoginSideBarElement'
import FolderPlus from './src/folderPlus'

import HomeSimple from "./src/HomeSimple"
import SongSimple from './src/SongSimple'
import Favorite from "./src/Favorite"
import Heart from "./src/Heart"
import MusicNote from "./src/MusicNote"
import Setting from './src/Setting'
import UploadMusicIcon from './src/UploadMusicIcon'
import Search from "./src/Search"
import DoubleMusicNote from './src/DoubleMusicNote'
import ColorSideBarElement from './ColorSidebarElement'

//musician logos
import DashboardIcon from "./src/DashboardIcon"
import AllMusicIcon from "./src/AllMusicIcon"
import { playlist,setPlaylistState,album, setAlbumState } from '../../../redux/data/uiData'
import { useAppDispatch } from '../../../redux/hooks'
import "./index.scss"
import "./mobileNavBar.scss"
import { useState,useEffect } from "react"

const MusicNavBar:React.FC<{data:any}> = ({data}) => {
    const dispatch = useAppDispatch()
 const [isUser,setIsUser]=useState(parseInt(localStorage.getItem("userId") as string))
 const [isAdmin,setIsAdmin]=useState(false)
    useEffect(()=>{
        const isAdmin= async()=>{
        const form = new FormData();
        form.append('userId',parseInt(localStorage.getItem("userId")));
        const result = await axios.post(`${configuration.BACK_END_HOST}/isadmin`, form)
        if(result.data.admin){
            setIsAdmin(result.data.admin)
        }
      }
      isAdmin()
      },[])

    return (
        <div>
            <div id="music-side-bar">
                
                {isUser ?(<div className="button logout">
                        <LoginSideBarElement title="Sign Out">
                        <Logout />
                        </LoginSideBarElement>
                    </div>):(<div className="button logout" >
                         
                         <LoginSideBarElement title="Sign In">
                        <Logout />
                        </LoginSideBarElement>
                        
                    </div>)}
                
                
               
                <h3>Main</h3>
                { data && data.UserAuth.isContributor ?(<NavLink to="/music/addMusic">
                    <div className='sidebar-upload-btn'>
                    <SideBarElement title="Upload Music">
                        <UploadMusicIcon />
                    </SideBarElement>
                    </div>
                </NavLink>): isAdmin ?(<NavLink to="/admin/multiple/uploads">
                    <div className='sidebar-upload-btn'>
                    <SideBarElement title="Upload Music">
                        <UploadMusicIcon />
                    </SideBarElement>
                    </div>
                </NavLink>):""}
                {/* {isUser ?(
                   
                        <a  target="_blank" rel="noopener noreferrer" href={`https://admin.leerecs.com/index.php?action=profile;area=summary;u=${isUser}`}>
                        <SideBarElement title="Account Setting">
                            
                                    <Setting/>
                                </SideBarElement>
                                </a>
                    ):""} */}
                {data&&data.UserAuth.isContributor  ? (<NavLink to="/musician">
                    <SideBarElement title="Dashboard(BETA)">
                        <DashboardIcon />
                    </SideBarElement>
                </NavLink>):""}
                <NavLink exact to="/music">
                    <SideBarElement title="Index">
                        <SongSimple />
                    </SideBarElement>
                </NavLink>
                {/* <Link to="/music/searchResult">
                    <div>
                        <SideBarElement title="Browse">
                            <PlayerIcon />
                        </SideBarElement>
                    </div>
                </Link> */}
                <NavLink to="/music/searchResult">
                    <div>
                        <SideBarElement title="Search">
                            <Search/>
                        </SideBarElement>
                    </div>
                </NavLink>
                
                <div>
                </div>

                
                {isUser ? (<>
                    <h3>Library</h3>
                    <div>
                        
                    </div></>):""}

                <div>
                    {data&&data.UserAuth.isContributor ?(<NavLink to="/music/mymusic" ><SideBarElement title="My Music">
                    <AllMusicIcon />
                        </SideBarElement>
                        </NavLink>):isAdmin ?(<NavLink to="/music/unassignedmusic" ><SideBarElement title="Unassigned Music">
                    <AllMusicIcon />
                        </SideBarElement>
                        </NavLink>):""}
                
                   {isUser  ?(<NavLink to="/music/likedSongs">
                        <SideBarElement title="Liked Songs">
                            <Heart />
                        </SideBarElement>
                    </NavLink>):isAdmin?(<NavLink to="/music/likedSongs">
                        <SideBarElement title="Liked Songs">
                            <Heart />
                        </SideBarElement>
                    </NavLink>):("")} 
                    {isUser?(<NavLink to="/music/history">
                        <SideBarElement title="History">
                            <Favorite />
                        </SideBarElement>
                    </NavLink>):isAdmin?(<NavLink to="/music/history">
                        <SideBarElement title="History">
                            <Favorite />
                        </SideBarElement>
                    </NavLink>):("")}
                    
                    {/* <SideBarElement title="Albums">
                        <AlbumOpen />
                    </SideBarElement> */}
                </div>
                <h3>Listen By Colour</h3>
                <div className="d-flex w-100 colors-element">
                {data && (data.UserAuth.color.map((color:any,idx:number)=>{
                 return(
                    <NavLink to={`/music/colour/${color.title.toLowerCase()}`} key={idx}>
                        <div title={color.desc}>
                            <ColorSideBarElement title={color.title}>
                                <DoubleMusicNote color={color.color} />
                            </ColorSideBarElement>
                            </div>
                        </NavLink>
                 )
                }))}
                </div>
                {(data&&data.UserAuth.isContributor)||data?.UserAuth.isAdmin  ?(<h3>PLAYLISTS</h3>):("")}
                
                {(data?.UserAuth.isContributor)||data?.UserAuth.isAdmin  ?(<div className='cursorPointer' onClick={(e:any)=>{
                        e.preventDefault()
                        dispatch(setPlaylistState(true))
                        dispatch(playlist())}}><SideBarElement  title="Create Playlist">
                                <FolderPlus />
                            </SideBarElement></div>):("")}
                {data && (data.UserAuth.userPlaylist.map((singlePlaylist: any,idx:number) => {
                    return (
                        <NavLink to={`/music/playlist/${singlePlaylist.title.toLowerCase().replaceAll(" ","-")}`} key={idx}>
                            <SideBarElement title={singlePlaylist.title}>
                                <MusicNote />
                            </SideBarElement>
                        </NavLink>
                    )
                }))}
                {(data?.UserAuth.isContributor)||data?.UserAuth.isAdmin   ?(<h3>ALBUM</h3>):("")}
                {(data?.UserAuth.isContributor)||data?.UserAuth.isAdmin  ?(<div className='cursorPointer' onClick={(e:any)=>{
                        e.preventDefault()
                        dispatch(setAlbumState('new'))
                        dispatch(album())}}><SideBarElement  title="Create Album">
                                <FolderPlus />
                            </SideBarElement></div>):("")}
                
                    {data && (data.UserAuth.userAlbum.map((singlePlaylist: any,idx:number) => {
                        return (
                            <NavLink to={`/music/album/${singlePlaylist.title.toLowerCase().replaceAll(" ","-")}`} key={idx} >
                                <SideBarElement title={singlePlaylist.title}>
                                    <MusicNote />
                                </SideBarElement>
                            </NavLink>
                        )
                    }))}
                    <div style={{marginBottom:"100px"}} >
                    
                    
                    
                   
                    </div>
            </div>
            
            <div id="mobile-nav-bar">
                <div className="nav-bar-wraper">
                    <div className="d-flex justify-content-between">
                        <NavLink to="/music"><SongSimple /></NavLink>
                        <NavLink to="/music/likedSongs"><Heart /></NavLink>
                        <NavLink to="/music/history"><Favorite /></NavLink>
                        <NavLink to="/"> <HomeSimple/></NavLink>
                    </div>
                </div>
            </div>
        </div>
        
    )
}

export default MusicNavBar
