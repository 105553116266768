import React from 'react'

//redux
import { useAppSelector,useAppDispatch } from "../../redux/hooks"
import { gql, useQuery } from "@apollo/client"
import {useLocation } from "react-router-dom"
import { setQuery } from '../../redux/data/search'
import Search from '../../components/musicPageComponents/navbar/src/Search'
//components
import MusicSideBar from "../../components/musicPageComponents/sidebar/index"
import MusicNavBar from '../../components/musicPageComponents/navbar'

import AddPlaylist from "../../components/addPlaylist/index"


const MusicPageWrapper = (props:any) => {
    const {addplaylist,isMini} = useAppSelector(state => state.uiData)
    const { searchQuery } = useAppSelector(state => state.search)

    //history hook
    const location = useLocation();
    const dispatch = useAppDispatch()
    
    const handleChange = (e: any) => {
      e.preventDefault()
      //@ts-ignore
      let searchQuery = document.getElementById("search-title").value
      
      dispatch(setQuery({ searchQuery: searchQuery }))
  }

  const QUERY =gql`query{
    UserAuth(userId:${localStorage.getItem("userId")}){
        isAdmin
        isContributor
        color{
            title
            color
            id
            desc
        }
        isLogedIn
        userPlaylist{
            title
            desc
            playListId
        }
        userAlbum{
            title
            desc
        }

        usernotification{
            music
    user_id
     buddie_list
     type
    username
    message
      userProfile
      owner_name
      date
      seen
        is_verified
        }
    }
}`

  //loadind query
  const {  data} = useQuery(QUERY/*, { pollInterval: 500 }*/)
    return (
        <div>
            {addplaylist && <AddPlaylist />}
            <div className="container-fluid px-0" id="music-home-page">
                <div className="row mx-0">
                    <div className={`${isMini?"col-md-1":"col-md-3"}  px-0`}>
                        <MusicSideBar data={data}></MusicSideBar>
                    </div>
                    <div id="scroller" className={` ${isMini?"col-md-11":"col-md-9"} contents `}>
                    
                    <MusicNavBar data={data}></MusicNavBar>
                        <div id="content-container" className="px-4 bg-color">
                        
                        {(location.pathname ==='/music/searchResult' ||location.pathname ==='/music/searchResult/music'|| location.pathname ==='/music/searchResult/artists'|| location.pathname ==='/music/searchResult/playlists'|| location.pathname ==='/music/searchResult/albums')&&(<form action="">
                  <div className="input-group d-flex mx-auto " style={{marginTop:'80px'}}>
                    <Search/>
                    <input type="text" id="search-title" value={searchQuery as string} placeholder="Search Music" onChange={handleChange} />
                    </div>
                    </form>)}
                        
                        {props.children}
                        </div>
                   

                    </div>
                </div>
            </div>
        </div>
    )
}

export default MusicPageWrapper
