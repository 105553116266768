import React from "react";
//redux
import {useQuery,gql} from "@apollo/client"
import NewMusic from "../../components/musicPageComponents/newMusic/index";
import Component from "../../components/musicPageComponents/allMusicInPlaylist/Component";
import { useAppSelector } from "../../redux/hooks";
import MusicFooter from "../main/components/footerSection/MusicFooter";



const MusicSearch = () => {
    const { searchQuery } = useAppSelector((state) => state.search);
    const { isThumbnail } = useAppSelector((state) => state.uiData);
  const QUERY =gql`query{
    SearchInAllMusic(query:"${searchQuery}"){
        title
        thumbnail
        desc
        file
        musicId
        username
        imageId
        messageId
        topicId
        memberId
        likes
        color_name
        playData{
        cover
        color
        singer
        name
        musicSrc
        }
    }
}`
   //loadind query
    const { loading, data, error } = useQuery(QUERY/*, { pollInterval: 500 }*/)
  return (
    <>
    { isThumbnail  && (<NewMusic data={data?.SearchInAllMusic} loading={loading} searchResult={true} error={error} allMusic={true} addMargin={true}></NewMusic>)}
    
    {!isThumbnail && (<Component  data={data?.SearchInAllMusic} loading={loading} error={error}/>)}
    {data && (<MusicFooter/>)}
    </>
  );
};

export default MusicSearch;
