// @ts-nocheck
import React, { useEffect, useState,useRef} from 'react'

//helpers
import { useQuery, gql } from "@apollo/client"
import { useAppSelector } from '../../../redux/hooks'
import { useLocation } from 'react-router'
import Parser from "rss-parser";

//components
import MusicElement from "./MusicElement"
import PodcastMusicElement from "./PodcastMusicElement"
import LottieWrapper from '../../lottie/lottie'

//preloader
import Preloader from '../newMusic/preloader'

import "./index.scss"

//configuration
import configuration from '../../../config/configuration'

interface props {
    allMusic?: boolean;
    searchResult?: boolean;
    addMargin?: boolean;
    musician?:string;
}

const NewPlaylist: React.FC<props> = (props) => {
    const musician=props.musician
    
    const QUERY =  gql`query{
        AllPlaylistsByUser(userId:${musician}) {
        title
        desc
        username
        cover
        playListId
        }
    }
    `

    const { searchQuery } = useAppSelector(state => state.search)
    const SEARCH_PLAYLIST_QUERY = gql`query{
    AllPlaylistsSearch(query:"${searchQuery}"){
    title
	desc
	username
	cover
    playListId
    musicCount
    }
}`

    const { loading, data } = useQuery(QUERY/*, { pollInterval: 500 }*/)
    const { loading: loadingSearch, data: searchData, error: searchError } = useQuery(SEARCH_PLAYLIST_QUERY/*, { pollInterval: 500 }*/)

    var count = 0;

    

    const location = useLocation()

    const noWrap = (link:String) => {
        if (location.pathname === link) {
            return "wrap"
        }
        else {
            return ""
        }
    }

    const [countnumber, setcountnumber] = useState(2)
    const ref = useRef([]); 
    const [podcast,setPodcast]= useState();
    const [hasPodcast,setHasPodcast]=useState(false);
    const status =useRef("initial")
    useEffect(()=>{
        // console.log("hello")
        
        const resultFunc = async () => {
            const listOfMusic = async () => {
              let parser = new Parser();
              const CORS_PROXY = "https://proxy.leerecs.com/?url=";
              let feed = await parser.parseURL(
                CORS_PROXY + "https://www.spreaker.com/show/3073366/episodes/feed"
              );
              if (feed) {
                feed.items.forEach((item) => {
                    // console.log(item)
                    const name =item.title.split(" with ");
                    if(name[1]){
                    if(name[1].toLowerCase().includes(props.username.toLowerCase())) {
                        setHasPodcast(true)
                    }}
                  ref.current.push({
                    imageUrl: item.itunes.image,
                    musicUrl: item.enclosure?.url,
                    artist: "leerecs",
                    musicName: item.title,
                    description: item.contentSnippet,
                  });

                });
               

                setPodcast({
                    imageUrl: feed.items[0].itunes.image,
                    musicUrl: feed.items[0].enclosure?.url,
                    artist: "leerecs",
                    musicName: feed.items[0].title,
                    description: feed.items[0].contentSnippet,
                })
            
                return ref.current;
              }
            };
      
       const result=listOfMusic()
            if (result) {
            //   const details = title(id);
            //   setstate({
            //     ...details,
            //     title: details.musicName,
            //     description: details.description,
            //     musicUrl: details.musicUrl,
            //     listOfPoscast: ref.current,
            //   });
            //   dispatch(setMusicProfile(
            //    { value:{
            //       title:details.musicName,
            //       description: details.description,
            //       imageUrl:details.imageUrl
      
            //     }}
            //   ))
            //   setimage(details.imageUrl);
            //   setStatus("success")
            }
            status.current="success"
          };
          //if (!state.listOfPoscast.length){
            if(status.current==="initial"){
                resultFunc()
            }
            
                
     },[props.username, status])
    useEffect(() => {
        function handleResize() {
            // console.log('resized to: ', window.innerWidth, 'x', window.innerHeight)
            let width = window.innerWidth
            if (width <= 1024) {
                setcountnumber(3)
            }
            if (width <= 768) {
                setcountnumber(3)
            }
        }
        handleResize()
        window.addEventListener('resize', handleResize)

    }, [])

    const preloader = () => {
        const i = props.allMusic ? [0, 1, 2, 3, 4, 0, 1, 2] : [...Array(countnumber).keys()]
        return i.map(() => {
            return (<Preloader />)
        })
    }

    const searchResult = (search: any) => {
        if (search.AllPlaylistsSearch.length !== 0) {
            return search.AllPlaylistsSearch.map((music: any) => {
                count++;
                if (props.allMusic) {
                    count = 0
                }
                if (count <= countnumber) {
                    return (
                        <MusicElement
                        link={`/music/playlist/${music.title.toLowerCase().replaceAll(" ","-")}`}
                            title={music.title}
                            thumbnail={`https:/${music.cover}`}
                            file={`https:/${music.file}`}
                            username={music.username}
                            count={music.musicCount} />)
                }
            })
        }
        if (search.AllPlaylistsSearch.length === 0) {
            return (<LottieWrapper url="https://assets3.lottiefiles.com/packages/lf20_ydo1amjm.json" width="calc(100% -  20px)" height="40vh" />)
        }
    }

    return (
        <div>
            {!props.searchResult && (
                <div id="Artist-new-playlist">
                    {loading && (
                        <div className={`row card-deck ${noWrap("/music/allplaylist")}`}>
                            {preloader()}
                        </div>)}
                        <div className={`row card-deck ${noWrap("/music/allplaylist")}`}>
                            {!loading && hasPodcast&&podcast&& ( <PodcastMusicElement
                                        link={`/music/playlist/sundaycoffee`}
                                        title={"Sunday Coffee"}
                                        thumbnail={podcast.imageUrl}
                                        file={`https:/`}
                                        username={"Leerecs"}
                                        count={0} />)}
                        {data && (data.AllPlaylistsByUser.map((music: any) => {
                            count++;
                            if (props.allMusic) {
                                count = 0
                            }
                            if (count <= countnumber) {
                                return (
                                    <MusicElement
                                    link={`/music/playlist/${music.title.toLowerCase().replaceAll(" ","-")}`}
                                        title={music.title}
                                        thumbnail={`${configuration.FILE_STORAGE_LINK}${music.cover}`}
                                        file={`${configuration.READFILE_LINK}${music.file}`}
                                        username={music.username}
                                        count={music.musicCount} />)
                            }
                        }))}
                        {/* {(data && (data.AllPlaylistsByUsername.length ===0))&&(<LottieWrapper url="https://assets3.lottiefiles.com/packages/lf20_ydo1amjm.json" width="calc(100% -  20px)" height="40vh" />)} */}
                    </div>
                   
                </div>)}
            {props.searchResult && (
                <div id="new-playlist">
                   
                    {loadingSearch && (
                        <div className={`row card-deck ${noWrap("/music/searchResult/playlists")}`}>
                            {preloader()}
                        </div>)}
                    <div className={`row card-deck ${noWrap("/music/searchResult/playlists")}`}>
                        {searchData && (searchResult(searchData))}
                        {searchError && (<LottieWrapper url="https://assets3.lottiefiles.com/packages/lf20_ydo1amjm.json" width="calc(100% -  20px)" height="40vh" />)}
                    </div>
                </div>
            )}
        </div>
    )
}

export default NewPlaylist
