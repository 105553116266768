// @ts-nocheck
import React from 'react'
import { useParams } from 'react-router-dom'
import AllMusicsInPlaylist from "../../components/musicPageComponents/allMusicInPlaylist/ColorPlaylist"
import "./index.scss"

const ColorPlaylist = (props: any) => {
    const {colorId}=useParams()
    return (
            <>     
        <AllMusicsInPlaylist ColorId={colorId}></AllMusicsInPlaylist>
                      
            </>
    )
}

export default ColorPlaylist
