// @ts-nocheck
import { useState } from "react";
import Music from "./src/music";
import Guitor from "./src/guitor";
import Radio from "./src/radio";

// helpers
import { Link } from "react-router-dom";
import { useEffect } from "react";
import "./index.scss";
// import current Music
import "./currentMusic.scss"
import PlayMusic from "./src/playMusic"
import Parser from "rss-parser";
import WebGl from "../webgl/index"

import { useAppDispatch } from "../../../../redux/hooks";
import { playSingle } from "../../../../redux/data/musicPlayer/index";
import { Menu } from "./MenuMain";
import ForwardArrow from './src/ForwardArrow'
import HomeShare from "../../../../components/musicPageComponents/allMusicByArtist/src/HomeShare";
import BackArrow from './src/BackArrow'
import SmallLoader from "../../../../components/signup/src/icons/smallLoader";
import { setsharedUrl } from "../../../../redux/data/artistProfile";
import LazyBackground from "../../../../components/wrappers/LazyBackground";
export default function Welcome(props) {
  const [musicinfo, setmusicinfo] = useState()
  const dispatch = useAppDispatch()
  const [info, setinfo] = useState(props.info);
  const [description,setDescription]=useState(props.info);
  const [buttons,setButtons]=useState([]);
  const [state, setstate] = useState(0)
  const [latestMusic, setlatestMusic] = useState("")
  useEffect(()=>{
    if(props.info){
    setinfo(props.info);
      setDescription(props.info.description)
        setButtons(props.info.HomeButtons)
         //setloaded(true)
        }

  },[props])
  useEffect(() => {
    // localStorage.removeItem("userId")
    // localStorage.removeItem("token")
    // localStorage.removeItem("musician")
    
    let parser = new Parser();
    async function getMoviesFromApi() {
      try {
        let response = await fetch(
          "https://proxy.leerecs.com?url=https://leerecs.com/indexFile.json"
        );
        let responseJson = await response.json();
        // setinfo(responseJson);
        //setDescription(responseJson.description)
        //setButtons(responseJson.HomeButtons)
        // setloaded(true)
      } catch (error) {
        console.error(error);
      }
    }
    getMoviesFromApi()


    const handleFeed = async () => {
      const CORS_PROXY = "https://proxy.leerecs.com/?url=";
      let feed = await parser.parseURL(
        CORS_PROXY + "https://www.spreaker.com/show/3073366/episodes/feed"
      );
      if (feed) {
        const rand = Math.floor(Math.random() * feed.items.length) + 1;
        setlatestMusic(feed.items[rand].itunes.image)
        setmusicinfo({
          value: [{
            singer: "leerecs",
            name: feed.items[rand].title as string,
            cover: feed.items[rand].itunes.image,
            musicSrc: feed.items[rand].enclosure?.url as String,
          }]
        })
      }
    };
    handleFeed();
  }, []);

  const manageMusic = () => {
    const onClick=() => {
      dispatch(playSingle(musicinfo));
    }
    return <>
      {!latestMusic ? (<>
      {buttons.length!==0 &&(
        <div className="cardPreloader"> <div className='h-100 d-flex justify-content-center align-items-center'><SmallLoader/></div></div>
      
      )}
      
      </>) : (
    <LazyBackground src={latestMusic} css="currentMusic" onClick={onClick}>
        <div className="HomeShare" onClick={(e) => {
                e.preventDefault()
                
                dispatch(setsharedUrl(
                    `https://leerecs.com/podcast/${musicinfo.value[0].name}`
                  ))
            }}><HomeShare/></div>
        <PlayMusic />
      
      </LazyBackground>)
      }
    </>;
  }
console.log(props.info)
  return (
    <div className="container-fluid" id="home">
      <WebGl />
      <Menu info={info} />
      <div className="d-md-flex justify-content-between">
        <div className="actual-content">
          <div className="d-md-flex justify-content-md-between">
            <div className="back-arrow">
              {(state !== 0) && (<div onClick={() => { setstate(state - 1) }} className="icons">
                <BackArrow />
              </div>)}
            </div>
            <div className="text-content">
              <div className="home-adcard">
              </div>
              <div className="the-cta-text">

                {(state === 0) && (
                  <div className="welcome-text"><div className="the-fat-text ml13" dangerouslySetInnerHTML={{__html: description!==null ? description[0].header:""}}>
                    
                  </div>
                    <div className="desc-text">
                      <p dangerouslySetInnerHTML={{__html: description!==null ? description[0].description:""}}>
                      </p>
                      <p dangerouslySetInnerHTML={{__html:description!==null ? description[0].sub:""}}>
                      </p>
                    </div>
                  </div>)}
                {(state === 1) && (
                  <div className="welcome-text"><div className="the-fat-text ml13" dangerouslySetInnerHTML={{__html:description!==null && description[1].header}}>
                  </div>
                    <div className="desc-text">
                      <p dangerouslySetInnerHTML={{__html:description!==null ? description[1].description:""}}>
                      </p>
                    </div>
                  </div>)}
                {(state === 2) && (
                  <div className="welcome-text"><div className="the-fat-text ml13" dangerouslySetInnerHTML={{__html:description!==null ? description[2].header:""}}>
                  </div>
                    <div className="desc-text">
                      <p dangerouslySetInnerHTML={{__html:description!==null ? description[2].description:""}}>
                      </p>

                    </div>
                  </div>)}
                  {buttons.length!==0 &&(<div className="bottom-cta">
                  <Link to={buttons?buttons[0]?.url:""}>
                    <div className="listen-to-music-btn music-btn-listen-cta">
                    <div className="single-link d-flex">
                   <Guitor/>
                    
                    
                   {buttons?buttons[0]?.text:""}
                      </div>
                    </div>
                  </Link>
                  
                    <div className="bottom-links">
                    <Link to={buttons?buttons[1]?.url:""}>
                      <div className="non-main-cta">
                        <div className="single-link d-flex">
                          <Music />
                          {buttons?buttons[1]?.text:""}
                        </div>
                      </div>
                      </Link>
                      <a href={buttons?buttons[2]?.url:""} target="_blank">
                      <div className="non-main-cta edgeleft">
                        <div className="single-link d-flex">
                          <Radio />
                          {buttons?buttons[2]?.text:""}
                        </div>
                      </div>
                      </a>
                    </div>
                  
                </div> )}
                
                <div></div>
              </div>
            </div>
            <div id="current-music">
              {(state === 0) && (
                <div>
                  {manageMusic()}
                </div>)}
              {(state === 1) && (
                <div className="d-flex">
                  <LazyBackground src={description[1].image} css="currentMusic"></LazyBackground>
                </div>)}
              {(state === 2) && (
                <div className="d-flex">
                  <LazyBackground src={description[2].image} css="currentMusic"></LazyBackground>
                </div>
              )}
              {buttons.length!==0&&(
                <div className="carousel-circle">
                <div className="carousel-container d-flex">
                  <div className={`circle rounded-circle ${(state === 0) && "active"}`} onClick={() => { setstate(0) }}></div>
                  <div className={`circle rounded-circle ${(state === 1) && "active"}`} onClick={() => { setstate(1) }}></div>
                  <div className={`circle rounded-circle ${(state === 2) && "active"}`} onClick={() => { setstate(2) }}></div>
                </div>
              </div>
              )}
              
            </div>
            <div className="forwardarrow">
              {(state !== 2 &&buttons.length!==0) && (<div onClick={() => { setstate(state + 1) }} className="icons">
                <ForwardArrow />
              </div>)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
