import React from 'react'


import { useAppDispatch } from "../../redux/hooks"

//dispatch action
import { hideContributeBtn } from "../../redux/data/navbar/index"

import "../musicHome/index"

import MusicSideBar from "../../components/musicPageComponents/sidebar/index"
import MusicNavBar from '../../components/musicPageComponents/navbar'

import AddMusic from "../../components/musicianPageComponents/addMusic/index"
//scss files
import "./index.scss"
import MusicFooter from '../main/components/footerSection/MusicFooter'

// import MusicOfficialPlayer from "../../components/musicPageComponents/musicPlayer/index"

const AddMusicPage = () => {
    const dispatch = useAppDispatch()
    const hideContribute = () => {
        dispatch(hideContributeBtn())
    }
    return (
        <>
       <AddMusic/>
       <MusicFooter/>
        </>
    )
}

export default AddMusicPage
