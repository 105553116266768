import React from 'react'

import "./HeartIcon.scss"

export default function HeartIcon() {
  return (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" width="23.722" height="21.64" viewBox="0 0 23.722 21.64" id="desktop">
        <g id="heart" transform="translate(-1.25 1.25)">
          <path id="Path_111" data-name="Path 111" d="M24.223,9.513A6.611,6.611,0,0,1,22.385,14.1c-2.712,2.812-5.343,5.745-8.156,8.453a1.628,1.628,0,0,1-2.286-.05L3.838,14.1a6.65,6.65,0,0,1,0-9.179,6.2,6.2,0,0,1,8.978,0l.3.3.294-.3a6.2,6.2,0,0,1,8.978,0,6.611,6.611,0,0,1,1.839,4.589Z" transform="translate(0 -3.5)" fill="none" stroke="#efe9f8" strokeLinejoin="round" strokeWidth="1.5" />
        </g>
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" width="16.667" height="15" viewBox="0 0 16.667 15" id="mobile">
        <g id="heart" transform="translate(-163 1)">
          <path id="Path_111" data-name="Path 111" d="M18.667,7.885a4.958,4.958,0,0,1-1.378,3.442c-2.034,2.109-4.008,4.308-6.117,6.34a1.221,1.221,0,0,1-1.714-.038l-6.079-6.3a4.988,4.988,0,0,1,0-6.884,4.65,4.65,0,0,1,6.733,0l.222.228.221-.228a4.648,4.648,0,0,1,6.733,0,4.958,4.958,0,0,1,1.379,3.442Z" transform="translate(161 -4)" fill="#fff" />
        </g>
      </svg>

    </div>
  )
}
