// @ts-nocheck
import { useState } from 'react'

//redux helpers
import { useAppDispatch, useAppSelector } from "../../redux/hooks"

//redux actions 
import { album} from "../../redux/data/uiData/index"

//helpers
import { useMutation, useQuery, gql } from '@apollo/client'

//components
import { FileUpload, useUpload } from "../../genericComponents/fileUploud/FileUpload"
import InputForm from '../formInput'

//icons components
import MusicIcon from "./src/MusicIcon"
import InfoIcon from "./src/InfoIcon"
import Proceed from "./src/Procced"
import Closeicon from "./src/Closeicon"
import AddSong from "../addSong"
import ImageIcon from "./src/ImageIcon"
import Done from '../sucess/icons/done_all'
import Loader from '../requestInterview/src/icons/loader'
import CloudErrorComponent from '../requestInterview/src/UploadIcon'
import ProgressBannerPlaylist from '../addPlaylist/ProgressBarPlaylist'
//info components
import Sucess from '../sucess'
import Loading from '../loading'
import Error from '../error'

//scss files
import "./index.scss"

//configuration 

enum options{
    DIGITAL_MEDIA_ONLY="DIGITAL_MEDIA_ONLY",
    PHYISICAL_MEDIA="PHYISICAL_MEDIA"
}
enum physical_media_type {
    CD="CD",
    VINYL="VINYL",

}
const AddPlaylist = () => {

    //toggle create playlist
    const {albumState,currentAlbum} = useAppSelector(state => state.uiData)
    const [close,setClose]=useState(false)
    const [playliststate, setplayliststate] = useState(albumState)
    const [option,setOption]=useState<options>(options.DIGITAL_MEDIA_ONLY)
    const [phyisicalType,setPhysicalType]=useState<physical_media_type>(physical_media_type.CD)
    //redux setup
    const dispatch = useAppDispatch()

    //state values
    const [uploading, setuploading] = useState(false)
    const[selectedSongs,setSelectedSongs]=useState<song[]>([])
    const[removedSong,setremovedSong]=useState<song[]>([]);
    const appendSong=(song:song)=>{   
        setSelectedSongs([...selectedSongs,song])
        const arr=[...removedSong]
        delete arr[arr.findIndex((s:song)=> s.musicId===song.musicId)]
        setremovedSong(arr.flat())
    }
    const pullSong=(song:song)=>{
        const arr=[...selectedSongs]
        delete arr[arr.findIndex((s:song)=> s.musicId===song.musicId)]
        setSelectedSongs(arr.flat())
        setremovedSong([...removedSong,song])
        console.log(removedSong)
    }

    //add playlust mutation
    const MUTATION = gql`
    mutation($title:String!,$desc:String!,$userId:Int!,$cover:Int!){
    CreateAlbum(albumData:{
    title:$title,
    desc:$desc,
    userId:$userId,
    cover:$cover,
    }){
        albumId
        desc
    }
    }`
     

    //add music to playlist mutation
    const ADDMUSIC = gql`mutation(
        $music_file:Int!,
        $music_thumbnail:Int!,
        $music_artist:Int!,
        $playlist_id:Int!,
    ){
    AddMusicToAlbum(albumMusicData:{
        music_file:$music_file,
        music_thumbnail:$music_thumbnail,
        music_artist:$music_artist,
        playlist_id:$playlist_id,
    }){
        desc
    }
}`

const REMOVEMUSIC = gql`mutation(
    $music_file:Int!,
    $music_thumbnail:Int!,
    $music_artist:Int!,
    $playlist_id:Int!,
){
    RemoveMusicFromAlbum(albumMusicData:{
    music_file:$music_file,
    music_thumbnail:$music_thumbnail,
    music_artist:$music_artist,
    playlist_id:$playlist_id,
}){
    desc
}
}`

    //all playlists
    const QUERY = gql`query{
    AllAlbumByUser(userId:${parseInt(localStorage.getItem("userId") as string)}){
        title
        albumId
    }
    }`

    //function to get form value
    const value = (id: string) => {
        var inputValue = (document.getElementById(id) as HTMLInputElement).value
        return inputValue;
    };

    //setting up usemutation
    const [CreateAlbum, { error }] = useMutation(MUTATION, { errorPolicy: "all" })

    //add Music to playlist mutation
    const [AddMusicToAlbum, { loading: addMusicLoading, data: addMusicData, error: addMusicerror }] = useMutation(ADDMUSIC, { errorPolicy: "all" })
    const [RemoveMusicToAlbum, { loading: RemoveMusicLoading, data: RemoveMusicData,}] = useMutation(REMOVEMUSIC, { errorPolicy: "all" })

    //setting up usequery
    const { data: queryData} = useQuery(QUERY/*, { pollInterval: 500 }*/)

    //function  to upload playlist thumbnail
    const [{ upload: uploadCover, setfiles: setCoverFile }, { error: uploadCoverError }] = useUpload("image-file-album")

    //handle add music to playlistt
    const addMusic = async (e: any) => {
        e.preventDefault()
        const albumId = value("playlist-id");
        setClose(true)
        try {
            selectedSongs.forEach(async(song)=>{
                const result = await AddMusicToAlbum({
                    variables: {
                        music_file:song.musicId ,
                        music_thumbnail:song.imageId,
                        music_artist: song.memberId,
                        playlist_id:parseInt( albumId as String ),
                    }
                })
                if (result) {
                    if (addMusicData) {
                    }
                }
            })

            removedSong.forEach(async(song)=>{
                const result = await RemoveMusicToAlbum({
                    variables: {
                        music_file:song.musicId ,
                        music_thumbnail:song.imageId,
                        music_artist: song.memberId,
                        playlist_id:parseInt( albumId as String ),
                    }
                })
                if (result) {
                    if (RemoveMusicData) {
                    }
                }
            })
        }
        catch (e) {
        }
    }

    
    const[addingstatus,setAddingStatus]=useState(false)
    //handling form submit
    const [message,setMessage]=useState("loading")
     const [color,setColor]=useState("#7171ff")
     const [width,setWidth]=useState("10%")
    //  const [btn,setbtn]=useState(false);
     const [icon,setIcon]=useState(<Loader/>)
     const [status,setStatus]=useState(null);
    const handleSubmit = async (e: any) => {
        e.preventDefault()
        setAddingStatus(true)
        // setbtn(true)
 
        //intializing
        setStatus(null)
        setMessage("loading ...")
        setColor("#7171ff")
        setWidth("10%")
        setIcon(<Loader/>)
        setTimeout(() => {
            
        },100);
        const [title, desc] = [value("title"), value("description")]

        //upload playlist thumbnaiil
        try {
            setuploading(!uploading)
           
            const thumbnail = await uploadCover("album")
           
            if (thumbnail) {
                setuploading(false)
                const result = await CreateAlbum({
                    variables: {
                        title: title,
                        desc: desc,
                        userId: parseInt(localStorage.getItem("userId") as string),
                        cover: thumbnail.id,
                    }
                })
                if (result) {
                    if (result) {
                        if (result.data) {
                            setWidth("100%")
                            setColor("#5DD97C")
                            setMessage(` Album created successfully <a href='/music/album/${title.toLocaleLowerCase().replaceAll(" ","-")}'>click here </a> to add songs`)
                            setIcon(<Done/>) 
                            setStatus(true)
                            // setbtn(false)
                        }
                        else{
                            console.log(result)
                            setColor("#FF7171")
                            setMessage(error.graphQLErrors[0])
                            // setbtn(false)
                            setIcon(<CloudErrorComponent/>) 
                        }
    
                    }
                }
                else{
                    setColor("#FF7171")
                    if(uploadCoverError && uploadCoverError!==message){
                setMessage(uploadCoverError)}
                // setbtn(false)
                setIcon(<CloudErrorComponent/>)
                }
            }
        } catch (error) {
            console.log(error)
            setColor("#FF7171")
            setMessage(e.message)
            // setbtn(false)
            setIcon(<CloudErrorComponent/>)
        }

    }

    return (

        <div>
            <div id="choose-playlist">
                <div className="panel">
                    <div className="row"> <div className="col-6"></div></div>
                
                </div>
            </div>
            
            {(playliststate === "add") && (
                <div id="choose-playlist">
                    <div className="panel">
                        <div className="d-flex justify-content-between">
                            <h6 className="panel-title">Update Album Music List</h6>
                            <div onClick={(e: any) => {
                                e.preventDefault()
                                dispatch(album())
                            }}>
                                <Closeicon />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                {close&&(addMusicLoading ||RemoveMusicLoading) && <Loading></Loading>}
                                { close&&(addMusicData||RemoveMusicData) && <Sucess close={setClose} text="Album updated successfully."></Sucess>}
                                {close&&addMusicerror && (addMusicerror.graphQLErrors.map(({ message }, i) => (
                                    <Error error={message}></Error>)))}
                            </div>
                        </div>
                    <p style={{color:"#efe9f8"}}>Add or remove music from your music list, select the album to edit in the dropdown list, and click “update album” to save your changes.</p>
                        <div className="d-flex justify-content-between">
                            <div className="image-upload col-md-6">
                            <div>
                            <AddSong id={currentAlbum} removeSongFunction={pullSong} addSongFunction={appendSong} selectedSongs={selectedSongs}/>
                                    </div>
                            </div>
                            <form action="" onSubmit={addMusic} style={{minWidth:"300px"}}>
                                {queryData && (
                                    <div>
                                        {
                                            (queryData.AllAlbumByUser.length !== 0) && (
                                                <div>
                                                    <div className="input-label">Choose an album</div>
                                                    <div id="form-input">
                                                        <div className="input-group-component d-flex">
                                                            <MusicIcon />
                                                            
                                                            <select id="playlist-id" className="form-control">
                                                                {(queryData.AllAlbumByUser.map((singlePlaylist: any) => {
                                                                    return (<option value={singlePlaylist.albumId} selected={singlePlaylist.albumId===currentAlbum}>{singlePlaylist.title}</option>)
                                                                }))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>)
                                        }
                                    </div>
                                )}
                                <div className="input-label">Create a new one</div>
                                <div className="create-new-playlist">
                                    <div className="d-flex" onClick={(e: any) => {
                                        e.preventDefault()
                                        setplayliststate("new")
                                    }}>
                                        {/* <AddPlaylistIcon /> */}
                                        <div className="create-text">Create A New album</div>
                                    </div>
                                </div>
                                <button className="btn red-btn" type="submit">
                                    <div className="d-flex">
                                        <Proceed />
                                        <div className="title-text">Update album</div>
                                    </div>
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            )}
            {
                (playliststate === "new") && (
                    <div id="add-playlist">
                        <div className="panel">
                            <div className="d-flex justify-content-between">
                                <h6 className="panel-title">Album Info</h6>
                                <div onClick={(e: any) => {
                                    e.preventDefault()
                                    dispatch(album())
                                }}>
                                    <Closeicon />
                                </div>
                            </div>
                            {/* <div style={{display:"flex",alignSelf:"start", cursor:"pointer", justifyContent:"space-around",color:"#fff",}} className="mb-3">
                                <div 
                                onClick={(e: any) => {
                                    setOption(options.DIGITAL_MEDIA_ONLY)
                                }}
                                style={{
                                    border:`1px solid ${option===options.DIGITAL_MEDIA_ONLY?"#F8107D":"#FFF"}`,
                                    borderRadius:"5px",
                                    padding:"0px 10px",
                                    height:"28px"
                                     }}>
                                Digital Online
                                </div>
                                <div>
                                <div 
                                onClick={(e: any) => {
                                    setOption(options.PHYISICAL_MEDIA)
                                }}
                                style={{
                                    border:`1px solid ${option===options.PHYISICAL_MEDIA?"#F8107D":"#FFF"}`,
                                    borderRadius:"5px",
                                    padding:"0px 10px",
                                     }}>
                                Phyisal Media
                                </div>
                                {option===options.PHYISICAL_MEDIA && (<div 
                                className='mt-2'
                                style={{
                                 display:"flex",
                                 justifyContent:"space-around"

                                }}>
                                    <div 
                                    onClick={()=>{
                                        setPhysicalType(physical_media_type.CD)  
                                    }}
                                    style={{
                                        border:`1px solid ${phyisicalType===physical_media_type.CD?"#F8107D":"#FFF"}`,
                                        borderRadius:"5px",
                                        padding:"0px 10px"
                                    }}>CD</div>
                                    <div
                                     onClick={()=>{
                                        setPhysicalType(physical_media_type.VINYL)  
                                    }}
                                     style={{
                                        border:`1px solid ${phyisicalType===physical_media_type.VINYL?"#F8107D":"#FFF"}`,
                                        borderRadius:"5px",
                                        padding:"0px 10px"
                                    }}>Vinyl</div>
 
                                </div>)}

                                </div>
                            </div> */}
                            <div className="row">
                                <div className="col-md-12">
                                {addingstatus &&( <ProgressBannerPlaylist close={setAddingStatus} message={message} width={width} color={color} icon={icon} status={status}/>)}   
                                     
                                </div>
                            </div>
                            <div className="row d-flex panel-forms">
                                <div className="image-upload col-md-4">
                                    <div>
                                        {setCoverFile && <FileUpload fileId="image-file-album" acceptedFiles="image/jpeg, image/png" title="Playlist Cover" text="Drag and drop an image" rule1="Add high quality image" rule2="Add image you have right to" icon={<ImageIcon></ImageIcon>} onDropHandler={setCoverFile} />}
                                    </div>
                                </div>
                                <form action="" onSubmit={handleSubmit} className="col-md-8">
                                    <InputForm placeholder="Album Title" icon={<MusicIcon />} id="title" type="text" />
                                    <InputForm placeholder="Description" icon={<InfoIcon />} id="description" type="text" area={true} />
                                    <button className="btn red-btn" type="submit">
                                        <div className="d-flex">
                                            <Proceed />
                                            <div className="title-text">Create Album</div>
                                        </div>
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default AddPlaylist
