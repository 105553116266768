import React from 'react'
import "./index.scss"

export default function SingleMusicPreloader() {
    const loop=()=>{
        let i=[0,1,2,3,4,5,6,7,8,9]
        return i.map((key:any,idx:any)=>{
            return <div className="single-preloader"></div>
        })
    }
    return (
        <div id="music-preloader">
            {loop()}
        </div>
    )
}
