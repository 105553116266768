import React from 'react'

//redux hooks
import {useAppDispatch,useAppSelector} from "../../../redux/hooks"

//helpers
import {Link} from "react-router-dom"

//wrapper
import LikeMusic from '../../wrappers/likeMusic'

//iicons
import HeartIcon from "./src/HeartIcon"
import PlayIcon from "./src/PlayIcon"

//redux actions
import { playSingle,setIndex } from "../../../redux/data/musicPlayer/index"
import { addMusic } from "../../../redux/data/playlist/index"
import { playlist } from "../../../redux/data/uiData/index"

interface props{
    title:string;
    thumbnail:string;
    file:string;
    username:string;
    count:Number;
    music_file: Number;
    music_thumbnail: Number;
    music_artist: Number;
    music_id:Number;
    index:number;
    userId:number;
}

const PodcastMusicElement:React.FC<props> = (props) => {
    const dispatch = useAppDispatch()
    const { musicData } = useAppSelector(state => state.musicPlayerData)
    const regex = /[?@\/#:\,;%+&=\{\}\[\]'\^-]/g
    return (
        <div className="the-music">
            <div className="row mx-0 single-music justify-content-between">
                <div className="col-1 px-0 numbering">{props.count}</div>
                <div className="col-9 px-0 music">
                    <div className="d-flex">
                        <div className="music-thumbnail" style={{ backgroundImage: `url("${props.thumbnail}")` }}></div>
                        <div className="music-info">
                            <Link to={`/a/${props.username.replaceAll(' ','-').toLowerCase()}`}  >
                            <div className="music-title">{props.username}</div></Link>
                            <Link to={`/a/${props.username.replaceAll(' ','-').toLowerCase()}/s/${props.username.replaceAll(regex,'').replaceAll(' ','-').toLowerCase()}`} ><div className="music-artist">{props.title}</div></Link>
                        </div>
                    </div>
                </div>
                {/* <div className="col-1 px-0 album"></div> */}
                <div className="col-1 px-0 react"><LikeMusic music_id={props.music_id}><HeartIcon /></LikeMusic></div>
                <div className="col-1 px-0 play">
                    <div className="d-flex justify-content-end">
                        <div></div>
                        <div onClick={(e)=>{
                            e.preventDefault()
                            //  alert(musicData.length)
                            if (musicData.length<2){
                                 
                                
                                dispatch(playSingle({
                                    value: [{
                                        name: props.title,
                                        singer: props.username,
                                        cover: props.thumbnail,
                                        musicSrc: props.file,
                                    }]
                                }))
                                
                                // alert(props.thumbnail)
                            }
                            else{
                                dispatch(setIndex({
                                    value: props.index
                                }))
                            }
                        }}>
                            <PlayIcon />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PodcastMusicElement
