import React from 'react'

//helpers
import {Link} from "react-router-dom"

interface props{
    artistName:string;
    artistProfile:string;
    profileLink:string;
}

const FeaturedArtistElement:React.FC<props> = (props) => {
    return (
        <div className="col-md-3 px-0 card">
            <Link to={props.profileLink}>
                <div className="artist" style={{ backgroundImage: `url("${props.artistProfile}")` }}></div>
                <div className="artist-name">{props.artistName}</div>
            </Link>
        </div>
    )
}

export default FeaturedArtistElement
