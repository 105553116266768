/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";

//redux hooks
import { useAppDispatch } from "../../../redux/hooks";
import { Link } from "react-router-dom";

//wrapper
import Player from "../../../pages/main/components/eventsSection/src/Player";
import { useQuery, gql } from "@apollo/client";
import configuration from "../../../config/configuration";

//iicons

//redux actions
import { playSingle } from "../../../redux/data/musicPlayer/index";

interface props {
  title: string;
  cover: string;
  count: Number;
  albumId: Number;
  username: string;
  songs:any;
}

const MusicElement: React.FC<props> = (props) => {
  const dispatch = useAppDispatch();
  const QUERY = gql`
    query {
        AllMusicInAlbum(albumId:${props.albumId}) {
            thumbnail
        file
        title
        tag
        desc
        musicId
        imageId
        username
        messageId
        topicId
        likes
        userId
      }
    }
  `;
let musicData: any=[];
  const { data } = useQuery(QUERY /*, { pollInterval: 500 }*/);
  useEffect(()=>{
    if(data){
   data.AllMusicInAlbum.map((music:any)=>{
    musicData.push({
        name: music.title,
        singer: music.username,
        cover: `${configuration.FILE_STORAGE_LINK}${music.thumbnail}`,
        musicSrc: `${configuration.READFILE_LINK}${music.file}`,
    }) 
    return music;
   })
    }
  },[])
  return (
    <div className="col-md-3 px-2  card  music-e-card  shadow-sm">
      {/* <Link to={`/music/album/${props.albumId}`}> */}
      <div className="cover">
        <div className="cover-wrapper">
          <div>
            <img src={props.cover} alt="" aria-hidden="false" loading="lazy" />
          </div>
        </div>
        <div className="music-play-btn" onClick={(e) => {
                                e.preventDefault()
                                console.log(props.songs)
                                    dispatch(playSingle({
                                        value: props.songs
                                    }))
                               
                            }}>
          <Player />
        </div>
      </div>
      <Link to={`/music/album/${props.title.toLowerCase().replaceAll(" ","-")}`}>
      <div className="song-name mt-2">{props.title}</div>
      </Link>
      <Link to={`/a/${props.username.replaceAll(' ','-').toLowerCase()}`}>
        <div className="numbering mb-1 mt-1">By {props.username}</div>
      </Link>
    </div>
  );
};

export default MusicElement;
