/* eslint-disable no-lone-blocks */
import update from "immutability-helper";
import { useCallback, useState } from "react";


import { Card } from "./Card";

const style = {
  width: '100%',
};
interface Music {
	thumbnail: string
	file: string
	title: string
	tag: string
	desc: string
	musicId: number
	imageId: number
	username: string
	messageId: number
	topicId: number
	memberId: number
    likes: number
    category: number
	userId: number
	color_name:string
    order_id:number
    playlist_id:number
}
 interface props{
    data:Music[]
    refetch:any
 }
export interface Item {
  id: number;
  text: string;
}

export interface ContainerState {
  cards: Item[];
}

export const Container:React.FC<props>  = ({data,refetch}) => {
  {
    const [cards, setCards] = useState(data);

    const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
      setCards((prevCards: Music[]) =>
        update(prevCards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevCards[dragIndex] as Music],
          ],
        })
      );
    }, []);

    const renderCard = useCallback(
      (card:Music, index: number) => {
        return (
          <Card
            key={card.order_id}
            index={index}
            id={card.order_id}
            text={card.title}
            data={card}
            moveCard={moveCard}
            refetch={refetch}
            setCards={setCards}
          />
        );
      },
      []
    );

    return (
      <>
        <div style={style}>{cards.map((card:Music, i:number) => renderCard(card, i))}</div>
      </>
    );
  }
};
