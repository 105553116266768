// @ts-nocheck
import React from 'react'

function ArtistPlayButton({data}) {
    return (
      <svg
        width="25"
        height="22"
        viewBox="0 0 15 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.906 1.53701C1.81491 1.483 1.71114 1.45406 1.60524 1.45313C1.49934 1.45221 1.39508 1.47933 1.30306 1.53174C1.21104 1.58415 1.13452 1.65998 1.0813 1.75154C1.02807 1.84309 1.00002 1.9471 1 2.05301V15.947C1.00002 16.0529 1.02807 16.1569 1.0813 16.2485C1.13452 16.34 1.21104 16.4159 1.30306 16.4683C1.39508 16.5207 1.49934 16.5478 1.60524 16.5469C1.71114 16.546 1.81491 16.517 1.906 16.463L13.629 9.516C13.7185 9.46288 13.7927 9.38737 13.8442 9.2969C13.8957 9.20643 13.9228 9.10411 13.9228 9.00001C13.9228 8.8959 13.8957 8.79358 13.8442 8.70311C13.7927 8.61264 13.7185 8.53713 13.629 8.48401L1.906 1.53701Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
}

export default ArtistPlayButton
