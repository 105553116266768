// @ts-nocheck
import React, { useState } from 'react'

//helpers
import { useQuery, gql } from "@apollo/client"

//redux hooks
import { useAppDispatch } from "../../../redux/hooks"

//redux actions
import { playSingle } from "../../../redux/data/musicPlayer/index"
import { setsharedUrl } from "../../../redux/data/artistProfile/index"

//icons
import PlayMusicIcon from "./src/PlayMusicIcon"

//prelaoders
import HeaderPreloader from "../headerPreloader/index"
import SingleMusicPreloader from "../singleMusicPreloader"

import MusicElement from "./MusicElement"

import "./index.scss"

//configuration
import configuration from '../../../config/configuration'
import ShareIcon from './src/ShareIcon'
import Thumbnail from './src/Thumnail'

interface props {
    data:any;
  loading: boolean;
  error: any;
}
const Component: React.FC<props> = ({data,loading,error}) => {
    return (
        <div id="all-music-in-playlist">
            {/* {loading && <HeaderPreloader />} */}
            {data && (
                <div className=" py-5" >
                    
                    
                </div>
            )}

            <div className="row mx-0 table-heading justify-content-between">
                <div className="col-md-1 px-0">#</div>
                <div className="col-md-6 px-0">Title</div>
                <div className="col-md-3 px-0"></div>
                <div className="col-md-1 px-0">React</div>
                <div className="col-md-1 px-0">
                    <div className="d-flex justify-content-end">
                        <div></div>
                        <div>Play</div>
                    </div>
                </div>
                <div className="col-md-12 px-0"><hr /></div>
            </div>
            {loading && (<SingleMusicPreloader />)}
            {data && (data.map((music: any, count: any) => {
                
                return (<MusicElement
                     recommend={music.playData}
                    music_file={music.musicId}
                    music_thumbnail={music.imageId}
                    music_artist={music.memberId}
                    title={music.title}
                    thumbnail={`${configuration.FILE_STORAGE_LINK}${music.thumbnail}`}
                    file={`${configuration.READFILE_LINK}${music.file}`}
                    username={music.username}
                    music_id={music.topicId}
                    count={(count + 1).toLocaleString('en-US', {
                        minimumIntegerDigits: 2,
                        useGrouping: false,
                    })}
                    color_name={music.color_name}
                    userId={music.user_id}
                    index={count}
                />)
            }))}
            <div style={{marginBottom:"80px"}}></div>
        </div>
    )
}

export default Component;
