import React,{useEffect} from 'react'

//helerps
import { gql, useMutation } from "@apollo/client"

//redux setup
import {useAppSelector} from "../../redux/hooks"


const AddHistory = () => {

    const { musicId } = useAppSelector(state => state.musicPlayerData)

    const MUTATION = gql`mutation(
        $music_id: Int!,
        $userId: Int!,
    ){
    AddMusicHistory(musicHistoryData:{
        music_id: $music_id,
	    userId: $userId,
    }){
        desc
    }
}`
const Add=gql`mutation(
    $music_id: Int!,
        $userId: Int!,
){
AddPlays(musicHistoryData:{
        music_id: $music_id,
	    userId: $userId,
    }){
    desc
}
}`

    const [AddMusicHistory, { loading, data, error }] = useMutation(MUTATION)
    const [AddPlays, { loading:loadingPlays, data:dataPlays, error:errorPlays }] = useMutation(Add)

    const handleClick = async () => {
        try {
            
            if (musicId!==0){
                
                const result1= await AddPlays({
                    variables: {
                        music_id: musicId,
                        userId: 1,
                    }
                })
               console.log(result1)
                const result = await AddMusicHistory({
                    variables: {
                        music_id: musicId,
                        userId: parseInt(localStorage.getItem("userId") as string),
                    }
                })
                console.log(result)
                if (result) {
                    
                }
                if(result1){

                }
            }

        } catch (e) {
        }

    }
    useEffect(() => {
        handleClick()
    }, [musicId])
    return (
       <div />
    )
}

export default AddHistory
