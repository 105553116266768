// @ts-nocheck
import { useState,useEffect } from 'react'
//redux helpers
import { useAppDispatch,useAppSelector } from "../../redux/hooks"
//redux actions 
import { playlist } from "../../redux/data/uiData/index"
//helpers
import { useMutation, useQuery, gql } from '@apollo/client'
//components
import { FileUpload, useUpload } from "../../genericComponents/fileUploud/FileUpload"
import InputForm from '../formInput'
import ProgressBannerPlaylist from './ProgressBarPlaylist'
import AddSong from '../addSong'
//icons components
import MusicIcon from "./src/MusicIcon"
import InfoIcon from "./src/InfoIcon"
import Proceed from "./src/Procced"
import Closeicon from "./src/Closeicon"
import ImageIcon from "./src/ImageIcon"
import Done from '../sucess/icons/done_all'
import Loader from '../signup/src/icons/loader'
import CloudErrorComponent from '../requestInterview/src/UploadIcon'
//info components
import Sucess from '../sucess'
import Loading from '../loading'
import Error from '../error'
//scss files
import "./index.scss"


const AddPlaylist = () => {

    //toggle create playlist
    const {PlaylistState,currentPlaylist} = useAppSelector(state => state.uiData)
    const [playliststate, setplayliststate] = useState(PlaylistState)
    const [close,setClose]=useState(false)
    

    //redux setup
    const dispatch = useAppDispatch()


    //state values
    const [uploading, setuploading] = useState(false)
    const[selectedSongs,setSelectedSongs]=useState<song[]>([])
    const[removedSong,setremovedSong]=useState<song[]>([]);
    const appendSong=(song:song)=>{   
        setSelectedSongs([...selectedSongs,song])
        const arr=[...removedSong]
        delete arr[arr.findIndex((s:song)=> s.musicId===song.musicId)]
        setremovedSong(arr.flat())
        
    }
    const pullSong=(song:song)=>{
        const arr=[...selectedSongs]
        delete arr[arr.findIndex((s:song)=> s.musicId===song.musicId)]
        setSelectedSongs(arr.flat())
        setremovedSong([...removedSong,song])
        
    }

    //add playlust mutation
    const MUTATION = gql`
    mutation($title:String!,$desc:String!,$userId:Int!,$cover:Int!){
    CreatePlaylist(playListData:{
    title:$title,
    desc:$desc,
    userId:$userId,
    cover:$cover,
    }){
        playListId
        desc
    }
    }`

    //add music to playlist mutation
    const ADDMUSIC = gql`mutation(
        $music_file:Int!,
        $music_thumbnail:Int!,
        $music_artist:Int!,
        $playlist_id:Int!,
    ){
    AddMusicToPlaylist(musicData:{
        music_file:$music_file,
        music_thumbnail:$music_thumbnail,
        music_artist:$music_artist,
        playlist_id:$playlist_id,
    }){
        desc
    }
}`
 //add music to playlist mutation
 const REMOVEMUSIC = gql`mutation(
    $music_file:Int!,
    $music_thumbnail:Int!,
    $music_artist:Int!,
    $playlist_id:Int!,
){
    RemoveMusicFromPlaylist(musicData:{
    music_file:$music_file,
    music_thumbnail:$music_thumbnail,
    music_artist:$music_artist,
    playlist_id:$playlist_id,
}){
    desc
}
}`
    //all playlists
    const QUERY = gql`query{
    MyPlaylists(userId:${parseInt(localStorage.getItem("userId") as string)}){
        title
        playListId
    }
    }`

    //function to get form value
    const value = (id: string) => {
        var inputValue = (document.getElementById(id) as HTMLInputElement).value
        return inputValue;
    };

    //setting up usemutation
    const [CreatePlaylist, { error }] = useMutation(MUTATION, { errorPolicy: "all" })

    //add Music to playlist mutation
    const [AddMusicToPlaylist, { loading: addMusicLoading, data: addMusicData, error: addMusicerror }] = useMutation(ADDMUSIC, { errorPolicy: "all" })
    const [RemoveMusicToPlaylist, { loading: removeMusicLoading, data: removeMusicData }] = useMutation(REMOVEMUSIC, { errorPolicy: "all" })

    //setting up usequery
    const { data: queryData } = useQuery(QUERY/*, { pollInterval: 500 }*/)

    //function  to upload playlist thumbnail
    const [{ upload: uploadCover, setfiles: setCoverFile }, { error: uploadCoverError }] = useUpload("image-file-playlist")

    //handle add music to playlistt
    const addMusic=async(e:any)=>{
        e.preventDefault()
        
        const playlistId=value("playlist-id");
        setClose(true)
        try{
            // console.log(musicplaylist_file)
            selectedSongs.forEach(async(song)=>{
            const result = await AddMusicToPlaylist({variables:{
                music_file: song.musicId,
                music_thumbnail: song.imageId,
                music_artist: song.memberId,
                playlist_id: parseInt(playlistId as string),
            }})
            if(result){
                
                if(addMusicData){
                    // console.log(addMusicData)
                }
            }
        })
        console.log(removedSong)

        removedSong.forEach(async(song)=>{
            const result = await RemoveMusicToPlaylist({variables:{
                music_file: song.musicId,
                music_thumbnail: song.imageId,
                music_artist: song.memberId,
                playlist_id: parseInt(playlistId as string),
            }})
            // console.log(result)
            if(result){
                
                if(addMusicData){
                     console.log(addMusicData)
                }
            }
        })
        }
        catch(e){
        }
    }

    const[addingstatus,setAddingStatus]=useState(false)
    //handling form submit
    const [message,setMessage]=useState("loading")
     const [color,setColor]=useState("#7171ff")
     const [width,setWidth]=useState("10%")
    //  const [,setbtn]=useState(false);
     const [icon,setIcon]=useState(<Loader/>)
     const [status,setStatus]=useState(null);
    const handleSubmit = async (e: any) => {
        e.preventDefault()
        setAddingStatus(true)
        // setbtn(true)
 
        //intializing
        setStatus(null)
        setMessage("loading ...")
        setColor("#7171ff")
        setWidth("10%")
        setIcon(<Loader/>)
        setTimeout(() => {
            
        },100);
        const [title, desc] = [value("title"), value("description")]

        //upload playlist thumbnaiil
        try {
            setuploading(!uploading)
            const thumbnail = await uploadCover("playlist")
             
            if (thumbnail) {
                setuploading(false)
                const result = await CreatePlaylist({
                    variables: {
                        title: title,
                        desc: desc,
                        userId: parseInt(localStorage.getItem("userId") as string),
                        cover: thumbnail.id,
                    }
                })
                if (result) {
                    if (result.data) {
                        setWidth("100%")
                        setColor("#5DD97C")
                        setMessage(` Playlist created successfully <a href='/music/playlist/${title.toLocaleLowerCase().replaceAll(" ","-")}'>click here </a> to add songs`)
                        setIcon(<Done/>) 
                        setStatus(true)
                        // setbtn(false)
                    }
                    else{
                        console.log(result)
                        setColor("#FF7171")
                        setMessage(error.graphQLErrors[0])
                        // setbtn(false)
                        setIcon(<CloudErrorComponent/>) 
                    }
                }
            }else{
                setColor("#FF7171")
                if(uploadCoverError && uploadCoverError!==message){
            setMessage(uploadCoverError)}
            // setbtn(false)
            setIcon(<CloudErrorComponent/>)
            }
        } catch (error) {
            setColor("#FF7171")
                setMessage(e.message)
                // setbtn(false)
                setIcon(<CloudErrorComponent/>)
        }

    }
    useEffect(()=>{
        if(uploadCoverError){
            setColor("#FF7171")
            setMessage(uploadCoverError)
            setIcon(<CloudErrorComponent/>)  
        }
       },[uploadCoverError])

    return (

        <div>
            {(playliststate === false) && (
                <div id="choose-playlist">
                    <div className="panel">
                        <div className="d-flex justify-content-between">
                            <h6 className="panel-title">Update Playlist Music List</h6>
                            <div onClick={(e: any) => {
                                e.preventDefault()
                                dispatch(playlist()) }}>
                                <Closeicon />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                {( close&&(addMusicLoading||removeMusicLoading)) && <Loading></Loading>}
                                { close&&(addMusicData||removeMusicData) && <Sucess close={setClose}  text="Playlist updated successfully "></Sucess>}
                                {close&&addMusicerror &&
                                    (
                                        addMusicerror.graphQLErrors.map(({ message }) => (
                                            <Error close={setClose} error={message}></Error>
                                        )))
                                }

                         {/* <div className=" progress-container-playlist mb-2 px-0">
                        <div className='cont'>
                        <div className='progress-bar'></div>
                        </div>
                        <div className='message'> <center> {message}</center></div>
                        <div className='close' onClick={(e)=>{setAddingStatus(false)}}> <Closeicon /></div>
                    </div> */}
                            </div>
                        </div>
                        <p  style={{color:"#efe9f8"}}>Add or remove music from your music list, select the album to edit in the dropdown list, and click “update playlsit” to save your changes.</p>
                        <div className="d-flex justify-content-between">
                            {/* <div className="panel-img"></div> */}
                            <div className="image-upload col-md-6">
                            <div>
                            <AddSong id={currentPlaylist} type="playlist" removeSongFunction={pullSong} addSongFunction={appendSong} selectedSongs={selectedSongs}/>
                                    </div>
                            </div>
                            <form action="" onSubmit={addMusic} style={{minWidth:"300px"}}>
                                {/* {console.log("query",queryData)} */}
                                {(queryData) && (
                                    <div>
                                        {(queryData.MyPlaylists.length !==0) && (
                                            <div>
                                                <div className="input-label">Choose a playlist</div>
                                                <div id="form-input" >
                                                    <div className="input-group-component d-flex">
                                                        <MusicIcon />
                                                        <select id="playlist-id" className="form-control">
                                                            {(queryData.MyPlaylists.map((singlePlaylist: any) => {
                                                                return (<option value={singlePlaylist.playListId} selected={singlePlaylist.playListId===currentPlaylist}>{singlePlaylist.title}</option>)
                                                            }))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                                <div className="input-label">Create a new one</div>
                                <div className="create-new-playlist">
                                    <div className="d-flex" onClick={(e:any)=>{
                                        e.preventDefault()
                                        setplayliststate(true)
                                    }}>
                                        {/* <AddPlaylistIcon /> */}
                                        <div className="create-text">Create A New Playlist</div>
                                    </div>
                                </div>
                                <button className="btn red-btn" type="submit">
                                    <div className="d-flex">
                                        <Proceed />
                                        <div className="title-text">Update playlist</div>
                                    </div>
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            )}
            {
                (playliststate === true) && (
                    <div id="add-playlist-home">
                        <div className="panel">
                            <div className="d-flex justify-content-between">
                                <h6 className="panel-title">Playlist Info</h6>
                                <div onClick={(e: any) => {
                                    e.preventDefault()
                                    dispatch(playlist())
                                }}>
                                    <Closeicon />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    {/* {(loading) && <Loading></Loading>}
                                    {(uploadingCover) && <Loading text="Uploading Cover"></Loading>}
                                    {(data && addMusicData) && <Sucess text="Added music to the new playlist"></Sucess>}
                                    {uploadCoverError && <Error text={uploadCoverError} />}
                                    {error &&
                                        (
                                            error.graphQLErrors.map(({ message }, i) => (
                                                <Error error={message}></Error>
                                            )))
                                    } */}
                                   {addingstatus &&( <ProgressBannerPlaylist close={setAddingStatus} message={message} width={width} color={color} icon={icon} status={status}/>)}   
                                      
                                </div>
                            </div>
                            <div className="row d-flex panel-forms">
                                <div className="image-upload col-md-4">
                                    <div>
                                        {setCoverFile && <FileUpload fileId="image-file-playlist" acceptedFiles="image/jpeg, image/png" title="Playlist Cover" text="Drag and drop an image" rule1="Add high quality image" rule2="Add image you have right to" icon={<ImageIcon></ImageIcon>}  onDropHandler={setCoverFile} />}
                                    </div>
                                </div>
                                <form action="" onSubmit={handleSubmit} className="col-md-8">
                                    <InputForm placeholder="Playlist Title" icon={<MusicIcon />} id="title" type="text" />
                                    <InputForm placeholder=" Playlsit Description" icon={<InfoIcon />} id="description" type="text" area={true} />
                                    <button className="btn red-btn" type="submit">
                                        <div className="d-flex">
                                            <Proceed />
                                            <div className="title-text">Create Playlist</div>
                                        </div>
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default AddPlaylist
