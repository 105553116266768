import React,{useState} from 'react';
import { Link } from 'react-router-dom';
import generateDate from '../../../../helper/generateDate';
import EyeIcon from '../../../../pages/Notification/src/EyeIcon';
import FollowedIcon from '../../../../pages/Notification/src/followedIcon';
import HeartIcon from '../../../../pages/Notification/src/HeartIcon';
import PlayIcon from '../../../../pages/Notification/src/PlayIcon';
import VerifiedIcon from '../../../../pages/Notification/src/verifiedIcon';
import AddBuddie from '../../../wrappers/AddBuddie';
import FollowIcon from '../../allMusicByArtist/src/FollowIcon';


const NotificationCard = (props) => {
    const regex = /[?@\/#:\,;%+&=\{\}\[\]\^-]/g
    const [beFriendStatus,setBeFriendStatus] =useState(props.buddie_list.includes(localStorage.getItem("userId"))?" Unfriend":" Befriend")
    const BefriendAction =(action,status)=>{
        // console.log(action)
        if(action==="Loading"){
            if(beFriendStatus==="Befriend"){
            setBeFriendStatus(" Unfriending...");}
            else{
                setBeFriendStatus(" Befriending...")
            }
        }
//  setBeFriendStatus(status());
 if(action==="added"){
    // console.log(action)
    setBeFriendStatus(" Unfriend");

 }
 if(action==="removed"){
    // console.log(action)
    setBeFriendStatus(" Befriend");
 }

    }
    return (
        <div className='d-flex notification align-items-center px-2' style={{backgroundColor:`${!props.seen?"#282828":"#282828"}`}}>
           {!props.seen &&  <div className='active-note mr-2 '></div>}
           {props.seen &&  <div className='active-note mr-2 ' style={{backgroundColor:"transparent"}}></div>}

           
            <div className='notification-image'>
           <div><div className="artist"  title={props.name}>
                    <div className='artist-wrapper'>
                        {props.is_verified &&(<div className="verified">
                          <VerifiedIcon/>
                        </div>)}
                        
                        <div>
                    <img  src={props.img} alt="" className='artist-img' loading='lazy' aria-hidden="false"/>
                    </div>
                    </div>
                </div></div>
                </div>
           <div className='notification-msg ml-3'>
            {props.is_verified&&(<Link to={`/a/${props.name.replaceAll(" ","-").toLowerCase()}`}>
            <span className='highlight'> {props.name}&nbsp;</span>
            </Link>)}
            {!props.is_verified&&(<Link to={`/a/${props.name.replaceAll(" ","-").toLowerCase()}`}>
            <span className='highlight'> {props.name}&nbsp;</span>
            </Link>)}
            
            {props.message.includes("</span>")&&props.message.split("</span>")[1]}
            {!props.message.includes("</span>")&&props.message}
            <div className="date">
                {generateDate(props.date)}
            </div>
            {/* <div className=''>
                {props.type==="like"&&( <Link to={`/a/${props.owner_name.replaceAll(" ","-").toLowerCase()}/s/${props.music.toLowerCase().replaceAll(regex,'').replaceAll(" ","-")}`}><button>View</button></Link>)}
            {props.type==="play"&&(<Link to={`/a/${props.owner_name.replaceAll(" ","-").toLowerCase()}/s/${props.music.toLowerCase().replaceAll(regex,'').replaceAll(" ","-")}`}><button>View</button></Link>)}
           </div> */}
            </div>
           {/* <div>{props.type==="like"&&( <Link to={`/a/${props.owner_name.replaceAll(" ","-").toLowerCase()}/s/${props.music.toLowerCase().replaceAll(regex,'').replaceAll(" ","-")}`}><button>View</button></Link>)}
           {props.type==="play"&&(<Link to={`/a/${props.owner_name.replaceAll(" ","-").toLowerCase()}/s/${props.music.toLowerCase().replaceAll(regex,'').replaceAll(" ","-")}`}><button>View</button></Link>)}
           {props.type==="friend"&&(
            <AddBuddie myBuddieId={props.user_id} Beaction={BefriendAction}>
            <div className='d-flex button'>
             <span> {beFriendStatus}</span>
            </div>
            </AddBuddie>
           )}
            </div> */}
             
        {props.type==="like" && (
            <Link to={`/a/${props.owner_name.replaceAll(" ","-").toLowerCase()}/s/${props?.music?.toLowerCase().replaceAll(regex,'').replaceAll(" ","-")}`}>
        <div className='notification-type d-flex justify-content-center align-items-center' style={{backgroundColor:"#F8107D"}}>
        <HeartIcon/>
        </div>
        </Link>
        ) }
        {props.type==="view" && (<Link to={`/a/${props.owner_name}`}><div className='notification-type d-flex justify-content-center align-items-center' style={{backgroundColor:"rgb(122,57,237,1)"}}>
        <EyeIcon/>
        </div></Link>) }
        {props.type==="play" && (
            <Link to={`/a/${props.owner_name.replaceAll(" ","-").toLowerCase()}/s/${props.music.toLowerCase().replaceAll(regex,'').replaceAll(" ","-")}`}>
        <div className='notification-type d-flex justify-content-center align-items-center' style={{backgroundColor:"rgb(245,158,12,1)"}}>
        <PlayIcon/>
        </div>
        </Link>
        ) }
        {props.type==="new" && (
            <Link to={`/a/${props.name.replaceAll(" ","-").toLowerCase()}/s/${props.music.toLowerCase().replaceAll(regex,'').replaceAll(" ","-")}`}>
        <div className='notification-type d-flex justify-content-center align-items-center' style={{backgroundColor:"rgb(245,158,12,1)"}}>
        <PlayIcon/>
        </div>
        </Link>
        ) }
        {props.type==="friend" && (
            <Link to={`/a/${props.owner_name}`}>
        <div className='notification-type d-flex justify-content-center align-items-center' style={{backgroundColor:"rgb(33,76,162,1)"}}>
        <FollowedIcon/>
        </div>
        </Link>
        ) }
        </div>
    );
}

export default NotificationCard;
