import React from 'react'
import { Link,useLocation } from 'react-router-dom'


//redux hooks
import {useAppDispatch} from "../../../redux/hooks"

//wrapper
import LikeMusic  from "../../wrappers/likeMusic"

//iicons
import AddPlaylistIcon from "./src/AddPlaylistIcon"
import Heartcon from "./src/Heartcon"
import MusicIcon from './src/MusicIcon'
import UserIcon from './src/UserIcon'
import Player from '../../../pages/main/components/eventsSection/src/Player'
import CardShareIcon from '../featuredArtists/CardShareIcon'
//redux actions
import { playSingle,setMusicId } from "../../../redux/data/musicPlayer/index"
import Eye from './src/Eye'
import Publish from '../../wrappers/Publish'
import { setsharedUrl } from "../../../redux/data/artistProfile";

interface props{
    title:string;
    thumbnail:string;
    file:string;
    username:string;
    count:Number;
    music_file: Number;
    music_thumbnail: Number;
    music_artist: Number;
    topic_id: Number;
    claim?:Number;
    messageId?:Number;
    approved?:Number;
    color_name:string
}

const MusicElement:React.FC<props> = (props) => {
    const dispatch = useAppDispatch()
    const location =useLocation()
    const regex = /[?@\/#:\,;%+&=\{\}\[\]'\^-]/g
    return (
        <div className="col-md-3 px-2  card  music-e-card  shadow-sm" >
            <div className="cover shadow-sm">
            
            <div className='cover-wrapper' style={{backgroundColor:`${props.color_name}`,border:`1px solid ${props.color_name}`}}>
               
                <div> 
                    <img src={props.thumbnail} alt="" aria-hidden="false" draggable="false"  />
                </div>
                </div>
                <div className='music-play-btn'onClick={(e) => {
                e.preventDefault()
                dispatch(playSingle({
                    value: [{
                        name: props.title,
                        singer: props.username,
                        cover: props.thumbnail,
                        musicSrc: props.file,
                        color:props.color_name
                    }]
                }))
                
                //console.log(props.topic_id)
                //@ts-ignore
                dispatch(setMusicId({ value: props.topic_id }))
                // @ts-ignore
            }}>
                <Player/>
                </div>
                </div>
                
            {/* <div className="actual">
                <div className="d-flex justify-content-between">
                    <div onClick={(e:any)=>{
                        e.preventDefault()
                        dispatch(addMusic({
                            music_artist:props.music_artist,
                            music_file:props.music_file,
                            music_thumbnail:props.music_thumbnail
                        }))
                        dispatch(playlist())
                    }}><AddPlaylistIcon /></div> 
                    {props.claim? (<div onClick={(e:any)=>{
                        dispatch(claimMusic())
                        dispatch(claim(props.messageId))
                    }}>
                        <ClaimIcon />
                    </div>):(<div></div>)}
                    <LikeMusic music_id={props.topic_id}>
                        <Heartcon musicId={props.topic_id} />
                    </LikeMusic>
                </div>
                <div>
                </div>
                <div className='music-e-player'onClick={(e) => {
                e.preventDefault()
                console.log(props.file)
                dispatch(playSingle({
                    value: [{
                        name: props.title,
                        singer: props.username,
                        cover: props.thumbnail,
                        musicSrc: props.file,
                    }]
                }))
                //@ts-ignore
                dispatch(setMusicId({ value: props.topic_id }))
                // @ts-ignore
            }}>
                    <Player/>
                </div>
            </div> */}
            <Link to={`/a/${props.username.replaceAll(" ","-").toLowerCase()}/s/${props.title.toLowerCase().replaceAll(regex,'').replaceAll(" ","-")}`}><div className="song-name" title={props.title} ><MusicIcon/> {props.title}</div></Link>
            <Link to={`/a/${props.username.replaceAll(" ","-").toLowerCase()}`}><div className="numbering" title={props.username}><UserIcon/> {props.username}</div></Link>
            <div className='card-share-btn' onClick={(e) => {
                e.preventDefault()
                
                dispatch(setsharedUrl(
                    `https://leerecs.com/a/${props.username.replaceAll(" ","-").toLowerCase()}/s/${props.title.replaceAll(regex,'').replaceAll(" ","-").toLowerCase()}`
                  ))
            }}
                    ><CardShareIcon></CardShareIcon></div>
                     <div className='karma'>{(location.pathname ==="/music/mymusic")&&( <Publish music_id={props.topic_id} ><Eye approved={props.approved}/></Publish>)} </div>
            
        </div>
    )
}

export default MusicElement
