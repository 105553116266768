import React, {useCallback} from 'react'
import {useDropzone} from 'react-dropzone'
import PencilIcon from '../singleSongProfile/src/PencilIcon'
import './index.scss'
interface props {
    onDropheader:any;
}

const MyDropzone: React.FC<props> =(props)=> {
  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    // console.log(acceptedFiles[0]);
    props.onDropheader(acceptedFiles[0])
  }, [])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  return (
    <div className="header-upload-dropzone" {...getRootProps()}>
      <input {...getInputProps()} />
      <PencilIcon/>
      {/* {
        isDragActive ?
          <p>Drop the files here ...</p> :
          <p>Drag 'n' drop some files here, or click to select files</p>
      } */}
    </div>
  )
}

export default MyDropzone;