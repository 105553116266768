//@ts-nocheck
import React, { useCallback, useEffect, useState } from 'react'

//helpers
import { useDropzone } from "react-dropzone"
import configuration from '../../config/configuration'
//icons
import PlayIcon from "./src/PlayIcon"

//scss files
import "./FileUpload.scss"
import axios from "axios"
//interfaces

export const useUpload = (domId) => {
    const [files, setfiles] = useState(null)
    const [loading, setloading] = useState(false)
    const [data, setdata] = useState(null)
    const [error, seterror] = useState(null)
    const upload = useCallback(async (type) => {
        console.log(files)
        if (files) {
            const fs=files.map(async(file)=>{

                setloading(true)
            console.log("herwe is all info of the file ❤️❤️❤️❤️❤️", files)
            const form = new FormData()
            const form1 = new FormData()
            form1.append("file", file, file.name)
            form.append("userId", localStorage.getItem("userId") )
            form.append("fileExt", type)
            form.append("mimeType", file.type)
            form.append("size", file.size)
            let fileSize = parseInt(file.size) / 1000000
            if (fileSize < 10) {
                try {
                    if (file) {


                        const result1 = await axios.post(`https://uploadbackend.leerecs.net/api/upload_video`, form1)
                        if (result1) {
                            form.append("fileTitle", result1.data.title)
                            
                            const result = await axios.post(`${configuration.BACK_END_HOST}/upload`, form)
                            if (result) {
                                setdata(result.data.fileId)
                                setloading(false)
                                const name=file.name.split(".")
                                return {
                                    id:result.data.fileId,
                                    title:name[0]
                                }
                            }
                        }
                    }
                } catch (error) {
                    console.log('errpr', error)
                    setloading(false)
                    seterror(error.toString())
                }
            }
            else {
                seterror("The filemust not be bigger than 10 MB")
            }
            })
            console.log(Promise.all(fs))
           return Promise.all(fs)
        }
    }, [files, domId])
    return [{ upload, setfiles }, { data, loading, error }]

}

export const useUploadCover = (domId) => {
    const [files, setfiles] = useState(null)
    const [loading, setloading] = useState(false)
    const [data, setdata] = useState(null)
    const [error, seterror] = useState(null)
    const upload = useCallback(async (type) => {
        const file =files[0]
        console.log((document.getElementById(domId) ).files)
        if (file) {
            console.log(file)
            setloading(true)
            const form = new FormData()
            const form1 = new FormData()
            form1.append("file", file, file.name)
            
            form.append("userId", localStorage.getItem("userId"))
            form.append("fileExt", type)
            form.append("mimeType", file.type)
            form.append("size", file.size)
            let fileSize = parseInt(file.size) / 1000000
            if (fileSize < 10) {
                try {
                    if (file) {


                        const result1 = await axios.post(`https://uploadbackend.leerecs.net/api/upload_video`, form1)
                        if (result1) {
                            form.append("fileTitle", result1.data.title)
                            
                            const result = await axios.post(`${configuration.BACK_END_HOST}/upload`, form)
                            if (result) {
                                setdata(result.data.fileId)
                                setloading(false)
                                return {
                                   name: result.data.filename,
                                   id:result.data.fileId
                                }
                            }
                        }
                    }
                } catch (error) {
                    // console.log('errpr', error)
                    setloading(false)
                    seterror(error.toString())
                }
            }
            else {
                seterror("The filemust not be bigger than 10 MB")
            }
        }
    }, [files, domId])
    return [{ upload, setfiles }, { data, loading, error }]

}

export const useUpdateUpload = (domId) => {
    const [files, setfiles] = useState(null)
    const [loading, setloading] = useState(false)
    const [data, setdata] = useState(null)
    const [error, seterror] = useState(null)

    const upload = useCallback(async (type, dbId) => {
        try {
            const file = (document.getElementById(domId) ).files[0] || files
            setloading(true)
            console.log("herwe is all info of the file ❤️❤️❤️❤️❤️", files, file)
            const form = new FormData()
            form.append("file", file, file.name)
            form.append("id_attach", dbId)
            form.append("fileExt", type)
            form.append("size", file.size)
            form.append("mimeType", file.type)
            try {
                if (file) {
                    const result = await axios.post(`${configuration.BACK_END_HOST}/updateUpload`, form)
                    if (result) {
                        console.log("upload result", result.data.fileId)
                        setdata(result.data.fileId)
                        setloading(false)
                        return result.data.fileId
                    }
                }
            } catch (error) {
                console.log('errpr', error)
                setloading(false)
                seterror(error.toString())
            }
        } catch (error) {
            setloading(false)
            return null
        }
    }, [files, domId])
    return [{ upload, setfiles }, { data, loading, error }]

}


export const FileUpload= (props) => {

    const onDrop = (acceptedFiles) => {
        return props.onDropHandler(acceptedFiles)
    }

    const { acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps } = useDropzone({ accept: props.acceptedFiles, onDrop });
    const acceptedFileItems = acceptedFiles.map(file => (
        <li key={(file ).path}>
            {(file ).path} - {file.size} bytes
        </li>
    ));

    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        <li key={(file ).path}>
            {(file ).path} - {file.size} bytes
            <ul>
                {errors.map(e => (
                    <li key={e.code}>{e.message}</li>
                ))}
            </ul>
        </li>
    ));
    return (
        <section className="container" id="file-uploader" style={{ backgroundImage: `url("${props.image}")` }}>
            <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} id={props.fileId} type="file" />
                <div className="all-upload-data">
                    <div className="title">{props.title}</div>
                    <div>
                        <div className="file-content">
                            <div className="mobile-icons">
                                <div className="file-icon-svg">{props.icon}</div>
                                <div className="text">{props.text}, or <span>Browse</span></div>
                            </div>
                            <div className="requirement">
                                <div className="d-flex">
                                    <div className="rounded-circle"></div>
                                    {props.rule1}
                                </div>
                                <div className="d-flex">
                                    <div className="rounded-circle"></div>
                                    {props.rule2}
                                </div>
                                <div className="d-flex">
                                    <div className="rounded-circle"></div>
                                    {props.rule3}
                                </div>
                                <h4>Accepted files</h4>
                                <ul>{acceptedFileItems}</ul>
                                <h4>Rejected files</h4>
                                <ul>{fileRejectionItems}</ul>
                            </div>
                        </div>
                    </div>
                    <div></div>
                </div>
            </div>
        </section>
    )
}

//multiple Uploads input

export const MultipleFileUpload= (props) => {

    const onDrop = (acceptedFiles) => {
        return props.onDropHandler(acceptedFiles)
    }

    const { acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps } = useDropzone({ accept: props.acceptedFiles, onDrop });
        console.log(acceptedFiles)
    const acceptedFileItems = acceptedFiles.map(file => (
        <li key={(file ).path}>
            {(file ).path} - {file.size} bytes
        </li>
    ));

    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        <li key={(file ).path}>
            {(file ).path} - {file.size} bytes
            <ul>
                {errors.map(e => (
                    <li key={e.code}>{e.message}</li>
                ))}
            </ul>
        </li>
    ));
    return (
        <section className="container" id="file-uploader" style={{ backgroundImage: `url("${props.image}")` }}>
            <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} id={props.fileId} type="file" />
                <div className="all-upload-data">
                    <div className="title">{props.title}</div>
                    <div>
                        <div className="file-content">
                            <div className="mobile-icons">
                                <div className="file-icon-svg">{props.icon}</div>
                                <div className="text">{props.text}, or <span>Browse</span></div>
                            </div>
                            <div className="requirement">
                                <div className="d-flex">
                                    <div className="rounded-circle"></div>
                                    {props.rule1}
                                </div>
                                <div className="d-flex">
                                    <div className="rounded-circle"></div>
                                    {props.rule2}
                                </div>
                                <h4>Accepted files</h4>
                                <ul>{acceptedFileItems}</ul>
                                <h4>Rejected files</h4>
                                <ul>{fileRejectionItems}</ul>
                            </div>
                        </div>
                    </div>
                    <div></div>
                </div>
            </div>
        </section>
    )
}
