import React from 'react'

export default function BackWardIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="21.5" height="22.121" viewBox="0 0 21.5 22.121">
            <g id="arrow-left" transform="translate(120.75 86.061)">
                <g id="Group_187" data-name="Group 187" transform="translate(-120 -85)">
                    <path id="Path_146" data-name="Path 146" d="M25,12H5" transform="translate(-5 -2)" fill="none" stroke="#bfbfbf" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                    <path id="Path_147" data-name="Path 147" d="M15,25,5,15,15,5" transform="translate(-5 -5)" fill="none" stroke="#bfbfbf" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                </g>
            </g>
        </svg>

    )
}
