import React from "react";
//redux
import { useAppSelector } from "../../redux/hooks";
//components
import {useQuery,gql} from "@apollo/client"
import NewMusic from "../../components/musicPageComponents/newMusic/index";
import FeaturedArtists from "../../components/musicPageComponents/featuredArtists/index";
import AllPlaylist from "../../components/musicPageComponents/allPlaylist/";
import AllAlbums from "../../components/musicPageComponents/albums";
import MusicFooter from "../main/components/footerSection/MusicFooter";




const AllSearches = () => {
  const { searchQuery } = useAppSelector((state) => state.search);
  const MUSIC_SEARCH_QUERY = gql`query{
    SearchInAllMusic(query:"${searchQuery}"){
        title
        thumbnail
        desc
        file
        musicId
        username
        imageId
        messageId
        topicId
        memberId
        likes
        color_name
        playData{
        cover
        color
        singer
        name
        musicSrc
        }
    }
}`;
const { loading: loadingMusicSearch,data: searchMusicData, error: searchMusicError,} = useQuery(MUSIC_SEARCH_QUERY);
const SEARCH_ARTISTS_QUERY = gql`query{
  AllFeaturedArtistSearch(query:"${searchQuery}"){
      username
      profileImg
      userId
      criteria
  }
}`
const { loading: loadingArtistSearch, data: searchArtistData, error: searchArtistError } = useQuery(SEARCH_ARTISTS_QUERY/*, { pollInterval: 500 }*/)

const SEARCH_PLAYLIST_QUERY = gql`query{
  AllPlaylistsSearch(query:"${searchQuery}"){
  title
desc
username
cover
  playListId
  musicCount
  }
}`
const { loading: loadingPlaylistSearch, data: searchPlaylistData, error: searchPlaylistError } = useQuery(SEARCH_PLAYLIST_QUERY/*, { pollInterval: 500 }*/)
const SEARCH_ALBUMS = gql`query{
  AllAlbumsSearch(query: "${searchQuery}"){
      title
    cover
      username
      albumId
    
  }
}`
 const { loading: loadingAlbumSearch, data: searchAlbumData, error: searchAlbumError } = useQuery(SEARCH_ALBUMS/*, { pollInterval: 500 }*/)
  return (
    <>
       <NewMusic data={searchMusicData?.SearchInAllMusic} loading={loadingMusicSearch} searchResult={true} error={searchMusicError}></NewMusic>
      <FeaturedArtists data={searchArtistData?.AllFeaturedArtistSearch} loading={loadingArtistSearch} error={searchArtistError} searchResult={true} />
      <AllPlaylist data={searchPlaylistData?.AllPlaylistsSearch} loading={loadingPlaylistSearch} error={searchPlaylistError} searchResult={true} />
      <AllAlbums data={searchAlbumData?.AllAlbumsSearch} loading={loadingAlbumSearch} error={searchAlbumError}  searchResult={true} />
      {searchAlbumData && <MusicFooter/>}

    </>
  );
};

export default AllSearches;
