import React from 'react'
import MusicSideBar from '../../components/musicPageComponents/sidebar'
import MusicNavBar from '../../components/musicPageComponents/navbar'
import { useQuery, gql } from '@apollo/client'

import { useHistory } from "react-router-dom"

const SecureContributor = (props: any) => {
    
    const history = useHistory()
    if(!localStorage.getItem("userId")){
        history.push("/login")
     }
    const QUERY = gql`query{
    IsContributor(
        userId:${localStorage.getItem("userId")},){
        desc
    }
}`
    const { loading, data, error } = useQuery(QUERY)
    
    return (
        <div>
            
            {data && (<div>{props.children}</div>)}
            {error && ( <div>
                         <div className='mx-auto text-center' style={{marginTop:"20%",width:"80%"}}>
                            <h3 className='text-white'>You have to be  a contributor to view this page please</h3>
                            <p className='text-white'> Please vist <u><a href="/music/becomeContributor" className='text-white font-weight-bold'> Become Contributor Page</a></u> and request to become a contributor if you want to proceed</p>
                         </div>
                    </div>
               )}
        </div>
    )
}

export default SecureContributor
