import React from 'react'

import { Link } from 'react-router-dom'

//icons
import AllIcon from './src/AllIcon'

//scss
import "./index.scss"
import { setShowPayWall } from '../../../redux/data/uiData';
import { useAppDispatch } from '../../../redux/hooks';

interface props{
    title:String;
    link:string;
}



const ShowMoreComponent:React.FC<props> = (props) => {
    const dispatch = useAppDispatch();
    return (
        <div id="show-more">
            {!localStorage.getItem('userId')&&props.title==='Show All Music'?(
                <div className="d-flex justify-content-between show-more" style={{cursor:"pointer"}} onClick={()=>{
                    dispatch(setShowPayWall(true))
                }}>
                
                    <div className="more" style={{color:"#bfbfbf !important"}}>{props.title}</div>
                    
                    <div className="show-more-icon">
                        <AllIcon />
                    </div>
                </div>
               ):( <Link to={props.link}>
                <div className="d-flex justify-content-between show-more">
                
                    <div className="more" style={{color:"#bfbfbf !important"}}>{props.title}</div>
                    
                    <div className="show-more-icon">
                        <AllIcon />
                    </div>
                </div>
                </Link>)}
          
        </div>
    )
}

export default ShowMoreComponent
