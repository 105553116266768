import React from "react";
//redux
import {useQuery,gql} from "@apollo/client"
import { useAppSelector } from "../../redux/hooks";
import AllPlaylist from "../../components/musicPageComponents/allPlaylist";
import MusicFooter from "../main/components/footerSection/MusicFooter";



const AlbumSearch = () => {
    const { searchQuery } = useAppSelector(state => state.search)
  const QUERY =gql`query{
  AllAlbumsSearch(query: "${searchQuery}"){
        title
	    cover
        username
        albumId
      
    }
}`
   //loadind query
    const { loading, data, error } = useQuery(QUERY/*, { pollInterval: 500 }*/)
  return (
    <>
      <AllPlaylist data={data?.AllAlbumsSearch} loading={loading} error={error} searchResult={true}   allMusic={true} addMargin={true}/>
      {data && <MusicFooter/>}

    </>
  );
};

export default AlbumSearch;
