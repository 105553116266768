// @ts-nocheck
import React, { useState } from 'react'

//helpers
import { useQuery, gql } from "@apollo/client"

//redux hooks
import { useAppDispatch } from "../../../redux/hooks"

//redux actions
import { playSingle } from "../../../redux/data/musicPlayer/index"
import { setsharedUrl } from "../../../redux/data/artistProfile/index"

//icons
import PlayMusicIcon from "./src/PlayMusicIcon"

//prelaoders
import HeaderPreloader from "../headerPreloader/index"
import SingleMusicPreloader from "../singleMusicPreloader"

import MusicElement from "./MusicElement"

import "./index.scss"

//configuration
import configuration from '../../../config/configuration'
import ShareIcon from './src/ShareIcon'
import MusicFooter from '../../../pages/main/components/footerSection/MusicFooter'

interface props {
    ColorId: string;
}
const AllMusicsInColor: React.FC<props> = (props) => {
    const dispatch = useAppDispatch()
    //state that will contain music data array
    let musicData: any = []
    // const [musics, setmusics] = useState([])

    const QUERY = gql`query{
    AllMusicInColor(colorId:"${props.ColorId}"){
        id
    title
    music{
        title
        thumbnail
        desc
        file
        musicId
        username
        imageId
        messageId
        topicId
        user_id
        color_name
    }
    }
}`
    const { loading, data, error } = useQuery(QUERY/*, { pollInterval: 500 }*/)

    //query to get playlist data
    const PLAYLISTDATA = gql`query{
    SinglePlaylistData(playListId:${props.playlistId}){
        title
	    desc
	    cover
    }
    }`

    const { loading: loadindInfo, data: playlistInfo, error: playlistError } = useQuery(PLAYLISTDATA/*, { pollInterval: 500 }*/)
    return (
        <div id="all-music-in-playlist">
            {loading && <HeaderPreloader />}
            {data && (
                <div className="header" style={{ backgroundColor:`${data.AllMusicInColor.title}`}}>
                    <div className="d-flex justify-content-between">
                        <div>
                            <div className="panel-title">playlist</div>
                            <div className="playlist-title">{data.AllMusicInColor.title}</div>
                            <div className="playlist-desc"> All music in category {data.AllMusicInColor.title}</div>
                        </div>
                        <div className="btn-collection">
                            <div onClick={(e) => {
                                e.preventDefault()
                                dispatch(playSingle({
                                    value: musicData
                                }))
                            }}>
                                <PlayMusicIcon />
                            </div>
                            <div style={{
                                marginTop: "15px"
                            }}
                            className='share-btn mt-2'
                                onClick={(e) => {
                                    e.preventDefault()
                                    dispatch(setsharedUrl(
                                        `https://leerecs.com/music/colour/${data.AllMusicInColor.title.toLowerCase()}`
                                    ))
                                }} style={{border:"none"}}>
                                <ShareIcon />
                            </div>
                        </div>
                    </div>
                    <div className="btn play-all-music" onClick={(e) => {
                        e.preventDefault()
                        dispatch(playSingle({
                            value: musicData
                        }))
                    }}>
                        Play All Music
                    </div>
                </div>
            )}

            <div className="row mx-0 table-heading justify-content-between">
                <div className="col-md-1 px-0">#</div>
                <div className="col-md-6 px-0">Title</div>
                <div className="col-md-3 px-0"></div>
                <div className="col-md-1 px-0">React</div>
                <div className="col-md-1 px-0">
                    <div className="d-flex justify-content-end">
                        <div></div>
                        <div>Play</div>
                    </div>
                </div>
                <div className="col-md-12 px-0"><hr /></div>
            </div>
            {loading && (<SingleMusicPreloader />)}
            {data && (data.AllMusicInColor.music.map((music: any, count: any) => {
                musicData.push({
                    name: music.title,
                    singer: music.username,
                    cover: `${configuration.FILE_STORAGE_LINK}${music.thumbnail}`,
                    musicSrc: `${configuration.READFILE_LINK}${music.file}`,
                    color:music.color_name
                })
                return (<MusicElement
                     recommend={data.AllMusicInColor.music.filter((song: any) =>song.title!==music.title)}
                    music_file={music.musicId}
                    music_thumbnail={music.imageId}
                    music_artist={music.memberId}
                    title={music.title}
                    thumbnail={`${configuration.FILE_STORAGE_LINK}${music.thumbnail}`}
                    file={`${configuration.READFILE_LINK}${music.file}`}
                    username={music.username}
                    music_id={music.topicId}
                    count={(count + 1).toLocaleString('en-US', {
                        minimumIntegerDigits: 2,
                        useGrouping: false,
                    })}
                    color_name={music.color_name}
                    userId={music.user_id}
                    index={count}
                />)
            }))}
            {/* <div style={{marginBottom:"80px"}}></div> */}
            {data && <MusicFooter/>}
        </div>
    )
}

export default AllMusicsInColor
