//@ts-nocheck
import React, { useCallback, useEffect, useState } from 'react'

//helpers
import { useDropzone } from "react-dropzone"
import configuration from '../../config/configuration'
//icons
import PlayIcon from "./src/PlayIcon"

//scss files
import "./FileUpload.scss"
import axios from "axios"
//interfaces
interface props {
    title: String;
    icon: JSX.Element;
    text: String;
    rule1: String;
    rule2: String;
    music?: String;
    image?: String;
    fileId: string;
    acceptedFiles: string;
    
}

export const useUpload = (domId) => {
    const [files, setfiles] = useState(null)
    const [loading, setloading] = useState(false)
    const [data, setdata] = useState(null)
    const [error, seterror] = useState(null)
    const upload = useCallback(async (type) => {
        const file = (document.getElementById(domId) as HTMLFormElement).files[0] || files
        // console.log((document.getElementById(domId) as HTMLFormElement).files)
        if (file) {
            setloading(true)
             console.log("herwe is all info of the file ❤️❤️❤️❤️❤️", files)
            const form = new FormData()
            const form1 = new FormData()
            form1.append("file", file, file.name)
            form.append("userId", localStorage.getItem("userId") as string)
            form.append("fileExt", type)
            form.append("mimeType", file.type)
            form.append("size", file.size)
            let fileSize = parseInt(file.size) / 1000000
            if (fileSize < 15) {
                try {
                    if (file) {

                        //uplaoding to s3bucket
                        //const result1 = await axios.post(`https://uploadbackend.leerecs.net/api/upload_video`, form1)
                        const result1 = await axios.post(`https://leerecs.com/backend/public/api/uploadfile`, form1)
                        if (result1) {
                            form.append("fileTitle", result1.data.title)
                            if(domId ==="image-file"){
                                return{
                                    id:0,
                                    filename:result1.data.title
                                }
                            }
                            
                            const result = await axios.post(`${configuration.BACK_END_HOST}/upload`, form)
                            if (result) {
                                setdata(result.data.fileId)
                                setloading(false)
                                // console.log(result);
                                return{ 
                                    id:result.data.fileId,
                                    filename:result.data.filename
                                }
                            }
                        }
                    }
                } catch (error) {
                    // console.log('errpr', error)
                    setloading(false)
                    seterror(error.message)
                }
            }
            else {
                seterror("The file must not be bigger than 10 MB")
            }
        }
        else{
            if(domId ==="image-file"){
                return{
                    id:0,
                    filename:"default-music.png"
                }
            }
            seterror("Invalid file, Please check the file you are trying to upload ")  
        }
    }, [files, domId])
    return [{ upload, setfiles }, { data, loading, error }]

}

export const useUpdateUpload = (domId) => {
    const [files, setfiles] = useState(null)
    const [loading, setloading] = useState(false)
    const [data, setdata] = useState(null)
    const [error, seterror] = useState(null)

    const upload = useCallback(async (type, dbId) => {
        try {
            const file = (document.getElementById(domId) as HTMLFormElement).files[0] || files
            setloading(true)
            // console.log("herwe is all info of the file ❤️❤️❤️❤️❤️", files, file)
            const form = new FormData()
            form.append("file", file, file.name)
            form.append("id_attach", dbId)
            form.append("fileExt", type)
            form.append("size", file.size)
            form.append("mimeType", file.type)
            try {
                if (file) {
                    const result = await axios.post(`${configuration.BACK_END_HOST}/updateUpload`, form)
                    // const result1 = await axios.post(`https://backend.leerecs.com/api/uploadfile`, form1)
                    if (result) {
                        // console.log("upload result", result.data.fileId)
                        setdata(result.data.fileId)
                        setloading(false)
                        return result.data.fileId
                    }
                }
            } catch (error) {
                console.log('errpr', error)
                setloading(false)
                seterror(error.toString())
            }
        } catch (error) {
            setloading(false)
            return null
        }
    }, [files, domId])
    return [{ upload, setfiles }, { data, loading, error }]

}


export const FileUpload: React.FC<props> = (props) => {

    const onDrop = (acceptedFiles) => {
        return props.onDropHandler(acceptedFiles[0])
    }

    const { acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps } = useDropzone({ accept: props.acceptedFiles, onDrop });
    const acceptedFileItems = acceptedFiles.map(file => (
        <li key={(file as any).path}>
            {(file as any).path} - {file.size} bytes
        </li>
    ));

    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        <li key={(file as any).path}>
            {(file as any).path} - {file.size} bytes
            <ul>
                {errors.map(e => (
                    <li key={e.code}>{e.message}</li>
                ))}
            </ul>
        </li>
    ));
    return (
        <section className="container" id="file-uploader" style={{ backgroundImage: `url("${props.image}")` }}>
            <div className='dropzone' >
                <input {...getInputProps()} id={props.fileId} type="file" />
                <div className="all-upload-data">
                    <div className="title">{props.title}</div>
                    <div>
                        <div className='recent-images-container d-flex'>
                            {props.images&&props.images.map((image,id)=><div 
                            onClick={(e)=>{
                               e.preventDefault();
                              props.selectImage(image.filename)
                            }}
                            key={id} 
                            className='recent-images' 
                            style={{backgroundImage:`url(${configuration.FILE_STORAGE_LINK}${image.filename})`,border:`${props.imageSelected==image.filename?'2px solid #F8107D':'none'}`}}
                            
                            >

                            </div>
                            )}
                            
                           
                        </div>
                        <div className="file-content" {...getRootProps()}>
                            <div className="mobile-icons">
                                <div className="file-icon-svg">{props.icon}</div>
                                <div className="text">{props.text}, or <span>Browse</span></div>
                            </div>
                            <div className="requirement" >
                                <div className="d-flex">
                                    <div className="rounded-circle"></div>
                                    {props.rule1}
                                </div>
                                
                                <div className="d-flex" >
                                    <div className="rounded-circle"></div>
                                    {props.rule2}
                                </div>
                                <div className="d-flex">
                                    <div className="rounded-circle"></div>
                                    {props.rule3}
                                </div>
                                <h4>Accepted files</h4>
                                <ul>{acceptedFileItems}</ul>
                                <h4>Rejected files</h4>
                                <ul>{fileRejectionItems}</ul>
                            </div>
                        </div>
                    </div>
                    <div></div>
                </div>
            </div>
        </section>
    )
}

//multiple Uploads input

export const MultipleFileUpload: React.FC<props> = (props) => {

    const onDrop = (acceptedFiles) => {
        return props.onDropHandler(acceptedFiles[0])
    }

    const { acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps } = useDropzone({ accept: props.acceptedFiles, onDrop });
    const acceptedFileItems = acceptedFiles.map(file => (
        <li key={(file as any).path}>
            {(file as any).path} - {file.size} bytes
        </li>
    ));

    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        <li key={(file as any).path}>
            {(file as any).path} - {file.size} bytes
            <ul>
                {errors.map(e => (
                    <li key={e.code}>{e.message}</li>
                ))}
            </ul>
        </li>
    ));
    return (
        <section className="container" id="file-uploader" style={{ backgroundImage: `url("${props.image}")` }}>
            <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} id={props.fileId} type="file" />
                <div className="all-upload-data">
                    <div className="title">{props.title}</div>
                    <div>
                        <div className="file-content">
                            <div className="mobile-icons">
                                <div className="file-icon-svg">{props.icon}</div>
                                <div className="text">{props.text}, or <span>Browse</span></div>
                            </div>
                            <div className="requirement">
                                <div className="d-flex">
                                    <div className="rounded-circle"></div>
                                    {props.rule1}
                                </div>
                                <div className="d-flex">
                                    <div className="rounded-circle"></div>
                                    {props.rule2}
                                </div>
                                <h4>Accepted files</h4>
                                <ul>{acceptedFileItems}</ul>
                                <h4>Rejected files</h4>
                                <ul>{fileRejectionItems}</ul>
                            </div>
                        </div>
                    </div>
                    <div></div>
                </div>
            </div>
        </section>
    )
}


