import React from "react";
//redux
import {useQuery,gql} from "@apollo/client"
import AllAlbums from "../../components/musicPageComponents/albums";
import MusicFooter from "../main/components/footerSection/MusicFooter";


const AllAlbum = () => {
  // const {addplaylist} = useAppSelector(state => state.uiData)
  const QUERY =gql`query{
    AllAlbumsWithMusic{
        title
	    cover
        albumId
        username
        songs{
             cover
             singer
              name
            musicSrc
}
}
}`
   //loadind query
    const { loading, data, error } = useQuery(QUERY/*, { pollInterval: 500 }*/)
  return (
    <>
      <AllAlbums data={data?.AllAlbumsWithMusic} loading={loading} error={error}  searchResult={false} allMusic={true} addMargin={true} />
      {data && <MusicFooter/>}
    </>
  );
};

export default AllAlbum;
