/* eslint-disable array-callback-return */
// @ts-nocheck
import React, { useEffect, useState} from 'react'

//helpers
import { useQuery, gql } from "@apollo/client"
import { useAppSelector } from '../../../redux/hooks'
import { useLocation } from 'react-router'

//components
import MusicElement from "./MusicElement"
import LottieWrapper from "../../lottie/lottie"

// preloader
import Preloader from './preloader'

import "./index.scss"

//configuration
import configuration from '../../../config/configuration'

interface props {
    allMusic?: boolean;
    searchResult?: boolean;
    addMargin?: boolean;
    musician?:Int;

}

const AllAlbums: React.FC<props> = (props) => {
    const QUERY = gql`query{
    AllAlbumByUser(userId:${props.musician}){
        title
	    cover
        albumId
        username
    }
}`

    const { searchQuery } = useAppSelector(state => state.search)
    const SEARCH_ALBUMS = gql`query{
    AllAlbumsSearch(query: "${searchQuery}"){
        title
	    cover
        albumId
      
    }
}`
    const { loading, data, error } = useQuery(QUERY/*, { pollInterval: 500 }*/)
    const { loading: loadingSearch, data: searchData, error: searchError } = useQuery(SEARCH_ALBUMS/*, { pollInterval: 500 }*/)
    var count = 0;

    

    const location = useLocation()

    const noWrap = (link:String) => {
        if (location.pathname === link) {
            return "wrap"
        }
        else {
            return ""
        }
    }

    const [countnumber, setcountnumber] = useState(3)
    useEffect(() => {
        function handleResize() {
            console.log('resized to: ', window.innerWidth, 'x', window.innerHeight)
            let width = window.innerWidth
            if (width <= 1024) {
                setcountnumber(3)
            }
            if (width <= 768) {
                setcountnumber(3)
            }
        }
        handleResize()
        window.addEventListener('resize', handleResize)

    }, [])

    const preloader = () => {
        const i = props.allMusic ? [0, 1, 2, 3, 4, 0, 1, 2] : [...Array(countnumber).keys()]
        return i.map(() => {
            return (<Preloader />)
        })
    }

    const searchResult = (search: any) => {
        if (search.AllAlbumsSearch.length !== 0) {
            return search.AllAlbumsSearch.map((album: any, idx: any) => {
                count++;
                if (props.allMusic) {
                    count = 0
                    return
                }
                if (count <= countnumber) {
                    
                    return (<MusicElement
                    
                        title={album.title}
                        cover={`${configuration.FILE_STORAGE_LINK}${album.cover}`}
                        count={(idx + 1).toLocaleString('en-US', {
                            minimumIntegerDigits: 2,
                            useGrouping: false,
                        })}
                        albumId={album.albumId}
                        username={album.username}
                    />)
                }
            })
        }
        if (search.AllAlbumsSearch.length === 0) {
            return (<LottieWrapper url="https://assets2.lottiefiles.com/packages/lf20_GlZGOi.json" width="calc(100% -  20px)" height="50vh" />)
        }
    }
    return (
        <div>
            {!props.searchResult && (
                <div id="single-artist-albums">
                    
                    {loading && (
                        <div className={`row card-deck ${noWrap("/music/allalbums")}`}>
                            {preloader()}
                        </div>)}
                    <div className={`row card-deck ${noWrap("/music/allalbums")}`}>
                        {data && (data.AllAlbumByUser.map((album: any, idx: any) => {
                            
                            count++;
                            if (props.allMusic) {
                                count = 0
                                return
                            }
                            if (count <= countnumber) {
                                return (<MusicElement
                                    title={album.title}
                                    cover={`${configuration.FILE_STORAGE_LINK}${album.cover}`}
                                    count={(idx + 1).toLocaleString('en-US', {
                                        minimumIntegerDigits: 2,
                                        useGrouping: false,
                                    })}
                                    username={album.username}
                                    albumId={album.albumId}
                                    
                                />)
                            }
                        }))}
                        {error && (<LottieWrapper url="https://assets2.lottiefiles.com/packages/lf20_GlZGOi.json" width="calc(100% -  20px)" height="50vh" />)}
                        {(data && (data.AllAlbumByUser.length === 0))&&(<LottieWrapper url="https://assets2.lottiefiles.com/packages/lf20_GlZGOi.json" width="calc(100% -  20px)" height="50vh" />)}
                    </div>
                </div>)}
            {props.searchResult && (
                <div id="artist-albums">
                    
                    {loadingSearch && (
                        <div className={`row card-deck ${noWrap("/music/searchResult/albums")}`}>
                            {preloader()}
                        </div>)}
                    <div className={`row card-deck ${noWrap("/music/searchResult/albums")}`}>
                        {searchData && (searchResult(searchData))}
                        {searchError && (<LottieWrapper url="https://assets2.lottiefiles.com/packages/lf20_GlZGOi.json" width="calc(100% -  20px)" height="50vh" />)}
                    </div>
                </div>
            )}

        </div>
    )
}

export default AllAlbums
