import React, { useState } from 'react'
import "./index.scss"
import ClosePanelIcon from './src/ClosePanelIcon'

// redux functionality
import {useAppDispatch,useAppSelector} from "../../../redux/hooks"
import {setShowAlbumConfig} from "../../../redux/data/uiData"

// apollo client
import {gql,useMutation} from "@apollo/client"

import Loading from '../../loading'
import Error from '../../error'
import Sucess from '../../sucess'




export default function AddToShop() {
    const dispatch = useAppDispatch()
    const [loading,setLoading]=useState(false)
    const[status,setStatus]=useState(false)
    const[error,setError]=useState()
    const[close,setClose]=useState(false)
    const [message,setMessage]=useState("")
    const { albumData } = useAppSelector((state) => state.uiData);

    const MUTATION = gql`mutation($shopData:albumToShopData!)
    {
        ClaimShop(shopData:$shopData){
            desc
        }
    }`
    const [ClaimShop, { data,}] = useMutation(MUTATION, { errorPolicy: "all" })
    const handleClaim=(e:any)=>{
        e.preventDefault()
        setLoading(true)
        setClose(true)
        
        const result =  ClaimShop({
            variables: {
                shopData:{
                albumName:albumData.albumName,
                owner:albumData.owner,
                action:albumData.action,
                message:albumData.message,
                url:albumData.url,    
                userId:albumData.userId,
                requester:albumData.requester
               }
            }
        }).then(function (response) {
             setMessage(response.data.ClaimShop.desc)
             setLoading(false)
             setStatus(true)
             setClose(true)
            // dispatch(setShowAlbumConfig(false))
            
        })
        
     }

    return (
        <div id="claim-music">
            <div className="panel">
                <div className="d-flex justify-content-between top-button">
                    <div className="title">{albumData.action==='ADD'?'ADD TO SHOP': albumData.action==='CLAIM'?"CLAIM SHOP":albumData.action==='REQUEST'?"REQUEST SHOP":albumData.action==='REQUEST-ACTIVITY'?"REQUEST SHOP ACTIVITY":'REMOVE FROM SHOP'}</div>
                    <div onClick={(e)=>{
                        e.preventDefault()
                        dispatch(setShowAlbumConfig(false))
                    }}>
                        <ClosePanelIcon />
                    </div>
                </div>
                {close&&loading && <Loading></Loading>}
                {close&&status&& <Sucess text={message} close={setClose}></Sucess>}
                { close&&error && !status&&  <Error error={error} close={setClose}></Error>}
                 {albumData.action==='ADD'&&(
                    <div className="prerequist">
                    <h6 className='fw-bold text-center'>You are about to request start selling this album on the Leerecs shop.</h6>
                    <p className='text-center'>Once you confirm, our team will receive your request and follow up with you.</p>
                        </div>
                 )}
                  {albumData.action==='CLAIM'&&(
                    <div className="prerequist">
                    <h6 className='fw-bold text-center'>You are about to request a shop on the Leerecs shop.</h6>
                    <p className='text-center'>Once you confirm, our team will receive your request and follow up with you.</p>
                        </div>
                 )}
                 {albumData.action==='REQUEST'&&(
                    <div className="prerequist">
                    <h6 className='fw-bold text-center'>You are about to request this artist to add thier shop to Leerecs shop.</h6>
                    <p className='text-center'>Once you confirm, our team will receive your request and follow up .</p>
                        </div>
                 )}

                {albumData.action==='REMOVE'&&(
                    <div className="prerequist">
                    <h6 className='fw-bold text-center'>You are about to remove this album on the Leerecs shop.</h6>
                    <p className='text-center'>Once you confirm, our team will receive your request and follow up with you.</p>
                        </div>
                 )}

                {albumData.action==='REQUEST-ACTIVITY'&&(
                   <div className="prerequist">
                   <h6 className='fw-bold text-center'>You are about to invite  this artist to sell their music on Leerecs shop.</h6>
                   <p className='text-center'>Once you confirm, this artist will receive an email and take action.</p>
                       </div>  
                )} 

               

                
                <div className="claim-music-btn mt-2" onClick={handleClaim} >
                      Comfirm
                </div>
                <div className="claim-music-btn mt-2" style={{backgroundColor:"#2ef9d9",color:"#000"}} onClick={(e)=>{
                        e.preventDefault()
                        dispatch(setShowAlbumConfig(false))
                    }}>
                      Cancel
                </div>
            </div>
        </div>
    )
}
