
//@ts-nocheck
import React, { useState,useEffect } from 'react'
import Select, {
    components,
    ControlProps,
    Props,
    StylesConfig,
  } from 'react-select';
//helpers
import { gql, useMutation, useQuery } from "@apollo/client"
import Closeicon from '../../addPlaylist/src/Closeicon';
import Done from '../../sucess/icons/done_all';
import CloudErrorComponent from '../../signup/src/CloudErrorComponent';
import axios from 'axios';
import configuration from '../../../config/configuration';
import ProgressBanner from './ProgressBanner';

//components
import InputForm from "../../formInput/index"

//icon
import TitleIcon from "./src/TitleIcon"
import CategoryIcon from "./src/CategoryIcon"
import TagIcon from "./src/TagIcon"
import DecriptionIcon from "./src/DescriptionIcon"
import MusicFileIcon from "./src/MusicFileIcon"
import ImageIcon from "./src/ImageIcon"
import Loader from '../../signup/src/icons/loader';

//info icons
// import Sucess from "../../sucess/index"
// import Loading from "../../loading/index"
// import Error from "../../error/index"


import { FileUpload, useUpload } from "../../../genericComponents/fileUploud/FileUpload"

import "./index.scss"

const Control = ({ children, ...props }: ControlProps<ColourOption, false>) => {
    // @ts-ignore
    const { emoji, onEmojiClick } = props.selectProps;
    const style = { cursor: 'pointer' };
  
    return (
      <components.Control {...props}>
        <span className='pl-1' >
        <TagIcon />
        </span>
        {children}
      </components.Control>
    );
  };

const AddMusic = () => {
    //state values
    

    //the graphl mutation
    const MUTATION = gql`mutation($thumbnail:String!,$file:Int!,$title:String!,$category:Int!,$tag:String!,$desc:String!,$userId:Int!)
{
    AddMusic(musicData:{thumbnail: $thumbnail,file: $file,title: $title,category:$category,tag: $tag,desc: $desc,userId:$userId}){
        title
        desc
    }
}`

    const CATEGORY_QUERY = gql`query{
    AllCategory{
    id
    title
    }
}`
const images_QUERY = gql`query{
    AllUserImage(userId:${localStorage.getItem('userId')}){
        filename
    }
}`


const Boards_QUERY = gql`query{
     AllBoards{
        id
        name
    }
}`
    const [selectedtags,setTags]=useState("")
    //apollo client hook
    const [AddMusic, { loading, data, error }] = useMutation(MUTATION, { errorPolicy: "all" })
    const { loading: categoryLoading, data: categoryData, error: categoryError } = useQuery(CATEGORY_QUERY/*, { pollInterval: 500 }*/)
    const { loading: boardLoading, data: boards, error: boardsError } = useQuery(Boards_QUERY/*, { pollInterval: 500 }*/)
    const { loading: imagesLoading, data:images , error: imageError } = useQuery(images_QUERY/*, { pollInterval: 500 }*/)
    //function to easily get the id of element
    const value = (id: string) => {
        var inputValue = (document.getElementById(id) as HTMLInputElement).value
        return inputValue;
    };

    const [{ upload: uploadMusic, setfiles: setMusicFile }, { loading: uplaodingMusic, error: uploadMusicError }] = useUpload("music-file")
    const [{ upload: uploadCover, setfiles: setCoverFile }, { loading: uploadingCover, error: uploadCoverError }] = useUpload("image-file")
   

    //function to handle submit
    const[addingstatus,setAddingStatus]=useState(false)
    const [selectedImage,setSelectedImage]=useState(null)
    const [message,setMessage]=useState("loading")
     const [color,setColor]=useState("#7171ff")
     const [width,setWidth]=useState("10%")
     const [btn,setbtn]=useState(false);
     const [icon,setIcon]=useState(<Loader/>)
     const [status,setStatus]=useState(null);
     useEffect(()=>{
        if(uploadMusicError){
            setColor("#FF7171")
            setMessage(uploadMusicError)
            setIcon(<CloudErrorComponent/>)
        }
       },[uploadMusicError])

       useEffect(()=>{
        if(uploadCoverError){
            setColor("#FF7171")
            setMessage(uploadCoverError)
            setIcon(<CloudErrorComponent/>)  
        }
       },[uploadCoverError])
    const handleSubmit = async (e: any) => {
        e.preventDefault();

        setAddingStatus(true)
        setbtn(true)
 
        //intializing
        setStatus(null)
        setMessage("loading ...")
        setColor("#7171ff")
        setWidth("10%")
        setIcon(<Loader/>)
        setTimeout(() => {
            
        },100);
        
            const check = new FormData()
            check.append("userId",parseInt(localStorage.getItem("userId") as string))
            check.append("songname",value("title"))
            const checkres = await axios.post(`${configuration.BACK_END_HOST}/checksong`, check)
           
            if(checkres.data.exists){
                setColor("#FF7171")
                setbtn(false)
                setIcon(<CloudErrorComponent/>)
                setMessage("Your have a song with the same title")
              return ;
            }
            try {
        const [title, desc, category] = [value("title"), value("description"), value("category")]
        const tags=selectedtags? selectedtags.map((item)=>item.value):[]
        
            const musicId = await uploadMusic("audio")
            
            if (musicId) {
                setWidth("40%")
                setColor("#7171ff")
                setMessage("Uploading Audio File")
                let musicThumbNail
               
                if(!selectedImage){
                    musicThumbNail = await uploadCover("image");}
                 
                // progress.style.width="60%"
                if (musicThumbNail||selectedImage) {
                    
                    setWidth("70%")
                    setColor("#7171ff")
                    setMessage("Uploading Cover File")
                   
                    const result = await AddMusic({
                        variables: {
                            thumbnail:selectedImage?selectedImage: musicThumbNail.filename as string,
                            file: musicId.id,
                            title: title,
                            category: parseInt(category as string),
                            tag: tags.toString(),
                            desc: desc,
                            userId: parseInt(localStorage.getItem("userId") as string)
                        }
                    })
                   
                    
                    
                    if (result) {
                        if (result.data) {
                            setWidth("100%")
                            setColor("#5DD97C")
                            const linkname= value("title").toLowerCase().replaceAll(regex,"").replaceAll(" ","-")
                            const ownername=result.data.AddMusic.desc
                            setMessage(`You have succesfully added a <a href='/a/${ownername}/s/${linkname}'  class='song-link'> new song`)
                            setIcon(<Done/>) 
                            setStatus(true)
                            setbtn(false)
                        }
                        else{
                            console.log(result)
                            console.log(error)
                            setColor("#FF7171")
                            setMessage(error.graphQLErrors[0])
                            setbtn(false)
                            setIcon(<CloudErrorComponent/>) 
                        }
                    }
                 
                }
                else{
                   
                    setColor("#FF7171")
                    if(uploadCoverError && uploadCoverError!=message){
                setMessage(uploadCoverError)}
                setbtn(false)
                setIcon(<CloudErrorComponent/>)
                }
            }
            else{
                
                setColor("#FF7171")
                if(uploadMusicError && uploadMusicError !=message){
                setMessage(uploadMusicError)}
                setIcon(<CloudErrorComponent/>)
                setbtn(false)
                
            }

        } catch (e) {
            console.log(e)
            console.log(error)
           setColor("#FF7171")
                setMessage(e.message)
                setbtn(false)
                setIcon(<CloudErrorComponent/>)
        }
    
    }
     
    
    const regex = /[?@\/#:\,;%+&=\{\}\[\]'\^-]/g
    return (
        <div id="add-music">
            <div className='px-3'>
            
            {addingstatus &&(<div className="col-10 mx-auto mt-2 loaders">
                <ProgressBanner close={setAddingStatus} message={message} width={width} color={color} icon={icon} status={status}/>
            </div>)}
            <h3>Manage music {">"} Add music</h3>
            <form action="" onSubmit={handleSubmit} autoComplete="off">
                <div className="d-md-flex justify-content-between top-header">
                    <div>
                        
                        <h1>Add Music </h1>
                        <h6>Add Music and Music description</h6>
                    </div>
                   
                    <button type="submit" className="btn add-music" disabled={btn}>Add Music</button>
                </div>
                <div className="row mx-0" onSubmit={handleSubmit}>
                    <div className="col-md-4 px-0 all-form-inputs">
                        <InputForm placeholder="Music Title" icon={<TitleIcon />} id="title" type="text" />
                        <div id="form-input">
                            <div className="input-group-component d-flex">
                                <CategoryIcon />
                                <select id="category" className="form-control" required>
                                    {categoryData && (categoryData.AllCategory.map((singlePlaylist: any) => {
                                        return (<option value={singlePlaylist.id}>{singlePlaylist.title}</option>)
                                    }))}
                                </select>
                            </div>
                        </div>
                                <Select
                                   isMulti
                                   name="boards"
                                   isClearable={false}
                                   components={{Control}}
                                   id="tags"
                                   options={boards&& boards.AllBoards.map((item)=>
                                    {
                                      return {value:item.id,label:item.name}
                                    })}
                                    onChange={(choice)=>{
                                        setTags(choice)}}
                                   className="mb-3 basic-multi-select"
                                //    classNamePrefix="tags"
                                 />
                         
                        {/* <InputForm placeholder="Music Tags" icon={<TagIcon />} id="tags" type="text" /> */}
                        <InputForm placeholder="Lyrics" icon={<DecriptionIcon />} id="description" type="text" area={true} />
                    </div>
                    <div className="col-md-4">
                        {setMusicFile && <FileUpload fileId="music-file" acceptedFiles="audio/mp3,audio/mpeg,audio/x-wav" title="Music Files" text="Drag and drop an audio" rule3="The file must not be bigger than 10 MB" rule1="Add high quality audio" rule2="Add music you have right to" icon={<MusicFileIcon></MusicFileIcon>} onDropHandler={setMusicFile} />}
                    </div>
                    <div className="col-md-4">
                        {setCoverFile && <FileUpload imageSelected={selectedImage} selectImage={setSelectedImage} images={images&&images.AllUserImage} fileId="image-file" acceptedFiles="image/jpeg, image/png" title="Music Cover" text="Drag and drop an image"  rule3="The file must not be bigger than 10 MB" rule1="Add high quality image" rule2="Add image you have right to" icon={<ImageIcon></ImageIcon>} onDropHandler={setCoverFile} />}
                    </div>
                    <button type="submit" className="btn add-music-mobile">Add Music</button>
                </div>
            </form>
            </div>
        </div>
    )
}

export default AddMusic
