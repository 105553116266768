import React from 'react'
import Login from '../../components/login'

const LoginPage= () => {
    return (
        <div>
            <Login />
        </div>
    )
}

export default LoginPage
