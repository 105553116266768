
import React,{useState} from 'react'

import AddBuddie from '../../../wrappers/AddBuddie'
import FollowIcon from '../../allMusicByArtist/src/FollowIcon'



export default function BeFriend(props:any) {
    const [beFriendStatus,setBeFriendStatus] =useState(props.buddie_list.includes(localStorage.getItem("userId") as string)?"Unfriend":"Befriend")
    
    const BefriendAction =(action: string,status:string)=>{
      console.log(props.userId)
      if(action==="Loading"){
          if(beFriendStatus==="Befriend"){
          setBeFriendStatus(" Unfriending");}
          else{
              setBeFriendStatus("Befriending")
          }
      }
      //  setBeFriendStatus(status());
 if(action==="added"){
    // console.log(action)
    setBeFriendStatus("Unfriend");

 }
 if(action==="removed"){
    // console.log(action)
    setBeFriendStatus("Befriend");
 }


  }
    return (
        <AddBuddie myBuddieId={props.userId} Beaction={BefriendAction}>
                  <div className='artist-btn text-center py-1 sider' style={{cursor:"pointer"}} >
                <FollowIcon/>
                <span className='' style={{cursor:'pointer'}}>{beFriendStatus} </span> </div>
                    </AddBuddie>
    )
}
