import React from 'react'
import "./index.scss"
import ClosePanelIcon from './src/ClosePanelIcon'

// redux functionality
import {useAppDispatch,useAppSelector} from "../../../redux/hooks"
import {claimMusic} from "../../../redux/data/uiData"

// apollo client
import {gql,useMutation} from "@apollo/client"

import Loading from '../../loading'
import Error from '../../error'
import Sucess from '../../sucess'


export default function ClaimMusic() {
    const dispatch = useAppDispatch()
    const MUTATION = gql`mutation(
       $userId:Int!,
       $messageId:Int!,
    ){
    ClaimYourMusic(claimInfo:{
        userId:$userId,
        messageId:$messageId,
    }){
        desc
    }
}`
    const [ClaimYourMusic,{loading,data,error}]=useMutation(MUTATION,{errorPolicy:"all"})

    const {messageId,userId} = useAppSelector(state => state.claim)
    
    const handleClaim=async (e:any)=>{
        e.preventDefault()
        const result = await ClaimYourMusic({
            variables:{
                userId: userId,
                messageId: messageId,
            }
        })
        if(result){
        }
    }
    return (
        <div id="claim-music">
            <div className="panel">
                <div className="d-flex justify-content-between top-button">
                    <div className="title">Claim Music</div>
                    <div onClick={(e)=>{
                        e.preventDefault()
                        dispatch(claimMusic())
                    }}>
                        <ClosePanelIcon />
                    </div>
                </div>
                {loading && <Loading></Loading>}
                {data && <Sucess text="Claimed"></Sucess>}
                {error &&
                    error.graphQLErrors.map(({ message }, i) => (
                        <Error error={message}></Error>
                    ))
                }
                <div className="header-img">
                </div>
                <div className="prerequist">
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea.
                </div>
                <div className="claim-music-btn" onClick={handleClaim}>
                    Claim Music
                </div>
                <div className="cancel" onClick={(e) => {
                    e.preventDefault()
                    dispatch(claimMusic())
                }}>
                    cancel
                </div>
            </div>
        </div>
    )
}
