
import React,{useState} from 'react'
import "./index.scss"
import ClosePanelIcon from './src/ClosePanelIcon'
import header from "./src/header.jpeg"
// redux functionality
import {useAppDispatch,useAppSelector} from "../../../redux/hooks"
import {claimProfile} from "../../../redux/data/uiData"
import InputForm from '../../formInput'
import TitleIcon from './src/TitleIcon'
import TagIcon from '../../../genericComponents/fileUploud/src/TagIcon'
import EmailIcon from './src/EmailIcon'
import PhoneIcon from './src/PhoneIcon'
import UserIcon from './src/UserIcon'
import DescriptionIcon from './src/DescriptionIcon'

// apollo client
import {gql,useMutation} from "@apollo/client"

import Loading from '../../loading'
import Error from '../../error'
import Sucess from '../../sucess'


export default function ClaimProfile() {
    const dispatch = useAppDispatch()
    const MUTATION = gql`mutation(
       $real_name:String!,
       $full_name:String!,
       $email:String!,
       $phone:String!,
       $social:String!,
       $title:String!,
       $desc:String!,


    ){
        ClaimRequest(claimInfo:{
        real_name:$real_name,
        full_name:$full_name,
        email:$email,
        phone:$phone,
        title:$title,
        social:$social
        desc:$desc
    }){
        desc
    }
}`
    const [ClaimProfile,{loading,data,error}]=useMutation(MUTATION,{errorPolicy:"all"})
    const [errorData,serErrorData]=useState("");
  

    const {claimData} = useAppSelector(state => state.claimProfile)
    const value = (id: string) => {
        var inputValue = (document.getElementById(id) as HTMLInputElement).value
        return inputValue;
    };
    
    const handleClaim=async (e:any)=>{
        e.preventDefault()
       
        const [ email,full_name, social,phone,description] = [ value("email"),value("full_name"), value("social"), value("phone"),value('description')]
        if(!social.match("https")){
            return(serErrorData("Invalid main social media URL"))
          }
        const result = await ClaimProfile({
            variables:{
                real_name:claimData.real_name,
                title:claimData.title,
                full_name:full_name,
                email:email,
                social:social,
                phone:phone,
                desc:description


            }
        })
        if(result){
          
        }
    }
    return (
        <div id="claim-music">
            <div className="profilePanel">
                <div className="d-flex justify-content-between top-button">
                    <div className="title">Confirm your account details</div>
                    <div onClick={(e)=>{
                        e.preventDefault()
                        dispatch(claimProfile())
                    }}>
                        <ClosePanelIcon />
                    </div>
                </div>
                {loading && <Loading></Loading>}
                {data && <Sucess text="Claimed"></Sucess>}
                {errorData && <Error error={errorData}></Error>}
                {error &&
                    error.graphQLErrors.map(({ message }, i) => (
                        <Error error={message}></Error>
                    ))
                }
                <form action="" onSubmit={handleClaim} autoComplete="off">
                <div className="prerequist">
                <div className="mb-2">
                  {/* Fill the form below and submit to claim this account */}
                  </div>
                  
                  <div className='container'>
                   <div className="mb-2">
                   <InputForm placeholder="Your Full name" icon={<UserIcon />} id="full_name" type="text" />
                   </div>
                   <div className="mb-2">
                   <InputForm placeholder="Your Email Address" icon={<EmailIcon />} id="email" type="email" />
                   </div>
                   <div className="mb-2">
                   <InputForm placeholder="Your Phone Number" icon={<PhoneIcon />} id="phone" type="tel" />
                   </div>
                   <div className="mb-2">
                   <InputForm placeholder="Your Social Media URL" icon={<TagIcon  />} id="social" type="url" />
                   </div>
                   <div>
                   <InputForm placeholder="Describe any details relevant to your request." icon={<DescriptionIcon  />} id="description" type="text" area={true} />
                   </div>
                   </div>
                  
                </div>
                <button type="submit" className='claim-music-btn'>
                    Claim Account
                </button>
                </form>
                
            </div>
        </div>
    )
}
