import React from "react";
//redux
import { useAppSelector } from "../../redux/hooks";
import {useQuery,gql} from "@apollo/client"
import FeaturedArtists from "../../components/musicPageComponents/featuredArtists/index";
import MusicFooter from "../main/components/footerSection/MusicFooter";



const  ArtistSearch= () => {
    const { searchQuery } = useAppSelector(state => state.search);
  const QUERY =gql`query{
    AllFeaturedArtistSearch(query:"${searchQuery}"){
        username
        profileImg
        userId
        criteria
    }
}`
   //loadind query
    const { loading, data, error } = useQuery(QUERY/*, { pollInterval: 500 }*/)
  return (
    <>
   <FeaturedArtists data={data?.AllFeaturedArtistSearch} loading={loading} error={error} searchResult={true} allMusic={true} addMargin={true} />
 {data && <MusicFooter/>}
    </>
  );
};

export default ArtistSearch;
