
//@ts-nocheck
import React, { useState } from 'react'
import Select, {
    components,
    ControlProps,
    Props,
    StylesConfig,
  } from 'react-select';

//helpers
import { gql, useMutation, useQuery } from "@apollo/client"

//components
import InputForm from "../../formInput/index"

//icon
import TitleIcon from "./src/TitleIcon"
import CategoryIcon from "./src/CategoryIcon"
import TagIcon from "./src/TagIcon"
import DecriptionIcon from "./src/DescriptionIcon"
import MusicFileIcon from "./src/MusicFileIcon"
import ImageIcon from "./src/ImageIcon"

//info icons
import Sucess from "../../sucess/index"
import Loading from "../../loading/index"
import Error from "../../error/index"


import { FileUpload, useUpload,useUploadCover } from "../../../genericComponents/fileUploud/PlaylistUpload"

import "./index.scss"

const Control = ({ children, ...props }: ControlProps<ColourOption, false>) => {
    // @ts-ignore
    // const { emoji, onEmojiClick } = props.selectProps;
    // const style = { cursor: 'pointer' };
  
    return (
      <components.Control {...props}>
        <span className='pl-1' >
        <TagIcon />
        </span>
        {children}
      </components.Control>
    );
  };

const AddMusics = () => {

    
    //state values
    const [uploading, setuploading] = useState(false)
    const [selectedtags,setTags]=useState("")
    //the graphl mutation
    const MUTATION = gql`mutation($thumbnail:String!,$file:Int!,$title:String!,$category:Int!,$tag:String!,$desc:String!,$userId:Int!)
{
    AddMusic(musicData:{thumbnail: $thumbnail,file: $file,title: $title,category:$category,tag: $tag,desc: $desc,userId:$userId}){
        title
    }
}`
const Boards_QUERY = gql`query{
    AllBoards{
       id
       name
   }
}`
const { loading: boardLoading, data: boards, error: boardsError } = useQuery(Boards_QUERY/*, { pollInterval: 500 }*/)
//add playlust mutation
const ADDPLAYLIST = gql`
mutation($title:String!,$desc:String!,$userId:Int!,$cover:Int!){
CreatePlaylist(playListData:{
title:$title,
desc:$desc,
userId:$userId,
cover:$cover,
}){
    playListId
    desc
}
}`

//add music to playlist mutation
const ADDMUSIC = gql`mutation(
    $music_file:Int!,
    $music_thumbnail:Int!,
    $music_artist:Int!,
    $playlist_id:Int!,
){
AddMusicToPlaylist(musicData:{
    music_file:$music_file,
    music_thumbnail:$music_thumbnail,
    music_artist:$music_artist,
    playlist_id:$playlist_id,
}){
    desc
}
}`

    //add playlust mutation
    const CREATEALBUM = gql`
    mutation($title:String!,$desc:String!,$userId:Int!,$cover:Int!){
    CreateAlbum(albumData:{
    title:$title,
    desc:$desc,
    userId:$userId,
    cover:$cover,
    }){
        albumId
        desc
    }
    }`

    //add music to playlist mutation
    const ADDMUSICALBUM = gql`mutation(
        $music_file:Int!,
        $music_thumbnail:Int!,
        $music_artist:Int!,
        $playlist_id:Int!,
    ){
    AddMusicToAlbum(albumMusicData:{
        music_file:$music_file,
        music_thumbnail:$music_thumbnail,
        music_artist:$music_artist,
        playlist_id:$playlist_id,
    }){
        desc
    }
}`

    const CATEGORY_QUERY = gql`query{
    AllCategory{
    id
    title
    }
}`

    //apollo client hook
    const [AddMusic, { loading, data, error }] = useMutation(MUTATION, { errorPolicy: "all" })
        //setting up usemutation
        const [CreatePlaylist, { loading:addPlaylist, data:playlistData, error:playlistError }] = useMutation(ADDPLAYLIST , { errorPolicy: "all" })

        //add Music to playlist mutation
        const [AddMusicToPlaylist, { loading: addMusicLoading, data: addMusicData, error: addMusicerror }] = useMutation(ADDMUSIC, { errorPolicy: "all" })
        const { loading: categoryLoading, data: categoryData, error: categoryError } = useQuery(CATEGORY_QUERY/*, { pollInterval: 500 }*/)


//setting up usemutation
const [CreateAlbum, { loading:albumLoading, data:albumData, error:albumError }] = useMutation(CREATEALBUM, { errorPolicy: "all" })

//add Music to playlist mutation
const [AddMusicToAlbum, { loading: addMusicLoadingALBUM, data: addMusicDataALBUM, error: addMusicerrorALBUM }] = useMutation(ADDMUSICALBUM, { errorPolicy: "all" })


    //function to easily get the id of element
    const value = (id: string) => {
        var inputValue = (document.getElementById(id) as HTMLInputElement).value
        return inputValue;
    };

    const [{ upload: uploadMusic, setfiles: setMusicFile }, { loading: uplaodingMusic, error: uploadMusicError }] = useUpload("music-file")
    const [{ upload: uploadCover, setfiles: setCoverFile }, { loading: uploadingCover, error: uploadCoverError }] = useUploadCover("image-file")

    //function to handle submit
    const handleSubmit = async (e: any) => {
        e.preventDefault();
        //the inputs values
        // console.log(setMusicFile)
        const [title, desc, category,type] = [value("title"), value("description"), value("category"),value("type")]
        let resultP;
        const tags= selectedtags.map((item)=>item.value)
        try {
            setuploading(!uploading)
            const Image = await uploadCover("image");
            if(Image){
                if(type==="Playlist"){
             resultP = await CreatePlaylist({
                variables: {
                    title: title,
                    desc: desc,
                    userId: parseInt(localStorage.getItem("userId") as string),
                    cover:Image.id,
                }
            })}

            if(type==="Album"){
                
                resultP =  await CreateAlbum({
                    variables: {
                        title: title,
                        desc: desc,
                        userId: parseInt(localStorage.getItem("userId") as string),
                        cover: Image.id,
                    }
                })
                
            }
        }
            
            
            const musicIds = await uploadMusic("audio")
            // console.log(musicIds)
            if (musicIds) {
                musicIds.map(async(musicId)=>{
                // const musicThumbNail = await uploadCover("image");
                if (Image) {
                   
                        setuploading(false)
                        // console.log(musicThumbNail)
                    const result = await AddMusic({
                        variables: {
                            thumbnail: Image.name,
                            file: musicId.id,
                            title: musicId.title,
                            category: parseInt(category as string),
                            tag: tags.toString(),
                            desc: desc,
                            userId: parseInt(localStorage.getItem("userId") as string)
                        }
                    })
                       
                    if(type==="Playlist"){
                    const addMusicResult = await AddMusicToPlaylist({
                        variables: {
                            music_file: musicId.id,
                            music_thumbnail: Image.id,
                            music_artist: parseInt(localStorage.getItem("userId") as string),
                            playlist_id: parseInt(resultP.data.CreatePlaylist.playListId as string),
                        }
                    })
            }
                    if(type==="Album"){
                        const addMusicResultalbum = await AddMusicToAlbum({
                            variables: {
                                music_file: musicId.id,
                                music_thumbnail: Image.id,
                                music_artist: parseInt(localStorage.getItem("userId") as string),
                                playlist_id: parseInt(resultP.data.CreateAlbum.albumId as string),
                            }
                        })
                    }
                    if (result) {
                        if (data) {
                            setuploading(false)
                        }
                    }

                    
                    
                }
            })

            }
            

        } catch (e) {
            setuploading(false)
        }
    }
    return (
        <div id="add-music">
            <h3>Manage music {">"} Add Playlist or Album</h3>
            <form action="" onSubmit={handleSubmit} autoComplete="off">
                <div className="d-md-flex justify-content-between top-header">
                    <div>
                        <h1>Playlist or Album</h1>
                        <h6>Add Multiple Music and Cover</h6>
                    </div>
                    <div className="col-md-6 px-0">
                        {(loading && !uploadMusicError && !uploadCoverError) && <Loading></Loading>}
                        {(uplaodingMusic && !uploadMusicError && !uploadCoverError) && <Loading text={`Adding Music To ${value("type")}`} />}
                        {(uploadingCover && !uploadMusicError && !uploadCoverError) && <Loading text={`Creating ${value("type")}`}/>}
                        {(data && !uploadMusicError && !uploadCoverError) && <Sucess text={` ${value("type")} succesfully Created`}></Sucess>}
                        {uploadMusicError && <Error error={uploadMusicError}></Error>}
                        {uploadCoverError && <Error error={uploadCoverError}></Error>}
                        {error &&
                            error.graphQLErrors.map(({ message }, i) => (
                                <Error error={message}></Error>
                            ))
                        }
                    </div>
                    <button type="submit" className="btn add-music">Create</button>
                </div>
                <div className="row mx-0" onSubmit={handleSubmit}>
                    <div className="col-md-4 px-0 all-form-inputs">
                        <InputForm placeholder="Album or Playlist Title" icon={<TitleIcon />} id="title" type="text" />
                        {/* <InputForm placeholder="Music Category" icon={<CategoryIcon />} id="category" type="text" /> */}
                        <div id="form-input">
                            <div className="input-group-component d-flex">
                                <CategoryIcon />
                                <select id="category" className="form-control">
                                    {categoryData && (categoryData.AllCategory.map((singlePlaylist: any) => {
                                        return (<option value={singlePlaylist.id}>{singlePlaylist.title}</option>)
                                    }))}
                                </select>
                            </div>
                        </div>
                        <div id="form-input">
                            <div className="input-group-component d-flex">
                                <CategoryIcon />
                                <select id="type" className="form-control">
                                    <option value={"Playlist"}>Playlist</option>
                                    <option value={"Album"}>Album</option>
                                </select>
                            </div>
                        </div>
                        <Select
                                   isMulti
                                   name="boards"
                                   isClearable={false}
                                   components={{Control}}
                                   id="tags"
                                   options={boards&& boards.AllBoards.map((item)=>
                                    {
                                      return {value:item.id,label:item.name}
                                    })}
                                    onChange={(choice)=>{
                                        setTags(choice)}}
                                   className="mb-3 basic-multi-select"
                                //    classNamePrefix="tags"
                                 />
                        <InputForm placeholder="Lyrics" icon={<DecriptionIcon />} id="description" type="text" area={true} />
                    </div>
                    <div className="col-md-4">
                        {setMusicFile && <FileUpload fileId="music-file" acceptedFiles="audio/mp3,audio/mpeg,audio/x-wav" title="Music Files" text="Drag and drop an audio" rule1="Add high quality audio" rule2="Add music you have right to" rule3="The file must not be bigger than 10 MB" icon={<MusicFileIcon></MusicFileIcon>} onDropHandler={setMusicFile} />}
                    </div>
                    <div className="col-md-4">
                        {setCoverFile && <FileUpload fileId="image-file" acceptedFiles="image/jpeg, image/png" title="Music Cover" text="Drag and drop an image" rule1="Add high quality image" rule2="Add image you have right to" rule3="The file must not be bigger than 10 MB" icon={<ImageIcon></ImageIcon>} onDropHandler={setCoverFile} />}
                    </div>
                    <button type="submit" className="btn add-music-mobile">Add Music</button>
                </div>
            </form>
        </div>
    )
}

export default AddMusics
