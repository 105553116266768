import React from 'react'

//helper
import {Link} from "react-router-dom"

//redux hooks
import {useAppDispatch} from "../../../redux/hooks"
import Player from '../../../pages/main/components/eventsSection/src/Player'
//redux actions
import { playSingle } from "../../../redux/data/musicPlayer/index"

interface props{
    title:string;
    thumbnail:string;
    file:string;
    username:string;
    count:Number;
    link:string;
    songs:any
}

const MusicElement:React.FC<props> = (props) => {
    const dispatch = useAppDispatch()
    return (
        <div className="col-md-3 px-2 card music-e-card  shadow-sm" onClick={(e) => {
            e.preventDefault()
        }}>
            {/* <Link to={props.link as string}> */}
                <div className="cover">
                   <div className='cover-wrapper'>
                    <div>
                        <img src={props.thumbnail} alt="" aria-hidden="false"  loading='lazy' />
                    </div>
                    {props.songs &&(
                        <div className='music-play-btn'onClick={(e) => {
                            e.preventDefault()
                            // console.log(props.songs)
                                dispatch(playSingle({
                                    value: props.songs
                                }))
                           
                        }}>
                    <Player/>
                 </div>
                    )}
                     
                   </div>
                </div>
                <Link to={props.link}><div className="song-name px-1 mt-2" >{props.title}</div></Link>
            <Link to={`/a/${props.username.replaceAll(' ','-').toLowerCase()}`}><div className="numbering mb-1 mt-1 px-1">By {props.username}</div></Link>
            
            
        </div>
    )
}

export default MusicElement
