// @ts-nocheck
import React, { useState } from 'react'

//redux helpers
import { useAppDispatch, useAppSelector } from "../../redux/hooks"

//redux actions 
import { setUpdateAudioFile } from "../../redux/data/uiData/index"

//helpers
import MusicFileIcon from '../../genericComponents/fileUploud/src/MusicFileIcon'
import axios from "axios"

//components
import { FileUpload, useUpload } from "../../genericComponents/fileUploud/FileUpload"

import Error from '../error'
import Sucess from '../sucess'
import Loading from '../loading'
//icons components

import Closeicon from "./src/Closeicon"


//scss files
import "./index.scss"

//configuration 
import configuration from '../../config/configuration'

const UpdateAudioFile = () => {

   const dispatch=useAppDispatch()
   const [status,setStatus]=useState("initial")
   const[close,setClose]=useState(false)
   const {idAttach} = useAppSelector(state => state.uiData)
    const [{ upload:Music, setfiles: setMusicFile }, { error: uploadMusicError }] = useUpload("image-file")
 const handleUpdtate= async(e)=>{
    e.preventDefault();
    setStatus("loading")
    setClose(true)
    const file= await Music("audio")
    const form = new FormData()
    form.append("id_attach",idAttach)
    form.append("newFile",file.filename)
     await axios.post(`${configuration.BACK_END_HOST}/updateaudio`, form)
    setStatus("success");

 }
    return (

        <div>
            <div id="choose-playlist">
                <div className="panel">
                    <div className="row"> <div className="col-6">
                   
                        </div></div>
                
                </div>
            </div>
            
           
            
                    <div id="add-playlist">
                        <div className="panel">
                            <div className="row">
                                <div className="col-md-12">
                                {(status==="loading") && <Loading></Loading>}
                    {status==="success" && close&& <Sucess close={setClose} text="Added music to the playlist"></Sucess>}
                    {uploadMusicError&&
                                    <Error error={uploadMusicError}></Error>}
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <h6 className="panel-title">Update Audio File</h6>
                                <div onClick={(e: any) => {
                                    e.preventDefault()
                                    dispatch(setUpdateAudioFile())
                                }}>
                                    <Closeicon />
                                </div>
                            </div>
                           
                            <div className="row d-flex panel-forms">
                                <div className="image-upload col-md-6 mx-auto">
                                    <div>
                                        {setMusicFile && <FileUpload fileId="image-file" acceptedFiles="audio/mp3,audio/mpeg,audio/x-wav" title="New Audio File" text="Drag and drop an audio file" rule3="The file must not be bigger than 10 MB" rule1="Add high quality audio" rule2="Add music you have right to" icon={<MusicFileIcon></MusicFileIcon>} onDropHandler={setMusicFile} />}
                                    </div>
                                     <div className='updt-file-btn' onClick={handleUpdtate} >Update Audio File</div>
                                </div>
                               
                            </div>
                        </div>
                    </div>
        
        </div>
    )
}

export default UpdateAudioFile
