// @ts-nocheck
import React, { useEffect, useRef, useState } from "react";
import { setMusicProfile } from "../../redux/data/musicPlayer/index.ts";
import { useAppDispatch } from "../../redux/hooks.ts";
import MusicCard from "./components/musicCard/index.jsx";
import BigLoader from "../../components/signup/src/icons/bigLoader";
import Suggestions from "../../components/suggestions/index.jsx";
import GoogleAds from "../../components/googleAds/GoogleAds.tsx";

// image test
import artistImage from "./assets/first.png";
import configuration from "../../config/configuration";
import "./index.scss";

import Parser from "rss-parser";
import { Menu } from "../main/components/welcome/MenuMain";
import WebGl from "../main/components/webgl/index";
import { setShowPayWall } from "../../redux/data/uiData";
//import { set } from "immer/dist/internal";

export default function MusicProfilePage(props) {
  const [isUser,setIsUser]=useState(parseInt(localStorage.getItem("userId")))
  const [id,setId ]= useState(props.match.params.title)
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [scrollDirection, setScrollDirection] = useState('down');
  const [image, setimage] = useState(artistImage);
  const [status,setStatus] =useState("initial")
  const [description,setDescription] =useState()
  const [scrollCount,setScrollCount] = useState(1)

  const getDescription =async () => {
    try{
      let res =await fetch(`${configuration.BACK_END_HOST}/podcastContent`);
      let resJson = await res.json()
      setDescription(resJson.data.content);

    }catch(error){
      //console.log(error)
    }
    
  }
  const changeId=(id)=>{
    setId(id)
  }
  const dispatch=useAppDispatch();
  const data = {
    "vibrant": "#f90a90",
    "lightVibrant": "#dd9bbb",
    "darkVibrant": "#740532",
    "muted": "#88747c",
    "lightMuted": "#c8bcb4",
    "darkMuted": "#542540"
  }
  const ref = useRef([]);
  const [state, setstate] = useState({
    title: "",
    description: "",
    listOfPoscast: "",
  });
  useEffect(()=>{
    getDescription()
  },[])
  useEffect(() => {
    const resultFunc = async () => {
      const listOfMusic = async () => {
        let parser = new Parser();
        const CORS_PROXY = "https://proxy.leerecs.com/?url=";
        let feed = await parser.parseURL(
          CORS_PROXY + "https://www.spreaker.com/show/3073366/episodes/feed"
        );
        if (feed) {
          feed.items.forEach((item, idx) => {
            ref.current.push({
              imageUrl: item.itunes.image,
              musicUrl: item.enclosure?.url,
              artist: "leerecs",
              musicName: item.title,
              description: item.contentSnippet,
            });
          });
          return ref.current;
        }
      };

      const title = (id) => {
        for (let index = 0; index < ref.current.length; index++) {
          const element = ref.current[index];
          if(id){
          if (element.musicName === id) {
            return element;
          }
        }
        else{
          return ref.current[0]
        }
      }
      };

      const result = await listOfMusic();
      if (result) {
        const details = title(id);
        setstate({
          ...details,
          title: details.musicName,
          description: details.description,
          musicUrl: details.musicUrl,
          listOfPoscast: ref.current,
        });
        dispatch(setMusicProfile(
         { value:{
            title:details.musicName,
            description: details.description,
            imageUrl:details.imageUrl

          }}
        ))
        setimage(details.imageUrl);
        setStatus("success")
      }
    };
    //if (!state.listOfPoscast.length){
      resultFunc()
    //}
  }, [id]);
  useEffect(() => {
    const handleScroll = () => {
      const currentScroll = window.pageYOffset || document.documentElement.scrollTop;
      if (currentScroll > lastScrollTop) {
        setScrollDirection('down');
      } else {
        setScrollDirection('up');
      }
  
      setLastScrollTop(currentScroll <= 0 ? 0 : currentScroll);
  
      // Show modal when scrolling down
      if (!isUser&&scrollDirection === "down" && (window.scrollY >= (scrollCount * window.innerHeight) - 1)) {
        setScrollCount(scrollCount + 1);
        console.log(scrollCount)
        dispatch(setShowPayWall(true));
      }
  
      // Show modal when scrolling up
      if (!isUser&&scrollDirection === "up" && (window.scrollY <= ((scrollCount - 1) * window.innerHeight) + 1)) {
        setScrollCount(scrollCount > 1 ? scrollCount - 1 : 1); // Ensure scrollCount does not go below 1
        dispatch(setShowPayWall(true));
      }
    };
  
    window.addEventListener('scroll', handleScroll);
  
    return () => window.removeEventListener('scroll', handleScroll);
  }, [dispatch, lastScrollTop, scrollCount, scrollDirection,isUser]);
  return (
    <>
    
    <WebGl />
    <Menu />
      <div
        id="podcast"
        className="container-fluid mt-3"
        style={{
          // backgroundColor: data.lightMuted,
          color: data.darkVibrant,
        }}
      >
        {/* {handleBodyColor()} */}
        {status==="initial"?(<div className="bigLoader"><BigLoader/></div>):(
          <div
          className="row mx-0"
          style={{
            // backgroundColor: data.lightMuted,
            color: data.darkVibrant,
          }}
        >
          
          <div
            className="col-lg-7 playlist-section p-xl-0"
            style={{
              // backgroundColor: data.lightMuted,
              color: "white",
            }}
          >
            <MusicCard
              artistImage={image}
              artistTitle={state.musicName}
              musicUrl={state.musicUrl}
              artistBio={description}
              website={"https://leerecs.com/"}
              data={data}
              podcast={{
                name: state.musicName,
                singer: state.artist,
                cover: image,
                musicSrc: state.musicUrl,
              }}
              podcastId={id}
            />
          </div>

          <div className=".col-lg-5">
            <GoogleAds adwidth={500} adheight={270}/>
          </div>
          
          <div
            className="col-lg-12 suggestion-section px-3"
            style={{ background: "rgba(73, 73, 73, 0.47) none repeat scroll 0% 0%", marginTop:"20px"}}
          >
            {/* {console.log("the data",data)} */}
            {state.listOfPoscast.length && (
              <Suggestions
                colorPalette={data}
                type={"podcast"}
                playlistData={state.listOfPoscast}
                changeSong={changeId}
              />
            )}
          </div>
        </div>

        )}
        
      </div>
    </>
  );
}
