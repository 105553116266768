import React from 'react'

export default function PlayIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18.623" height="21.533" viewBox="0 0 18.623 21.533">
      <g id="play-outline" transform="translate(-5.25 -0.187)">
        <path id="Path_112" data-name="Path 112" d="M7.2,4.564A.8.8,0,0,0,6,5.248v18.41a.8.8,0,0,0,1.2.684l15.534-9.205a.8.8,0,0,0,0-1.367Z" transform="translate(0 -3.5)" fill="#fff" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      </g>
    </svg>

  )
}
