import React from 'react'
import { useAppSelector } from '../../../redux/hooks'

interface props{
    title:String
}

const SideBarElement:React.FC<props> = (props) => {
    const {isMini} = useAppSelector(state => state.uiData)
    return (
        <div className="sideElement" style={{width:isMini?'15%':''}}>
            <div className="d-flex">
                {props.children}
                {!isMini && (
                    <div className="text-name mt-1">{props.title}</div>
                )}
                
            </div>
        </div>
    )
}

export default SideBarElement
