import React from 'react'
import { Link,useLocation } from 'react-router-dom'


//redux hooks
import {useAppDispatch} from "../../../redux/hooks"
import configuration from '../../../config/configuration'

//iicons
import MusicIcon from './src/MusicIcon'
import UserIcon from './src/UserIcon'
import Player from '../../../pages/main/components/eventsSection/src/Player'
import CardShareIcon from '../featuredArtists/CardShareIcon'
//redux actions
import { playSingle,setMusicId } from "../../../redux/data/musicPlayer/index"
import Eye from './src/Eye'
import {setUpdateAudioFile,setIdAttach} from "../../../redux/data/uiData/index"

import Publish from '../../wrappers/Publish'
import { setsharedUrl } from "../../../redux/data/artistProfile";

interface props{
    title:string;
    thumbnail:string;
    file:string;
    username:string;
    count:Number;
    music_file: Number;
    music_thumbnail: Number;
    music_artist: Number;
    topic_id: Number;
    claim?:Number;
    messageId?:Number;
    approved?:Number;
    color_name?:string
    playData?:any;
}

const MusicElement:React.FC<props> = (props) => {
    const dispatch = useAppDispatch()
    const location =useLocation()
    const regex = /[?@\/#:\,;%+&=\{\}\[\]\^-]/g
    const currentSong={
        name:props.title,
         singer: props.username,
         cover: props.thumbnail,
         musicSrc: props.file,
         color:props.color_name
    }
    const handleError = (event:any) => {
        event.target.src = '/assets/images/default-music.png'; // Replace with your default image URL
    };
    return (
        <div className="col-md-3 px-2  card  music-e-card  shadow-sm" >
            <div className="cover shadow-sm">
            {/* {console.log(props.color_name)} */}
            <div className='cover-wrapper' style={{backgroundColor:`${props.color_name}`, border:`2px solid ${props.color_name}`}}>
               
                <div> 
                    <img src={props.thumbnail} alt="" aria-hidden="false" draggable="false" onError={handleError}   />
                </div>
                </div>
                <div className='music-play-btn'onClick={(e) => {
                e.preventDefault()
                //console.log(props.playData)
                dispatch(playSingle({
                    value: [currentSong]
                }))
                
                //console.log(props.topic_id)
                //@ts-ignore
                dispatch(setMusicId({ value: props.topic_id }))
            }}>
                <Player/>
                </div>
                </div>
            <Link to={`/a/${props.username.replaceAll(" ","-").toLowerCase()}/s/${props.title.toLowerCase().replaceAll(regex,'').replaceAll(" ","-")}`}><div className="song-name" title={props.title} ><MusicIcon/> {props.title}</div></Link>
            <Link to={`/a/${props.username.replaceAll(" ","-").toLowerCase()}`}><div className="numbering" title={props.username}><UserIcon/> {props.username}</div></Link>
            <div className='card-share-btn' onClick={(e) => {
                e.preventDefault()
                
                dispatch(setsharedUrl(
                    `https://leerecs.com/a/${props.username.replaceAll(" ","-").toLowerCase()}/s/${props.title.replaceAll(regex,'').replaceAll(" ","-").toLowerCase()}`
                  ))
            }}
                    ><CardShareIcon></CardShareIcon></div>
                     <div className='karma'>{(location.pathname ==="/music/mymusic")&&( <Publish music_id={props.topic_id} ><Eye approved={props.approved}/></Publish>)} </div>
                    {(location.pathname ==="/music/mymusic")&& <div className='replace-btn' 
                    onClick={(e)=>{
                       e.preventDefault();
                       dispatch(setUpdateAudioFile())
                       dispatch(setIdAttach(props.music_file))
                    }}>Replace Audio File</div>}
                     
            
        </div>
    )
}

export default MusicElement
