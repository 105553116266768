import React , {useState,useEffect} from 'react'
import {Container} from './src/Container'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'


//helpers
import { useQuery, gql } from "@apollo/client"

//redux hooks
import { useAppDispatch } from "../../../redux/hooks"

//redux actions
import { playSingle } from "../../../redux/data/musicPlayer/index"
import { album,setAlbumState, setCurrentAlbum } from '../../../redux/data/uiData'

//icons
import PlayMusicIcon from "./src/PlayMusicIcon"
import ContentEditable from 'react-contenteditable'
import PencilIcon from '../singleSongProfile/src/PencilIcon'

//prelaoders
import HeaderPreloader from "../headerPreloader/index"
import SingleMusicPreloader from "../singleMusicPreloader"

import MusicElement from "./MusicElement"
import { setsharedUrl } from '../../../redux/data/artistProfile'
import ShareIcon from '../allMusicInPlaylist/src/ShareIcon'

import "./index.scss"

//configuration
import configuration from '../../../config/configuration'
import PlaylistInfo from './PlaylistInfo'
import MusicEmptyCase from '../../../pages/Emptycase/MusicEmptyCase'
import BigLoader from '../../signup/src/icons/bigLoader'
import MusicFooter from '../../../pages/main/components/footerSection/MusicFooter'

interface props {
    playlistId: String;
}
const AllMusicsInAlbum: React.FC<props> = (props) => {
    const dispatch = useAppDispatch()
    //state that will contain music data array
    let musicData:any=[]
    // const [musics, setmusics] = useState([])
    const isUser=parseInt(localStorage.getItem("userId") as string)

    const QUERY = gql`query{
    AllMusicInAlbum(albumId:"${props.playlistId.replaceAll('-',' ')}"){
        title
        thumbnail
        desc
        file
        musicId
        username
        imageId
        messageId
        color_name
        topicId
        userId
        order_id
        playlist_id
    }
}`
    const { loading, data, error,refetch } = useQuery(QUERY/*, { pollInterval: 500 }*/)

    //query to get playlist data
    const PLAYLISTDATA = gql`query{
    SingleAlbumData(albumId:"${props.playlistId.replaceAll('-',' ')}"){
        title
	    desc
	    cover
        userId
        albumId
        coverId
    }
    }`

    const { loading: loadindInfo, data: playlistInfo, error: playlistError } = useQuery(PLAYLISTDATA/*, { pollInterval: 500 }*/)
   const[title, setTitle]=useState(playlistInfo &&playlistInfo.SingleAlbumData.title)

    return (
        <>
        {loadindInfo&&(
           <div id="all-music-in-playlist">
           {loadindInfo && <HeaderPreloader />}
           {loading && (<SingleMusicPreloader />)}
           </div>
        )}
        {!loadindInfo&&(
            <>
            <div id="all-music-in-playlist">
                {loadindInfo && <HeaderPreloader />}
                {playlistInfo && (
    
                    <PlaylistInfo musicData={musicData}
                     cover={playlistInfo.SingleAlbumData.cover} 
                     title={playlistInfo.SingleAlbumData.title} 
                     desc={playlistInfo.SingleAlbumData.desc}
                     playlistId={playlistInfo.SingleAlbumData.albumId}
                     coverId={playlistInfo.SingleAlbumData.coverId}
                     userId={playlistInfo.SingleAlbumData.userId}
                     />
                    
                )}
                {data&&playlistInfo&& data.AllMusicInAlbum.length!==0 &&(
                    <div className="row mx-0 table-heading justify-content-between">
                    <div className="col-md-1 px-0">#</div>
                    <div className="col-md-6 px-0">Title</div>
                    <div className="col-md-3 px-0"></div>
                    <div className="col-md-1 px-0">React</div>
                    <div className="col-md-1 px-0">
                        <div className="d-flex justify-content-end">
                            <div></div>
                            <div>Play</div>
                        </div>
                    </div>
                    <div className="col-md-12 px-0"><hr /></div>
                </div>
                )}
                
                {loading && (<SingleMusicPreloader />)}
                {data && data.AllMusicInAlbum.length===0&&playlistInfo ?(
                    <div className=' center-div text-white'>
                    <h1>Empty Album</h1>
                    {isUser&&playlistInfo && isUser===playlistInfo.SingleAlbumData.userId ?( <p>Add your first song.</p>)
                    : (<p>The owner of this album hasn't added music yet.</p>)}
                   
                    </div>
                ):!playlistInfo?(<MusicEmptyCase/>):""}
                {data &&playlistInfo &&isUser!==playlistInfo.SingleAlbumData.userId&& (data.AllMusicInAlbum.map((music: any, count: any) => {
                    musicData.push({
                        name: music.title,
                        singer: music.username,
                        cover: `${configuration.FILE_STORAGE_LINK}${music.thumbnail}`,
                        musicSrc: `${configuration.READFILE_LINK}${music.file}`,
                        color:music.color_name
                    })
                    return (<MusicElement
                         recommend={data.AllMusicInAlbum.filter((song:any)=>song.title!==music.title)}
                        music_file={music.musicId}
                        music_thumbnail={music.imageId}
                        music_artist={music.memberId}
                        title={music.title}
                        thumbnail={`${configuration.FILE_STORAGE_LINK}${music.thumbnail}`}
                        file={`${configuration.READFILE_LINK}${music.file}`}
                        username={music.username}
                        color_name={music.color_name}
                        music_id={music.topicId}
                        count={(count + 1).toLocaleString('en-US', {
                            minimumIntegerDigits: 2,
                            useGrouping: false,
                        })}
                        index={count}
                        userId={music.userId}
                         />)
                }))} 
                {data && isUser&&playlistInfo && isUser===playlistInfo.SingleAlbumData.userId &&(
                <>
                    {data.AllMusicInAlbum.map((music:any)=>{
                        musicData.push({
                            name: music.title,
                            singer: music.username,
                            cover: `${configuration.FILE_STORAGE_LINK}${music.thumbnail}`,
                            musicSrc: `${configuration.READFILE_LINK}${music.file}`,
                            color:music.color_name
                        })
                    })}
                    <DndProvider backend={HTML5Backend}>
                    <Container data={data.AllMusicInAlbum}  refetch={refetch}/>
                </DndProvider>
                </>
                )}
                
                
    
             {isUser&&playlistInfo && isUser===playlistInfo.SingleAlbumData.userId ?(
                <div className='add-songs-to-playlist' onClick={(e)=>{
                    e.preventDefault()
                    dispatch(album())
                    dispatch(setCurrentAlbum( parseInt(playlistInfo.SingleAlbumData.albumId as string)))
                    dispatch(setAlbumState('add'))
                  }}> Add Music</div>
             ):''}
              
              <div style={{marginBottom:"80px"}}></div>
            </div>
            <MusicFooter/>
            </>
        )}
        
        </>
    )
}

export default AllMusicsInAlbum
