import React from 'react'


//redux hooks
import { useAppDispatch } from "../../../../redux/hooks"

//redux actions
import { playSingle, setMusicId } from "../../../../redux/data/musicPlayer/index"
import { addMusic } from "../../../../redux/data/playlist/index"
import { playlist } from "../../../../redux/data/uiData/index"

interface props {
  title: string;
  thumbnail: string;
  file: string;
  username: string;
  count: Number;
  music_file: Number;
  music_thumbnail: Number;
  music_artist: Number;
  topic_id: Number;
}

const MusicIcon: React.FC<props> = (props) => {
  const dispatch = useAppDispatch()
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" onClick={(e) => {
      e.preventDefault()
      alert(props.file)
      dispatch(playSingle({
        value: [{
          name: props.title,
          singer: props.username,
          cover: props.thumbnail,
          musicSrc: props.file,
        }]
      }))
      //@ts-ignore
      dispatch(setMusicId({ value: props.topic_id }))

    }}>
      <g id="library_music" transform="translate(-2 -2)">
        <path id="Path_73" data-name="Path 73" d="M8,16H20V4H8Zm4.5-6a2.447,2.447,0,0,1,1.5.51V5h4V7H15v5.5A2.5,2.5,0,1,1,12.5,10Z" fill="#bfbfbf" opacity="0.3" />
        <path id="Path_74" data-name="Path 74" d="M20,2H8A2.006,2.006,0,0,0,6,4V16a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2V4A2.006,2.006,0,0,0,20,2Zm0,14H8V4H20Zm-7.5-1A2.5,2.5,0,0,0,15,12.5V7h3V5H14v5.51A2.447,2.447,0,0,0,12.5,10a2.5,2.5,0,0,0,0,5ZM2,6V20a2.006,2.006,0,0,0,2,2H18V20H4V6Z" fill="#bfbfbf" />
      </g>
    </svg>

  )
}

export default MusicIcon
