// @ts-nocheck
import React , {useState,useEffect,useCallback} from 'react'
import { useDropzone } from 'react-dropzone'
import axios from 'axios'
//helpers
import { useQuery, gql,useMutation } from "@apollo/client"

//redux hooks
import { useAppDispatch } from "../../../redux/hooks"

//redux actions
import { playSingle } from "../../../redux/data/musicPlayer/index"
//icons
import PlayMusicIcon from "./src/PlayMusicIcon"
import ContentEditable from 'react-contenteditable'
import PencilIcon from '../singleSongProfile/src/PencilIcon'
import Loader from '../../requestInterview/src/icons/loader'
import Done from '../../sucess/icons/done_all'
import CloudErrorComponent from '../../signup/src/CloudErrorComponent'
import ProgressBanner from '../../musicianPageComponents/addMusic/ProgressBanner'
import { setDeleteAlbum,setCurrentAlbum,setAssignPlaylist } from '../../../redux/data/uiData'

import { setsharedUrl } from '../../../redux/data/artistProfile'
import ShareIcon from '../allMusicInPlaylist/src/ShareIcon'

import "./index.scss"

//configuration
import configuration from '../../../config/configuration'
import DeleteIcon from '../allMusicInPlaylist/src/DeleteIcon'
import UserIcon from '../newMusic/src/UserIcon'
import AssignIcon from './src/Assign'

interface props {
    musicData: any;
    title:string;
    desc:string;
    cover:string;
    playlistId:number;
    coverId:number;
    userId:number;
}
const PlaylistInfo: React.FC<props> = (props) => {
    const dispatch = useAppDispatch()
    const [title,setTitle]=useState(props.title)
    const[desc,setDesc]=useState(props.desc)
    const [isAdmin,setIsAdmin] = useState(false)
    const[cover,setCover]=useState(`${configuration.FILE_STORAGE_LINK}${props.cover}`)
    const[profile,setProfile]=useState()
    const isUser=parseInt(localStorage.getItem("userId") as string)
    const [edit,setEdit]=useState(isUser===props.userId)

    const [message,setMessage]=useState("loading")
    const[addingstatus,setAddingStatus]=useState(false)
    const [color,setColor]=useState("#7171ff")
    const [width,setWidth]=useState("10%")
    const [btn,setbtn]=useState(false);
    const [icon,setIcon]=useState(<Loader/>)
    const [status,setStatus]=useState(null);

      //the graphl mutation
      const MUTATION = gql`mutation($cover:Int!,$desc:String!,$title:String!,$playlistId:Int!)
      {
        UpdateAlbum(albumData:{cover: $cover,desc: $desc,title: $title,playlistId:$playlistId}){
              desc
          }
      }`
      const [UpdateAlbum, { loading, data, error }] = useMutation(MUTATION, { errorPolicy: "all" })
      
      const handleUpdate= async (e)=>{
        e.preventDefault();
        let pId=props.coverId
        setAddingStatus(true)
        setbtn(true)
 
        //intializing
        setStatus(null)
        setMessage("loading ...")
        setColor("#7171ff")
        setWidth("10%")
        setIcon(<Loader/>)
        setTimeout(() => {
            
        },100);
        
        if(profile){
            setWidth("40%")
                setColor("#7171ff")
                setMessage("Uploading Cover File")
            const form1 = new FormData();
        const form = new FormData();
        form1.append("file",profile,profile.name)
        const result1 = await axios.post(`https://leerecs.com/backend/public/api/uploadfile`, form1)
        form.append("fileTitle", result1.data.title)
        form.append("userId", localStorage.getItem("userId") as string)
        form.append("fileExt", "cover")
        form.append("mimeType", profile.type)
        form.append("size", profile.size)
        const result = await axios.post(`${configuration.BACK_END_HOST}/upload`, form)
    //    console.log(result)
        pId=result.data.fileId;

        }
        const result = await UpdateAlbum({
            variables: {
                cover:pId,
                desc: desc.replace(/&nbsp;/g, ''),
                title: title.replace(/&nbsp;/g, ''),
                playlistId: parseInt(props.playlistId),
                
            }
        })
        if(result){
            setWidth("100%")
            setColor("#5DD97C")
            setMessage(`You have successfully updated this album`)
            setIcon(<Done/>) 
            setStatus(true)
            setbtn(false)
            if((props.title !== title)){
                window.location.replace(`/music/album/${title.replaceAll(" ","-").replace(/&nbsp;/g, '').toLowerCase()}`)
              }

        }else{
            setColor("#FF7171")
            setMessage(error.graphQLErrors[0])
            setbtn(false)
            setIcon(<CloudErrorComponent/>) 
        }
        

      }

    const onDrop = useCallback(acceptedFiles => {
        // console.log(acceptedFiles[0]);
        setProfile(acceptedFiles[0]);
          const reader = new FileReader()
          reader.addEventListener("load",()=>setCover(reader.result))
          reader.readAsDataURL(acceptedFiles[0])
          
      }, [])
      const {getRootProps, getInputProps, isDragActive} = useDropzone({accept:"image/jpeg, image/png",multiple:false,onDrop})

      useEffect(()=>{
        const isAdmin= async()=>{
        const form = new FormData();
        form.append('userId',parseInt(localStorage.getItem("userId")));
        const result = await axios.post(`${configuration.BACK_END_HOST}/isadmin`, form)
        if(result.data.admin){
            setEdit(result.data.admin)
            setIsAdmin(result.data.admin)
        }
      }
      isAdmin()
      },[])
   
    return (       
                <div className="header" style={{ backgroundImage: `linear-gradient(rgba(56, 56, 56, 0.22) 0%, #292929 100%),url("${cover}`}}>
                    {addingstatus &&(<div className="col-10 mx-auto mt-2 loaders">
                <ProgressBanner close={setAddingStatus} message={message} width={width} color={color} icon={icon} status={status}/>
            </div>)}
            {edit&&(
                <div className="upload-dropzone"{...getRootProps()}>
                <input {...getInputProps()} multiple={false} />
                    <PencilIcon/></div>
            )}
                    
                    <div className="d-flex justify-content-between">
                        <div>
                            <div className="panel-title">album</div>
                            <div className="playlist-title">
                                <ContentEditable
                                disabled={!edit}
                                html={title}
                                onChange={(e)=>{
                                    setTitle(e.target.value)
                                }}
                                className=" mr-3"
                                tagName='span'
                                />
                                {edit&&(<PencilIcon/>)}
                                </div>
                            <div className="playlist-desc"><ContentEditable
                                disabled={!edit}
                                html={desc}
                                onChange={(e)=>{
                                    setDesc(e.target.value)
                                }}
                                className=" mr-3"
                                tagName='span'
                                />{edit&&(<PencilIcon/>)}
                                </div>
                                <div className="d-flex">
                               {edit&&(profile||(title!==props.title)||(desc!==props.desc))&&( 
                               <button disabled={btn} className='apdate-btn mt-2 mr-4' onClick={handleUpdate} >Update Album</button>
                               )}
                               {isAdmin&&( 
                               <div disabled={btn} className=' mt-2 mr-4' onClick={(e)=>{
                                  e.preventDefault();
                                 dispatch(setAssignPlaylist({id:props.playlistId,type:"album"}))
                               }} ><AssignIcon/></div>
                               )}
                                {edit&&( 
                               <div disabled={btn} className='mt-2 mr-1' onClick={(e)=>{
                                  e.preventDefault();
                                 dispatch(setDeleteAlbum())
                                //  console.log(props.playlistId)
                                 dispatch(setCurrentAlbum(parseInt(props.playlistId)))
                               }} ><DeleteIcon/></div>
                               )}
                               
                               </div>
                               
                        </div>
                        <div className="btn-collection">
                            <div onClick={(e) => {
                                e.preventDefault()
                                dispatch(playSingle({
                                    value: props.musicData
                                }))
                            }}>
                                <PlayMusicIcon />
                            </div>
                            <div style={{
                                marginTop: "15px"
                            }}
                            className='share-btn'
                                onClick={(e) => {
                                    e.preventDefault()
                                    dispatch(setsharedUrl(
                                        `${window.location.href}`
                                      ))
                                }}>
                                <ShareIcon />
                            </div>
                            
                        </div>
                        
                    </div>
                   
                </div> 
    )
}

export default PlaylistInfo;
