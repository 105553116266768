import React from 'react'

//helper
import {gql,useQuery} from "@apollo/client"

//react components
import CountCard from "./CountCard"

//svg elemnts
import Favorite from './src/Favorite'
import MusicIcon from "./src/MusicIcon"
import ThumbIcon from "./src/ThumbIcon"

//css files
import "./index.scss"

const Dashbaord = () => {
    const QUERY = gql`query{
    AllUserDataCount(userId:${localStorage.getItem("userId")}){
        allMusic
        allAlbum
        allPlaylist
    }
}`
    const { loading, data, error } = useQuery(QUERY/*, { pollInterval: 500 }*/)
    return (
        <div id="dashboard">
            <h3>DASHBOARD</h3>
            {data && (
                <div className="row mx-0 justify-content-between">
                    <CountCard title={(<div> All<br />Music</div>)} logo={(<Favorite></Favorite>)} count={data.AllUserDataCount.allMusic}/>
                    <CountCard title={(<div> All<br />Albums</div>)} logo={(<MusicIcon></MusicIcon>)} count={data.AllUserDataCount.allAlbum} />
                    <CountCard title={(<div> All<br />Playlists</div>)} logo={(<ThumbIcon></ThumbIcon>)} count={data.AllUserDataCount.allPlaylist} />
                </div>
            )}
        </div>
    )
}

export default Dashbaord
