// @ts-nocheck
import React, { useState, useEffect,useCallback,useRef } from "react";
import { Editor } from '@tinymce/tinymce-react';
import ContentEditable from "react-contenteditable";
import axios from "axios";
import {useDropzone} from 'react-dropzone'
//helpers
import { useQuery, gql } from "@apollo/client";
import BigLoader from "../../signup/src/icons/bigLoader";

//redux hooks
import { useAppDispatch } from "../../../redux/hooks";

//redux actions
import { playSingle,setMusicId } from "../../../redux/data/musicPlayer/index";
import { setsharedUrl } from "../../../redux/data/artistProfile/index";
import LikeMusic from "../../wrappers/likeMusic";

//icons
import PlayMusicIcon from "./src/PlayMusicIcon";
import { Link } from "react-router-dom";
import LottieWrapper from "../../lottie/lottie";
//prelaoders
import HeaderPreloader from "../headerPreloader/index";
import SingleMusicPreloader from "../singleMusicPreloader";

import MusicElement from "./MusicElement";
import Closeicon from "../../addPlaylist/src/Closeicon";
import Loading from "../../loading";
import Sucess from "../../sucess";
import PlayIcon from "./src/PlayIcon";
import HeartIcon from "./src/HeartIcon";
// import HeartIcon from "../allMusicByArtist/src/HeartIcon";
import ShareButton from "./src/shareIcon";
import PencilIcon from "./src/PencilIcon";
import { addMusic } from "../../../redux/data/playlist";
import { playlist } from "../../../redux/data/uiData";
import "./index.scss";
import Done from "../../sucess/icons/done_all";
import CloudErrorComponent from "../../requestInterview/src/UploadIcon";
import Loader from "../../requestInterview/src/icons/loader";

//configuration
import configuration from "../../../config/configuration";
import AddPlaylistIcon from "../../addAlbum/src/AddPlaylistIcon";
import RecommededArtists from "../featuredArtists/RecommendedArtists";
import { setPlaylistState } from "../../../redux/data/uiData";
import AllAlbums from "../albums";
import AllPlaylist from "../allPlaylist";
import NewMusic from "../RecommededSongs";


interface props {
  songData:any
}
const SingleSongProfile: React.FC<props> = (props) => {
  const dispatch = useAppDispatch();
  const currentSong={
               name:props.songData.title,
                singer: props.songData.username,
                cover: `${configuration.FILE_STORAGE_LINK}${props.songData.thumbnail}`,
                musicSrc: `${configuration.READFILE_LINK}${props.songData.file}`,
                color:props.songData.color_name
  }

  const QUERY =gql`query{
    RecommendedMusic(songId:${props.songData.id_msg}) {
         title
         thumbnail
         file
         username
         musicId
         imageId
         memberId
         topicId
         messageId
         color_name
     }
     }`
      const categoryQuery =gql`query{
        AllCategory {
          id
          title
         color
          desc
         }
         }`

// const { loading, data, error } = useQuery(QUERY/*, { pollInterval: 500 }*/)
const {  data:categoryData,} = useQuery(categoryQuery/*, { pollInterval: 500 }*/)

    const user=localStorage.getItem("token");
  const [cover,setCover]=useState(`${configuration.FILE_STORAGE_LINK}${props.songData.thumbnail}`)
  const [currentLyrics,setCurrentLyrics]=useState(props.songData.lyrics)
  const [currentTitle,setCurrentTitle]=useState(props.songData.title)
  const [file,setFile]=useState();
  const [errorData, seterrorData] = useState<String | null>(null)
  const [updating, setupdating] = useState(false)
  const [status,setStatus] =useState(false)
  const [color,setColor] = useState(props.songData.color)
  const [owner,setOwner] =useState(props.songData.user_id===parseInt(localStorage.getItem("userId") as string))
  const onDrop = useCallback(acceptedFiles => {
    setFile(acceptedFiles[0]);
      const reader = new FileReader()
      reader.addEventListener("load",()=>{
        setCover(reader.result)
      })
      reader.readAsDataURL(acceptedFiles[0])
      
  }, [])
  const handleTitle=(e)=>{
    setCurrentTitle(e.target.value.replace(/&nbsp;/g, ''))
  }
  const handleLyrics=(e)=>{
    setCurrentLyrics(e.target.value)
  }
  const handleColor=(e)=>{
    setColor(parseInt(e.target.value))
    
  }

  const handleUpdate =async(e)=>{
    e.preventDefault();
    setAddingStatus(true)
    setupdating(true)
    setStatus(false)
    if(currentTitle !== props.songData.title){
      const check = new FormData()
      check.append("userId",props.songData.user_id)
      check.append("songname",currentTitle)
      const checkres = await axios.post(`${configuration.BACK_END_HOST}/checksong`, check)
      if(checkres.data.exists){
        return (seterrorData("Your have a song with the same title"))
      }
    }
    const form = new FormData()
    form.append("cover_id",props.songData.cover_id)
    form.append("id_msg",props.songData.id_msg);
    form.append("color",parseInt(color));
    if(currentLyrics !== props.songData.lyrics){
      form.append("lyrics",currentLyrics.replace(/&nbsp;/g, ''))
     
    }
    if(currentTitle !== props.songData.title){

      form.append("title",currentTitle)
    }
    if(file){
      const form1= new FormData()
      form1.append("file",file,file.name)
      const result1 = await axios.post(`${configuration.BACK_END_HOST}/uploadfile`, form1)
      form.append("cover",result1.data.title.replace(/&nbsp;/g, ''))
    }

      await axios.post(`${configuration.BACK_END_HOST}/updatemusic`, form)
    setupdating(false)
    setStatus(true)

    if(currentTitle !== props.songData.title){
      window.location.replace(`/a/${props.songData.username.replaceAll(" ","-").toLowerCase()}/s/${currentTitle.replaceAll(/[?@\/#:\,;%+&=\{\}\[\]'\^-]/g,'').replaceAll(" ","-").toLowerCase()}`)
    }


    

  }
  const {getRootProps, getInputProps,} = useDropzone({accept:"image/jpeg, image/png",multiple:false,onDrop})
  //state that will contain music data array
  // let musicData: any = [];
  // const [countNumber, setcountnumber] = useState(5);
  // var count = 0;



const [like,setLike]=useState(false)
const liked = gql`query{
  IsMusicLikedByUser(likeInfo:{
      userId:${localStorage.getItem("userId")},
      musicId:${props.songData.topicId}
  }){
      desc
  }
}`

const { loading:lileLoading, data:LikedData, error:likeError } = useQuery(liked/*, { pollInterval: 500 }*/)

  
 const[addingstatus,setAddingStatus]=useState(false)
 const [message,setMessage]=useState("loading")
 const editorRef = useRef(null);
 const [edit,setEdit]=useState(false)
 const [text, setText] = useState('');


 //like useEffect
 useEffect(() => {
  if(LikedData){
    setLike(true)
  }
  if(likeError){
    setLike(false)
  }
  if(lileLoading){
    setLike(false)
  }
}, [LikedData])
useEffect(()=>{
const progress =document.querySelector(".progress-bar")
 if(addingstatus){
if(updating && !errorData){
    progress.style.width="30%"
    setMessage("loading")
}
if(status && !errorData){
    progress.style.width="100%"
    progress.style.backgroundColor="#5DD97C"
    progress.classList.add('successfull')
    setMessage("The song profile has been updated successfully")
    
}

if(errorData){
  setMessage(errorData)
  progress.style.backgroundColor ="#FF7171"
}
     
 }

},[updating ,status,errorData])

useEffect(()=>{
  const isAdmin= async()=>{
  const form = new FormData();
  form.append('userId',parseInt(localStorage.getItem("userId")));
  const result = await axios.post(`${configuration.BACK_END_HOST}/isadmin`, form)
  if(result.data.admin){
      setOwner(result.data.admin)
  }
}
isAdmin()
},[])

const handleError = (event) => {
  event.target.src = '/assets/images/default-music.png'; // Replace with your default image URL
};
 return (
  <div>
     <div className=" col-10 loader">                       
                     
                     {addingstatus &&(<div className="col-10 mx-auto mt-2 loaders"><div className=" progress-container px-0">
                        <div className='cont'>
                        <div className='progress-bar btn-success'></div>
                        </div>
                        <div className='message fw-bold text-sucess-pane' style={{fontWeight:"bold"}}> {status?<span className='ml-2'> <Done/> {message} </span>:<span className='ml-3'> {updating && !errorData ?<Loader/>:<CloudErrorComponent/>}  {message}</span>}</div>
                        <div className='close' onClick={(e)=>{setAddingStatus(false)}}> <Closeicon /></div>
                    </div></div>)}   
                        </div>
    <div id="all-music-in-playlist">
      {/* {loadindInfo && <HeaderPreloader />} */}

      
        <div>
          <div
            className="fixed artist-cover filter-blur"
            style={{
              backgroundImage: `linear-gradient(rgba(94, 94, 94, 0) 0%, rgba(15, 15, 15, 0.64) 0%),url("${configuration.FILE_STORAGE_LINK}${props.songData.owner.headerImg}")`,
            }}
          ></div>
          <div className="artist-header">
            <div className="row">
              <div className="col-7 col-sm-5 col-md-4  mt-5 col-lg-3">
                <div className="cover-single">
                  <div className="cover-wrapper-single" style={{backgroundColor:`${props.songData.color_name}`,border:`1px solid ${props.songData.color_name}`}}>
                    <img src={cover} alt="" onError={handleError}  />
                  </div>
                  {(owner)&&(<div className="upload-dropzone" {...getRootProps()}>
                  <input {...getInputProps()} multipe={false} />
                     <PencilIcon/></div>)}
                  
                </div>
              </div>
              <div className="col-auto">
                <div>
                  <div
                    className="playlist-title-single mt-4"
                    style={{ color: "#fff" }}
                  >
                    {" "}
                    <ContentEditable
                      disabled={!owner}
                      html={currentTitle}
                      tagName='span'
                      onChange={handleTitle}
                    /> {owner&&(<PencilIcon/>)}
                  </div>
                  <Link to={`/a/${props.songData.owner.username.replaceAll(' ','-').toLowerCase()}`}>
                  <div
                    className="panel-title "
                    style={{ color: "#fff", fontSize: "20px" }}
                  >
                    {" "}
                    {props.songData.username}
                  </div>
                  </Link>
                  <div
                    className="playlist-desc d-flex"
                    style={{ color: "#fff" }}
                  >
                    <div
                      className="px-4 py-1 mx-1 btn-single"title="play"
                      onClick={(e) => {
                        e.preventDefault();
                        // let recommended;
                        // if(data){
                        //   recommended =data.RecommendedMusic.map((song)=>( {name:song.title,
                        //     singer: song.username,
                        //     cover: `${configuration.FILE_STORAGE_LINK}${song.thumbnail}`,
                        //     musicSrc: `${configuration.READFILE_LINK}${song.file}`,
                        //     color:song.color_name}))
                        // }
                        dispatch(
                          playSingle({
                            value: [currentSong],
                          })
                        );
                        dispatch(setMusicId({ value: props.songData.topicId}))
                      
                      }}
                    >
                      <PlayIcon /> Play
                    </div>
                    <LikeMusic music_id={props.songData.topicId}>
                    <div className="px-4 btn-single py-1 mx-1" title="Like" onClick={()=>{if(localStorage.getItem("token"))
                     { setLike(!like)}}}>
                      <HeartIcon  musicId={props.songData.topicId} like={like}/> {like ?"Unlike":"Like"}
                    </div></LikeMusic>
                    <div
                      className="px-4 py-1 mx-1 btn-single"
                      title="Share"
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(setsharedUrl(`${window.location.href}`));
                      }}
                    >
                      <ShareButton /> Share
                    </div>
                  </div>
                  <div className="mt-1 text-white text-bold"> {props.songData.playlistNo} Playlist {props.songData.albumNo} Albums</div>
                  
                  {user &&( <div className="mt-2 add-to-playlist font-weight-bold d-flex"  title="Add Song To Playlist" >  <span className="mr-2" onClick={(e:any)=>{
                        e.preventDefault()
                        dispatch(addMusic({
                            music_artist:props.songData.user_id,
                            music_file:props.songData.musicId,
                            music_thumbnail:props.songData.cover_id
                        }))
                         dispatch(setPlaylistState(false))
                        dispatch(playlist())
                    }}>
                      <AddPlaylistIcon/>
                       </span><div id="form-input">
                   {owner &&( <div className="input-group-component d-flex mt-1" style={{width:"150%"}} >
                        {/* <CategoryIcon /> */}
                     <select id="category" className="form-control" onChange={handleColor}>
                          
                          {categoryData && (categoryData.AllCategory.map((singlePlaylist: any) => {
                                          return (<option selected={color ===singlePlaylist.id ?"Selected":""} value={singlePlaylist.id}>{singlePlaylist.title}</option>)
                                      }))}
                            
                          </select> 
                    </div>)}  
                    
                </div></div>)}


                 
                </div>
              </div>
            </div>
            
                   
                   {((color!==props.songData.color)||file||(currentLyrics!==props.songData.lyrics)||(currentTitle!==props.songData.title))&&(<div className="apply-edit-btn" onClick={handleUpdate}>Save Changes</div>)} 
          </div>
        </div>
     
      <div className="row" style={{ marginLeft: 0, marginRight: 0 }}>
        <div className="col-8">
          <h4
            className="ml-4 mt-3"
            style={{ color: "#fff", fontWeight: "bold" }}
          >
            Lyrics {owner&&( <span onClick={(e)=>{
              e.preventDefault()
              setEdit(!edit)
            }}><PencilIcon/></span>)}
          </h4>
        </div>
      </div>

      <div className="row mt-3" style={{ marginLeft: 0, marginRight: 0 }}>
        <div className="col-8 ml-3 " style={{ color: "#fff" }}>
          { owner&&edit && <div> <Editor
         onInit={(evt, editor) => {editorRef.current = editor}}
         value={currentLyrics}
         init={{
           height: 500,
           menubar: false,
           skin: 'oxide-dark',
           content_css: 'dark',
           plugins: [
             'advlist autolink lists link image charmap print preview anchor',
             'searchreplace visualblocks code fullscreen',
             'insertdatetime media table paste code help wordcount formatpainter'
           ],
           toolbar: 'undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment | footnotes | mergetags',
           
         }}
         onEditorChange={(newValue, editor) => {
          setCurrentLyrics(newValue);
          setText(editor.getContent({format: 'text'}));
          
        }}
       /></div>}
       <div id="lyrics">
        {!edit && 
        <ContentEditable disabled={true} html={currentLyrics} onChange={handleLyrics} />}
        </div>
         
        </div>
        <div className="col-sm-12 col-md-12 ml-md-4 ml-sm-4 col-lg-3">
          
            <div className="row song-owner py-2">
              <div className="col-3 col-md-2 col-sm-3 col-lg-5">
                <div className="artist"  title={props.songData.owner.username}>
                  <div className="artist-wrapper">
                    <div>
                      <img
                      style={{height:"100%"}}
                     
                        src={`${configuration.FILE_STORAGE_LINK}${props.songData.owner.profileImg}`}
                        alt=""
                        className="artist-img"
                        loading="lazy"
                        aria-hidden="false"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 mt-lg-2 mt-md-3 mt-sm-3">
                <Link to={`/a/${props.songData.owner.username.replaceAll(' ','-').toLowerCase()}`}>
                  {" "}
                  <div className="artist-name ">
                    {props.songData.owner.username}
                  </div>
                </Link>
                <div className="artist-title">Artist</div>
              </div>
            </div>
          
        </div>
      </div>
      <div className="px-3 mb-5">
      
        <NewMusic searchResult={false} name={`More Like ${props.songData.title}`} songId={props.songData.messageId}></NewMusic>
        <RecommededArtists name={`More Artists Like ${props.songData.owner.username}`}  searchResult={false} userId={props.songData.owner.userId}/>
        <AllPlaylist searchResult={false} />
        <AllAlbums searchResult={false} />
      </div>
    </div>
    
    </div>
  );
};

export default SingleSongProfile;