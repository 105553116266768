// @ts-nocheck
import React, { useState,useEffect } from 'react'

import { useAppSelector, useAppDispatch } from "../../../redux/hooks"

import { Link,NavLink,useHistory, useLocation } from "react-router-dom"
import axios from 'axios'
import { setIsMini } from '../../../redux/data/uiData'

import UploadMusicIcon from '../sidebar/src/UploadMusicIcon'
import Logout from "./src/Logout"
import Contribute from "./src/Contribute"
import SearchIcon from './src/SearchIcon'
import MobileNavBarIcon from './src/MobileNavBarIcon'
import BackWardIcon from "./src/BackWardIcon"
import Setting from '../sidebar/src/Setting'


import HomeSimple from "../sidebar/src/HomeSimple"
import Favorite from "../sidebar/src/Favorite"
import Heart from "../sidebar/src/Heart"
import FolderPlus from '../sidebar/src/folderPlus'
import DoubleMusicNote from '../sidebar/src/DoubleMusicNote'


import SideBarElement from './SideBarElement'

import { callUpdateFunction } from '../../../redux/data/uiData'

import { useQuery, gql,} from '@apollo/client'

import "./index.scss"
import "./topNavBar.scss"
import "./sidebar.scss"
import "../sidebar/index.scss"

import configuration from '../../../config/configuration'
import LoginSideBarElement from '../sidebar/LoginSideBarElement'
import AddMusicIcon from '../sidebar/src/AddMusicIcon'
import AllMusicIcon from '../sidebar/src/AllMusicIcon'
import NotificationContainer from './src/NotificationContainer'
import NotificationIcon from '../../../pages/musicianDashboard/src/NotificationIcon'
import ColorSideBarElement from '../sidebar/ColorSidebarElement'
import { setIsThumbnail } from '../../../redux/data/uiData'
import Mini from './src/mini'
import Max from './src/max'
import List from './src/List'
import Grid from './src/grid'


const MusicNavBar:React.FC<{data:any}> = ({data}) => {

    const { updateBtn,isMini,isThumbnail } = useAppSelector(state => state.uiData)
    // moblie menu clicked
    const [mobile, setmobile] = useState(false)
    const user=localStorage.getItem("token")
    const isUser=parseInt(localStorage.getItem("userId") as string)
    //history hook
    const history = useHistory()
    const location = useLocation();

    //dispatch hook
    const dispatch = useAppDispatch()

    const QUERY = gql`query{
    userInfo(userId:${localStorage.getItem("userId")}){
        profileImg
        username
    }
}`
    //apllo clinet setup
    const {  data: currentData } = useQuery(QUERY/*, { pollInterval: 500 }*/)

      const countNotification=()=>{
        let count = 0;

       for (let i = 0; i < data.UserAuth.usernotification.length; i++) {
            if (!data.UserAuth.usernotification[i].seen) {
            count++;
         }
          }
          return count
      }

    return (
        <div>
            <div className="d-flex justify-content-between" id="music-nav-bar">
            <div className="minimizer d-flex align-items-center justify-content-center shadow-lg "  onClick={()=>{
                         dispatch(setIsMini())
                    }}
                    style={{
                        position: "absolute",
                        width: "40px",
                        height: "40px",
                         backgroundColor: "#0f0f0f",
                         padding: "10px",
                         borderRadius: "50%",
                        left:"5px",
                         top:"15px"
                    }}
                    >
                        {isMini ? <Mini/>:<Max/>}
                        </div>
                <form action="">
                    
                </form>
                <div className="user-button d-flex">
                    {( data && user&&!data.UserAuth.isAdmin&&!data.UserAuth.isContributor &&location.pathname !=="/music/becomeContributor") && (
                        <Link to="/music/becomeContributor">
                            <div className="become-contributor-btn d-flex justify-content-between">
                                <Contribute />
                                Become Contributor
                            </div></Link>)}
                           
                           {location.pathname==="/music/allmusic" ||location.pathname==="/music/searchResult/music" ? (
                            <div className='text-white mr-3 ' onClick={()=>{
                                dispatch(setIsThumbnail())
                            }}> {isThumbnail ? (<List />):(<Grid/>)}</div>
                           ):""}
                        
                        
                        {currentData && (<> <NotificationContainer notification={data?.UserAuth.usernotification}/>
                        {currentData.userInfo.profileImg ? (<Link to={data?.UserAuth.isContributor?`/a/${currentData.userInfo.username.replaceAll(" ","-").toLowerCase()}`:(`/a/${currentData.userInfo.username.replaceAll(" ","-").toLowerCase()}`)}>
                            <div className="rounded-circle " style={{ backgroundImage: `url("${configuration.FILE_STORAGE_LINK}${currentData.userInfo.profileImg}")` }}>
                                </div>
                                </Link>) : (<Link to={ data?.UserAuth.isContributor?`/a/${currentData.userInfo.username.replaceAll(" ","-")}`:`/a/${currentData.userInfo.username.replaceAll(" ","-").toLowerCase()}`}>
                                    <div className="rounded-circle " style={{ backgroundImage: `url("https://leerecs.net/backend/storage/app/attachments/thumb_avatar-default-image.jpg")` }}></div>
                                    </Link>
                                    )}</>)}
                    
                    <div className="view-public-profile logout">
                      
                        {updateBtn && currentData &&(location.pathname ===`/a/${currentData.userInfo.username.replaceAll(' ','-').toLowerCase()}`||data?.UserAuth.isAdmin) && (<div className="edit-profile-btn" onClick={(e)=>{
                            e.preventDefault()
                            dispatch(callUpdateFunction())
                        }} >Update Profile</div>)}
                        {((data?.UserAuth.isContributor)&&currentData &&(location.pathname !==`/a/${currentData.userInfo.username.replaceAll(' ','-').toLowerCase()}`))&&  (
                        <Link className='view-profile' to={`/a/${currentData.userInfo.username.replaceAll(" ","-").toLowerCase()}`}>
                            <div style={{marginBottom:'5px'}}>{currentData.userInfo.username} </div>
                            <div className='view-profile-text' style={{color:"#d9d9d9"}}>View Public Profile</div>
                            </Link>
                        )}
                    </div>
                </div>
            </div>

            <div id="top-mobile-nav-bar" className={`${mobile ?"nav-height":""}`} >
                <div className="row mx-0 d-flex justify-content-between the-nav-bar-wrapper">
                    <Link to="/music/searchResult">
                        <SearchIcon />
                    </Link>
                    <Link to="/">
                    <img src="https://d2vf2lffk4redv.cloudfront.net/3e64483b-8097-4cad-bc57-0f33018c7293.jfif" alt="" className="logo" />
                    </Link>
                    <div onClick={(e) => {
                        e.preventDefault()
                        setmobile(!mobile)
                    }} className="relative">
                        {data&&countNotification()!==0 &&(<div className='active-note'/>)}
                        
                        <MobileNavBarIcon />
                    </div>
                </div>
                {(location.pathname === "/music/searchResult/") && (<div id="searchBarNavigator">
                    <div className="row mx-0 d-flex justify-content-between the-nav-bar-wrapper">
                        <div onClick={(e) => {
                            e.preventDefault()
                            history.goBack()
                        }}>
                            <BackWardIcon />
                        </div>
                        <form action="">
                           
                        </form>
                        <div onClick={(e) => {
                            e.preventDefault()
                            setmobile(!mobile)
                        }}>
                            <MobileNavBarIcon />
                        </div>
                    </div>
                </div>)}
                {mobile && (
                    <div className="navigator">
                        <div className="cancel" onClick={(e) => {
                            e.preventDefault()
                            setmobile(!mobile)
                        }}></div>
                        <div className="actions">
                            <div className="d-flex">
                                <Link to={data?.UserAuth.isContributor?`/a/${currentData.userInfo.username.replaceAll(" ","-").toLowerCase()}`:('#')} onClick={(e)=>{setmobile(!mobile)}}>
                                    {currentData && (currentData.userInfo.profileImg ? (<div className="rounded-circle" style={{ backgroundImage: `url("${configuration.FILE_STORAGE_LINK}${currentData.userInfo.profileImg}")` }}></div>) : (<div className="rounded-circle" style={{ backgroundImage: `url("https://d2vf2lffk4redv.cloudfront.net/3e64483b-8097-4cad-bc57-0f33018c7293.jfif")` }}></div>))}
                                </Link>
                                {currentData && (<div className="user-name">{currentData.userInfo.username}</div>)}
                            </div>
                            {(user&&!data?.UserAuth.isContributor && location.pathname !== "/music/becomeContributor") && (
                                <Link to="/music/becomeContributor" onClick={(e)=>{setmobile(!mobile)}}>
                                    <div className="become-contributor-btn">
                                        <div className="d-flex">
                                            <Contribute />
                                            <div className="text">Become Contributors</div>
                                        </div>
                                    </div></Link>)}
                            <div className="action-bntns">
                            {isUser ?(<div className="button logout mt-3" >
                        <LoginSideBarElement title="Sign Out">
                        <Logout />
                                </LoginSideBarElement>
                    </div>):(<div className="button logout" >
                        
                         <LoginSideBarElement title="Sign In">
                        <Logout />
                                </LoginSideBarElement>
                        
                    </div>)}
                                <h3>Main</h3>
                                {isUser&&data?.UserAuth.isContributor ?(<NavLink to="/music/addMusic">
                    <div className='sidebar-upload-btn'>
                    <SideBarElement title="Upload Music">
                        <UploadMusicIcon />
                    </SideBarElement>
                    </div>
                </NavLink>):""}
                {isUser ?(
                        <a href={`https://admin.leerecs.com/index.php?action=profile;area=summary;u=${isUser}`}>
                        <SideBarElement title="Account Setting">
                                    <Setting/>
                                </SideBarElement>
                                </a>
                    ):""}
                                {user&&data?.UserAuth.isContributor ? (<div>
                                    <NavLink to="/music" onClick={(e)=>{setmobile(!mobile)}}>
                                        <SideBarElement title="Index">
                                            <HomeSimple />
                                        </SideBarElement>
                                    </NavLink>
                                    <NavLink to="/music/likedSongs" onClick={(e)=>{setmobile(!mobile)}}>
                                        <SideBarElement title="Liked Songs">
                                            <Heart />
                                        </SideBarElement>
                                    </NavLink>
                                    <NavLink to="/music/history" onClick={(e)=>{setmobile(!mobile)}}>
                                        <SideBarElement title="Recently Played">
                                            <Favorite />
                                        </SideBarElement>
                                    </NavLink>
                                    <NavLink to="/music/notification" onClick={(e)=>{setmobile(!mobile)}}>
                                        <SideBarElement title="Notification">
                                            <NotificationIcon/>
                                            {data && countNotification()!==0 &&(<div className='text-white note'>{countNotification()}</div>)}
                                        </SideBarElement>
                                    </NavLink>
                                </div>) :
                                    (<div>
                                        <NavLink to="/music" onClick={(e)=>{setmobile(!mobile)}}>
                                            <SideBarElement title="Index">
                                                <HomeSimple />
                                            </SideBarElement>
                                        </NavLink>
                                        <NavLink to="/music/searchResult/" onClick={(e)=>{setmobile(!mobile)}}>
                                            <SideBarElement title="Search">
                                            <SearchIcon />
                                            </SideBarElement>
                                        </NavLink>
                                        {user &&(<NavLink to="/music/notification" onClick={(e)=>{setmobile(!mobile)}}>
                                        <SideBarElement title="Notification">
                                            <NotificationIcon/>
                                            {data && countNotification()!==0 &&(<div className='text-white note'>{countNotification()}</div>)}
                                            
                                        </SideBarElement>
                                    </NavLink>)}
                                    </div>)}
                                {user&&data?.UserAuth.isContributor  && (
                                    <div>
                                        <h3 className="authetication">Musician Actions</h3>
                                        <NavLink to="/music/addMusic" onClick={(e)=>{setmobile(!mobile)}}>
                                            <SideBarElement title="Add Music">
                                                <AddMusicIcon />
                                            </SideBarElement>
                                        </NavLink>
                                        <SideBarElement title="Create a New Playlist" >
                                                <FolderPlus />
                                            </SideBarElement>
                                        <NavLink to="/music/mymusic" onClick={(e)=>{setmobile(!mobile)}}>
                                            <SideBarElement title="My Music">
                                                <AllMusicIcon />
                                            </SideBarElement>
                                        </NavLink>
                                    </div>
                                )}
                                <h3 className="authetication">Listen By Colour</h3>
                                <div className="d-flex w-100 colors-element">
                                {data && (data.UserAuth.color.map((color:any,idx)=>{
                 return(
                    <NavLink key={idx} to={`/music/colour/${color.title.toLowerCase()}`} onClick={(e)=>{setmobile(!mobile)}}>
                            <ColorSideBarElement title={color.title}>
                                <DoubleMusicNote color={color.title} />

                            </ColorSideBarElement>
                        </NavLink>
                 )
                }))}
                </div>

                                
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default MusicNavBar
