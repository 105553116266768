import React, { useState } from 'react'
import "./index.scss"
import ClosePanelIcon from './src/ClosePanelIcon'

// redux functionality
import {useAppDispatch,useAppSelector} from "../../../redux/hooks"
import {setDeletePlaylist} from "../../../redux/data/uiData"

// apollo client
import {gql,useMutation} from "@apollo/client"

import Loading from '../../loading'
import Error from '../../error'
import Sucess from '../../sucess'
import axios from 'axios'
import configuration from '../../../config/configuration'
import { readBuilderProgram } from 'typescript'


export default function DeletePlaylist() {
    const dispatch = useAppDispatch()
    const [loading,setLoading]=useState(false)
    const[status,setStatus]=useState(false)
    const[error,setError]=useState()
    const[close,setClose]=useState(false)
    const {currentPlaylist} = useAppSelector(state => state.uiData)
    const MUTATION = gql`mutation($playlistId:Int!)
    {
        DeletePlaylist(playlistId: $playlistId,){
            desc
        }
    }`
     const [DeletePlaylist, { loading:DeletePlaylistLoading, data, error:DeletePlaylistError }] = useMutation(MUTATION, { errorPolicy: "all" })
    
       const { assignData } = useAppSelector((state) => state.assignMusic);
    
    const value = (id: string) => {
        var inputValue = (document.getElementById(id) as HTMLInputElement).value
        return inputValue;
    };
    
    const HandleDelete = async (e:any)=>{
        e.preventDefault()
        
            setLoading(true)
            setClose(true)
           
            const result =  DeletePlaylist({
                variables: {
                    playlistId:currentPlaylist,
                   
                }
            }).then(function (response) {
                console.log(response)
                setLoading(false) 
                setStatus(true)
                
            })
            .catch(function (error) {
                setLoading(false);
                if(error.response){
                  setError(error.response.data.message);
                }
                setStatus(false);
              });
       
       
    }
   


    return (
        <div id="claim-music">
            <div className="panel">
                <div className="d-flex justify-content-between top-button">
                    <div className="title">Delete Playlist</div>
                    <div onClick={(e)=>{
                        e.preventDefault()
                        dispatch(setDeletePlaylist())
                    }}>
                        <ClosePanelIcon />
                    </div>
                </div>
                {close&&loading && <Loading></Loading>}
                {close&&status&& <Sucess text="Deleted successfully" close={setClose}></Sucess>}
                { close&&error && !status&&  <Error error={error} close={setClose}></Error>}
                
                <div className="prerequist">
                Are you sure you want to delete this playlist
                    </div>
                {/* <InputForm placeholder="User username" icon={<TitleIcon />} id="real_name" type="text" /> */}
                <div style={{ width:'100%' }}>
        
                 </div>

                
                <div className="claim-music-btn mt-2" onClick={HandleDelete}>
                      Delete 
                </div>
                <div className="claim-music-btn mt-2 " style={{backgroundColor:"#007bff"}} onClick={(e)=>{
                        e.preventDefault()
                        dispatch(setDeletePlaylist())
                    }}>
                      Cancel
                </div>
            </div>
        </div>
    )
}
