import React from 'react';

export default function ShareIcon() {
  return <div>
      <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
          <g id="Group_306" data-name="Group 306" transform="translate(-1256 -123)">
              <circle id="Ellipse_19" data-name="Ellipse 19" cx="30" cy="30" r="30" transform="translate(1256 123)" fill="#10f88b" />
              <circle id="Ellipse_55" data-name="Ellipse 55" cx="15" cy="15" r="15" transform="translate(1271 138)" fill="#fff" />
              <g id="share" transform="translate(1277 144.36)">
                  <g id="Group_307" data-name="Group 307" transform="translate(3 2)">
                      <path id="Path_239" data-name="Path 239" d="M18,6a1,1,0,1,0-1-1A1,1,0,0,0,18,6Z" transform="translate(-9 -2)" fill="#10f88b" opacity="0.3" />
                      <path id="Path_240" data-name="Path 240" d="M6,13a1,1,0,1,0-1-1A1,1,0,0,0,6,13Z" transform="translate(-3 -5.335)" fill="#10f88b" opacity="0.3" />
                      <path id="Path_241" data-name="Path 241" d="M18,20.02a1,1,0,1,0-1-1A1,1,0,0,0,18,20.02Z" transform="translate(-9 -8.64)" fill="#10f88b" opacity="0.3" />
                      <path id="Path_242" data-name="Path 242" d="M13,11.387a1.941,1.941,0,0,0-1.307.513L6.94,9.133A2.182,2.182,0,0,0,7,8.667,2.182,2.182,0,0,0,6.94,8.2l4.7-2.74A2,2,0,1,0,11,4a2.182,2.182,0,0,0,.06.467l-4.7,2.74a2,2,0,1,0,0,2.92L11.107,12.9a1.881,1.881,0,0,0-.053.433A1.947,1.947,0,1,0,13,11.387Zm0-8.053A.667.667,0,1,1,12.333,4,.669.669,0,0,1,13,3.333Zm-8,6a.667.667,0,1,1,.667-.667A.669.669,0,0,1,5,9.333Zm8,4.68a.667.667,0,1,1,.667-.667A.669.669,0,0,1,13,14.013Z" transform="translate(-3 -2)" fill="#10f88b" />
                  </g>
              </g>
          </g>
      </svg>

  </div>;
}
