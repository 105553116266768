
//@ts-nocheck
import React, { useState,useEffect } from 'react'

//helpers
import { gql, useMutation, useQuery } from "@apollo/client"

//components
import InputForm from "../../formInput/index"
import ProgressBanner from '../addMusic/ProgressBanner'
import Loader from '../../signup/src/icons/loader'
import CloudErrorComponent from '../../signup/src/CloudErrorComponent'
import Done from '../../sucess/icons/done_all'

//icon
import TitleIcon from "./src/TitleIcon"
import CategoryIcon from "./src/CategoryIcon"
import TagIcon from "./src/TagIcon"
import DecriptionIcon from "./src/DescriptionIcon"
import MusicFileIcon from "./src/MusicFileIcon"
import ImageIcon from "./src/ImageIcon"

//info icons
import Sucess from "../../sucess/index"
import Loading from "../../loading/index"
import Error from "../../error/index"


import { MultipleFileUpload, useUpload } from "../../../genericComponents/fileUploud/MultipleUploads"

import "./index.scss"

const AddMusic = () => {
    //state values
    const [uploading, setuploading] = useState(false)
    const [songs,setSongs]=useState([])


    const [{ upload: uploadMusic, setfiles: setMusicFile }, { loading: uplaodingMusic, error: uploadMusicError }] = useUpload("music-file")

    //loading info
    const[addingstatus,setAddingStatus]=useState(false)
    const [message,setMessage]=useState("loading")
     const [color,setColor]=useState("#7171ff")
     const [width,setWidth]=useState("10%")
     const [btn,setbtn]=useState(false);
     const [icon,setIcon]=useState(<Loader/>)
     const [status,setStatus]=useState(null);

    //function to handle submit
    const handleSubmit = async (e: any) => {
        e.preventDefault();
          try {
            setAddingStatus(true)
        setbtn(true)
 
        //intializing
        setStatus(null)
        setMessage("loading ...")
        setColor("#7171ff")
        setWidth("10%")
        setIcon(<Loader/>)
        setTimeout(() => {
            
        },100);
            setuploading(!uploading)
           setMessage("Uploading Music")
            console.log("uploading")
            const musicId = await uploadMusic("audio")
            if(musicId){
                console.log(musicId)
            setIcon(<Done/>) 
            setStatus(true)
            setbtn(false)
            setWidth("100%")
            setColor("#5DD97C")
            setMessage("You have succesfully added  music")}
            else{
                setColor("#FF7171")
                if(uploadMusicError && uploadMusicError !=message){
                setMessage(uploadMusicError)}
                setIcon(<CloudErrorComponent/>)
                setbtn(false)
            }
        }
         catch (e) {
            setuploading(false)
            setStatus(false)
            setColor("#FF7171")
                setMessage(e.message)
                setbtn(false)
                setIcon(<CloudErrorComponent/>)
        }
    }

    useEffect(()=>{
        if(uploadMusicError){
            setColor("#FF7171")
            setMessage(uploadMusicError)
            setIcon(<CloudErrorComponent/>)
        }
       },[uploadMusicError])
    return (
        <div id="add-music">
            {addingstatus &&(<div className="col-10 mx-auto mt-2 loaders">
                <ProgressBanner close={setAddingStatus} message={message} width={width} color={color} icon={icon} status={status}/>
            </div>)}
            <h3>Manage music {">"} Add music</h3>
            <form action="" onSubmit={handleSubmit} autoComplete="off">
                <div className="d-md-flex justify-content-between top-header">
                    <div>
                        <h1>Upload Multiple Music</h1>
                        <h6>Add Music and music description</h6>
                    </div>
                    {/* <div className="col-md-6 px-0">
                        {(uplaodingMusic && !uploadMusicError ) && <Loading text={"Uploading Music"} />}
                        {(status && !uploadMusicError && !uplaodingMusic) && <Sucess text="You have succesfully added a music"></Sucess>}
                        {uploadMusicError && <Error error={uploadMusicError}></Error>}
                        
                    </div> */}
                    <button type="submit" className="btn add-music" disabled={btn}>Upload</button>
                </div>
                <div className="row mx-0" onSubmit={handleSubmit}>
                    
                    <div className="col-md-4 mx-auto">
                        {setMusicFile && <MultipleFileUpload setSongs={setSongs} fileId="music-file" acceptedFiles="audio/mp3, audio/mpeg" title="Music Files" text="Drag and drop an audio" rule1="Add high quality audio" rule2="Add music you have right to" icon={<MusicFileIcon></MusicFileIcon>} onDropHandler={setMusicFile} />}
                    </div>
                    
                    <button type="submit" className="btn add-music-mobile">Add Music</button>
                </div>
            </form>
        </div>
    )
}

export default AddMusic
