
import React,{useState} from 'react'
import "./index.scss"
import ClosePanelIcon from './src/ClosePanelIcon'
// redux functionality
import {useAppDispatch,useAppSelector} from "../../../redux/hooks"
import {setShowFriends} from "../../../redux/data/uiData"
import configuration from '../../../config/configuration'
import { Link } from 'react-router-dom'


// apollo client
import {gql,useQuery} from "@apollo/client"
import AddBuddie from '../../wrappers/AddBuddie'
import FollowIcon from '../allMusicByArtist/src/FollowIcon'
import BeFriend from './src/BeFriend'



export default function Friends() {
    const dispatch = useAppDispatch()
    const [beFriendStatus,setBeFriendStatus] =useState("Befriend")
    
    const QUERY= gql`query{
        AllUserFriends(userId:${localStorage.getItem('userId')}){
           name
           picture
           buddie_list
           userId
            
        }
        }`
    const { loading, data, error } = useQuery(QUERY/*, { pollInterval: 500 }*/)  
    const BefriendAction =(action: string)=>{
      // console.log(action)
      if(action==="Loading"){
          if(beFriendStatus==="Befriend"){
          setBeFriendStatus(" Unfriend");}
          else{
              setBeFriendStatus(" Befriend")
          }
      }
//  setBeFriendStatus(status());


  }
    return (
        <div id="claim-music">
            <div className="profilePanel">
                <div className="d-flex justify-content-between top-button">
                    <div className="title">Your Friends</div>
                    <div onClick={(e)=>{
                        e.preventDefault()
                        dispatch(setShowFriends())
                    }}>
                        <ClosePanelIcon />
                    </div>
                </div>
               
                <div className='friend_list'>
                    {data&&data.AllUserFriends.length===0&&(
                        <div className='text-center text-white'>You have no friends yet</div>
                    )}
                    {data&&data.AllUserFriends.map((user:any,index:number)=>{
                  return ( <div className="row mt-2">
                    <div className="col-1 py-2 text-white">{index+1}</div>
                    <div className="col-2">
                  <div className="artist"  title={user.name}>
                    <div className="artist-wrapper">
                      <div>
                        <img
                        style={{height:"100%"}}
                       
                          src={`${configuration.FILE_STORAGE_LINK}${user.picture}`}
                          alt=""
                          className="artist-img"
                          loading="lazy"
                          aria-hidden="false"
                        />
                      </div>
                    </div>
                  </div></div> <div className="col-4"> <Link to={`/a/${user.name.replaceAll(' ','-').toLowerCase()}`}
                  onClick={(e)=>{
                    dispatch(setShowFriends())
                  }}
                  > <div className='friend-name py-2'>{user.name}</div></Link>
                  </div> <div className='col-5'><BeFriend userId={user.userId} buddie_list={user.buddie_list}/></div></div>
                  )
                    })}
                    
                </div>
                
            </div>
        </div>
    )
}
