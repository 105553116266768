import React, { useEffect } from "react";

//redux hooks
import { useAppDispatch } from "../../../redux/hooks";
import { Link } from "react-router-dom";

//wrapper
import LikeMusic from "../../wrappers/likeMusic";
import Player from "../../../pages/main/components/eventsSection/src/Player";
import { useQuery, gql } from "@apollo/client";
import configuration from "../../../config/configuration";

//iicons
import AddPlaylistIcon from "./src/AddPlaylistIcon";
import Heartcon from "./src/Heartcon";

//redux actions
import { playSingle, setMusicId } from "../../../redux/data/musicPlayer/index";
import { setsharedUrl } from "../../../redux/data/artistProfile";
import CardShareIcon from "../featuredArtists/CardShareIcon";
import { addMusic } from "../../../redux/data/playlist/index";
import { playlist } from "../../../redux/data/uiData/index";

interface props {
  title: string;
  cover: string;
  count: Number;
  albumId: Number;
  username: string;
  songs:any;
}

const MusicElement: React.FC<props> = (props) => {
  const dispatch = useAppDispatch();
  const handleError = (event:any) => {
    event.target.src = '/assets/images/thumb_avatar-default-image.jpg'; // Replace with your default image URL
};
  return (
    <div className="col-md-3 px-2  card  music-e-card  shadow-sm">
      {/* <Link to={`/music/album/${props.albumId}`}> */}
      <div className="cover">
        <div className="cover-wrapper">
          <div>
            <img src={props.cover} alt="" aria-hidden="false" loading="lazy" onError={handleError}  />
          </div>
        </div>
         {
          props.songs&& props.songs.length>0 &&(<div className="music-play-btn" onClick={(e) => {
            e.preventDefault()
            // console.log(props.songs)
                dispatch(playSingle({
                    value: props.songs
                }))
           
        }}>
<Player />
</div>)
         }
        
      </div>
      <Link to={`/music/album/${props.title.toLowerCase().replaceAll(" ","-")}`}>
      <div className="song-name mt-2">{props.title}</div>
      </Link>
      <Link to={`/a/${props.username.replaceAll(' ','-').toLowerCase()}`}>
        <div className="numbering mb-1 mt-1">By {props.username}</div>
      </Link>

      <div className='card-share-btn' onClick={(e) => {
                e.preventDefault()
                
                dispatch(setsharedUrl(
                    `https://leerecs.com/music/album/${props.title.toLowerCase().replaceAll(" ","-")}`
                  ))
            }}
                    ><CardShareIcon></CardShareIcon></div>
    </div>
  );
};

export default MusicElement;
