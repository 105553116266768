import React,{useState} from 'react';
import { ItemTypes } from '../../components/musicPageComponents/allMusicInAlbum/src/ItemTypes';
import AddBuddie from '../../components/wrappers/AddBuddie';
import generateDate from '../../helper/generateDate';
import { Link } from 'react-router-dom';
import VerifiedIcon from '../../components/musicPageComponents/allMusicByArtist/src/VerifiedIcon';
import HeartIcon from './src/HeartIcon';
import EyeIcon from './src/EyeIcon';
import FollowedIcon from './src/followedIcon';
import PlayIcon from './src/PlayIcon';

const NotificationCard = (props:any) => {
    const regex = /[?@\/#:\,;%+&=\{\}\[\]\^-]/g
    const [beFriendStatus,setBeFriendStatus] =useState(props.info.buddie_list.includes(localStorage.getItem("userId"))?" Unfriend":" Befriend")
    const BefriendAction =(action:any,status:any)=>{
        // console.log(action)
        if(action==="Loading"){
            if(beFriendStatus==="Befriend"){
            setBeFriendStatus(" Unfriending...");}
            else{
                setBeFriendStatus(" Befriending...")
            }
        }
//  setBeFriendStatus(status());
 if(action==="added"){
    // console.log(action)
    setBeFriendStatus(" Unfriend");

 }
 if(action==="removed"){
    // console.log(action)
    setBeFriendStatus(" Befriend");
 }

    }
    return (
        <div className='notification-card d-flex align-items-center px-2' style={{backgroundColor:`${!props.info.seen?"#282828":"#282828"}`}}>
            {!props.info.seen && (<div className='active mr-2'></div>)}
            {props.info.seen && (<div className='active mr-2' style={{backgroundColor:"transparent"}}></div>)}
            
        <div className="notification-image">
            <div className="artist-wrapper">
                {props.info.is_verified && (<div className='verified'><VerifiedIcon/></div>)}
                
                <div>
                    <img src={props.image} alt="" />
                </div>

            </div>
        </div>
        <div className="card-message ml-3">
            {props.info.is_verified &&(<Link to={`/a/${props.info.username.replaceAll(" ","-").toLowerCase()}`}>
            <span className='highlight'>{props.info.username}</span> </Link>)}
            {!props.info.is_verified &&(<Link to={`/a/${props.info.username.replaceAll(" ","-").toLowerCase()}`}>
            <span className='highlight'>{props.info.username}</span> </Link>)}
            
            {props.info.message.includes("</span>")&& (props.info.message.split("</span>")[1])}
            {!props.info.message.includes("</span>")&& (props.info.message)}
            <div className='date'>
                {generateDate(props.info.date)}
            {/* {props.info.type==="like"&&(<Link to={`/a/${props.info.owner_name.replaceAll(" ","-").toLowerCase()}/s/${props.info.music.toLowerCase().replaceAll(regex,'').replaceAll(" ","-")}`}><button>View</button></Link>)}
           {props.info.type==="play"&&(<button>View</button>)}
           {props.info.type==="friend"&&(
            <AddBuddie myBuddieId={props.info.user_id} Beaction={BefriendAction}>
            <div className='d-flex button'>
                 <span> {beFriendStatus}</span>
            </div>
            </AddBuddie>
           )} */}

            </div>
            {/* <div className=''>
            {props.info.type==="like"&&(<Link to={`/a/${props.info.owner_name.replaceAll(" ","-").toLowerCase()}/s/${props.info.music.toLowerCase().replaceAll(regex,'').replaceAll(" ","-")}`}><button>View</button></Link>)}
           {props.info.type==="play"&&(<button>View</button>)}
          
            </div> */}

        </div>
        {props.info.type==="like" && (
        <Link to={`/a/${props.info.owner_name.replaceAll(" ","-").toLowerCase()}/s/${props.info.music.toLowerCase().replaceAll(regex,'').replaceAll(" ","-")}`}>
        <div className='notification-type d-flex justify-content-center align-items-center' style={{backgroundColor:"#F8107D"}}>
        <HeartIcon/>
        </div>
        </Link>
        ) }
        {props.info.type==="view" && (
        <Link to={`/a/${props.info.owner_name}`}>
        <div className='notification-type d-flex justify-content-center align-items-center' style={{backgroundColor:"rgb(122,57,237,1)"}}>
        <EyeIcon/>
        </div>
        </Link>
        ) }
        {props.info.type==="play" && (
        <Link to={`/a/${props.info.owner_name.replaceAll(" ","-").toLowerCase()}/s/${props.info.music.toLowerCase().replaceAll(regex,'').replaceAll(" ","-")}`}>
        <div className='notification-type d-flex justify-content-center align-items-center' style={{backgroundColor:"rgb(245,158,12,1)"}}>
        <PlayIcon/>
        </div>
        </Link>
        ) }
        {props.info.type==="new" && (
        <Link to={`/a/${props.info.username.replaceAll(" ","-").toLowerCase()}/s/${props.info.music.toLowerCase().replaceAll(regex,'').replaceAll(" ","-")}`}>
        <div className='notification-type d-flex justify-content-center align-items-center' style={{backgroundColor:"rgb(245,158,12,1)"}}>
        <PlayIcon/>
        </div>
        </Link>
        ) }
        {props.info.type==="friend" && (
        <Link to={`/a/${props.info.owner_name}`}>
        <div className='notification-type d-flex justify-content-center align-items-center' style={{backgroundColor:"rgb(33,76,162,1)"}}>
        <FollowedIcon/>
        </div>
        </Link>
        ) }
        
        </div>
    );
}

export default NotificationCard;
