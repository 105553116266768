// @ts-nocheck

import React,{useState,useEffect} from "react";

import "./index.scss";

import ArtistPlayButton from "./src/artistPlayButton";
import ArtistLikeButton from "./src/artistLikeButton";
import ArtistStoreButton from "./src/artistStoreButton";
import CardShareIcon from "../../../../components/musicPageComponents/featuredArtists/CardShareIcon";
//import GoogleAds from "../../../../components/googleAdds/GoogleAds";

import { useDispatch } from "react-redux";
import { playSingle } from "../../../../redux/data/musicPlayer/index";
import { setsharedUrl } from "../../../../redux/data/artistProfile/index";

import configuration from "../../../../config/configuration";

function ArtistCard({
  artistImage,
  artistTitle,
  artistBio,
  listOfMusic,
  musicUrl,
  podcast,
  data,
  podcastId,
}) {
  const [info, setinfo] = useState(null);
  const dispatch = useDispatch();
  useEffect(()=>{
    async function getMoviesFromApi() {
      try {
        let response = await fetch(
          
        );
        let responseJson = await response.json();
        // console.log("the info", responseJson);
        setinfo(responseJson.musicBio)
        //console.log(info)
      } catch (error) {
        // console.error(error);
      }
    }
    getMoviesFromApi()

  },[])

  return (
    <>
      <div id="playlist_info" style={{ paddingLeft: "15px" }}>
        <div className="row ">
          <div className="col-md-3 pd-l col-12 mr-5">
          <div
            className="   background-img"
            style={{
              backgroundImage: `url("${artistImage}")`,
            }}
          ></div>
          </div>
          <div className="col-md-7 music-details ml-auto">
            <div className="Playlist-title" style={{ color: "white" }}>
              {artistTitle}
            </div>
            <div className="Playlist-Description" style={{ color: "white" }}>
              {artistBio}
            </div>
            <div className="d-flex  btn-collection">
              <div
                className="btn-custom d-flex"
                style={{ color: "white", background: "#F8107D" }}
                
                onClick={(e) => {
                  e.preventDefault()
                  
                  dispatch(playSingle({
                    value: [podcast]
                }))
                }}
              >
                <div className="icon">
                  <ArtistPlayButton data={data} />
                </div>
                <div className="btn-title mx-2 mt-1 ">Play</div>
              </div>
              <div
                className="btn-custom d-flex mx-2"
                style={{ color: "white", background: data.darkVibrant }}
                onClick={() => {
                  dispatch(
                    setsharedUrl(
                      `https://leerecs.net/podcast/${podcastId?(podcastId):""}`
                    )
                  );
                }}
              >
                <div className="icon ">
                  <CardShareIcon />
                </div>
                <div className="btn-title mx-2 mt-1">Share</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ArtistCard;
