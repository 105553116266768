import React from 'react'

export default function MusicIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11.675" height="19.814" viewBox="0 0 11.675 19.814">
      <g id="music-2" transform="translate(0.75 0.75)">
        <path id="Path_87" data-name="Path 87" d="M12.087,16.227v3.052a2.035,2.035,0,0,1-2.035,2.035H9.035A2.035,2.035,0,0,1,7,19.279V18.262a2.035,2.035,0,0,1,2.035-2.035Zm0,0V8.087m0,0V4.017L17.175,3V7.07Z" transform="translate(-7 -3)" fill="none" stroke="#bfbfbf" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      </g>
    </svg>
  )
}
