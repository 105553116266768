import React from 'react'
import MusicSideBar from "../../components/musicPageComponents/sidebar/index"
import MusicNavBar from '../../components/musicPageComponents/navbar'
import AllLikedMusic from "../../components/musicPageComponents/allLikedMusic/index"

import "./index.scss"
import MusicFooter from '../main/components/footerSection/MusicFooter'

const LikedMusicByUser = (props:any) => {
    return (
        <>
                        <AllLikedMusic></AllLikedMusic>
                    
        </>
    )
}

export default LikedMusicByUser
