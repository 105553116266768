// @ts-nocheck
import React,{useEffect, useState} from 'react'

//helpers
import { useQuery, gql } from "@apollo/client"
import { useAppSelector } from '../../../redux/hooks'
import {Link,useLocation} from "react-router-dom"

//components
import MusicElement from "./UnassignedMusicElement"
import ShowMoreComponent from "../showMoreComponent/index"
import LottieWrapper from '../../lottie/lottie'

//preloader
import Preloader from "./preloader"


//icons
import AllIcon from "./src/AllIcon"

import "./index.scss"
import axios from "axios"

//configuration
import configuration from '../../../config/configuration'
import { config } from 'process'

interface props{
    allMusic?:boolean;
    searchResult?:boolean;
    addMargin?: boolean;
    musician?:string;
}

const UnassignedNewMusic: React.FC<props> = (props) => {
    const [musician,setMusician]=useState(props.musician)

    
    const QUERY = musician ?(gql`query{
       AllMusicByArist(username:"${musician}") {
            title
            thumbnail
            file
            username
            musicId
            imageId
            memberId
            topicId
            messageId
        }
        }`): (gql`query{
    AllMusic{
        title
        thumbnail
        file
        username
        musicId
        imageId
        memberId
        topicId
        anonymous
        messageId
    }
    }`
)

    const { searchQuery } = useAppSelector(state => state.search)
    const MUSIC_SEARCH_QUERY = gql`query{
    SearchInAllMusic(query:"${searchQuery}"){
        title
        thumbnail
        desc
        file
        musicId
        username
        imageId
        messageId
        topicId
        memberId
        likes
    }
}`

    const { loading, data, error } = useQuery(QUERY/*, { pollInterval: 500 }*/)
    const { loading: loadingSearch, data: searchData, error: searchError } = useQuery(MUSIC_SEARCH_QUERY/*, { pollInterval: 500 }*/)
    var count=0;
    const[songData,setSongData]=useState(null)

    const location=useLocation()
    const [isAdmin,setIsAdmin]=useState()
    const [countnumber, setcountnumber] = useState(5)
    useEffect(() => {
        function handleResize() {
            
            let width = window.innerWidth
            if (width <= 1024) {
                setcountnumber(4)
            }
            if (width <= 768) {
                setcountnumber(4)
            }
        }
    const fetchSongs = async()=>{
        const result = await axios.get(`${configuration.BACK_END_HOST}/getuploads`)
        setSongData(result.data.data)
    }
    fetchSongs()
        handleResize()
        window.addEventListener('resize', handleResize)

    }, [])
    const preloader = () => {
        const i = props.allMusic ? [0, 1, 2, 3, 4, 0, 1, 2, 3, 4, 0, 1, 2, 3, 4] : [...Array(countnumber).keys()]
        return i.map(() => {
            return (<Preloader />)
        })
    }

    const noWrap=(link:String)=>{
        if (location.pathname ===link){
            return "wrap"
        }
        else{
            return ""
        }
    }
    const searchResult=(search:any) =>{
        
        if (search.SearchInAllMusic.length !== 0){
            return search.SearchInAllMusic.map((music: any, idx: any) => {
                count++;
                if (props.allMusic) {
                    count = 0
                }
                if (count <= countnumber) {
                    return (<MusicElement
                        music_file={music.musicId}
                        music_thumbnail={music.imageId}
                        music_artist={music.memberId}
                        topic_id={music.topicId}
                        title={music.title}
                        thumbnail={`https:/${music.thumbnail}`}
                        file={`https:/${music.file}`}
                        username={music.username}
                        count={(idx + 1).toLocaleString('en-US', {
                            minimumIntegerDigits: 2,
                            useGrouping: false,
                        })} />)
                }
            })
        }
        if (search.SearchInAllMusic.length === 0){
            return (<LottieWrapper url="https://assets3.lottiefiles.com/packages/lf20_oURJje.json" width="calc(100% -  20px)" height="30vh" />)
        }
    }
    useEffect(()=>{
        const isAdmin= async()=>{
        const form = new FormData();
        form.append('userId',parseInt(localStorage.getItem("userId")));
        const result = await axios.post(`${configuration.BACK_END_HOST}/isadmin`, form)
        if(result.data.admin){
            setIsAdmin(result.data.admin)
        }
    }
    isAdmin()
    },[])
    
    return ( 
        <>{isAdmin&&(
            <div> 
            {!props.searchResult && (
                <div id="All-music">
                    <div className="d-flex justify-content-between">
                        <h3>Unassigned Music</h3>
                        {/* <ShowMoreComponent title="Show All Music" link="/music/allmusic" /> */}
                    </div>
                    {loading && (
                        <div className={`row card-deck ${noWrap("/music/allmusic")}`}>
                            {preloader()}
                        </div>)}
                        {musician?(<div className={`row card-deck ${noWrap("/music/allmusic")}`}>
                        {data && (
                            data.AllMusicByArist.map((music: any, idx: any) => {
                                count++;
                                if (props.allMusic) {
                                    count = 0
                                }
                                if (count <= countnumber) {
                                    return (<MusicElement
                                        music_file={music.musicId}
                                        music_thumbnail={music.imageId}
                                        music_artist={music.memberId}
                                        topic_id={music.topicId}
                                        title={music.title}
                                        thumbnail={`https:/${music.thumbnail}`}
                                        file={`http:/${music.file}`}
                                        username={music.username}
                                        claim={music.anonymous}
                                        messageId={music.messageId}
                                        count={(idx + 1).toLocaleString('en-US', {
                                            minimumIntegerDigits: 2,
                                            useGrouping: false,
                                        })} />)
                                }
                            }))}
                    </div>):(isAdmin?(<div className={`row card-deck ${noWrap("/music/allmusic")}`}>
                        {songData && (
                            songData.map((music: any, idx: any) => {
                                count++;
                                if (props.allMusic) {
                                    count = 0
                                }
                                const name1=music.attachment_type.split("/")
                                const name2= name1[1]?name1[1].split("."):name1[0]
                                if (count <= countnumber) {
                                    return (<MusicElement
                                        music_file={81}
                                        music_thumbnail={81}
                                        music_artist={56}
                                        topic_id={music.id_attach}
                                        title={name2[0]}
                                        thumbnail={`${configuration.FILE_STORAGE_LINK}/default-music.png`}
                                        file={`${configuration.READFILE_LINK}/${music.filename}`}
                                        username={"admin"}
                                        claim={music.anonymous}
                                        messageId={5}
                                        count={(idx + 1).toLocaleString('en-US', {
                                            minimumIntegerDigits: 2,
                                            useGrouping: false,
                                        })} />)
                                }
                            }))}
                    </div>):null)}
                    
                </div>
            )}
            {
                props.searchResult && (
                    <div id="new-music">
                        <div className="d-flex justify-content-between">
                            <h3>Music Results</h3>
                            <ShowMoreComponent title="Show All Search Results" link="/music/searchResult/music" />
                        </div>
                        {loadingSearch && (
                            <div className={`row card-deck ${noWrap("/music/searchResult/music")}`}>
                                {preloader()}
                            </div>)}
                        <div className={`row card-deck ${noWrap("/music/searchResult/music")}`}>
                            {searchData && (searchResult(searchData))}
                            {searchError && (<LottieWrapper url="https://assets3.lottiefiles.com/packages/lf20_oURJje.json" width="calc(100% -  20px)" height="30vh" />)}
                        </div>
                    </div>
                )
            }
        </div>
        )}</>
       
    )
}

export default UnassignedNewMusic
