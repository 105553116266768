import React, { useState } from 'react'
import "./index.scss"
import ClosePanelIcon from './src/ClosePanelIcon'
import InputForm from '../../formInput'
import TitleIcon from './src/TitleIcon'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
// redux functionality
import {useAppDispatch,useAppSelector} from "../../../redux/hooks"
import {assignMusicUi} from "../../../redux/data/uiData"

// apollo client
import {gql,useQuery} from "@apollo/client"

import Loading from '../../loading'
import Error from '../../error'
import Sucess from '../../sucess'
import axios from 'axios'
import configuration from '../../../config/configuration'


export default function AssignMusic() {
    const dispatch = useAppDispatch()
    const [loading,setLoading]=useState(false)
    const[status,setStatus]=useState(false)
    const[error,setError]=useState()
    const[close,setClose]=useState(false)
    const QUERY = gql`query{
        AllArtistWithMusic{
            username
            profileImg
            userId
            criteria
            songs{
            color
            cover
            singer
            name
            musicSrc
        }
        }
    }`
    const { loading:fetchingUsers, data, error:UserError } = useQuery(QUERY/*, { pollInterval: 500 }*/)
    
       const { assignData } = useAppSelector((state) => state.assignMusic);
    
    const value = (id: string) => {
        var inputValue = (document.getElementById(id) as HTMLInputElement).value
        return inputValue;
    };
    const [realName,setRealName]=useState("")
    const HandleAssignMusic = async (e:any)=>{
        e.preventDefault()
        
            setLoading(true)
            setClose(true)
            const form = new FormData()
            form.append("id_attach",`${assignData.id_attach}`)
            form.append("real_name",realName)
            form.append("title",assignData.name)
            const result = axios.post(`${configuration.BACK_END_HOST}/assign`,form).then(function (response) {
                setLoading(false) 
                setStatus(true)
                
            })
            .catch(function (error) {
                setLoading(false);
                if(error.response){
                  setError(error.response.data.message);
                }
                setStatus(false);
              });
       
       
    }
   
      
    
      const handleOnSelect = (item: any) => {
        // the item selected
        setRealName(item.name)
      }
    
     

      const formatResult = (item:any) => {
        return (
          <>
            {/* <span style={{ display: 'block', textAlign: 'left' }}>id: {item.id}</span> */}
            <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
          </>
        )
      }

    return (
        <div id="claim-music">
            <div className="panel">
                <div className="d-flex justify-content-between top-button">
                    <div className="title">Assign Music</div>
                    <div onClick={(e)=>{
                        e.preventDefault()
                        dispatch(assignMusicUi())
                    }}>
                        <ClosePanelIcon />
                    </div>
                </div>
                {close&&loading && <Loading></Loading>}
                {close&&status&& <Sucess text="Assigned" close={setClose}></Sucess>}
                { close&&error && !status&&  <Error error={error} close={setClose}></Error>}
                
                <div className="prerequist">
                Write below the Username of the user You want to assign the Music
                    </div>
                {/* <InputForm placeholder="User username" icon={<TitleIcon />} id="real_name" type="text" /> */}
                <div style={{ width:'100%' }}>
         {
            data&&
            <ReactSearchAutocomplete
            items={data.AllArtistWithMusic.map((item:any) =>{
                return {
                  id:item.userId,
                  name:item.username
                }
            })}
            
            onSelect={handleOnSelect}
            autoFocus
            styling={{backgroundColor:"#383838",color:"white",hoverBackgroundColor: "#383839",}}
            formatResult={formatResult}
          />
         } 
        </div>

                
                <div className="claim-music-btn mt-2" onClick={HandleAssignMusic}>
                    Assign Music
                </div>
            </div>
        </div>
    )
}
