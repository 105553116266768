import Closeicon from "../addPlaylist/src/Closeicon"
import GoogleButton from "../socialLogin/GoogleButton";
import FacebookButton from "../socialLogin/FacebookButton";
import { useState } from "react";
import {useHistory } from "react-router-dom";
import { setShowPayWall } from "../../redux/data/uiData";
import { hideContributeBtn } from "../../redux/data/navbar";
import configuration from "../../config/configuration";
import { useDispatch } from "react-redux";
import axios from "axios";
import { useAppSelector } from "../../redux/hooks";
import "./index.scss"


const Pay_Wall = () => {

  document.body.style.overflow = 'hidden';
  const history=useHistory()
  const dispatch = useDispatch()
  const [error, seterror] = useState<String>("");
  const [loading, setloading] = useState<Boolean>(false);
  const [success, setsuccess] = useState<Boolean>(false);
  const [close,setClose] =useState<Boolean>(false);
  const { nextRoute } = useAppSelector((state) => state.artistProfile);
  const value = (id: string) => {
    var inputValue = (document.getElementById(id) as HTMLInputElement).value
    return inputValue;
  };

  const handleSubmit = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    setClose(true)
    setloading(true)
    seterror("");
    setsuccess(false);
    const [
      email,
      password,
    ] = [
        value('emailAdress'),
        value('password'),
      ];

      if (password && email) {
        //needs refactorings
        axios.post(`${configuration.BACK_END_HOST}/login?email=${email}&password=${password}`)
          .then(function (response) {
            setsuccess(true);
            setloading(false);
            seterror('');
            localStorage.setItem("userId", response.data.data.user_id)
            
            localStorage.setItem("token", response.data.data.token?response.data.data.token:"old")
            if ((!(typeof response.data.data.musician === "undefined"))){
              localStorage.setItem("musician", response.data.data.musician)
              dispatch(hideContributeBtn())
            }
            localStorage.setItem("isContributor",response.data.data.userGroup)
            localStorage.setItem("usergroupId",response.data.data.usergroupId)
  
            if (nextRoute){
              history.push(nextRoute)
            }
            else{
              // history.push("/music")
            }
            document.body.style.overflow = 'auto';
            dispatch(setShowPayWall(false)) 
          })
          .catch(function (error) {
            setloading(false);
            if(error.response){
              seterror(error.response.data.message);
            }
            setsuccess(false);
          });
      }
      else {
        setloading(false);
        seterror("Fill the forms please");
        setsuccess(false);
      }

    
  };
  
    return (

        <div>
                <div id="paywall">
                    <div className="panel">
                        <div className="d-flex justify-content-between">
                            <h6 className="panel-title">Login to never see this popup</h6>
                            <div onClick={(e: any) => {
                                e.preventDefault()
                                document.body.style.overflow = 'auto';
                                dispatch(setShowPayWall(false)) 
                              }}>
                                <Closeicon />
                            </div>
                        </div>
                       
                        <div className="col-md-11 col-lg-10 col-xl-8" style={{paddingRight:"0px" }}>
                    <div className="col-md-12 col-xl-12" style={{paddingRight:"0px"}}>
                      {/* <h1>Log In Account</h1>
                      <h6>
                        <Link to="/signup" className="create-account"> Create your free account.</Link>
                      </h6> */}
                      <div className="row">
                        <form action="" onSubmit={handleSubmit}>
                         
                          <div className="col-md-12 row forms">
                            <div className="col-md-12">
                              <div className="label">Email Address</div>
                              <input className="input-style" type="email" id="emailAdress" />
                            </div>
                            <div className="col-md-12">
                              <div className="label">Password</div>
                              <input
                                className="input-style"
                                type="password"
                                id="password"
                              />
                            </div>
                            <div className="col-md-6">
                              <button
                                className="btn btn-primary create-btn"
                                type="submit"
                              >
                                Log In Account
                              </button>
                            </div>

                            <div className="col-md-6">
                              <a href="https://admin.leerecs.com/index.php?action=reminder" target="__blank">
                                <div className="btn btn-outline btn-primary login-btn">
                                  Forgot Password
                                </div>
                              </a>
                            </div>
                          </div>
                        </form>
                        <div className='d-flex flex-column align-items-center w-100 mt-3 justify-content-center '>
                          <div className='text-white  mb-2  row' style={{width:"100%"}}>
                          <div className="col-5 border-bottom"/>
                             <div className="col-auto mr-2">OR</div>
                             <div className="col-5    border-bottom"/>

                             </div>
                      
                        {/* <GoogleButton close={setClose} success={setsuccess} loading={setloading} error={setloading}/>
                        
                        
                        <FacebookButton close={setClose} success={setsuccess} loading={setloading} error={setloading}/> */}
                        
                        </div>
                        
                      </div>
                    </div>
                  </div>
                        
                    </div>
                </div>
            
        </div>
    )
}

export default Pay_Wall
