// @ts-nocheck
import React, { useEffect, useState, useRef,} from 'react'
//helpers
import { useLocation } from 'react-router'
import Parser from "rss-parser";

//components
import MusicElement from "./MusicElement"
import ShowMoreComponent from '../showMoreComponent'
import LottieWrapper from '../../lottie/lottie'
//preloader
import Preloader from '../newMusic/preloader'
import "./index.scss"
//configuration
import configuration from '../../../config/configuration'

interface props {
    allMusic?: boolean;
    searchResult?: boolean;
    addMargin?: boolean;
    musician?:string;
    data:any;
    loading: boolean;
    error: any;
}

const NewPlaylist: React.FC<props> = (props) => {

    const musician =props.musician;
    var count = 0;
    const margin = (props.addMargin) && "marg-t"
    const location = useLocation()

    const noWrap = (link:String) => {
        if (location.pathname === link) {
            return "wrap"
        }
        else {
            return ""
        }
    }

    const [countnumber, setcountnumber] = useState(5)
    const ref = useRef([]);
    const [podcast,setPodcast]=useState()
    useEffect(() => {
        function handleResize() {
            console.log('resized to: ', window.innerWidth, 'x', window.innerHeight)
            let width = window.innerWidth
            if (width <= 1024) {
                setcountnumber(4)
            }
            if (width <= 768) {
                setcountnumber(4)
            }
        }
        handleResize()
        window.addEventListener('resize', handleResize)
        const resultFunc = async () => {
            const listOfMusic = async () => {
              let parser = new Parser();
              const CORS_PROXY = "https://proxy.leerecs.com/?url=";
              let feed = await parser.parseURL(
                CORS_PROXY + "https://www.spreaker.com/show/3073366/episodes/feed"
              );
              if (feed) {
                feed.items.forEach((item, idx) => {
                    // console.log(item)
                    
                  ref.current.push({
                    imageUrl: item.itunes.image,
                    musicUrl: item.enclosure?.url,
                    artist: "leerecs",
                    musicName: item.title,
                    description: item.contentSnippet,
                  });
                });

                setPodcast({
                    imageUrl: feed.items[0].itunes.image,
                    musicUrl: feed.items[0].enclosure?.url,
                    artist: "leerecs",
                    musicName: feed.items[0].title,
                    description: feed.items[0].contentSnippet,
                })
                return ref.current;
              }
            };
            listOfMusic();
          };
          //if (!state.listOfPoscast.length){
            resultFunc()

    }, [])

    const preloader = () => {
        const i = props.allMusic ? [0, 1, 2, 3, 4, 0, 1, 2] : [...Array(countnumber).keys()]
        return i.map((item,id) => {
            return (<Preloader  key={id}/>)
        })
    }

    return (
        <div>
            
                <div id={props.allMusic?"all-playlist":"new-playlist"}>
                    <div className={`d-flex justify-content-between ${margin} `}>
                        <h3>{location.pathname ==="/music/allplaylist"?"All Playlist":"Trending Playlists"}</h3>
                        {!props.allMusic && !props.searchResult && <ShowMoreComponent title="Show More Playlists" link="/music/allplaylist" />}
                        {!props.allMusic && props.searchResult  && (
              <div>
                <ShowMoreComponent
                  title="Show All Playlists Results" link="/music/searchResult/playlists"
                />
              </div>
            )}
                    </div>
                    {props.loading && (
                        <div className={`row card-deck ${noWrap("/music/allplaylist")}`}>
                            {preloader()}
                        </div>)}
                        {musician?( <div className={`row card-deck ${noWrap("/music/allplaylist")}`}>
                        {props.data && (props.data.map((music: any, idx: any) => {
                            count++;
                            if (props.allMusic) {
                                count = 0
                            }
                            if (count <= countnumber) {
                                return (
                                    <MusicElement
                                    key={idx}
                                    link={`/music/playlist/${music.title.toLowerCase().replaceAll(" ","-")}`}
                                        title={music.title}
                                        thumbnail={`${configuration.FILE_STORAGE_LINK}${music.cover}`}
                                        file={`${configuration.READFILE_LINK}${music.file}`}
                                        username={music.username}
                                        count={music.musicCount} />)
                            }
                        }))}
                        {!props.error &&(<LottieWrapper url="https://assets3.lottiefiles.com/packages/lf20_ydo1amjm.json" width="calc(100% -  20px)" height="40vh" />)}
                        {(props.data&& (props.data.length === 0)) &&(<LottieWrapper url="https://assets3.lottiefiles.com/packages/lf20_ydo1amjm.json" width="calc(100% -  20px)" height="40vh" />)}
                    </div>):( <div className={`row card-deck ${noWrap("/music/allplaylist")}`}>
                        {console.log(podcast)}
                        {!props.loading&&podcast&&(<MusicElement
                                        link={`/music/playlist/sundaycoffee`}
                                        title={"Sunday Coffee"}
                                        thumbnail={podcast.imageUrl}
                                        file={`https:/`}
                                        username={"leerecs"}
                                        count={35} 
                                        />)}
                        {props.data && (props.data.map((music: any, idx: any) => {
                            count++;
                            if (props.allMusic) {
                                count = 0
                            }
                            if (count <= countnumber) {
                                return (
                                    <MusicElement
                                    key={idx}
                                        link={`/music/playlist/${music.title.toLowerCase().replaceAll(" ","-")}`}
                                        title={music.title}
                                        thumbnail={`${configuration.FILE_STORAGE_LINK}${music.cover}`}
                                        file={`${configuration.READFILE_LINK}${music.file}`}
                                        username={music.username}
                                        count={music.musicCount} 
                                        songs={music.songs}
                                        />)
                            }
                        }))}
                    </div>)}
                   
                </div>
        </div>
    )
}

export default NewPlaylist
