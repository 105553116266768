// @ts-nocheck
import {useState,useEffect} from 'react';

import Closeicon from "../addPlaylist/src/Closeicon"
import { setMusicShowPayWall } from '../../redux/data/uiData';
import { useDispatch } from "react-redux";
import { useAppSelector } from '../../redux/hooks';
import { Link } from 'react-router-dom';
import YouTube from 'react-youtube';
import "./index.scss"
enum contentType {
    VIDEO="VIDEO",
    AUDIO="AUDIO",
}
const MusicPayWall = () => {
    const dispatch = useDispatch()
    const [content, setContent] = useState(null);
    const [loaded,setLoaded]=useState(false)
   
    const[isEnded,setIsEnded]=useState<boolean>(false)
    const { audioCounter } = useAppSelector((state) => state.uiData);
    useEffect(()=>{
      async function getMoviesFromApi() {
        try {
          let response = await fetch(
            "https://proxy.leerecs.com/?url=https://leerecs.com/indexFile.json"
          );
          let responseJson = await response.json();
          console.log(responseJson)
          setContent(responseJson.adsContent);
          setLoaded(true)
          
        } catch (error) {
          console.error(error);
        }
      }
      getMoviesFromApi()
    },[])
   
    

    const handleEnded = () => {
        setIsEnded(true) // Replace this with your desired action
      };
    
      const opts = {
        height: '200',
        width: '400',
        playerVars: {
          autoplay: 1, // 0 to disable autoplay
        },
      };
    
      const handleVideoEnd = (event) => {
          handleEnded ()
      };
    
    const playSong = () => {
    const audioElement = document.querySelector('.music-player-audio');
    if (audioElement) {
      audioElement.play();
    }
  };
    return (
            <div>
                <div id="paywall">
                <div className="panel" style={{width:"500px"}}>
                    
                        <div className="d-flex justify-content-between">
                        <h6 className="panel-title">To listen without advertisement log in or create account</h6>
                        {isEnded &&(
                        <div onClick={(e: any) => {
                            e.preventDefault()
                            document.body.style.overflow = 'auto';
                           playSong()
                            dispatch(setMusicShowPayWall (false)) 
                           
                          }}>
                            <Closeicon />
                        </div>
                        )}
                    </div>
                    
                

                        <div className="col-md-11 col-lg-10 col-xl-8">
                    <div className="col-md-12 col-xl-12">

{loaded&&content[audioCounter%content.length].type===contentType.AUDIO &&(
    <div>
    <img 
    width="400px"
    height="200px"
    src={content[audioCounter%content?.length].image} alt="" />
  

<audio onEnded={handleEnded} id="myAudio" src={content[audioCounter%content?.length].src} controls autoPlay={true} style={{width:"400px",marginTop:"10px"}}>
 </audio>
</div>
)}
{loaded&&content[audioCounter%content?.length].type===contentType.VIDEO &&(
        <div class="video-container">
        <YouTube
      videoId={content[audioCounter%content?.length].src}
      opts={opts}
      onEnd={handleVideoEnd}
    />
      </div>
    )
}
                    

            <Link to="/login" onClick={()=>{
              document.body.style.overflow = 'auto';
              dispatch(setMusicShowPayWall (false)) 
              }}> 
              <h5 style={{
                textAlign:"center",
                color:"#f92e8d",
                marginTop:"10px",
                textDecoration:"underline",
              }}>Login or create a new Account</h5>
              </Link> 
                       
                    </div>
                    </div>
                </div>

                </div>
            
        </div>
    );
}

export default MusicPayWall;
