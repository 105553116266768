import React from 'react'

const AllMusicIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25.542" height="25.574" viewBox="0 0 25.542 25.574">
      <g id="app-store" transform="translate(-3.968 -1)">
        <rect id="Rectangle_153" data-name="Rectangle 153" width="10" height="10" rx="4" transform="translate(3.968 2.574)" fill="#383838" />
        <path id="Path_84" data-name="Path 84" d="M46.036,6.054,41.486,1.5a1.719,1.719,0,0,0-2.432,0L34.5,6.054a1.721,1.721,0,0,0,0,2.432l4.55,4.55a1.719,1.719,0,0,0,2.432,0l4.55-4.55A1.721,1.721,0,0,0,46.036,6.054Z" transform="translate(-17.029)" fill="#383838" />
        <rect id="Rectangle_154" data-name="Rectangle 154" width="10" height="10" rx="4" transform="translate(17.968 16.574)" fill="#383838" />
        <rect id="Rectangle_155" data-name="Rectangle 155" width="10" height="10" rx="4" transform="translate(3.968 16.574)" fill="#383838" />
      </g>
    </svg>

  )
}

export default AllMusicIcon
