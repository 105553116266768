import React from 'react'
import { Link,NavLink } from "react-router-dom"
import { useHistory } from 'react-router-dom'
import { useAppSelector } from '../../../redux/hooks'

interface props{
    title:String
}


const LoginSideBarElement:React.FC<props> = (props) => {
    const history =useHistory();
    const {isMini} = useAppSelector(state => state.uiData)
    const handleLogOut = (e: any) => {
        e.preventDefault()
        localStorage.removeItem("userId")
        localStorage.removeItem("token")
        localStorage.removeItem("musician")
        localStorage.removeItem("userType")
        localStorage.removeItem("isContributor")
        history.push("/login")
    }
    return (
        <div className="sideElement" style={{width:isMini?'15%':''}}>
            <div className="d-flex head-title ">
            <Link to="/"><img src="/assets/ico/favicon-logo.png" alt="" className="logo mr-3" /></Link>
               <div  className='d-flex pointer' style={{marginLeft:"auto"}} onClick={(e) => {
                        handleLogOut(e)
                    }}>
                        {!isMini &&(<div className="text-name head-title   mr-2 mt-1">{props.title}</div>)}
                
                <div className='' style={{marginTop:"0.1rem"}}>
                {props.children}</div>
                </div>
            </div>
        </div>
    )
}

export default LoginSideBarElement
