// @ts-nocheck
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import MenuForMain from "./menu";
import "./index.scss"
import "./menu.scss"
import "./currentMusic.scss"
import { useLocation } from "react-router";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { ProductionMenuItems, MainMenuItems, SharedItems } from "./menus/Menus"

export function Menu(props) {
  const [menu, setmenu] = useState(0);
  // create a state variable to store the CSS class for the nav element
  const [navClass, setNavClass] = useState('');
  const [fixed, setfixed] = useState(false);
  const location = useLocation();
    // create a function to change the CSS style of the nav element
    function changeNavStyle(scrollY) {
      if (scrollY > 100) {
        // set the CSS class for the nav element
        setNavClass('scrolled');
      } else {
        // remove the CSS class from the nav element
        setNavClass('');
      }
    }
  
    // add a scroll event listener
    window.addEventListener('scroll', () => {
      // call the function with the current scroll position
      changeNavStyle(window.scrollY);
    });
  
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    var timeline = gsap.timeline();
    ScrollTrigger.create({
      trigger: "#events,#compare",
      animation: timeline,
      start: "top center+=300px",
      end: "top center",
      onEnter: () => {
        setfixed(true);
      },
      onLeaveBack: () => {
        setfixed(true);
      }
    });

    //nav background
 


  }, [])
  const handleHide = () => {
    setmenu(0);
  };
  const manageLocation = () => {
    switch (location.pathname) {
      case "/":
        return <MainMenuItems info={props} />
      case "/production":

        return <ProductionMenuItems />
        
      default:
        return <SharedItems />
    }
  }
  const manageTitle=()=>{
    switch (location.pathname) {
      case "/":
        return "Request Interview"
      case "/production":

        return "Request Review"

      default:
        return "Request Interview"
    }
  }
  return (
    <div id={"menu-main-item"} className={`container-fluid fixed-menu ${navClass}`} style={{zIndex:"2000"}}>
      <div className="d-flex justify-content-btn mobile-nav">
      {location.pathname==='/'?(<a href="#home"
        ><div className="rounded-circle"></div></a>):(<Link to="/"
        ><div className="rounded-circle"></div></Link>)}
        
        <div className="nav-bar d-flex">
          <div></div>
          {manageLocation()}
          <div className="cta d-flex">
            <Link to={localStorage.getItem("userId") ? "/music" : "/login"}>
              <div className="register">
                Login Or Register
              </div>
            </Link>
            <Link to="/request">
              <div className="interview">{manageTitle()}</div>
            </Link>
            <div
              className={`menu-icon ${menu && "animate-hamburger"}`}
              id="menu-item"
              onClick={(e) => {
                e.preventDefault();
                setmenu(!menu);
              }}
            >
              <div className="line1"></div>
              <div className="line2"></div>
              <div className="line3"></div>
            </div>
            <div className={`d-none ${menu && "d-block"}`}>
              <MenuForMain setMenu={handleHide} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
    ;
}
