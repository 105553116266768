// @ts-nocheck
import React, { useEffect, useRef, useState, } from "react";
import { useQuery, useMutation, gql } from "@apollo/client";
import MusicSideBar from "../../components/musicPageComponents/sidebar/index";
import MusicNavBar from "../../components/musicPageComponents/navbar";
import UserEmptyCase from "../Emptycase/UserEmptyCase";
import AllMusicsByArtist from "../../components/musicPageComponents/allMusicByArtist";
import Parser from "rss-parser";
import axios from "axios";
import configuration from "../../config/configuration";

//wrappers
import SecureRoute from "../wrapper/SecureRoute";
import BigLoader from "../../components/signup/src/icons/bigLoader";

// import "./index.scss"
import MusicFooter from "../main/components/footerSection/MusicFooter";
import NonContributorProfile from "../../components/musicPageComponents/allMusicByArtist/NonContributorProfile";
import { useParams } from "react-router-dom";
import ReactGA from 'react-ga4';

const ArtistProfile = (props: any) => {
  const ref = useRef([]);
  const [ghost, setGhost] = useState();
  const {username}=useParams()
  const [products,setPoducts]=useState([])

  const USERDATA = gql`query{
        UserInfoByusername(username:"${username.replaceAll(
          "-",
          " "
        )}") {
           username
           title
           website
           bio
           profileImg
           headerImg
           profileId
           headerId
           userId
           karma
           buddies
           buddie_list
           id_group
           songTitle
           shop_status
           shop_requests
         }
       }`;
  const MUTATION = gql`
    mutation ($user_id: Int!, $profile_id: Int!) {
      ViewProfile(user_id: $user_id, profile_id: $profile_id) {
        desc
      }
    }
  `;
  const [Addview] = useMutation(MUTATION, { errorPolicy: "all" });
  const {
    loading: loadindInfo,
    data: UserInfo,
    error: UserError,
  } = useQuery(USERDATA /*, { pollInterval: 500 }*/);
  console.log(UserInfo)
  useEffect(() => {
    const resultFunc = async () => {
      const listOfMusic = async () => {
        let parser = new Parser();
        const CORS_PROXY = "https://proxy.leerecs.com/?url=";
        let feed = await parser.parseURL(
          CORS_PROXY + "https://www.spreaker.com/show/3073366/episodes/feed"
        );
        //console.log(feeds)
        if (feed) {
          feed.items.forEach((item, idx) => {
            // console.log(item)
            const name = item.title.split(" with ");

            if (
              name.length > 1 &&
              name[1].toLowerCase().replaceAll("-", " ") ===
                username.replaceAll("-", " ")
            ) {
              setGhost({
                username: name[1],
                title: "ghost",
                website: "",
                bio: "No bio",
                profileImg: "thumb_avatar-default-image.jpg",
                headerImg: "profile-default-bg.jpg",
                profileId: 0,
                headerId: 0,
                userId: 0,
                karma: 0,
                buddies: 0,
                buddie_list: "",
                id_group: 10,
                songTitle: item.title,
              });
            }

            ref.current.push({
              imageUrl: item.itunes.image,
              musicUrl: item.enclosure?.url,
              artist: "leerecs",
              musicName: item.title,
              description: item.contentSnippet,
            });
          });
          return ref.current;
        }
      };

      const title = (id) => {
        for (let index = 0; index < ref.current.length; index++) {
          const element = ref.current[index];
          if (id) {
            if (element.musicName === id) {
              return element;
            }
          } else {
            return ref.current[0];
          }
        }
      };

      const result = await listOfMusic();
    };

    resultFunc();
  }, []);

  useEffect(() => {
    const view = async () => {
      const result = await Addview({
        variables: {
          user_id: parseInt(localStorage.getItem("userId")),
          profile_id: UserInfo.UserInfoByusername.userId,
        },
      });
      if (result) {
        // console.log(result)
      }
    };

    if (UserInfo) {
      view();
    }
  }, [UserInfo]);
  useEffect(()=>{
    ReactGA.event({
      category: 'Profile', // You can choose your own category name
      action: 'View Profile', // You can name the action as you like
      label: 'Viewed user Profile' // Optional, to provide more details about the event
    })
  },[])

  useEffect(()=>{
  
const getProduct=async ()=>{
  const products = await axios.get(`${configuration.BACK_END_HOST}/shop/products`)
  console.log(products)
  setPoducts(products?.data?.filter((product)=>product.vendor&&product.vendor.toLowerCase().replaceAll(" ","-")===username))
  }
  getProduct()
    
  },[username])
  return (
    <>
      {UserInfo && UserInfo.UserInfoByusername.id_group === 9 ? (
        <>
          <AllMusicsByArtist
            shop_status={UserInfo.UserInfoByusername.shop_status}
            shop_requests={UserInfo.UserInfoByusername.shop_requests}
            song={UserInfo.UserInfoByusername.songTitle}
            buddie_list={
              UserInfo.UserInfoByusername.buddie_list
                ? UserInfo.UserInfoByusername.buddie_list
                : ""
            }
            products={products}
            buddies={UserInfo.UserInfoByusername.buddies}
            karma={UserInfo.UserInfoByusername.karma}
            userId={UserInfo.UserInfoByusername.userId}
            profileId={UserInfo.UserInfoByusername.profileId}
            headerId={UserInfo.UserInfoByusername.headerId}
            title={UserInfo.UserInfoByusername.title}
            website={UserInfo.UserInfoByusername.website}
            bio={UserInfo.UserInfoByusername.bio}
            headerImage={UserInfo.UserInfoByusername.headerImg}
            profileImage={UserInfo.UserInfoByusername.profileImg}
            username={UserInfo.UserInfoByusername.username}
            id_group={UserInfo.UserInfoByusername.id_group}
          ></AllMusicsByArtist>
          <MusicFooter/>
        </>
      ) : UserInfo && UserInfo.UserInfoByusername.id_group !== 9 ? (
        <>
          <NonContributorProfile
            shop_status={UserInfo.UserInfoByusername.shop_status}
            song={UserInfo.UserInfoByusername.songTitle}
            buddie_list={
              UserInfo.UserInfoByusername.buddie_list
                ? UserInfo.UserInfoByusername.buddie_list
                : ""
            }
            products={products}
            buddies={UserInfo.UserInfoByusername.buddies}
            karma={UserInfo.UserInfoByusername.karma}
            userId={UserInfo.UserInfoByusername.userId}
            profileId={UserInfo.UserInfoByusername.profileId}
            headerId={UserInfo.UserInfoByusername.headerId}
            title={UserInfo.UserInfoByusername.title}
            website={UserInfo.UserInfoByusername.website}
            bio={UserInfo.UserInfoByusername.bio}
            headerImage={UserInfo.UserInfoByusername.headerImg}
            profileImage={UserInfo.UserInfoByusername.profileImg}
            username={UserInfo.UserInfoByusername.username}
            id_group={UserInfo.UserInfoByusername.id_group}
          />
          <MusicFooter/>
        </>
      ) : !loadindInfo && !UserInfo && ghost ? (
        <>
        <AllMusicsByArtist
          song={ghost.songTitle}
          buddie_list={""}
          products={products}
          buddies={ghost.buddies}
          karma={ghost.karma}
          userId={ghost.userId}
          profileId={ghost.profileId}
          headerId={ghost.headerId}
          title={ghost.title}
          website={ghost.website}
          bio={ghost.bio}
          headerImage={ghost.headerImg}
          profileImage={ghost.profileImg}
          username={ghost.username}
          id_group={ghost.id_group}
        ></AllMusicsByArtist>
        <MusicFooter/>
        </>
      ) : loadindInfo ? (
        <div className="bigLoader">
          <BigLoader />
        </div>
      ) : (
        <UserEmptyCase />
      )}
    </>
  );
};

export default ArtistProfile;
