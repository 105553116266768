// @ts-nocheck
import React, { useEffect, useState} from 'react'

//helper
import {useQuery,gql} from "@apollo/client"
import { useAppSelector } from '../../../redux/hooks'
import { Link, useLocation } from "react-router-dom"

//components
import FeaturedArtistElement from "./FeaturedArtistElement"
import ShowMoreComponent from '../showMoreComponent'
import LottieWrapper from '../../lottie/lottie'

//preloader
import Preloader from "./preloader"

import "./index.scss"

import configuration from "../../../config/configuration"
import MusicFooter from '../../../pages/main/components/footerSection/MusicFooter'

interface props {
    allMusic?: boolean;
    searchResult?:boolean;
    addMargin?:boolean;
}

const AllArtists:React.FC<props> = (props) => {
    const QUERY = gql`query{
    AllArtist{
        username
        profileImg
        userId
    }
}`

    const { searchQuery } = useAppSelector(state => state.search)
    const SEARCH_ARTISTS_QUERY = gql`query{
    AllFeaturedArtistSearch(query:"${searchQuery}"){
        username
        profileImg
        userId
        criteria
    }
}`
    const { loading, data, error } = useQuery(QUERY/*, { pollInterval: 500 }*/)
    const { loading: loadingSearch, data: searchData, error: searchError } = useQuery(SEARCH_ARTISTS_QUERY/*, { pollInterval: 500 }*/)
    var count = 0;
    // @ts-ignore
    const margin = (props.addMargin) && "marg-t"
    const [countnumber, setcountnumber] = useState(4)
    useEffect(() => {
        function handleResize() {
            console.log('resized to: ', window.innerWidth, 'x', window.innerHeight)
            let width = window.innerWidth
            if (width <= 1024) {
                setcountnumber(4)
            }
            if (width <= 768) {
                setcountnumber(3)
            }
        }
        handleResize()
        window.addEventListener('resize', handleResize)

    }, [])
    const preloader = () => {
        const i = props.allMusic ? [0, 1, 2, 3, 4, 0, 1, 2] : [...Array(countnumber).keys()]
        return i.map(() => {
            return (<Preloader />)
        })
    }


    const searchResult = (search: any) => {
        if (search.AllFeaturedArtistSearch.length !== 0) {
            return search.AllFeaturedArtistSearch.map((artist: any) => {
                count++;
                if (props.allMusic) {
                    count = 0
                }
                if (count <= countnumber) {
                    return (<FeaturedArtistElement
                        artistName={artist.username}
                        artistProfile={`https:/${artist.profileImg}`}
                        profileLink={`/music/artistMusics/${artist.userId}`}
                    />)
                }
            })
        }
        if (search.AllFeaturedArtistSearch.length === 0) {
            return (<LottieWrapper url="https://assets3.lottiefiles.com/datafiles/wqxpXEEPRQf1JnQ/data.json" width="calc(100% -  20px)" height="30vh" />)
        }
    }

    const location = useLocation()

    const noWrap = (link: String) => {
        if (location.pathname === link) {
            return "wrap"
        }
        else {
            return ""
        }
    }
    return (
        <div>
            {!props.searchResult && (
            <div id="featured-artists">
                <div className={`d-flex justify-content-between ${margin} `}>
                    <h3>All artists</h3>
                    {!props.allMusic && <ShowMoreComponent title="All Artists" link="/music/allfeatured" />}
                </div>
                {loading && (
                        <div className={`row card-deck ${noWrap("/music/allfeatured")}`}>
                        {preloader()}
                    </div>)}
                    <div className={`row card-deck ${noWrap("/music/allfeatured")}`}>
                    {data && (data.AllArtist.map((artist: any) => {
                        count++;
                        if (props.allMusic) {
                            count = 0
                        }
                        if (count <= countnumber) {
                            return (<FeaturedArtistElement
                                artistName={artist.username}
                                artistProfile={`${configuration.FILE_STORAGE_LINK}${artist.profileImg}`}
                                profileLink={`/artist/${artist.username}`}
                            />)
                        }
                    }))}
                </div>
                {data && <MusicFooter/>}
            </div>)}
            {props.searchResult && (
                <div id="featured-artists">
                    <div className={`d-flex justify-content-between ${margin} `}>
                        <h3>Featured artists</h3>
                        {!props.allMusic && <ShowMoreComponent title="All Featured Artists Results" link="/music/searchResult/artists" />}
                    </div>
                    {loadingSearch && (
                        <div className={`row card-deck ${noWrap("/music/searchResult/artists")}`}>
                            {preloader()}
                        </div>)}
                    <div className={`row card-deck ${noWrap("/music/searchResult/artists")}`}>
                        {searchData && (searchResult(searchData))}
                        {searchError && (<LottieWrapper url="https://assets3.lottiefiles.com/datafiles/wqxpXEEPRQf1JnQ/data.json" width="calc(100% -  20px)" height="30vh" />)}
                    </div>
                </div>
            )}
        </div>
    )
}

export default AllArtists
