import type { Identifier, XYCoord } from 'dnd-core'
import type { FC } from 'react'
import { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { gql, useMutation} from "@apollo/client"

import { ItemTypes } from './ItemTypes'
import MusicElement from '../MusicElement'
import configuration from '../../../../config/configuration'
import { resolveProjectReferencePath } from 'typescript'

const style = {
  with:'100%',
  cursor: 'move',
}
interface Music {
	thumbnail: string
	file: string
	title: string
	tag: string
	desc: string
	musicId: number
	imageId: number
	username: string
	messageId: number
	topicId: number
	memberId: number
    likes: number
    category: number
	userId: number
	color_name:string
    order_id:number
    playlist_id:number
}
export interface CardProps {
  id: any
  text: string
  index: number,
  data:Music,
  refetch:any,
  setCards:any,
  moveCard: (dragIndex: number, hoverIndex: number) => void
}

interface DragItem {
  index: number
  id: string
  type: string
}

export const Card: FC<CardProps> = ({ id, text, index,data, moveCard,refetch,setCards }) => {
  const ref = useRef<HTMLDivElement>(null)
  const MUTATION = gql`mutation($prevId:Int!,$nextId:Int!,$playlistId:Int!)
  {
    sortAlbum(sortAlbumData:{prevId: $prevId,nextId:$nextId,playlistId: $playlistId}){
          desc
      }
  }`
  const [SortMusic, { loading, data:sorted, error }] = useMutation(MUTATION, { errorPolicy: "all" })
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
    drop(item: DragItem, monitor){
        console.log(index)
       console.log(item)
        const result =  SortMusic({
            variables: {
                prevId:data.order_id,
                nextId:item.index+1,
                playlistId:data.playlist_id
            }
        }).then((res)=> refetch().then((response:any)=>setCards(response.data.AllMusicInAlbum)))
        
     
    }
  })

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index }
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0 : 1
  drag(drop(ref))
  return (
    <div ref={ref} style={{ ...style, opacity }} data-handler-id={handlerId}>
      <MusicElement
                     recommend={[]}
                    music_file={data.musicId}
                    music_thumbnail={data.imageId}
                    music_artist={data.memberId}
                    title={data.title}
                    thumbnail={`${configuration.FILE_STORAGE_LINK}${data.thumbnail}`}
                    file={`${configuration.READFILE_LINK}${data.file}`}
                    username={data.username}
                    color_name={data.color_name}
                    music_id={data.topicId}
                    count={index+1}
                    index={index}
                    userId={data.userId}
                     />
    </div>
  )
}
