import React from 'react';
import LottieWrapper from '../../components/lottie/lottie';
import './index.scss'

const MentainancePage = (props:any) => {
    console.log(props.info.title)
    return (
        <div id="empty-case" className='container'>
           <div className='content'>
           <div className="the-fat-text ml13" dangerouslySetInnerHTML={{__html: props.info.title}}>

           </div>
           <div className="the-fat-text ml13" dangerouslySetInnerHTML={{__html: props.info.description}}>

           </div>
           <div className="the-fat-text ml13" dangerouslySetInnerHTML={{__html: props.info.sub}}>

           </div>
        
           <LottieWrapper url="https://assets9.lottiefiles.com/packages/lf20_GmXdtd.json" width="calc(100% -  20px)" height="30vh" />
           </div>
            
        </div>
    );
}

export default MentainancePage;
