import React from 'react'

export default function PlayMusicIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
      <g id="Group_101" data-name="Group 101" transform="translate(-1256 -123)">
        <circle id="Ellipse_19" data-name="Ellipse 19" cx="30" cy="30" r="30" transform="translate(1256 123)" fill="#f8107d" />
        <g id="play_circle_filled" transform="translate(1474 195)">
          <path id="Path_109" data-name="Path 109" d="M17,2A15,15,0,1,0,32,17,15.005,15.005,0,0,0,17,2ZM14,23.75V10.25L23,17Z" transform="translate(-205 -59)" fill="#fff" />
        </g>
      </g>
    </svg>

  )
}
