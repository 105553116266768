/* eslint-disable jsx-a11y/anchor-is-valid */
// @ts-nocheck
import React, { useState, useRef, MutableRefObject,useEffect } from 'react';
//helpers
import { Link } from "react-router-dom"
import { useMutation, gql, ApolloError } from "@apollo/client"

//google recaptcha
import ReCAPTCHA from "react-google-recaptcha"

import Sucess from "../sucess/index"
import Loading from "../loading/index"
import Error from "../error/index"

import "./index.scss"

import axios from "axios";

import configuration from '../../config/configuration';
import UploadInterviewIcon from './src/UploadInterviewIcon';

import GoBackIcon from "../login/src/GoBackIcon"



export default function RequestInterview() {

  //states
  const [errorData, seterrorData] = useState<String | null>(null)

  //apollo client initialization
  const MUTATION = gql`mutation(
    $email:String!,
    $name:String!,
    $imageId:Int!,
    $audioId:Int!,
    $title:String!,
    $username:String!,
    $memberIp:String!,
    $dateRegester:Float!
  ){
    RequestInterview(interviewInfo:{
        email:$email,
        name: $name,
        imageId:$imageId,
        audioId:$audioId,
        username:$username,
        title:$title,
        memberIp:$memberIp,
        dateRegester:$dateRegester,

    }){
        desc
    }
}`
  const [RequestInterview, { loading, data, error }] = useMutation(MUTATION, {
    errorPolicy: "all"
  })
  //END OF APOLLO CLIENT INITIALIZATION

  //captch ref
  const reRef = useRef()
  const [uploading, setuploading] = useState(false)
  const [filename, setfilename] = useState("Upload Original Tune")
  const [success,setSuccess] =useState(false)
  const [close,setClose] =useState<Boolean>(false);
const [ip,setIp]=useState("")

  //  UPLOAD FILE FUNCTION HANDLER
  const uploadMusic = async (fileId: string, type: string) => {
    const file = (document.getElementById(fileId) as HTMLFormElement).files[0]
    if(!file){
      return(seterrorData("Invalid file, Please check the file you are trying to upload "))
    }
    const form = new FormData()
    const form1 = new FormData()
    setfilename(file.name)
    let fileSize = parseInt(file.size) / 1000000
    if (fileSize < 15) {
      form1.append("file", file, file.name)
      form.append("userId", ((localStorage.getItem("userId") as string) || 1))
      form.append("fileExt", type)
      form.append("mimeType", "mp3")
      form.append("size", file.size)
      try {
        if (file) {
          // const result1 = await axios.post(`https://uploadbackend.leerecs.net/api/upload_video`, form1)
          // console.log(result1)
          const result1 = await axios.post(`https://leerecs.com/backend/public/api/uploadfile`, form1)
          if (result1) {
            form.append("fileTitle", result1.data.title)
            const result = await axios.post(`${configuration.BACK_END_HOST}/uploadInterview`, form)
            if (result) {
              return { audio: result.data.audioId, title: result.data.title || "anonymous music" }
            }
          }
        }
      } catch (e) {
        return (null)
      }
    }
    else {
      setuploading(false)
      seterrorData("File must not be bigger than 10 MB")
    }

  }

  const value = (id: string) => {
    var inputValue = (document.getElementById(id) as HTMLInputElement).value
    return inputValue;
  };
  const handleSubmit = async (e: { preventDefault: () => void; }) => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    seterrorData(null)
    setClose(true)
    setuploading(false)
    e.preventDefault();
    const [
      email,
      name,
      profile,
      title,
      memberIp,
      dateRegester
    ] = [
        value('emailAdress'),
        value('name'),
        value('profile'),
        value('song'),
        ip,
        Date.now()
      ];
    //@ts-ignore
    const regex = /[?@\/#:\,;%+&=\{\}\[\]'\^-]/g
    if(name.match(regex)){
      return(seterrorData("Usernames can only contain letters, numbers, underscores and dots."))
    }
    if(!profile.match("https")){
      return(seterrorData("Invalid main social media URL"))
    }
    const { checked } = document.getElementById('flexCheckDefault');
    if (!checked) {
     return (seterrorData("Please accept terms and condition to proceed"))
    }
    //checking username
    const check1 = new FormData()
            check1.append("username",name)
            const checkres1 = await axios.post(`${configuration.BACK_END_HOST}/checkname`, check1)
          
            if(checkres1.data.exists){
              return (seterrorData("Username already exist, please try to choose another username"))
            }

    //checking email
    const check2 = new FormData();
    check2.append("email",email)
    const checkres2 =await axios.post(`${configuration.BACK_END_HOST}/checkemail`, check2)
    if(checkres2.data.exists){
      return (seterrorData("It looks like you already have an account, please try to log in <a href='/login' class='request-a' >here</a>"))
    }
    if (checked) {
      const token = await reRef.current.executeAsync()
      // console.log(token)
      
      reRef.current.reset()
      if (token) {
        //PROCESS THE SIGN UP
        setuploading(true)
        const musicThumbNail = await uploadMusic("music-file", "audio");
        // console.log(musicThumbNail)
        if (musicThumbNail) {
          try {
            setuploading(false)
            const result = await RequestInterview({
              variables: {
                email: email,
                name: profile,
                imageId:0,
                audioId: musicThumbNail.audio,
                username: name,
                title: title,
                memberIp:memberIp,
                dateRegester:dateRegester
              }
            })
            if (result) {
              if (data) {
                
              }
            }
            // console.log(result)
            setSuccess(true)
          } catch (e) {
          }
        }
      }
    }
  };

  const getData = async () => {
    const res = await axios.get('https://geolocation-db.com/json/')
    console.log(res.data);
    setIp(res.data.IPv4)
  }
  useEffect(()=>{
getData()
  },[])
  return (
    <div className="container-fluid" id="signup">
      <div className="row">
      <div className="col-8  px-3 loaders">
                        { close&&(loading || uploading) && <Loading></Loading>}
                        {(close&&data && !errorData &&success) &&<Sucess text="You will be sent email if accepted, if you miss it check your junk folder." close={setClose}></Sucess>}
                        {close&&errorData && <Error close={setClose} error={errorData}></Error>}
                        {close&&error &&
                          error.graphQLErrors.map((error, i) => {
                            // console.log(error)
                            return(
                            <Error error={error.message} close={setClose}></Error>
                          )})
                        }
                      </div>
        <div className="col-md-4 ">
          <div className="row onboard">
            <div className="col-md-12 pl-0">
              <div className="d-flex justify-content-end w-100">
                <h6 className="onboard-title">
                The True Alternative to Alternative Music(TM).
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-8 justify-content-center">
          <div className="row  sign-up">
            <div className="row mx-md-5 mx-4 justify-content-center">
              <div className="col-md-1  ">
                <Link to="/" >
                  <div className="go-back-icon">
                    <GoBackIcon />
                  </div>
                </Link>
              </div>
              <div className="col-md-9 col-lg-9 col-xl-9 px-md-5 ">
                <h1>Request Music Review</h1>
                <h6>
                  Please upload your original tunes only (submitting someone else's work disqualifies from future submissions).  Applying on this form does not guarantee the approval of the material submitted prior to its evaluation.
                </h6>
                <div className="row">
                  <form action="" onSubmit={handleSubmit} >
                    <div className="col-md-12 row forms">
                      
                      <div className="col-md-12">
                        <div className="label">Email Address <span>*</span></div>
                        <input className="input-style" type="email" id="emailAdress" required />
                      </div>
                      <div className="col-md-12">
                        <div className="label">Artist or Project Name/Username <span>*</span></div>
                        <input className="input-style" type="text" id="name" accept="audio/mp3"  required/>
                      </div>
                      <div className="col-md-12">
                        <div className="label">Main Social Media Account(Instagram/YouTube/Facebook/Etc.) <span>*</span></div>
                        <input className="input-style" type="url" id="profile"  required/>
                      </div>
                      <div className="col-md-12">
                        <div className="label">Song Title<span>*</span></div>
                        <input className="input-style" type="text" id="song"  required/>
                      </div>
                      <div className="col-md-12">
                        <div className="label">Upload Your Original Tune</div>
                        <div className="information">
                          <div><span>*</span>The file must not be bigger than 10MB</div>
                          <div><span>*</span>The file must be an mp3</div>
                        </div>
                        <div className="file-container">
                          <input type="file" name="music-id" accept="audio/mp3,audio/mpeg,audio/x-wav" id="music-file" onChange={(e) => {
                            const file = (document.getElementById("music-file") as HTMLFormElement).files[0]
                            setfilename(file.name)
                          }} required />
                        </div>
                        <div className="upload-file" onClick={(e) => {
                          e.preventDefault()
                          document.getElementById("music-file").click()
                        }}>
                          <div className="d-flex">
                            <UploadInterviewIcon />
                            <div className="text">{filename}</div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 submit-btn">
                        <button
                          className="btn btn-primary create-btn"
                          type="submit"
                        >
                          Submit
                        </button>
                      </div>
                      <div className="col-md-6">
                        <Link to="/login">
                          <div className="btn btn-outline btn-primary login-btn">
                            Cancel
                          </div>
                        </Link>
                      </div>
                      <div className="col-md-12">
                        <div className="label">Grant Permission terms</div>
                        <div className="terms">
                          I the author certify that the uploaded file is my original work and I own its rights and I grant permission and consent to LEERECS(SM) for the use of the uploaded music recording (and its contents) as identified in the file's title and or meta-data for promotion and presentation under any legal condition, including but not limited to: publicity, advertising, and web content:
                        </div>
                      </div>
                      <div className="col-md-12 recaptcha">
                        <ReCAPTCHA sitekey={configuration.RECAPTCHA_SECRET_KEY} ref={reRef} theme="dark" size="invisible" />
                      </div>
                      <div className="col-md-12">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexCheckDefault"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckDefault"
                            required>
                            I certify I understand and accept the above terms.
                          </label>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
