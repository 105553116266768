import React,{useEffect,useState} from "react";
//redux
import {useQuery,gql} from "@apollo/client"

//components
import NewMusic from "../../components/musicPageComponents/newMusic/index";
import FeaturedArtists from "../../components/musicPageComponents/featuredArtists/index";
import AllPlaylist from "../../components/musicPageComponents/allPlaylist/";
import AllAlbums from "../../components/musicPageComponents/albums";
import MusicFooter from "../main/components/footerSection/MusicFooter";
import "./index.scss";
import Products from "../../components/musicPageComponents/albums/products";
import configuration from "../../config/configuration";
import axios from "axios";

const MusicHomePage = () => {
  const [products,setPoducts]=useState([])
  const QUERY =gql`query{
    MusicHome(userId:null){
       music{
        title
        thumbnail
        file
        username
        musicId
        imageId
        memberId
        topicId
        anonymous
        messageId
        color_name
        playData{
        cover
        color
        singer
        name
        musicSrc
        }
       }
       musicians{
         username
        profileImg
        userId
        criteria
        songs{
        cover
        singer
        name
        musicSrc
        color
        }
       }
       playlists{
title
            desc
            username
            cover
            playListId
            musicCount
            songs{
        cover
        color
        singer
        name
        musicSrc
    }
       }
       albums{
  title
	    cover
        albumId
        username
        songs{
             cover
             singer
              name
            musicSrc
}
       }
    }
}`
   //loadind query
    const { loading, data, error } = useQuery(QUERY/*, { pollInterval: 500 }*/)
    useEffect(()=>{
  
        const getProduct=async ()=>{
          const products = await axios.get(`${configuration.BACK_END_HOST}/shop/products`)
          setPoducts(products?.data)
          }
          getProduct()
            
          },[])
  return (
    <>
      <NewMusic data={data?.MusicHome.music} loading={loading} searchResult={false} error={error}></NewMusic>
      <FeaturedArtists data={data?.MusicHome.musicians} loading={loading} error={error} searchResult={false} />
      <Products data={products}  loading={loading} error={error}  searchResult={false}/>
      <AllAlbums data={data?.MusicHome.albums} loading={loading} error={error}  searchResult={false} />
      <AllPlaylist data={data?.MusicHome.playlists} loading={loading} error={error} searchResult={false} />
      
      
      
      {<MusicFooter/>}
    </>
  );
};

export default MusicHomePage;
