import React from 'react';
import LottieWrapper from '../../components/lottie/lottie';
import { Link } from 'react-router-dom';
import './index.scss'

const UserEmptyCase = () => {
    return (
        <div id="empty-case" className='container'>
           <div className='content'>
            <h1>404</h1>
            <h1>You Hit a Broken String</h1>
            <h4> <Link to="/music">Go home</Link>  and keep practicing</h4>
           <LottieWrapper url="https://assets9.lottiefiles.com/packages/lf20_GmXdtd.json" width="calc(100% -  20px)" height="30vh" />
           </div>
            
        </div>
    );
}

export default UserEmptyCase;
