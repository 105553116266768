import React from 'react'

import Statistics from './src/Statistics'

import "./countCard.scss"

interface props{
    title:JSX.Element;
    logo:JSX.Element;
    count:String;
    // countFunction:()=>String;
}

const CountCard:React.FC<props> = (props) => {
    return (
        <div className="col-md-4 " id="CountCard">
            <div className="d-flex justify-content-between">
                <div className="rounded-circle">
                    {props.logo}
                </div>
                <Statistics />
            </div>
            <div className="title">
                {props.title}
            </div>
            <div className="count">{props.count}</div>
        </div>
    )
}

export default CountCard
