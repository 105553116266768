// @ts-nocheck
import React, { useEffect, useState} from 'react'
//helper
import { useLocation } from "react-router-dom"
import Previous from '../newMusic/src/Previous'
import { useAppDispatch } from '../../../redux/hooks'
import { setShowPayWall } from '../../../redux/data/uiData'
import Next from '../newMusic/src/Next'
//components
import FeaturedArtistElement from "./FeaturedArtistElement"
import ShowMoreComponent from '../showMoreComponent'
import LottieWrapper from '../../lottie/lottie'

//preloader
import Preloader from "./preloader"

import "./index.scss"

import configuration from "../../../config/configuration"

interface props {
    allMusic?: boolean;
    searchResult?:boolean;
    addMargin?:boolean;
    data:any;
    loading: boolean;
    error: any;

}

const FeaturedArtists:React.FC<props> = (props) => {
    var count = 0;
    // @ts-ignore
    const margin = (props.addMargin) && "marg-t"
    const dispatch= useAppDispatch()
    const [countnumber, setcountnumber] = useState(5)
    useEffect(() => {
        function handleResize() {
            console.log('resized to: ', window.innerWidth, 'x', window.innerHeight)
            let width = window.innerWidth
            if (width <= 1024) {
                setcountnumber(4)
            }
            if (width <= 768) {
                setcountnumber(4)
            }
        }
        handleResize()
        window.addEventListener('resize', handleResize)

    }, [])
    const preloader = () => {
        const i = props.allMusic ? [0, 1, 2, 3, 4, 0, 1, 2] : [...Array(countnumber).keys()]
        return i.map((item,id) => {
            return (<Preloader key={id} />)
        })
    }
    const location = useLocation()
    const noWrap = (link: String) => {
        if (location.pathname === link) {
            return "wrap"
        }
        else {
            return "nowrap"
        }
    }
     const [next5,setNext5]=useState(0)
     
    return (
        <div>
            
        <div id={`${props.allMusic ?"all-featured-artists":"featured-artists"}`}>
                <div className={`d-flex justify-content-between ${margin} `}>
                    <h3>{location.pathname==="/music/allfeatured"?"All Artist":"Trending artists"}</h3>
                    {!props.allMusic && !props.searchResult && (<ShowMoreComponent title="All Featured Artists" link="/music/allfeatured" />)}
                    {!props.allMusic && props.searchResult && (
              <div>
              <ShowMoreComponent
                title="All Featured Artists Results" link="/music/searchResult/artists" 
              />
            </div>
            )}
                </div>
                {props.loading && (
                        <div className={`row card-deck ${props.allMusic?'wrap':'nowrap'}`}>
                        {preloader()}
                    </div>)}
                    <div className='slider'>
                        {props.data && next5<0 &&(
                            <div className='previous-5 slider-icon' onClick={(e)=>{
                                e.preventDefault()
                                if(next5<0){
                                setNext5(next5+1015)}
                            }}><Previous/></div>
                        )}
                    <div className={`row card-deck ${props.allMusic?'wrap':'nowrap'}`} style={{transform:`translate3d(${next5}px,0px,0px)`}}>
                    {props.data && (props.data.map((artist: any,idx:number) => {
                        count++;
                        if (props.allMusic) {
                            count = 1
                        }
                        if (count) {
                            return (<FeaturedArtistElement
                                key={idx}
                                artistName={artist.username}
                                artistProfile={`${configuration.FILE_STORAGE_LINK}${artist.profileImg}`}
                                profileLink={`/music/artistMusics/${artist.userId}`}
                                songs={artist.songs}
                                allMusic={props.allMusic}
                            />)
                        }
                    }))}
                    {props.error &&(<LottieWrapper url="https://assets3.lottiefiles.com/datafiles/wqxpXEEPRQf1JnQ/data.json" width="calc(100% -  20px)" height="30vh" />) }
                </div>
                 {props.data&&(next5>-(props.data.length/5-1)*1000)&&(
                        <div className='next-5 slider-icon' onClick={(e)=>{
                            e.preventDefault()
                           
                            if(next5>-(props.data.length/5-1)*1000){
                            setNext5(next5-1015)}
                            if(next5>-1015){
                                if(!isUser){
                                dispatch(setShowPayWall(true))
                                }
                                }
                        }}> <Next/></div>
                    )}
            </div>
            </div>
            
        </div>
    )
}

export default FeaturedArtists
